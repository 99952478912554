import React from "react";
import styled from "styled-components";
import Colors from "themes/colors";
import { Paper, Stack } from "@mui/material";
import Typo from "components/atoms/Typo";
import directApplyIcon from "assets/icons/direct-apply4x.png";
import CompanyLogo from "../../atoms/CompanyLogo";
import Text from "components/atoms/Text";
import SkillTag from "components/atoms/SkillTag";
import ZigupSpinner from "../ZigupSpinner";

const JdCardWrapper = styled.div`
    width: 100%;
    padding: 12px;
    cursor: pointer;
    padding: 16px;
    box-sizing: border-box;
    &:hover {
        background-color: ${Colors.background_2};
    }
`;
const JobNameWrapper = styled.div`
    flex: 1;
`;
const JobCompanyName = styled(Text)`
    font-size: 12px;
    color: #6756ac;
    margin-bottom: 4px;
`;
const JobExtraInfo = styled(Text)`
    margin-bottom: 8px;
    font-size: 12px;
    color: #646464;
`;

const OfferCard = (props) => {
    const { offer } = props;

    const makeYearString = () => {
        const tmpFromYear = offer.jd.fromYear ?? 0;
        const tmpToYear = offer.jd.toYear ?? 0;
        const fromYear = tmpFromYear === null || undefined ? 0 : tmpFromYear;
        const toYear = tmpToYear === null || undefined ? 0 : tmpToYear;
        if (fromYear === 0 && toYear === 0) return "";
        return fromYear + "~" + toYear + "y";
    };

    return offer ? (
        <a
            href={`/jd/${offer.seoURL || offer.jdId}`}
            style={{
                textDecoration: "none",
                color: "black",
            }}
        >
            <Paper
                elevation={0}
                sx={{
                    width: "100%",
                    minHeight: "125px",
                    height: "100%",
                    borderWidth: 1,
                    borderColor: Colors.outline_2,
                    borderStyle: "solid",
                    borderRadius: "0.25rem",
                }}
            >
                <JdCardWrapper>
                    <Stack direction="column" spacing={2}>
                        {offer.jd.isCompanyHireDirectly ? (
                            <Stack
                                direction={"row"}
                                alignItems="center"
                                justifyContent="flex-start"
                                spacing={0.5}
                            >
                                <img
                                    src={directApplyIcon}
                                    alt="directApplyIcon"
                                    width="16px"
                                    height="16px"
                                />
                                <Text
                                    style={{
                                        fontFamily: "Roboto",
                                        fontWeight: 400,
                                        fontSize: "0.75rem",
                                        color: Colors.purple_blue,
                                    }}
                                >
                                    Direct hiring offers
                                </Text>
                            </Stack>
                        ) : null}
                        <Stack direction={"row"} spacing={2}>
                            <CompanyLogo
                                width={80}
                                height={80}
                                source={offer.jd.companyData.logoURL}
                            />
                            <Stack
                                justifyContent="space-between"
                                alignItems="flex-start"
                                sx={{
                                    marginBottom: "6px",
                                    display: "flex",
                                    flex: 1,
                                }}
                            >
                                <JobNameWrapper>
                                    <Typo.H4Regular
                                        style={{
                                            marginBottom: 4,
                                            marginTop: 0,
                                        }}
                                    >
                                        {offer.jd.title}
                                    </Typo.H4Regular>
                                </JobNameWrapper>
                                <JobCompanyName>
                                    {offer.jd.company}
                                </JobCompanyName>
                                <JobExtraInfo>
                                    {offer.jd.locations
                                        .slice(0, 3)
                                        .join(", ") ?? ""}{" "}
                                    | {makeYearString()}
                                </JobExtraInfo>
                                {offer.jd.customInfo?.role?.value &&
                                offer.jd.customInfo?.role?.value.length ? (
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        sx={{
                                            overflow: "hidden",
                                            flexWrap: "wrap",
                                            width: "100%",
                                            display: "flex",
                                        }}
                                    >
                                        {offer.jd.customInfo.role.value.map(
                                            (skill, index) => (
                                                <SkillTag
                                                    key={skill + index}
                                                    skill={skill}
                                                />
                                            )
                                        )}
                                    </Stack>
                                ) : null}
                            </Stack>
                        </Stack>
                    </Stack>
                </JdCardWrapper>
            </Paper>
        </a>
    ) : (
        <Paper
            elevation={0}
            sx={{
                width: "100%",
                minHeight: "145px",
                height: "100%",
                borderWidth: 1,
                borderColor: Colors.outline_2,
                borderStyle: "solid",
                borderRadius: "0.25rem",
            }}
        >
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ height: "100%", width: "100%" }}
            >
                <ZigupSpinner size={"2rem"} />
            </Stack>
        </Paper>
    );
};

export default OfferCard;
