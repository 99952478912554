import React from "react";
import Typo from "../../atoms/Typo";
import Stack from "@mui/material/Stack";
import Colors from "themes/colors";
import { useLocation } from "react-router-dom";
import QueryString from "qs";

const ForumCategory = ({ keyword }) => {
    const location = useLocation();
    const { type } = QueryString.parse(location.search, {
        ignoreQueryPrefix: true,
    });

    const pages = [
        {
            name: "All",
            url: keyword
                ? `/forum/list?type=all&keyword=${keyword}`
                : "/forum/list?type=all",
            key: "all",
        },
        {
            name: "Discussion",
            url: keyword
                ? `/forum/list?type=discussion&keyword=${keyword}`
                : "/forum/list?type=discussion",
            key: "discussion",
        },
        {
            name: "Referrals",
            url: keyword
                ? `/forum/list?type=LFR&keyword=${keyword}`
                : "/forum/list?type=LFR",
            key: "LFR",
        },
        {
            name: "Candidates",
            url: keyword
                ? `/forum/list?type=LFC&keyword=${keyword}`
                : "/forum/list?type=LFC",
            key: "LFC",
        },
    ];

    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            style={{
                minHeight: "1rem",
                height: "max-content",
            }}
        >
            {pages.map((page, index) => {
                const isCurrent = type === page.key;

                const TextComponent = isCurrent ? Typo.Body2Bold : Typo.Body2;
                return (
                    <Stack key={index}>
                        <a
                            key={page.name}
                            href={page.url}
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <TextComponent
                                style={{
                                    fontSize: "1.25rem",
                                    fontFamily: "Lato",
                                    fontWeight: 400,
                                    color: isCurrent
                                        ? Colors.header_black
                                        : Colors.text_grey,
                                    height: "max-content",
                                }}
                            >
                                {page.name}
                            </TextComponent>
                        </a>
                    </Stack>
                );
            })}
        </Stack>
    );
};
export default ForumCategory;
