import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Text from "components/atoms/Text";
import { useMediaQuery } from "react-responsive";
import Stack from "@mui/material/Stack";
import Colors from "themes/colors";
import styled from "styled-components";
import { ALERT_TYPE, EXPERIENCE_TYPE } from "../../../constants/constants";
import { Container, InputAdornment } from "@mui/material";
import NormalTextField from "components/atoms/NormalTextField";
import InputMask from "react-input-mask";
import { isEmail, isLinkedinLink, isRequired } from "utils/validator";
import ZigupButton from "../../atoms/ZigupButton";
import Typo from "../../atoms/Typo";
import NoticePeriodSelector from "../../organisms/NoticePeriodSelector";
import NoticeUpdateInformation from "../../atoms/NoticeUpdateInformation";
import NavigationGuard from "../../organisms/NavigationGuard";
import { useStateCallback } from "../../atoms/useStateCallback";
import { setMyInformation } from "../../../communication/membership";
import ZigupSpinner from "./../../organisms/ZigupSpinner";
import { AlertContext } from "context/AlertContext";
import { Logger } from "utils";
import moment from "moment";

const ExperienceButton = styled(Stack)`
    width: 50%;
    max-width: 50%;
    height: auto;
    min-height: 100px;
    max-height: 300px;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border: ${({ selected }) =>
        selected ? "1px solid #6837f2" : "1px solid #dedede"};
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex: 1;
`;
const ExperienceButtonTitle = styled(Text)`
    margin-vertical: 24px;
    font-size: 20px;
    font-weight: 700;
    color: ${(props) => (props.selected ? "#5e14e9" : "#646464")};
`;

const ExperienceButtonDesc = styled(Text)`
    color: ${(props) => (props.selected ? "#5e14e9" : "#646464")};
    text-align: center;
`;

const EditBasicInformationPage = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const navigate = useNavigate();
    const { state } = useLocation();
    const { openAlert } = useContext(AlertContext);

    const [activeGuard, setActiveGuard] = useStateCallback(true);
    const [mobile, setMobile] = useState(state.userInfo.mobile);
    const [isValid, setIsValid] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState(state.userInfo.name);
    const [anonymousName, setAnonymousName] = useState(
        state.userInfo.anonymousName
    );
    const [isExperienced, setIsExperienced] = useState(
        state.userInfo.isExperienced
    );
    const [linkedinLink, setLinkedinLink] = useState(
        state.userInfo.linkedinLink
    );
    const [email, setEmail] = useState(state.userInfo.email);
    const [currentSalary, setCurrentSalary] = useState(
        state.userInfo.currentSalary
    );
    const [desiredSalary, setDesiredSalary] = useState(
        state.userInfo.desiredSalary
    );
    const [noticePeriod, setNoticePeriod] = useState(
        state.userInfo.noticePeriod
    );
    const [isLastWorkingDayChecked, setIsLastWorkingDayChecked] = useState(
        !!state.userInfo.lastWorkingDay
    );
    const [dateOfLastWorkingDay, setDateOfLastWorkingDay] = useState(
        state.userInfo.lastWorkingDay
            ? moment(state.userInfo.lastWorkingDay, "DD/MM/YYYY")
            : moment(null, "DD/MM/YYYY")
    );

    const [pageText, setPageText] = useState({
        title: "My basic information",
        fresher: { title: "Fresher", desc: "Students, Graduates and Interns" },
        experienced: { title: "Experienced" },
    });

    useEffect(() => {
        // ReactGA.event({
        //     category: "SignUp",
        //     action: "sign_up_first_page_start",
        //     label: "sign_up",
        // });
    }, [state.userInfo]);

    useEffect(() => {
        const check =
            isRequired(name).valid &&
            isRequired(anonymousName).valid &&
            currentSalary > 0 &&
            desiredSalary > 0 &&
            isLinkedinLink(linkedinLink).valid &&
            isRequired(noticePeriod).valid &&
            (isLastWorkingDayChecked ? !!dateOfLastWorkingDay?._isValid : true);
        setIsValid(check);
        return () => {
            setIsValid(false);
        };
    }, [
        isExperienced,
        name,
        linkedinLink,
        anonymousName,
        currentSalary,
        desiredSalary,
        noticePeriod,
    ]);

    const save = async () => {
        if (isValid) {
            setActiveGuard(false, async () => {
                const userInfo = {
                    name,
                    anonymousName,
                    isExperienced,
                    currentSalary,
                    desiredSalary,
                    noticePeriod,
                };
                if (dateOfLastWorkingDay) {
                    userInfo.lastWorkingDay =
                        dateOfLastWorkingDay.format("DD/MM/YYYY");
                }
                if (email?.length) {
                    userInfo.email = email;
                }
                if (linkedinLink) {
                    userInfo.linkedinLink = linkedinLink;
                }
                try {
                    setIsLoading(true);
                    const res = await setMyInformation(userInfo);
                    if (res) {
                        window.location.href = "/my/profile";
                    }
                } catch (e) {
                    openAlert({
                        showAlert: true,
                        text: "Failed to change data",
                        alertType: ALERT_TYPE.ERROR,
                    });
                    Logger.error(e);
                } finally {
                    setIsLoading(false);
                }
            });
        }
    };

    return (
        <Container maxWidth="md" sx={{ minHeight: "60vh" }}>
            <NavigationGuard when={activeGuard} />
            {isLoading ? (
                <div
                    style={{
                        position: "fixed",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "rgba(0, 0, 0, 0.2)",
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 20,
                    }}
                >
                    <ZigupSpinner size="2rem" />
                </div>
            ) : null}
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                    marginTop: "2rem",
                    marginBottom: "2rem",
                    width: "100%",
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ width: "100%" }}
                    spacing={1}
                >
                    <Typo.Button1
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/my/profile")}
                    >
                        My profile
                    </Typo.Button1>
                    <Typo.Button1>/</Typo.Button1>
                    <Typo.Button1>{pageText.title}</Typo.Button1>
                </Stack>
                <Stack
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{ width: "100%" }}
                >
                    <Text
                        style={{
                            fontSize: "2rem",
                            lineHeight: "3rem",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                        }}
                    >
                        {pageText.title}
                    </Text>
                </Stack>
                <Stack style={{ width: "100%" }}>
                    <NoticeUpdateInformation />
                </Stack>
                <Stack
                    direction={"row"}
                    justifyContent="space-around"
                    alignItems="flex-start"
                    style={{
                        width: "100%",
                        height: "max-content",
                    }}
                    margin={2}
                >
                    <ExperienceButton
                        mobile={isMobile ? 1 : 0}
                        selected={isExperienced === EXPERIENCE_TYPE.FRESHER}
                        onClick={() =>
                            setIsExperienced(EXPERIENCE_TYPE.FRESHER)
                        }
                    >
                        <ExperienceButtonTitle
                            selected={isExperienced === EXPERIENCE_TYPE.FRESHER}
                        >
                            {pageText.fresher.title}
                        </ExperienceButtonTitle>
                        <ExperienceButtonDesc
                            selected={isExperienced === EXPERIENCE_TYPE.FRESHER}
                        >
                            {pageText.fresher.desc}
                        </ExperienceButtonDesc>
                    </ExperienceButton>
                    <div style={{ width: 16 }} />
                    <ExperienceButton
                        mobile={isMobile ? 1 : 0}
                        selected={isExperienced === EXPERIENCE_TYPE.EXPERIENCED}
                        onClick={() =>
                            setIsExperienced(EXPERIENCE_TYPE.EXPERIENCED)
                        }
                    >
                        <ExperienceButtonTitle
                            selected={
                                isExperienced === EXPERIENCE_TYPE.EXPERIENCED
                            }
                        >
                            {pageText.experienced.title}
                        </ExperienceButtonTitle>
                    </ExperienceButton>
                </Stack>
                <NormalTextField
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    title="Name"
                    placeholder="Your full name"
                />
                <InputMask
                    mask={
                        mobile.length === 11 ? "999-9999-9999" : "99999-99999"
                    }
                    value={mobile}
                    disabled={true}
                    onChange={(event) => setMobile(event.target.value)}
                    maskChar=""
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Stack
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Text
                                        style={{
                                            color: "gray",
                                            fontSize: 14,
                                        }}
                                    >
                                        +91
                                    </Text>
                                </Stack>
                            </InputAdornment>
                        ),
                    }}
                >
                    {(inputProps) => (
                        <NormalTextField
                            {...inputProps}
                            title="Mobile"
                            fullWidth
                            placeholder="e.g. 9999999999"
                            containerStyle={{ marginBottom: "1rem" }}
                            disabled={true}
                        />
                    )}
                </InputMask>
                <NormalTextField
                    value={anonymousName}
                    onChange={(event) => setAnonymousName(event.target.value)}
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    title="Anonymous name"
                    placeholder="Anonymous name"
                />
                <NormalTextField
                    value={linkedinLink || ""}
                    onChange={(event) => setLinkedinLink(event.target.value)}
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    title="Linkedin profile (Optional)"
                    placeholder="Linkedin profile URL"
                    alertMessage={
                        isLinkedinLink(linkedinLink).valid
                            ? ""
                            : isLinkedinLink(linkedinLink).error
                    }
                    alertMessageColor="red"
                />
                <NormalTextField
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    title="eMail address(Optional)"
                    placeholder="e.g. abc@gmail.com"
                    alertMessage={
                        isEmail(email).valid ? "" : isEmail(email).error
                    }
                    alertMessageColor="red"
                />
                <NormalTextField
                    value={currentSalary}
                    onChange={(event) => setCurrentSalary(event.target.value)}
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    title="Current salary"
                    placeholder="e.g. 10"
                    type="number"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <Text
                                    style={{
                                        color: Colors.purple_blue,
                                    }}
                                >
                                    Lakh per Annum
                                </Text>
                            </InputAdornment>
                        ),
                    }}
                />
                <NormalTextField
                    value={desiredSalary}
                    onChange={(event) => setDesiredSalary(event.target.value)}
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    title="Expected salary"
                    placeholder="e.g. 15"
                    type="number"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <Text
                                    style={{
                                        color: Colors.purple_blue,
                                    }}
                                >
                                    Lakh per Annum
                                </Text>
                            </InputAdornment>
                        ),
                    }}
                />
                <NoticePeriodSelector
                    id="edit-profile-notice-period-selector"
                    noticePeriod={noticePeriod}
                    setNoticePeriod={setNoticePeriod}
                    isLastWorkingDayChecked={isLastWorkingDayChecked}
                    setIsLastWorkingDayChecked={setIsLastWorkingDayChecked}
                    dateOfLastWorkingDay={dateOfLastWorkingDay}
                    setDateOfLastWorkingDay={setDateOfLastWorkingDay}
                />
                <ZigupButton
                    buttonText={"SAVE CHANGES"}
                    id={"my-profile-edit-basic-save-change-btn"}
                    disabled={!isValid || isLoading}
                    onClickHandler={save}
                    isLoading={isLoading}
                    buttonTextSize="1rem"
                    containerStyle={{ height: "4rem", marginTop: "1rem" }}
                />
            </Stack>
        </Container>
    );
};
export default EditBasicInformationPage;
