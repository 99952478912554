import Stack from "@mui/material/Stack";
import Typo from "./Typo";
import React from "react";
import Colors from "themes/colors";
import saveDiskIcon from "assets/icons/save.png";
const NoticeUpdateInformation = () => {
    return (
        <Stack
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
            style={{
                width: "100%",
                height: "3rem",
                backgroundColor: Colors.direct_hiring_background,
            }}
        >
            <Stack
                direction={"row"}
                justifyContent="center"
                alignItems="center"
                padding={2}
                spacing={2}
            >
                <Stack
                    style={{
                        width: "1.5rem",
                        height: "1.5rem",
                    }}
                >
                    <img
                        src={saveDiskIcon}
                        alt="saveIcon"
                        width="100%"
                        height="100%"
                    />
                </Stack>
                <Typo.Body2
                    style={{
                        color: Colors.jd_text_grey,
                        flex: 1,
                    }}
                >
                    These information will be automatically saved and loaded
                    when logged in, each time you apply
                </Typo.Body2>
            </Stack>
        </Stack>
    );
};
export default NoticeUpdateInformation;
