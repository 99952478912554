import React, { useContext, useEffect, useState } from "react";
import Text from "components/atoms/Text";
import Stack from "@mui/material/Stack";
import NormalTextField from "../../atoms/NormalTextField";
import { Collapse, Container, Divider, InputAdornment } from "@mui/material";
import ZigupButton from "../../atoms/ZigupButton";
import { isRequired } from "../../../utils/validator";
import Colors from "themes/colors";
import SkillSetAutocomplete from "../../organisms/SkillSetAutocomplete";
import { useMediaQuery } from "react-responsive";
import { postSalary } from "../../../communication/salary";
import { useNavigate } from "react-router-dom";
import CompanyAutocomplete from "../../organisms/CompanyAutoComplete";
import moment from "moment";
import Typo from "components/atoms/Typo";
import NormalDatePicker from "components/atoms/NormalDatePicker";
import { Logger } from "utils";
import TechnicalAreaSelector from "components/organisms/TechnicalAreaSelector";
import Modal from "react-modal";
import { AuthContext } from "../../../context/AuthContext";
import ReactGA from "react-ga4";
import GlobalHeader from "components/organisms/GlobalHeader";
import Footer from "components/organisms/Footer";

const AddSalaryPage = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const { userState, checkAuth } = useContext(AuthContext);
    const [company, setCompany] = useState();
    const [dateOfOffer, setDateOfOffer] = useState(moment(null, "DD/MM/YYYY"));
    const [yearsOfExperience, setYearsOfExperience] = useState();
    const [title, setTitle] = useState();
    const [location, setLocation] = useState();
    const [salary, setSalary] = useState();
    const [bonus, setBonus] = useState();
    const [stock, setStock] = useState();
    const [totalSalaries, setTotalSalaries] = useState(0);
    const [hike, setHike] = useState();
    const [techArea, setTechArea] = useState({
        area: { value: "" },
        role: { value: [] },
    });
    const [techAreaOthersRole, setTechAreaOthersRole] = useState("");
    const [role, setRole] = useState();
    const [skillset, setSkillset] = useState([]);
    const [education, setEducation] = useState();
    const [previousCompany, setPreviousCompany] = useState();
    const [additionalInformation, setAdditionalInformation] = useState();
    const [route, setRoute] = useState("");
    const [interviewRounds, setInterviewRounds] = useState("");
    const [preparations, setPreparations] = useState("");
    const [questions, setQuestions] = useState("");
    const [workCulture, setWorkCulture] = useState("");
    const [negotiation, setNegotiation] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [shareMore, setShareMore] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const additionalInfos = [
        {
            question: "How did you apply for the job? (Optional)",
            placeholder: "e.g. Through Linkedin, Naukri, Referral from someone",
            multiline: false,
            rows: 1,
            value: route,
            setValue: setRoute,
        },
        {
            question: "What were the interview rounds you had? (Optional)",
            placeholder:
                "e.g. 3(Phone screen => Assignment => Technical interview",
            multiline: false,
            rows: 1,
            value: interviewRounds,
            setValue: setInterviewRounds,
        },
        {
            question:
                "What were your preparations to get this offer? (Optional)",
            placeholder:
                "e.g. Cracked 100 LC before phone screen, Studied Node.js as this was must-have tech stack, Made a github account and contributed everyday",
            multiline: true,
            rows: 6,
            value: preparations,
            setValue: setPreparations,
        },
        {
            question:
                "What kind of questions were you asked in the interview? (Optional)",
            placeholder:
                "e.g. Mainly DS & Algo. Some of questions include linked list...",
            multiline: true,
            rows: 6,
            value: questions,
            setValue: setQuestions,
        },
        {
            question:
                "If you have joined, how is actual work & company like? (Optional)",
            placeholder:
                "e.g. Work culture, engineering process, daily routine, Pros & Cons",
            multiline: true,
            rows: 6,
            value: workCulture,
            setValue: setWorkCulture,
        },
        {
            question: "How was your salary negotiation process? (Optional)",
            placeholder:
                "e.g. I had 2 different offers in my hand, negotiated iwth those and got 40% raise from the initial offer",
            multiline: true,
            rows: 6,
            value: negotiation,
            setValue: setNegotiation,
        },
    ];

    const customModalStyles = {
        content: {
            width: "80vw",
            maxWidth: "400px",
            height: "20vh",
            minHeight: "250px",
            maxHeight: "480px",
            backgroundColor: "white",
            border: "0",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            boxSizing: "border-box",
            paddingLeft: "2rem",
            paddingRight: "2rem",
        },
        overlay: {
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!userState.auth) navigate("/");
        ReactGA.event({
            category: "Salary",
            action: "add_salary_started",
            label: "salary",
        });
    }, [userState.auth]);

    useEffect(() => {
        setTotalSalaries(
            (salary ? 1 * salary : 0) +
                (bonus ? 1 * bonus : 0) +
                (stock ? 1 * stock : 0)
        );
    }, [salary, bonus, stock]);

    useEffect(() => {
        const check =
            isRequired(company).valid &&
            dateOfOffer.isValid() &&
            isRequired(yearsOfExperience).valid &&
            isRequired(title).valid &&
            isRequired(salary).valid &&
            isRequired(bonus).valid &&
            isRequired(stock).valid &&
            isRequired(hike).valid &&
            (techArea?.area?.value === "others"
                ? techAreaOthersRole
                : techArea?.role?.value.length);
        setIsValid(check);
        return () => {
            setIsValid(false);
        };
    }, [
        company,
        dateOfOffer,
        yearsOfExperience,
        title,
        salary,
        bonus,
        stock,
        hike,
        techArea,
        techAreaOthersRole,
    ]);

    const handleSubmit = () => {
        // if (!isAuthenticated) showSignUpModal();
        if (!shareMore) {
            setIsOpen(true);
            return;
        }
        doSubmitSalary();
    };

    const doSubmitSalary = async () => {
        const params = {
            company,
            dateOfOffer: dateOfOffer.format("MM/YYYY"),
            dateOfOfferTimestamp: dateOfOffer.unix() * 1000,
            yearsOfExperience,
            title,
            salary: "" + salary,
            bonus: "" + bonus,
            stock: "" + stock,
            totalCompensations: "" + totalSalaries,
            role:
                techArea?.area?.value === "others"
                    ? techAreaOthersRole
                    : techArea?.role?.value[0],
            hike,
            skillset,
            education,
            previousCompany,
            additionalInformation,
        };

        if (route) params.route = route;
        if (interviewRounds) params.interviewRounds = interviewRounds;
        if (preparations) params.preparations = preparations;
        if (questions) params.questions = questions;
        if (workCulture) params.culture = workCulture;
        if (negotiation) params.negotiation = negotiation;

        setIsLoading(true);
        try {
            const res = await postSalary(params);

            if (res.salary?._id) {
                ReactGA.event({
                    category: "Salary",
                    action: "salary_add_complete",
                    label: "salary",
                });
                navigate(`/salary/${res.salary._id}`);
            }
        } catch (e) {
            Logger.error("ERROR", e);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <GlobalHeader />
            <Container
                maxWidth="sm"
                sx={{ minHeight: "60vh", padding: "2rem 0rem" }}
            >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <Stack
                        justifyContent="center"
                        alignItems="flex-start"
                        style={{ width: "100%" }}
                    >
                        <Typo.H1 style={{ fontSize: 24 }}>Share salary</Typo.H1>
                    </Stack>
                    <CompanyAutocomplete
                        title="Company"
                        value={company}
                        setValue={setCompany}
                        isMobile={isMobile}
                        style={{ marginBottom: 0 }}
                    />
                    <NormalTextField
                        value={location || ""}
                        onChange={(event) => setLocation(event.target.value)}
                        fullWidth
                        title="Location"
                        placeholder="e. g. Bangalore, WFH was also available"
                    />
                    <NormalDatePicker
                        title="Date of Offer"
                        views={["year", "month"]}
                        value={dateOfOffer}
                        setValue={setDateOfOffer}
                        isMobile={isMobile}
                        disableFuture
                    />
                    <NormalTextField
                        value={yearsOfExperience || ""}
                        onChange={(event) =>
                            setYearsOfExperience(event.target.value)
                        }
                        fullWidth
                        title="Years of experience"
                        placeholder="e.g. 5"
                        type="number"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <Text style={{ color: Colors.purple_blue }}>
                                        Year(s)
                                    </Text>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <NormalTextField
                        value={title || ""}
                        onChange={(event) => setTitle(event.target.value)}
                        fullWidth
                        title="Title"
                        placeholder="e. g. Sofware Development Engineer 1"
                    />
                    <NormalTextField
                        value={salary || ""}
                        onChange={(event) => setSalary(event.target.value)}
                        fullWidth
                        title="Salary"
                        placeholder="e.g. 25"
                        type="number"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <Text style={{ color: Colors.purple_blue }}>
                                        Lakh per Annum
                                    </Text>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <NormalTextField
                        value={bonus || ""}
                        onChange={(event) => setBonus(event.target.value)}
                        fullWidth
                        title="Bonus"
                        placeholder="e.g. 5"
                        type="number"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <Text style={{ color: Colors.purple_blue }}>
                                        Lakh per Annum
                                    </Text>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <NormalTextField
                        value={stock || ""}
                        onChange={(event) => setStock(event.target.value)}
                        fullWidth
                        title="Stock"
                        placeholder="e.g. 20"
                        type="number"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <Text style={{ color: Colors.purple_blue }}>
                                        Lakh per Annum
                                    </Text>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <NormalTextField
                        value={totalSalaries || ""}
                        onChange={(event) =>
                            setTotalSalaries(event.target.value)
                        }
                        fullWidth
                        title="Total salary"
                        placeholder="e.g. 50"
                        type="number"
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position="start">
                                    <Text style={{ color: Colors.purple_blue }}>
                                        Lakh per Annum
                                    </Text>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <NormalTextField
                        value={hike || ""}
                        onChange={(event) => setHike(event.target.value)}
                        fullWidth
                        title="Hike"
                        placeholder="e.g. 10"
                        type="number"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <Text style={{ color: Colors.purple_blue }}>
                                        %
                                    </Text>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TechnicalAreaSelector
                        isMobile={isMobile}
                        techArea={techArea}
                        setTechArea={setTechArea}
                        techAreaOthersRole={techAreaOthersRole}
                        setTechAreaOthersRole={setTechAreaOthersRole}
                        style={{ marginBottom: 0 }}
                        title="Role"
                    />
                    <SkillSetAutocomplete
                        value={skillset}
                        setValue={setSkillset}
                        isMobile={isMobile}
                        title="Required Skillsets"
                        style={{ marginBottom: 0 }}
                    />
                    <NormalTextField
                        value={education || ""}
                        onChange={(event) => setEducation(event.target.value)}
                        fullWidth
                        title="Education (Optional)"
                        placeholder="Your education"
                    />
                    <NormalTextField
                        value={previousCompany || ""}
                        onChange={(event) =>
                            setPreviousCompany(event.target.value)
                        }
                        fullWidth
                        title="Previous company (Optional)"
                        placeholder="e.g. amazon"
                    />
                    <NormalTextField
                        value={additionalInformation || ""}
                        onChange={(event) =>
                            setAdditionalInformation(event.target.value)
                        }
                        fullWidth
                        title="Additional information (Optional)"
                        placeholder="e.g. Benefits, Interview tips, etc"
                        multiline
                        rows={6}
                    />
                    <Divider />
                    <div style={{ height: "1rem" }} />
                    {shareMore ? null : (
                        <ZigupButton
                            buttonText="Share More Information"
                            onClickHandler={() => {
                                setShareMore(true);
                            }}
                            containerStyle={{
                                height: "2rem",
                                marginTop: "1rem",
                            }}
                        />
                    )}
                    <Collapse in={shareMore} style={{ width: "100%" }}>
                        <Stack
                            justifyContent="center"
                            alignItems="flex-start"
                            style={{ width: "100%" }}
                        >
                            <Typo.H1 style={{ fontSize: 24 }}>
                                Additional Information
                            </Typo.H1>
                        </Stack>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                        >
                            {additionalInfos.map((additional) => (
                                <NormalTextField
                                    key={additional.question}
                                    value={additional.value || ""}
                                    onChange={(event) =>
                                        additional.setValue(event.target.value)
                                    }
                                    fullWidth
                                    title={additional.question}
                                    placeholder={additional.placeholder}
                                    multiline={additional.multiline}
                                    rows={additional.rows}
                                />
                            ))}
                        </Stack>
                    </Collapse>
                    <ZigupButton
                        id="add-salary-btn"
                        onClickHandler={handleSubmit}
                        disabled={isLoading || !isValid}
                        containerStyle={{
                            height: "3rem",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                        }}
                        buttonText={"Submit"}
                        isLoading={isLoading}
                    />
                </Stack>
                <Modal
                    isOpen={isOpen}
                    style={customModalStyles}
                    onRequestClose={() => {
                        setIsOpen(false);
                    }}
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEsc
                    ariaHideApp={false}
                >
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <Typo.H3>Share extra data!</Typo.H3>
                        <Typo.Body2 style={{ textAlign: "center" }}>
                            Share how you received the offer from the company,
                            how you prepared, and interview tips
                        </Typo.Body2>
                        <div style={{ height: "2rem" }} />
                        <ZigupButton
                            id="load-additional-information-btn"
                            onClickHandler={() => {
                                setShareMore(true);
                                setIsOpen(false);
                            }}
                            buttonText={"YES I WILL SHARE"}
                        />
                        <Typo.Body2
                            style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                                fontSize: 12,
                            }}
                            onClick={() => {
                                setIsOpen(false);
                                doSubmitSalary();
                            }}
                        >
                            Not now
                        </Typo.Body2>
                    </Stack>
                </Modal>
            </Container>
            <Footer />
        </>
    );
};
export default AddSalaryPage;
