import React from "react";
import styled from "styled-components";
import { ROLE_TEXT_ARR } from "constants/constants";
import Typo from "./Typo";
import { Skeleton } from "@mui/material";

const SkillContainer = styled.div`
    align-items: center;
    justify-content: center;
    background-color: #f2f1f3;
    padding: 4px 8px;
    min-width: 40px;
    height: 24px;
    margin-right: 4px;
    margin-bottom: 4px;
    border-radius: 4px;
    display: flex;
    box-sizing: border-box;
`;

const SkillTag = ({ skill }) => {
    const getText = (_skill) => {
        for (const role of ROLE_TEXT_ARR) {
            if (role.key === _skill) return role.name;
        }
        return _skill;
    };

    return skill ? (
        <SkillContainer>
            <Typo.Body1 style={{ fontSize: 12 }}>{getText(skill)}</Typo.Body1>
        </SkillContainer>
    ) : (
        <Skeleton variant="rectangular" height={24} width={40} />
    );
};

export default SkillTag;
