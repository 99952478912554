import axios from "axios";
import { Logger } from "utils";
import * as Api from "./api";

export function RetryAxiosInstance() {
    const axiosInstance = axios.create();
    const MAX_RETRY = 5;
    const DEFAULT_PERIOD = 3000;
    let currentRetry = 0;

    const getPeriod = (retryCount) => {
        return retryCount * DEFAULT_PERIOD;
    };

    const onRequest = (config) => {
        const {
            method,
            url,
            data,
            uploadProgressCallback,
            fileDownload,
            headers,
        } = config;

        if (!config.retry)
            Logger.log(
                "axios ======================>",
                method,
                url,
                data ?? ""
            );
        config = {
            ...config,
            timeout:
                headers["Content-Type"] === "multipart/form-data"
                    ? 30000
                    : 10000,
            responseType: fileDownload ? "blob" : "json",
            withCredentials: true,
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                // Logger.log(
                //     "onUploadProgress",
                //     `${((loaded / total) * 100).toFixed(0)}%`
                // );
                if (uploadProgressCallback !== undefined) {
                    uploadProgressCallback.onUploadProgress(progressEvent);
                }
            },
        };
        return config;
    };

    const onResponseSuccess = (response) => {
        const { url } = response.config;
        const { status } = response;
        Logger.log("axios <======================", status, url, response.data);
        return response;
    };

    const checkLoginApi = (url) => {
        switch (url) {
            case makeFullUrl(Api.Urls.login.login):
                return true;
            default:
                return false;
        }
    };

    const checkRetryApi = (url) => {
        switch (url) {
            case makeFullUrl(Api.Urls.log.saveUserLog):
                return true;
            default:
                return false;
        }
    };

    const makeFullUrl = (_url) => {
        const endpoint = Api.Urls.prefix + _url;
        return process.env.REACT_APP_API_HOST + endpoint;
    };

    const onResponseRejected = (error) => {
        Logger.log(
            "axios error",
            error.config?.url,
            error,
            error.response?.data ?? ""
        );
        if (error.config) {
            // if (checkRetryApi(error.config.url)) {
            //     return retry(error.config);
            // }
        }
        return Promise.reject(error);
    };

    const retry = (config) => {
        const errorConfig = {
            ...config,
            retry: true,
        };
        return new Promise((resolve) => {
            if (currentRetry < MAX_RETRY) {
                // eslint-disable-next-line no-plusplus
                currentRetry += 1;
                setTimeout(() => {
                    Logger.log(
                        `axios retry[${currentRetry}]`,
                        errorConfig?.method,
                        errorConfig?.url
                    );
                    resolve(axiosInstance.request(errorConfig));
                }, getPeriod(currentRetry));
            } else {
                Logger.log(
                    "Retried 5 times but still failed",
                    errorConfig?.url
                );
                resolve(errorConfig);
            }
        });
    };

    axiosInstance.defaults.timeout = 10000;
    axiosInstance.defaults.withCredentials = true;
    axiosInstance.interceptors.request.use(onRequest);
    axiosInstance.interceptors.response.use(
        onResponseSuccess,
        onResponseRejected
    );
    return axiosInstance;
}
