import React from "react";
import { Route, Routes } from "react-router-dom";
import MainPage from "components/pages/MainPage";
import IntroPage from "components/pages/IntroPage";
import AuthRoute from "components/pages/AuthRoute";
import SignUpPage from "components/pages/signUp/SignUpPage";
import SignUpFirstPage from "components/pages/signUp/SignUpFirstPage";
import SignUpSecondPage from "components/pages/signUp/SignUpSecondPage";
import NotFoundPage from "components/pages/NotFoundPage";
import SalaryPage from "components/pages/salary/SalaryPage";
import SalaryListPage from "components/pages/salary/SalaryListPage";
import SalaryDetailPage from "components/pages/salary/SalaryDetailPage";
import JdDetailPage from "components/pages/jd/JdDetailPage";
import JdApplyPage from "components/pages/jd/JdApplyPage";
import JdDonePage from "components/pages/jd/JdDonePage";
import JdListPage from "components/pages/jd/JdListPage";
import JdPage from "components/pages/jd/JdPage";
import ReferralDetailPage from "components/pages/referral/ReferralDetailPage";
import ReferralApplyPage from "components/pages/referral/ReferralApplyPage";
import ReferralDonePage from "components/pages/referral/ReferralDonePage";
import OfferPage from "components/pages/offer/OfferPage";
import OfferListPage from "components/pages/offer/OfferListPage";
import { Helmet } from "react-helmet-async";
import SearchPage from "components/pages/search/SearchPage";
import { LoadingProvider } from "context/LoadingContext";
import { AlertProvider } from "context/AlertContext";
import AddSalaryPage from "./components/pages/salary/AddSalaryPage";
import SignUpDonePage from "components/pages/signUp/SignUpDonePage";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AuthProvider } from "context/AuthContext";
import Background from "components/atoms/Background";
import RedirectCompensation from "./components/pages/salary/RedirectCompensation";
import RouteChangeTracker from "utils/routeChangeTracker";
import ForumPage from "./components/pages/forum/ForumPage";
import ForumListPage from "./components/pages/forum/ForumListPage";
import ForumDiscussionDetailPage from "components/pages/forum/ForumDiscussionDetailPage";
import ForumLFRDetailPage from "components/pages/forum/ForumLFRDetailPage";
import ForumLFCDetailPage from "components/pages/forum/ForumLFCDetailPage";
import RedirectReferral from "./components/pages/forum/RedirectReferral";
import TmpOfferListPage from "./components/pages/offer/TmpOfferListPage";
import FaqPage from "components/pages/FaqPage";
import MyPage from "./components/pages/my/MyPage";
import MyProfilePage from "./components/pages/my/MyProfilePage";
import EditBasicInformationPage from "./components/pages/my/EditBasicInformationPage";
import EditWorkExperiencePage from "./components/pages/my/EditWorkExperiencePage";
import EditTechAreaPage from "./components/pages/my/EditTechAreaPage";
import EditPreferencePage from "./components/pages/my/EditPreferencePage";
import EditEducationPage from "./components/pages/my/EditEducationPage";
import ManageCVPage from "./components/pages/my/ManageCVPage";
import ContactUsPage from "components/pages/ContactUsPage";
import CompanyListPage from "components/pages/company/CompanyListPage";
import CompanyPage from "components/pages/company/CompanyPage";
import CompanyDetailPage from "components/pages/company/CompanyDetailPage";

const App = () => {
    RouteChangeTracker();
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <LoadingProvider>
                <AlertProvider>
                    <Helmet>
                        <title>
                            Salary Breakup, Referral, Community - Zigup
                        </title>
                        <meta
                            name="description"
                            property="description"
                            content="Zigup is a anonymous career platform that allows you to apply for jobs at top product-based companies, share and explore information about salary breakups, job referrals, and interviews to help you advance your career."
                        />
                        <meta
                            name="keywords"
                            property="keywords"
                            content="PBC, PBCs, MNC, MNCs, Product based company, salary, breakup, employee referral, referral, career, opportunities, opportunity, jobs, chat, india,"
                        />
                        <meta content="Zigup" property="og:title" />
                        <meta content="https://zigup.in" property="og:url" />
                        <meta
                            content="https://hire.zigup.in/static/media/logo-horizontal.4663103fd0a4bca8ea17.png"
                            property="og:image"
                        />
                        <meta
                            content="Irreplaceable tech community where you can share everything related with your career"
                            property="og:description"
                        />
                        <meta
                            name="title"
                            property="og:title"
                            content="Referral, Salary Breakup, Community - Zigup"
                        />
                    </Helmet>
                    <AuthProvider>
                        <Background>
                            <Routes>
                                <Route path="/intro" element={<IntroPage />} />
                                <Route path="/sign-up" element={<SignUpPage />}>
                                    <Route
                                        path="basic"
                                        element={<SignUpFirstPage />}
                                    />
                                    <Route
                                        path="additional"
                                        element={<SignUpSecondPage />}
                                    />
                                </Route>
                                <Route element={<AuthRoute />}>
                                    <Route path="/" element={<MainPage />} />
                                    <Route
                                        path="/welcome"
                                        element={<SignUpDonePage />}
                                    />
                                    <Route
                                        path="/search"
                                        element={<SearchPage />}
                                    />
                                    <Route path="/faq" element={<FaqPage />} />
                                    <Route
                                        path="/contact-us"
                                        element={<ContactUsPage />}
                                    />
                                    <Route
                                        path="/salary/list"
                                        element={<SalaryListPage />}
                                    />
                                    <Route
                                        path="/salary/add"
                                        element={<AddSalaryPage />}
                                    />
                                    <Route
                                        path="/salary"
                                        element={<SalaryPage />}
                                    >
                                        <Route
                                            path=":id"
                                            element={<SalaryDetailPage />}
                                        />
                                    </Route>
                                    <Route
                                        path="/compensation"
                                        element={<RedirectCompensation />}
                                    >
                                        <Route
                                            path=":id"
                                            element={<RedirectCompensation />}
                                        />
                                    </Route>
                                    <Route
                                        path="/company/list"
                                        element={<CompanyListPage />}
                                    />
                                    <Route
                                        path="/company"
                                        element={<CompanyPage />}
                                    >
                                        <Route
                                            path=":id"
                                            element={<CompanyDetailPage />}
                                        />
                                    </Route>
                                    <Route
                                        path="/jd/list"
                                        element={<JdListPage />}
                                    />
                                    <Route path="/jd" element={<JdPage />}>
                                        <Route
                                            path=":id"
                                            element={<JdDetailPage />}
                                        />
                                        <Route
                                            path=":id/apply"
                                            element={<JdApplyPage />}
                                        />
                                        <Route
                                            path=":id/done"
                                            element={<JdDonePage />}
                                        />
                                    </Route>
                                    <Route
                                        path="/offer"
                                        element={<OfferPage />}
                                    >
                                        <Route
                                            path="list"
                                            element={<OfferListPage />}
                                        />
                                        <Route
                                            path=":id"
                                            element={<ReferralDetailPage />}
                                        />
                                        <Route
                                            path=":id/apply"
                                            element={<ReferralApplyPage />}
                                        />
                                        <Route
                                            path=":id/done"
                                            element={<ReferralDonePage />}
                                        />
                                    </Route>
                                    <Route
                                        path="/forum/list"
                                        element={<ForumListPage />}
                                    />
                                    <Route
                                        path="/forum"
                                        element={<ForumPage />}
                                    >
                                        <Route
                                            path="discussion/:id"
                                            element={
                                                <ForumDiscussionDetailPage />
                                            }
                                        />
                                        <Route
                                            path="looking-for-referral/:id"
                                            element={<ForumLFRDetailPage />}
                                        />
                                        <Route
                                            path="looking-for-candidate/:id"
                                            element={<ForumLFCDetailPage />}
                                        />
                                        <Route
                                            path="looking-for-candidate/:id/apply"
                                            element={<ReferralApplyPage />}
                                        />
                                        <Route
                                            path="looking-for-candidate/:id/done"
                                            element={<ReferralDonePage />}
                                        />
                                    </Route>
                                    <Route
                                        path="/referral"
                                        element={<RedirectReferral />}
                                    >
                                        <Route
                                            path=":id"
                                            element={<RedirectReferral />}
                                        />
                                    </Route>
                                    <Route path="/my" element={<MyPage />}>
                                        <Route
                                            path="profile"
                                            element={<MyProfilePage />}
                                        />
                                        <Route
                                            path="profile/edit/basic"
                                            element={
                                                <EditBasicInformationPage />
                                            }
                                        />
                                        <Route
                                            path="profile/edit/work"
                                            element={<EditWorkExperiencePage />}
                                        />
                                        <Route
                                            path="profile/edit/education"
                                            element={<EditEducationPage />}
                                        />
                                        <Route
                                            path="profile/edit/tech"
                                            element={<EditTechAreaPage />}
                                        />
                                        <Route
                                            path="profile/edit/preference"
                                            element={<EditPreferencePage />}
                                        />
                                        <Route
                                            path="profile/edit/cv"
                                            element={<ManageCVPage />}
                                        />
                                    </Route>
                                </Route>
                                <Route
                                    path="/offers/tmp/:hash"
                                    element={<TmpOfferListPage />}
                                />
                                <Route path="*" element={<NotFoundPage />} />
                            </Routes>
                        </Background>
                    </AuthProvider>
                </AlertProvider>
            </LoadingProvider>
        </LocalizationProvider>
    );
};

export default App;
