import React from "react";
import styled from "styled-components";
import Stack from "@mui/material/Stack";
import Text from "components/atoms/Text";
import Colors from "themes/colors";
import { Container } from "@mui/material";
import StarImage from "assets/images/stars.png";
import UserImage1 from "assets/images/review-indian/review1.png";
import UserImage2 from "assets/images/review-indian/review2.png";
import AppstoreImage from "assets/images/appstore-badge.png";
import PlaystoreImage from "assets/images/playstore-badge.png";
import MockUpImage from "assets/images/mockup.png";
import SalaryImage from "assets/images/salary.png";
import ReferralImage from "assets/images/referrals.png";
import MessageImage from "assets/images/messages.png";
import CheckIcon from "@mui/icons-material/Check";
import CircleIcon from "@mui/icons-material/Circle";

import { useMediaQuery } from "react-responsive";

const BackgroundContainer = styled.div`
    width: 100vw;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
`;

const ZigupDetailSection = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

    return (
        <>
            <BackgroundContainer
                id={"Zigup"}
                style={{ backgroundColor: Colors.white }}
            >
                <Container
                    maxWidth="xl"
                    style={{
                        paddingTop: 64,
                        paddingBottom: 64,
                        paddingLeft: isMobile ? 16 : 128,
                        paddingRight: isMobile ? 16 : 128,
                    }}
                >
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <div style={{ flex: 1, display: "flex" }}>
                            <img
                                src={SalaryImage}
                                alt="Salary"
                                width="100%"
                                height="auto"
                            />
                        </div>
                        <Stack
                            direction="column"
                            spacing={2}
                            alignItems="flex-start"
                            justifyContent="center"
                            style={{
                                flex: 1,
                                display: "flex",
                                padding: isMobile ? 0 : 64,
                                boxSizing: "border-box",
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: isMobile ? 24 : 36,
                                    color: "#2a2828",
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                }}
                            >
                                Salary breakups
                            </Text>
                            <Text
                                style={{
                                    fontSize: isMobile ? 16 : 20,
                                    color: "#2a2828c7",
                                }}
                            >
                                Detailed Salary breakups, can be found on Zigup
                                only.
                            </Text>
                            <Text
                                style={{
                                    fontSize: isMobile ? 14 : 16,
                                    color: "#2d2c3199",
                                }}
                            >
                                Average salary is not useful, but Breakups are.
                            </Text>
                            <Stack
                                direction="column"
                                spacing={1}
                                alignItems="flex-start"
                                justifyContent="center"
                            >
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <CheckIcon
                                        sx={{
                                            color: "#4173e3",
                                            fontSize: isMobile ? 16 : 20,
                                        }}
                                    />
                                    <Text
                                        style={{
                                            color: "#4173e3",
                                            fontSize: isMobile ? 14 : 16,
                                        }}
                                    >
                                        Hike
                                    </Text>
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <CheckIcon
                                        sx={{
                                            color: "#4173e3",
                                            fontSize: isMobile ? 16 : 20,
                                        }}
                                    />
                                    <Text
                                        style={{
                                            color: "#4173e3",
                                            fontSize: isMobile ? 14 : 16,
                                        }}
                                    >
                                        Base salary
                                    </Text>
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <CheckIcon
                                        sx={{
                                            color: "#4173e3",
                                            fontSize: isMobile ? 16 : 20,
                                        }}
                                    />
                                    <Text
                                        style={{
                                            color: "#4173e3",
                                            fontSize: isMobile ? 14 : 16,
                                        }}
                                    >
                                        Bonus and stocks
                                    </Text>
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <CheckIcon
                                        sx={{
                                            color: "#4173e3",
                                            fontSize: isMobile ? 16 : 20,
                                        }}
                                    />
                                    <Text
                                        style={{
                                            color: "#4173e3",
                                            fontSize: isMobile ? 14 : 16,
                                        }}
                                    >
                                        Previous company
                                    </Text>
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <CheckIcon
                                        sx={{
                                            color: "#4173e3",
                                            fontSize: isMobile ? 16 : 20,
                                        }}
                                    />
                                    <Text
                                        style={{
                                            color: "#4173e3",
                                            fontSize: isMobile ? 14 : 16,
                                        }}
                                    >
                                        Education
                                    </Text>
                                </Stack>
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="flex-start"
                                justifyContent="center"
                            >
                                <Text
                                    style={{
                                        color: "#a4b0b7",
                                        fontSize: isMobile ? 12 : 14,
                                    }}
                                >
                                    + Interview preparation, Rounds, Questions,
                                    Negotiation, Work cultures and so on...
                                </Text>
                            </Stack>
                        </Stack>
                    </Stack>
                </Container>
                <Container
                    maxWidth="xl"
                    style={{
                        paddingTop: 64,
                        paddingBottom: 64,
                        paddingLeft: isMobile ? 16 : 128,
                        paddingRight: isMobile ? 16 : 128,
                    }}
                >
                    {isMobile ? (
                        <Stack
                            direction="column"
                            spacing={2}
                            alignItems="flex-start"
                            justifyContent="center"
                            sx={{
                                flex: 1,
                                display: "flex",
                            }}
                        >
                            <Stack
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                                sx={{
                                    flex: 1,
                                    display: "flex",
                                    backgroundColor: "#5956FF",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    paddingTop: "120px",
                                    paddingBottom: "120px",
                                }}
                            >
                                <img
                                    src={StarImage}
                                    alt="star"
                                    width="50%"
                                    height="auto"
                                />
                                <Text
                                    style={{
                                        color: Colors.white,
                                        fontSize: 36,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                        textAlign: "center",
                                    }}
                                >
                                    Guaranteed by Developers
                                </Text>
                                <Text
                                    style={{
                                        color: Colors.white,
                                        fontSize: 16,
                                        maxWidth: 400,
                                        textAlign: "center",
                                    }}
                                >
                                    Zigup had acquired 4,000+ salary data from
                                    software engineers.
                                </Text>
                                <Text
                                    style={{
                                        color: "#5956ff",
                                        fontSize: 14,
                                        backgroundColor: "#dae9f2",
                                    }}
                                >
                                    Salary breakup post : 4,000+ and growing
                                    even now
                                </Text>
                                <Stack direction="row" spacing={2}>
                                    <Stack
                                        direction="column"
                                        alignItems="flex-start"
                                        justifyContent="center"
                                        spacing={1}
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CircleIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                    fontWeight: 700,
                                                }}
                                            >
                                                Breakup details
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                Hike
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                Base salary
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                Bonus and stocks
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                Previous company
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                Education
                                            </Text>
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        direction="column"
                                        alignItems="flex-start"
                                        justifyContent="center"
                                        spacing={1}
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CircleIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                    fontWeight: 700,
                                                }}
                                            >
                                                Covering area
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                Software engineers
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                PM
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                Product designers
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                HR
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                Marketing
                                            </Text>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    backgroundColor: "#DEF4EE",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    boxSizing: "border-box",
                                    paddingTop: "16px",
                                }}
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{ flex: 1, width: "100%" }}
                                >
                                    <Stack
                                        direction="column"
                                        alignItems="flex-start"
                                        justifyContent="flex-end"
                                        spacing={2}
                                        sx={{
                                            flex: 1,
                                            padding: "32px",
                                            boxSizing: "border-box",
                                        }}
                                    >
                                        <img
                                            src={StarImage}
                                            alt="star"
                                            width="50%"
                                            height="auto"
                                        />
                                        <Text
                                            style={{
                                                color: Colors.black,
                                                fontSize: 24,
                                                fontWeight: 700,
                                            }}
                                        >
                                            Best app for working professionals
                                        </Text>
                                        <Text
                                            style={{
                                                color: Colors.black_light,
                                                fontSize: 16,
                                            }}
                                        >
                                            The best app for all the working
                                            professional, I don't think I was
                                            ever satisfied and amazed by the
                                            information I have got from an app.
                                        </Text>
                                        <Text
                                            style={{
                                                color: "#A4A8B2",
                                                fontSize: 13,
                                            }}
                                        >
                                            From google playstore review
                                        </Text>
                                    </Stack>
                                    <div
                                        style={{
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        <img
                                            src={UserImage1}
                                            alt="User_1"
                                            height="100%"
                                            width="auto"
                                        />
                                    </div>
                                </Stack>
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    backgroundColor: "#DEF4EE",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    boxSizing: "border-box",
                                    paddingTop: "16px",
                                }}
                            >
                                <div
                                    style={{
                                        flex: 1,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        src={UserImage2}
                                        alt="User_2"
                                        height="100%"
                                        width="auto"
                                    />
                                </div>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{ flex: 1, width: "100%" }}
                                >
                                    <Stack
                                        direction="column"
                                        alignItems="flex-start"
                                        justifyContent="flex-end"
                                        spacing={2}
                                        sx={{
                                            flex: 1,
                                            padding: "32px",
                                            boxSizing: "border-box",
                                        }}
                                    >
                                        <img
                                            src={StarImage}
                                            alt="star"
                                            width="50%"
                                            height="auto"
                                        />
                                        <Text
                                            style={{
                                                color: Colors.black,
                                                fontSize: 24,
                                                fontWeight: 700,
                                            }}
                                        >
                                            Genuine information
                                        </Text>
                                        <Text
                                            style={{
                                                color: Colors.black_light,
                                                fontSize: 16,
                                            }}
                                        >
                                            I feel data from this app is quite
                                            genuine. Compared to glassdoor I can
                                            say that data is more updated and
                                            breakup well, while glassdoor
                                            summarise
                                        </Text>
                                        <Text
                                            style={{
                                                color: "#A4A8B2",
                                                fontSize: 13,
                                            }}
                                        >
                                            From google playstore review
                                        </Text>
                                    </Stack>
                                </Stack>
                            </div>
                        </Stack>
                    ) : (
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="stretch"
                            justifyContent="center"
                            sx={{ flex: 1, display: "flex" }}
                        >
                            <Stack
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                                sx={{
                                    flex: 1,
                                    display: "flex",
                                    backgroundColor: "#5956FF",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    paddingTop: "120px",
                                    paddingBottom: "120px",
                                }}
                            >
                                <img
                                    src={StarImage}
                                    alt="star"
                                    width="50%"
                                    height="auto"
                                />
                                <Text
                                    style={{
                                        color: Colors.white,
                                        fontSize: 36,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                        textAlign: "center",
                                    }}
                                >
                                    Guaranteed by Developers
                                </Text>
                                <Text
                                    style={{
                                        color: Colors.white,
                                        fontSize: 16,
                                        maxWidth: 400,
                                        textAlign: "center",
                                    }}
                                >
                                    Zigup had acquired 5,000+ salary data from
                                    software engineers.
                                </Text>
                                <Text
                                    style={{
                                        color: "#5956ff",
                                        fontSize: 14,
                                        backgroundColor: "#dae9f2",
                                    }}
                                >
                                    Salary breakup post : 5,000+ and growing
                                    even now
                                </Text>
                                <Stack direction="row" spacing={2}>
                                    <Stack
                                        direction="column"
                                        alignItems="flex-start"
                                        justifyContent="center"
                                        spacing={1}
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CircleIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                    fontWeight: 700,
                                                }}
                                            >
                                                Breakup details
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                Hike
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                Base salary
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                Bonus and stocks
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                Previous company
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                Education
                                            </Text>
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        direction="column"
                                        alignItems="flex-start"
                                        justifyContent="center"
                                        spacing={1}
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CircleIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                    fontWeight: 700,
                                                }}
                                            >
                                                Covering area
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                Software engineers
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                PM
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                Product designers
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                HR
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CheckIcon
                                                sx={{
                                                    color: "#9ffff4",
                                                    fontSize: isMobile
                                                        ? 16
                                                        : 20,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    color: "#9ffff4",
                                                    fontSize: 16,
                                                }}
                                            >
                                                Marketing
                                            </Text>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack
                                direction="column"
                                spacing={2}
                                alignItems="flex-start"
                                justifyContent="center"
                                sx={{
                                    flex: 1,
                                    display: "flex",
                                }}
                            >
                                <div
                                    style={{
                                        flex: 1,
                                        display: "flex",
                                        backgroundColor: "#DEF4EE",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%",
                                        boxSizing: "border-box",
                                        paddingTop: "16px",
                                    }}
                                >
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{ flex: 1, width: "100%" }}
                                    >
                                        <Stack
                                            direction="column"
                                            alignItems="flex-start"
                                            justifyContent="flex-end"
                                            spacing={2}
                                            sx={{
                                                flex: 1,
                                                padding: "32px",
                                                boxSizing: "border-box",
                                            }}
                                        >
                                            <img
                                                src={StarImage}
                                                alt="star"
                                                width="50%"
                                                height="auto"
                                            />
                                            <Text
                                                style={{
                                                    color: Colors.black,
                                                    fontSize: 24,
                                                    fontWeight: 700,
                                                }}
                                            >
                                                Best app for working
                                                professionals
                                            </Text>
                                            <Text
                                                style={{
                                                    color: Colors.black_light,
                                                    fontSize: 16,
                                                }}
                                            >
                                                The best app for all the working
                                                professional, I don't think I
                                                was ever satisfied and amazed by
                                                the information I have got from
                                                an app.
                                            </Text>
                                            <Text
                                                style={{
                                                    color: "#A4A8B2",
                                                    fontSize: 13,
                                                }}
                                            >
                                                From google playstore review
                                            </Text>
                                        </Stack>
                                        <div
                                            style={{
                                                flex: 1,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-start",
                                            }}
                                        >
                                            <img
                                                src={UserImage1}
                                                alt="User_1"
                                                height="100%"
                                                width="auto"
                                            />
                                        </div>
                                    </Stack>
                                </div>
                                <div
                                    style={{
                                        flex: 1,
                                        display: "flex",
                                        backgroundColor: "#DEF4EE",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%",
                                        boxSizing: "border-box",
                                        paddingTop: "16px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <img
                                            src={UserImage2}
                                            alt="User_2"
                                            height="100%"
                                            width="auto"
                                        />
                                    </div>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{ flex: 1, width: "100%" }}
                                    >
                                        <Stack
                                            direction="column"
                                            alignItems="flex-start"
                                            justifyContent="flex-end"
                                            spacing={2}
                                            sx={{
                                                flex: 1,
                                                padding: "32px",
                                                boxSizing: "border-box",
                                            }}
                                        >
                                            <img
                                                src={StarImage}
                                                alt="star"
                                                width="50%"
                                                height="auto"
                                            />
                                            <Text
                                                style={{
                                                    color: Colors.black,
                                                    fontSize: 24,
                                                    fontWeight: 700,
                                                }}
                                            >
                                                Genuine information
                                            </Text>
                                            <Text
                                                style={{
                                                    color: Colors.black_light,
                                                    fontSize: 16,
                                                }}
                                            >
                                                I feel data from this app is
                                                quite genuine. Compared to
                                                glassdoor I can say that data is
                                                more updated and breakup well,
                                                while glassdoor summarise
                                            </Text>
                                            <Text
                                                style={{
                                                    color: "#A4A8B2",
                                                    fontSize: 13,
                                                }}
                                            >
                                                From google playstore review
                                            </Text>
                                        </Stack>
                                    </Stack>
                                </div>
                            </Stack>
                        </Stack>
                    )}
                </Container>
                <Container
                    maxWidth="xl"
                    style={{
                        paddingTop: 64,
                        paddingBottom: 64,
                        paddingLeft: isMobile ? 16 : 128,
                        paddingRight: isMobile ? 16 : 128,
                    }}
                >
                    <Stack
                        direction="row"
                        spacing={isMobile ? 2 : 4}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Stack
                            direction="column"
                            spacing={4}
                            alignItems="flex-start"
                            justifyContent="center"
                            style={{
                                flex: isMobile ? 2 : 1,
                                display: "flex",
                                padding: isMobile ? 0 : 64,
                                boxSizing: "border-box",
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: isMobile ? 24 : 36,
                                    color: "#2a2828",
                                    fontWeight: 700,
                                    fontFamily: "Poppins",
                                }}
                            >
                                The only place to request Referrals
                            </Text>
                            <Text
                                style={{
                                    fontSize: isMobile ? 16 : 24,
                                    color: "#2a2828c7",
                                }}
                            >
                                Simply post a request, and you will get a
                                message from the Referrers
                            </Text>
                        </Stack>
                        <div
                            style={{
                                flex: isMobile ? 3 : 1,
                                display: "flex",
                            }}
                        >
                            <img
                                src={ReferralImage}
                                alt="Referral"
                                width="100%"
                                height="auto"
                            />
                        </div>
                    </Stack>
                </Container>
            </BackgroundContainer>
            <BackgroundContainer style={{ backgroundColor: "#F6F6FB" }}>
                <Container
                    maxWidth="xl"
                    style={{
                        paddingTop: 64,
                        paddingBottom: 64,
                        paddingLeft: isMobile ? 16 : 128,
                        paddingRight: isMobile ? 16 : 128,
                    }}
                >
                    <Stack
                        direction="row"
                        spacing={4}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <div
                            style={{
                                display: "flex",
                                flex: isMobile ? 3 : 1,
                                alignItems: "center",
                                justifyContent: "center",
                                padding: isMobile ? 16 : 0,
                            }}
                        >
                            <img
                                src={MessageImage}
                                alt="Message"
                                width="100%"
                                height="auto"
                            />
                        </div>
                        <Stack
                            direction="column"
                            spacing={4}
                            alignItems="flex-start"
                            justifyContent="center"
                            style={{
                                flex: isMobile ? 2 : 1,
                                display: "flex",
                                padding: isMobile ? 16 : 64,
                                boxSizing: "border-box",
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: isMobile ? 24 : 36,
                                    color: "#2a2828",
                                    fontWeight: 700,
                                    fontFamily: "Poppins",
                                }}
                            >
                                Helping each other on DM
                            </Text>
                            <Text
                                style={{
                                    fontSize: isMobile ? 16 : 24,
                                    color: "#2a2828c7",
                                }}
                            >
                                Users can exchange referrals to anonymous users
                                on DM
                            </Text>
                        </Stack>
                    </Stack>
                </Container>
            </BackgroundContainer>
            <BackgroundContainer
                style={{ backgroundColor: Colors.background_blue }}
            >
                <Container maxWidth="lg" style={{ padding: 64 }}>
                    {isMobile ? (
                        <Stack
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            spacing={6}
                            sx={{ width: "100%" }}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={2}
                            >
                                <Stack
                                    direction="column"
                                    alignItems="flex-start"
                                    sx={{ flex: 1 }}
                                    spacing={4}
                                >
                                    <Text
                                        style={{
                                            fontFamily: "Poppins",
                                            fontSize: 26,
                                            fontWeight: 700,
                                            color: "white",
                                        }}
                                    >
                                        Irreplaceable Tech Community
                                    </Text>
                                    <Text
                                        style={{
                                            fontFamily: "Poppins",
                                            fontSize: 22,
                                            fontWeight: 700,
                                            color: "white",
                                        }}
                                    >
                                        Find everything you need for a job, at
                                        Zigup
                                    </Text>
                                </Stack>
                                <Stack
                                    direction="column"
                                    alignItems="center"
                                    sx={{ flex: 1 }}
                                >
                                    <img
                                        src={MockUpImage}
                                        alt="MockUpImage"
                                        width="100%"
                                        height="auto"
                                    />
                                </Stack>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <a href="https://play.google.com/store/apps/details?id=in.zigup&hl=en&gl=US">
                                    <img
                                        src={PlaystoreImage}
                                        width="140"
                                        alt="PlaystoreImage"
                                    />
                                </a>
                                <a href="https://apps.apple.com/kr/app/zigup/id1562615317">
                                    <img
                                        src={AppstoreImage}
                                        width="140"
                                        alt="AppstoreImage"
                                    />
                                </a>
                            </Stack>
                        </Stack>
                    ) : (
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ width: "100%" }}
                        >
                            <Stack
                                direction="column"
                                alignItems="flex-start"
                                sx={{ flex: 1 }}
                                spacing={4}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Poppins",
                                        fontSize: 40,
                                        fontWeight: 700,
                                        color: "white",
                                    }}
                                >
                                    Irreplaceable Tech Community
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Poppins",
                                        fontSize: 26,
                                        fontWeight: 700,
                                        color: "white",
                                    }}
                                >
                                    Find everything you need for a job, at Zigup
                                </Text>
                                <Stack direction="row" spacing={2}>
                                    <a href="https://play.google.com/store/apps/details?id=in.zigup&hl=en&gl=US">
                                        <img
                                            src={PlaystoreImage}
                                            width="140"
                                            alt="PlaystoreImage"
                                        />
                                    </a>
                                    <a href="https://apps.apple.com/kr/app/zigup/id1562615317">
                                        <img
                                            src={AppstoreImage}
                                            width="140"
                                            alt="AppstoreImage"
                                        />
                                    </a>
                                </Stack>
                            </Stack>
                            <Stack
                                direction="column"
                                alignItems="center"
                                sx={{ flex: 1 }}
                            >
                                <img
                                    src={MockUpImage}
                                    alt="MockUpImage"
                                    width="80%"
                                    height="auto"
                                />
                            </Stack>
                        </Stack>
                    )}
                </Container>
            </BackgroundContainer>
        </>
    );
};

export default ZigupDetailSection;
