import * as Api from "./api";
import { post, get } from "./process";

export async function detail(salaryID, isAuth) {
    return get(
        Api.Urls.salary.detail(salaryID),
        {},
        isAuth ? Api.Types.WithAccessToken : Api.Types.Simple
    );
}

export async function recommend(salaryID, params = {}) {
    return get(Api.Urls.salary.recommend(salaryID), params, Api.Types.Simple);
}

export async function list(params) {
    const { salaries } = await post(
        Api.Urls.salary.list,
        params,
        Api.Types.Simple
    );
    return salaries;
}

export async function viewedCompanies(salaryID) {
    return get(Api.Urls.salary.viewedCompanies(salaryID), {}, Api.Types.Simple);
}

export async function postSalary(params) {
    return await post(
        Api.Urls.salary.postSalary,
        params,
        Api.Types.WithAccessToken
    );
}

export async function salaryAdjoiningList(id, params = {}) {
    return await get(
        Api.Urls.salary.salaryAdjoiningList(id),
        params,
        Api.Types.Simple
    );
}

export async function insight(params) {
    return await get(Api.Urls.salary.insight, params, Api.Types.Simple);
}
