import styled from "styled-components";
import Stack from "@mui/material/Stack";
import React, { useState } from "react";
import Colors from "themes/colors";
import Typo from "./Typo";
import ZigupSpinner from "../organisms/ZigupSpinner";

const Button = styled(Stack)`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
    background-color: ${({ disabled }) =>
        disabled ? `${Colors.grey}` : `${Colors.purple_blue}`};
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
    border-radius: 4px;
    padding: 4px 8px 4px 8px;
    box-sizing: border-box;
`;

const ZigupButton = ({
    onClickHandler,
    id,
    buttonText,
    buttonTextSize = "0.8rem",
    buttonTextStyle,
    disabled,
    containerStyle,
    isLoading = false,
}) => {
    const [hover, setHover] = useState(false);

    const buttonContainerStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: disabled ? "default" : "pointer",
        opacity: disabled ? 0.6 : 1,
        backgroundColor: disabled
            ? Colors.grey
            : hover
            ? Colors.purple
            : Colors.purple_blue,
        pointerEvents: disabled ? "none" : "auto",
        borderRadius: 4,
        padding: "4px 8px 4px 8px",
        width: "100%",
        height: "3rem",
        ...containerStyle,
    };
    const _buttonTextStyle = {
        color: "white",
        fontSize: buttonTextSize,
        ...buttonTextStyle,
    };
    return (
        <Button
            id={id ?? "zigup-btn"}
            onClick={onClickHandler}
            disabled={disabled}
            style={buttonContainerStyle}
            onMouseEnter={() => {
                setHover(true);
            }}
            onMouseLeave={() => {
                setHover(false);
            }}
        >
            {isLoading ? (
                <ZigupSpinner size={"1rem"} />
            ) : (
                <Typo.Button1 style={_buttonTextStyle}>
                    {buttonText}
                </Typo.Button1>
            )}
        </Button>
    );
};
export default ZigupButton;
