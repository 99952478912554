import * as Api from "./api";
import { get, post } from "./process";

export async function allList(params) {
    return await post(Api.Urls.forum.allList, params, Api.Types.Simple);
}
export async function discussionList(params) {
    return await post(Api.Urls.forum.discussionList, params, Api.Types.Simple);
}
export async function lfcList(params) {
    return await post(Api.Urls.forum.lfcList, params, Api.Types.Simple);
}
export async function lfrList(params) {
    return await post(Api.Urls.forum.lfrList, params, Api.Types.Simple);
}
export async function discussionDetailAdjoiningList(id, params = {}) {
    return await get(
        Api.Urls.forum.discussionDetailAdjoiningList(id),
        params,
        Api.Types.Simple
    );
}
export async function lfcDetailAdjoiningList(id, params = {}) {
    return await get(
        Api.Urls.forum.lfcDetailAdjoiningList(id),
        params,
        Api.Types.Simple
    );
}
export async function lfrDetailAdjoiningList(id, params = {}) {
    return await get(
        Api.Urls.forum.lfrDetailAdjoiningList(id),
        params,
        Api.Types.Simple
    );
}
export async function discussionDetail(id, params = {}) {
    return await get(
        Api.Urls.forum.discussionDetail(id),
        params,
        Api.Types.Simple
    );
}
export async function lfcDetail(id, params = {}) {
    return await get(Api.Urls.forum.lfcDetail(id), params, Api.Types.Simple);
}
export async function lfrDetail(id, params = {}) {
    return await get(Api.Urls.forum.lfrDetail(id), params, Api.Types.Simple);
}
export async function forumMainList(params) {
    return await get(Api.Urls.forum.mainList, params, Api.Types.Simple);
}
