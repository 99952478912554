import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Stack } from "@mui/material";
import { list } from "../../../communication/comment";
import Comment from "../Comment";
import Text from "components/atoms/Text";
import Colors from "themes/colors";

import {
    COMMENT_HANDLE_TYPE,
    COMMENT_VIEW_TYPE,
} from "../../../constants/constants";
import AddIcon from "@mui/icons-material/Add";
import ZigupSpinner from "../ZigupSpinner";
import { Logger } from "utils";

const ShowMoreContainer = styled(Stack)`
    padding: 12px 16px 12px 0px;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
`;

const Comments = (props) => {
    const { id } = props;
    const [comments, setComments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pagination, setPagination] = useState({
        hasNext: false,
        lastIndex: 0,
        totalCount: 0,
    });
    const [pageText, setPageText] = useState({
        commentLabel: "Comment",
        no_comment: "No comment",
        unable_comment_placeholder: "You can't add a comment to notice",
        placeholder: "add a comment",
        load_more: "Load more",
        thread_load_more: "View previous replies",
        delete_comment: "Deleted comment",
    });

    useEffect(async () => {
        try {
            setIsLoading(true);
            const res = await list({
                objectId: id,
                lastIndex: 0,
                limit: 10,
            });
            setPagination(res.result.pagination);
            setComments(res.result.comments);
        } catch (e) {
            Logger.warn(e);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const loadMoreComments = async () => {
        try {
            setIsLoading(true);
            const fetchCommentData = await list({
                objectId: id,
                lastIndex: pagination.lastIndex,
                limit: 10,
            });
            setComments([...fetchCommentData.result.comments, ...comments]);
            setPagination(fetchCommentData.result.pagination);
        } catch (e) {
            Logger.warn(e);
        } finally {
            setIsLoading(false);
        }
    };

    const updateCommentData = (comment) => {
        const newArr = [...comments];
        const idx = newArr.findIndex((ele) => ele._id === comment._id);
        if (idx > -1) {
            newArr.splice(idx, 1, comment);
            setComments(newArr);
        }
    };

    const commentEventHandler = (params, eventType) => {
        const { comment, newContent } = params;
        switch (eventType) {
            case COMMENT_HANDLE_TYPE.REPLY_LOAD_MORE:
                replyLoadMore(comment).catch((err) => {
                    Logger.error(err);
                });
                break;
        }
    };

    const replyLoadMore = async (comment) => {
        const fetchReplyData = await ForumReply.fetch({
            commentId: comment._id,
            lastIndex: comment.replyData.pagination.lastIndex,
            limit: 5,
        });
        const newReplyData = {
            pagination: {
                ...fetchReplyData.pagination,
                totalCount: fetchReplyData.pagination.totalDocumentCount,
            },

            //todo 대댓글 정렬 기준 확인
            replies: [...fetchReplyData.replies, ...comment.replyData.replies],
        };

        const newArr = [...commentData];
        newArr.splice(
            newArr.findIndex((ele) => ele._id === comment._id),
            1,
            { ...comment, replyData: newReplyData }
        );
        setCommentData(newArr);
    };

    const renderSeparator = (thickness = 1) => {
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                style={{
                    width: "100%",
                    height: thickness,
                    backgroundColor: Colors.light_purple,
                    marginTop: "1rem",
                    marginBottom: "1rem",
                }}
            />
        );
    };
    if (isLoading || !id) {
        return (
            <Stack
                justifyContent={"center"}
                alignItems={"center"}
                style={{ minHeight: "20vh" }}
            >
                <ZigupSpinner size={"3rem"} />
            </Stack>
        );
    } else {
        if (comments.length < 1) {
            return (
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{ minHeight: "20vh" }}
                >
                    <Text>No comments yet</Text>
                </Stack>
            );
        } else {
            return (
                <Stack
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{
                        width: "100%",
                    }}
                >
                    {pagination?.hasNext ? (
                        <ShowMoreContainer onClick={loadMoreComments}>
                            <AddIcon sx={{ color: "#646464", fontSize: 20 }} />
                            <Text
                                style={{
                                    color: "#646464",
                                    marginLeft: 4,
                                }}
                            >
                                {pageText.load_more}
                            </Text>
                        </ShowMoreContainer>
                    ) : null}
                    {comments?.length > 0
                        ? comments.map((comment, index) => {
                              return (
                                  <Stack key={index} style={{ width: "100%" }}>
                                      <Comment
                                          objectType={props.objectType}
                                          viewType={COMMENT_VIEW_TYPE.COMMENT}
                                          comment={comment}
                                          updateCommentData={updateCommentData}
                                          isNotice={props.isNotice}
                                          eventHandler={commentEventHandler}
                                          setBackgroundColor={
                                              // isEditingComment
                                              //     ? item.isEditing
                                              //         ? "transparent"
                                              //         : "rgba(0,0,0,0.05)"
                                              //     : "transparent"
                                              "transparent"
                                          }
                                          isFirstComment={index === 0}
                                          setDisableDots={false}
                                      />
                                      {index === comments.length - 1
                                          ? null
                                          : renderSeparator(0.5)}
                                  </Stack>
                              );
                          })
                        : null}
                </Stack>
            );
        }
    }
};
export default Comments;
