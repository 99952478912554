import React from "react";
import { Stack } from "@mui/material";
import Typo from "components/atoms/Typo";
import ZigupButton from "../../atoms/ZigupButton";
import styled from "styled-components";
import { isRequired } from "../../../utils/validator";
import ProfileCheck from "../../atoms/ProfileCheck";
import companyIcon from "assets/icons/icon-company.png";
import collegeIcon from "assets/icons/icon-college.png";
import { EXPERIENCE_TYPE } from "../../../constants/constants";
import { useNavigate } from "react-router";
import Colors from "themes/colors";
import Text from "components/atoms/Text";

const ItemContainer = styled(Stack)`
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

const SubjectText = styled(Text)`
    font-size: 0.75rem;
    color: ${Colors.light_grey_3};
    font-family: Roboto;
    font-weight: 400;
`;

const WorkExperience = ({ userInfo }) => {
    const navigate = useNavigate();
    return (
        <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
            padding={2}
            margin={1}
            style={{ backgroundColor: "white" }}
        >
            <ItemContainer>
                <Stack>
                    <Typo.Body1Bold style={{ fontWeight: "500" }}>
                        {userInfo.isExperienced === EXPERIENCE_TYPE.EXPERIENCED
                            ? "Work Experience"
                            : "Education"}
                    </Typo.Body1Bold>
                </Stack>
                <Stack>
                    <ZigupButton
                        id={"work-experience-edit-btn"}
                        buttonText={"EDIT"}
                        containerStyle={{
                            width: "max-content",
                            height: "max-content",
                            backgroundColor: "transparent",
                        }}
                        buttonTextSize={"1rem"}
                        buttonTextStyle={{ color: "#646464" }}
                        onClickHandler={() =>
                            navigate(
                                userInfo.isExperienced ===
                                    EXPERIENCE_TYPE.EXPERIENCED
                                    ? "edit/work"
                                    : "edit/education",
                                {
                                    state: { userInfo: userInfo },
                                }
                            )
                        }
                    />
                </Stack>
            </ItemContainer>
            <Stack
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={
                    userInfo.isExperienced === EXPERIENCE_TYPE.EXPERIENCED
                        ? "center"
                        : "flex-start"
                }
                spacing={2}
                style={{ width: "100%" }}
            >
                <img
                    src={
                        userInfo.isExperienced === EXPERIENCE_TYPE.EXPERIENCED
                            ? companyIcon
                            : collegeIcon
                    }
                    alt="experienced-icon"
                    width="40"
                    height="40"
                />
                {userInfo?.isExperienced === EXPERIENCE_TYPE.EXPERIENCED ? (
                    <Stack>
                        <Typo.Body1>{userInfo?.company}</Typo.Body1>
                    </Stack>
                ) : (
                    <Stack spacing={0.5}>
                        <Typo.Body1>
                            {userInfo?.education?.university.value}
                        </Typo.Body1>
                        <Typo.Body1
                            style={{
                                fontSize: "0.875rem",
                                color: Colors.light_grey_3,
                            }}
                        >
                            {userInfo?.education?.course.value}
                        </Typo.Body1>
                        <Typo.Body1
                            style={{
                                fontSize: "0.875rem",
                                color: Colors.light_grey_3,
                            }}
                        >
                            {userInfo?.education?.specialization.value}
                        </Typo.Body1>
                    </Stack>
                )}
            </Stack>
            {userInfo.isExperienced === EXPERIENCE_TYPE.EXPERIENCED ? (
                <>
                    <ItemContainer>
                        <SubjectText>Currently hired</SubjectText>
                        <ProfileCheck
                            checked={isRequired(userInfo.employed + "").valid}
                        />
                    </ItemContainer>
                    <ItemContainer>
                        <SubjectText>Current(Last) employer</SubjectText>
                        <ProfileCheck
                            checked={isRequired(userInfo.company + "").valid}
                        />
                    </ItemContainer>
                    <ItemContainer>
                        <SubjectText>Designation</SubjectText>
                        <ProfileCheck
                            checked={
                                isRequired(userInfo.designation + "").valid
                            }
                        />
                    </ItemContainer>
                    <ItemContainer>
                        <SubjectText>Years of relevant experience</SubjectText>
                        <ProfileCheck
                            checked={
                                isRequired(userInfo.relevantExperience + "")
                                    .valid
                            }
                        />
                    </ItemContainer>
                </>
            ) : (
                <>
                    <ItemContainer>
                        <SubjectText>Course</SubjectText>
                        <ProfileCheck
                            checked={
                                isRequired(
                                    userInfo.education?.course.value + ""
                                ).valid
                            }
                        />
                    </ItemContainer>
                    <ItemContainer>
                        <SubjectText>Specialization</SubjectText>
                        <ProfileCheck
                            checked={
                                isRequired(
                                    userInfo.education?.specialization.value +
                                        ""
                                ).valid
                            }
                        />
                    </ItemContainer>
                    <ItemContainer>
                        <SubjectText>University/ Institute</SubjectText>
                        <ProfileCheck
                            checked={
                                isRequired(
                                    userInfo.education?.university.value + ""
                                ).valid
                            }
                        />
                    </ItemContainer>
                </>
            )}
        </Stack>
    );
};
export default WorkExperience;
