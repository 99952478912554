import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga4";
import { PRELOAD_IMAGES } from "./constants/constants";

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

PRELOAD_IMAGES.map((image) => {
    const imageElement = new Image();
    imageElement.src = image;
});

ReactDOM.render(
    <BrowserRouter>
        <HelmetProvider>
            <App />
        </HelmetProvider>
    </BrowserRouter>,
    document.getElementById("root")
);
