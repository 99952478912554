import React from "react";
import styled from "styled-components";
import Stack from "@mui/material/Stack";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Text from "components/atoms/Text";
import Colors from "themes/colors";
import BackgroundImage from "assets/images/bg.jpg";
import MainImage from "assets/images/img-main.png";
import ZigupImage from "assets/images/logo-zigup.png";
import ZigupCandidateImage from "assets/images/logo-candidates-db.png";
import HyperhireImage from "assets/images/made-by-hh.png";
import AppstoreImage from "assets/images/appstore-badge.png";
import PlaystoreImage from "assets/images/playstore-badge.png";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import CheckIcon from "@mui/icons-material/Check";

import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const BackgroundContainer = styled.div`
    width: 100vw;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-image: url(${BackgroundImage});
    background-size: cover;
`;

const ReverseTriangle = styled.div`
    width: 0px;
    height: 0px;
    border-top: 140px solid transparent;
    border-left: 50vw solid white;
    border-right: 50vw solid white;
`;

const MainSection = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const navigate = useNavigate();

    const onZigupLogoClick = () => {
        navigate("/");
    };

    return (
        <BackgroundContainer style={{ backgroundColor: Colors.white }}>
            <div
                style={{
                    padding: isMobile ? 16 : 96,
                    boxSizing: "border-box",
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        width: "100%",
                        boxSizing: "border-box",
                        marginBottom: "40px",
                    }}
                >
                    <div
                        style={{ flex: 1, display: "flex", cursor: "pointer" }}
                        onClick={onZigupLogoClick}
                    >
                        <img
                            src={ZigupImage}
                            alt="ZigupLogo"
                            height="30"
                            width="auto"
                        />
                    </div>
                    {isMobile ? null : (
                        <div style={{ flex: 1, display: "flex" }}>
                            <img
                                src={HyperhireImage}
                                alt="HyperhireLogo"
                                height="30"
                                width="auto"
                            />
                        </div>
                    )}
                    <div
                        style={{
                            justifyContent: "flex-end",
                            display: "flex",
                        }}
                    >
                        <AnchorLink
                            href="#Zigup"
                            style={{
                                color: Colors.white,
                                marginRight: 24,
                                fontSize: 16,
                            }}
                        >
                            Zigup
                        </AnchorLink>
                        <AnchorLink
                            href="#Hyperhire"
                            style={{ color: Colors.white, fontSize: 16 }}
                        >
                            About hyperhire
                        </AnchorLink>
                    </div>
                </Stack>
                <Stack
                    direction="column"
                    spacing={4}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Text
                        style={{
                            color: Colors.white,
                            fontSize: isMobile ? 42 : 66,
                            fontFamily: "Poppins",
                            fontWeight: 700,
                            textAlign: "center",
                        }}
                    >
                        Irreplaceable tech community
                    </Text>
                    <Text
                        style={{
                            color: Colors.white,
                            fontSize: isMobile ? 24 : 32,
                            width: "80%",
                            maxWidth: 800,
                            fontWeight: 700,
                            textAlign: "center",
                        }}
                    >
                        Where you can share everything related with your career,
                        Zigup
                    </Text>
                    <Stack
                        direction={isMobile ? "column" : "row"}
                        spacing={isMobile ? 1 : 4}
                    >
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <CheckIcon
                                sx={{ color: "#d6dbfd", fontSize: 20 }}
                            />
                            <Text style={{ color: "#d6dbfd", fontSize: 16 }}>
                                Salaries
                            </Text>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <CheckIcon
                                sx={{ color: "#d6dbfd", fontSize: 20 }}
                            />
                            <Text style={{ color: "#d6dbfd", fontSize: 16 }}>
                                Product-based companies
                            </Text>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <CheckIcon
                                sx={{ color: "#d6dbfd", fontSize: 20 }}
                            />
                            <Text style={{ color: "#d6dbfd", fontSize: 16 }}>
                                Jobs and offers
                            </Text>
                        </Stack>
                    </Stack>
                    <img
                        src={MainImage}
                        alt="Main"
                        width={isMobile ? "100%" : "70%"}
                        height="auto"
                        style={{ padding: 50, boxSizing: "border-box" }}
                    />
                    <Text
                        style={{
                            color: Colors.white,
                            fontSize: isMobile ? 24 : 32,
                            fontWeight: 700,
                            width: "80%",
                            maxWidth: 750,
                            textAlign: "center",
                        }}
                    >
                        Everything about working professionals at product-based
                        company
                    </Text>
                    <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        spacing={0}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            spacing={2}
                        >
                            <a href="https://play.google.com/store/apps/details?id=in.zigup&hl=en&gl=US">
                                <img
                                    src={PlaystoreImage}
                                    alt="Playstore"
                                    height="44"
                                    width="auto"
                                />
                            </a>
                            <a href="https://apps.apple.com/kr/app/zigup/id1562615317">
                                <img
                                    src={AppstoreImage}
                                    alt="Appstore"
                                    height="44"
                                    width="auto"
                                />
                            </a>
                        </Stack>
                        <Text
                            style={{
                                color: "#ffffffa1",
                                fontFamily: "Inter",
                                fontSize: isMobile ? 12 : 14,
                            }}
                        >
                            Available on Play Store & App Store
                        </Text>
                    </Stack>
                    <div
                        style={{
                            width: "80%",
                            maxWidth: 600,
                            backgroundColor: "#ffffff36",
                            boxSizing: "border-box",
                            padding: 30,
                        }}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ marginBottom: 2 }}
                        >
                            <img
                                src={ZigupCandidateImage}
                                alt="ZigupCandidateImage"
                                height="40"
                            />
                            <a href="https://hire.zigup.in">
                                <ArrowCircleRightOutlinedIcon
                                    fontSize="large"
                                    sx={{ color: "white" }}
                                />
                            </a>
                        </Stack>
                        <Stack
                            direction="column"
                            alignItems="flex-start"
                            justifyContent="center"
                            spacing={2}
                        >
                            <Text
                                style={{
                                    color: Colors.white,
                                    fontSize: 23,
                                    fontWeight: 700,
                                }}
                            >
                                Zigup for Business
                            </Text>
                            <Text
                                style={{
                                    color: Colors.white,
                                    fontSize: 18,
                                }}
                            >
                                Maximize your hiring with Zigup
                            </Text>
                        </Stack>
                    </div>
                </Stack>
            </div>
            <ReverseTriangle />
        </BackgroundContainer>
    );
};

export default MainSection;
