import { ROLE_TEXT_ARR } from "constants/constants";

export const capitalize = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getString = (text) => {
    if (text === undefined) return;
    if (text.substr(text.length - 5, 5) === "<br/>") {
        text = text.substr(0, text.length - 5);
    }
    const tmp = text.replace(/<br[/]>/g, "\n");
    return " - " + tmp;
};

export const getRole = (_role) => {
    for (const role of ROLE_TEXT_ARR) {
        if (role.key === _role) return role.name;
    }
    return _role;
};
