import validator from "validator";
import isStrongPassword from "validator/es/lib/isStrongPassword";

export const valid = () => {
    return {
        valid: true,
    };
};

export const invalid = (error) => {
    return {
        valid: false,
        error,
    };
};

export const isArray = (v) => {
    if (Array.isArray(v)) return valid();
    return invalid();
};

export const isString = (txt) => {
    if (typeof txt === "string") {
        return valid();
    } else {
        return invalid("Not a valid string");
    }
};

export const isRequired = (v) => {
    if (!v) return invalid();
    if (!isString(v)) return invalid();
    if (v?.trim().length < 1) return invalid();
    return valid();
};

export const isPassword = (v) => {
    const options = {
        minLength: 8,
        minNumbers: 1,
        minSymbols: 1,
        minLowercase: 0,
        minUppercase: 0,
    };

    const valid = isStrongPassword(v, options);
    if (valid) return valid();
    return invalid("error");
};

export const isPhoneNumber = (v) => {
    const _v = v.replace(/(-)/g, "");
    if (!_v) return invalid();
    if (!isNumeric(_v)) return invalid("Not a valid phone number");
    if (_v.trim().length < 10 || _v.trim().length > 11)
        return invalid("Not a valid phone number");
    return valid();
};

export const isName = (v) => {
    if (!v) return invalid();
    return valid();
    /*
    if (!v) return Validator.valid();

    let regex1 = /\s\s/g;
    if (regex1.test(v))
        return Validator.invalid("Not a valid name");

    let regex2 = /[^a-zA-Z\s]/g;
    if (!regex2.test(v)) return Validator.valid();
    return Validator.invalid("Name cannot contains number or special characters");
    */
};

export const isLinkedinLink = (v) => {
    if (!v) return valid();

    if (
        v.toLowerCase().indexOf("linkedin.com/in/") < 0 &&
        v.toLowerCase().indexOf("linkedin.com/mwlite/in") < 0
    ) {
        return invalid(
            "Please enter valid url - url should contain 'linkedin.com/in/'"
        );
    }

    return valid();
};

export const isURL = (v) => {
    if (!v) return valid();
    if (!isURL(v)) return invalid("Not a valid URL");
    return valid();
};

export const isEmail = (v) => {
    if (!v) return valid();

    if (!validator.isEmail(v))
        return invalid("Please enter valid email address");
    // TODO add maxlength

    return valid();
};

export const isEmailId = (v) => {
    if (!v) return valid();
    return isEmail(v + "@fake.domain");
};

export const isDomain = (v) => {
    if (!v) return valid();

    if (!validator.isFQDN(v)) return invalid("Not a valid domain");

    return valid();
};

export const isNumeric = (v) => {
    if (!v) return valid();

    if (isNaN(v * 1)) return invalid("Not a number");

    return valid();
};

export const isAlphabets = (v) => {
    if (!v) return valid();

    const regex = /^[A-Za-z]+$/;
    if (!regex.test(v)) {
        return invalid("Not only alphabets");
    }

    return valid();
};

export const isAlphabetsOrWhitespace = (v) => {
    if (!v) return valid();

    const regex = /^[A-Za-z\s]+$/;
    if (!regex.test(v)) {
        return invalid("Not only alphabets");
    }

    return valid();
};

export const isAlphaNumeric = (v) => {
    if (!v) return valid();

    const regex = /^[A-Za-z0-9]+$/;
    if (!regex.test(v)) {
        return invalid("Not only alphabets");
    }

    return valid();
};

export const isLength = (length) => {
    return (v) => {
        if (!v) return valid();
        if (v.trim().length < length.min || v.trim().length > length.max) {
            return invalid("Length is invalid");
        }

        return valid();
    };
};

// export const isLength = (length) => {
//     return (v) => {
//         if (!v) return valid();
//
//         if (v.trim().length !== length) {
//             return invalid("Length is invalid");
//         }
//
//         return valid();
//     };
// };

export const isNotEmptyArray = (v) => {
    if (!(v instanceof Array)) return invalid();
    if (v.length < 1) return invalid();
    return valid();
};

export const removeComma = (v) => {
    return Number(v.replace(/,/g, ""));
};
