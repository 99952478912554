import React from "react";
import { Stack } from "@mui/material";
import Typo from "components/atoms/Typo";
import Colors from "themes/colors";
import ZigupButton from "../../atoms/ZigupButton";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import ideaImage from "assets/images/img-idea.png";

const Feedback = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const navigate = useNavigate();

    return (
        <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
            padding={2}
            margin={1}
            style={{ backgroundColor: "transparent" }}
        >
            <Stack>
                <Typo.Body1Bold
                    style={{
                        fontSize: "1.25rem",
                        textAlign: "center",
                        color: Colors.search_card_black,
                        fontWeight: 500,
                        fontFamily: "Lato",
                    }}
                >
                    We’re waiting for your feedbacks!
                </Typo.Body1Bold>
            </Stack>
            <Stack>
                <Typo.Body1
                    style={{
                        textAlign: "center",
                        fontFamily: "Roboto",
                        fontWeight: 400,
                    }}
                >
                    Send us your opinions about how to make Zigup community
                    better.
                </Typo.Body1>
            </Stack>
            <Stack
                style={{
                    width: isMobile ? "11rem" : "18rem",
                    height: isMobile ? "10rem" : "15rem",
                }}
            >
                <img
                    src={ideaImage}
                    alt="feedback-image"
                    width="100%"
                    height="100%"
                />
            </Stack>
            <Stack>
                <ZigupButton
                    id={"my-profile-feedback-btn"}
                    buttonText={"Send us feedbacks"}
                    containerStyle={{
                        width: "max-content",
                        height: "max-content",
                        padding: "0.5rem",
                        borderRadius: 4,
                        borderWidth: 0.5,
                        borderStyle: "solid",
                        borderColor: Colors.faq_text,
                        backgroundColor: "transparent",
                    }}
                    buttonTextSize={"1rem"}
                    buttonTextStyle={{ color: Colors.faq_text }}
                    onClickHandler={() => navigate("/contact-us")}
                />
            </Stack>
        </Stack>
    );
};
export default Feedback;
