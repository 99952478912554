import React, { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Container, Stack } from "@mui/material";
import GlobalHeader from "../../organisms/GlobalHeader";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Footer from "../../organisms/Footer";
import Colors from "themes/colors";

const MyPage = () => {
    const { userState } = useContext(AuthContext);
    const location = useLocation();

    return (
        <Stack
            direction="column"
            sx={{
                minHeight: "100vh",
                backgroundColor:
                    location.pathname === "/my/profile"
                        ? Colors.default_color
                        : Colors.white,
            }}
        >
            <GlobalHeader />
            {userState.auth ? (
                <Outlet />
            ) : (
                <Container maxWidth="sm" sx={{ flex: 1 }}>
                    <Navigate to="/" replace />
                </Container>
            )}
            <Footer />
        </Stack>
    );
};
export default MyPage;
