import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Colors from "themes/colors";
import Text from "components/atoms/Text";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, Stack } from "@mui/material";
import { useNavigate } from "react-router";
import { useState } from "react";
import { SEARCH_TYPE } from "constants/constants";
import { useMediaQuery } from "react-responsive";

const NormalTextFieldBase = styled(TextField)({
    height: 56,
    "& .MuiOutlinedInput-root": {
        borderWidth: 0,
        backgroundColor: Colors.white,
        borderTopLeftRadius: 50,
        borderBottomLeftRadius: 50,
        borderTopRightRadius: 50,
        borderBottomRightRadius: 50,
        "&.Mui-focused fieldset": {
            border: "1px black solid",
        },
    },
    "& input::placeholder": {
        fontSize: "14px",
    },
    "& .MuiOutlinedInput-input": {
        borderWidth: 0,
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
        },
    },
});

const SearchTag = styled.div`
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 37px;
    padding: 0.5rem 1rem;
    width: max-content;
    box-sizing: border-box;
    border: 1px ${Colors.search_tag_border} solid;
    border-radius: 20px;
    background-color: ${({ selected }) =>
        selected ? Colors.search_tag_background : null};
`;

const SearchTextField = (props) => {
    const {
        containerStyle,
        defaultType,
        prevSearchKeyword,
        showTags = true,
        ...rest
    } = props;

    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

    const [type, setType] = useState(defaultType || SEARCH_TYPE.ALL.value);
    const [searchValue, setSearchValue] = useState(prevSearchKeyword || "");

    const getUrl = (keyword, _searchType = type) => {
        switch (_searchType) {
            case SEARCH_TYPE.ALL.value:
                if (keyword) {
                    return `/search?keyword=${keyword}`;
                } else {
                    return "/search";
                }
            case SEARCH_TYPE.SALARY.value:
                if (keyword) {
                    return `/salary/list?keyword=${keyword}&page=1`;
                } else {
                    return "/salary/list";
                }
            case SEARCH_TYPE.COMPANY.value:
                if (keyword) {
                    return `/company/list?keyword=${keyword}&page=1`;
                } else {
                    return "/company/list";
                }
            case SEARCH_TYPE.JOB.value:
                if (keyword) {
                    return `/jd/list?keyword=${keyword}&page=1`;
                } else {
                    return "/jd/list";
                }
            case SEARCH_TYPE.DISCUSSIONS.value:
                if (keyword) {
                    return `/forum/list?type=discussion&keyword=${keyword}&page=1`;
                } else {
                    return `/forum/list?type=discussion`;
                }
            case SEARCH_TYPE.LFR.value:
                if (keyword) {
                    return `/forum/list?type=LFR&keyword=${keyword}&page=1`;
                } else {
                    return "/forum/list?type=LFR";
                }
            case SEARCH_TYPE.LFC.value:
                if (keyword) {
                    return `/forum/list?type=LFC&keyword=${keyword}&page=1`;
                } else {
                    return "/forum/list?type=LFC";
                }
        }
    };

    const renderSearchTypeTags = () => {
        if (!showTags) return null;
        return (
            <Stack
                direction="row"
                justifyContent={isMobile ? "flex-start" : "center"}
                spacing={1}
                sx={{
                    marginBottom: "1rem",
                    width: "100%",
                    height: "max-content",
                    overflowX: "scroll",
                    "::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
            >
                {Object.keys(SEARCH_TYPE).map((searchType) => {
                    const selected = type === SEARCH_TYPE[searchType].value;
                    return (
                        <SearchTag
                            key={searchType}
                            selected={selected}
                            onClick={() => {
                                setType(SEARCH_TYPE[searchType].value);
                                if (searchValue) {
                                    navigate(
                                        getUrl(
                                            searchValue,
                                            SEARCH_TYPE[searchType].value
                                        )
                                    );
                                }
                            }}
                        >
                            <Text
                                style={{
                                    color: selected
                                        ? Colors.white
                                        : Colors.salary_text_grey,
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {SEARCH_TYPE[searchType].text}
                            </Text>
                        </SearchTag>
                    );
                })}
            </Stack>
        );
    };

    return (
        <Stack
            direction="column"
            alignItems="center"
            sx={{
                width: "100vw",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                boxSizing: "border-box",
            }}
        >
            <Stack
                direction="column"
                alignItems="flex-start"
                style={{
                    display: "flex",
                    boxSizing: "border-box",
                    maxWidth: 600,
                    width: "100%",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    ...containerStyle,
                }}
                spacing={1}
            >
                <NormalTextFieldBase
                    id="salary-search"
                    value={searchValue}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            navigate(getUrl(searchValue));
                        }
                    }}
                    onChange={(e) => {
                        setSearchValue(e.target.value);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                    sx={{
                        outline: "none",
                        display: "flex",
                        flex: 1,
                        minWidth: "200px",
                    }}
                    {...rest}
                />
                {isMobile ? renderSearchTypeTags() : null}
            </Stack>
            {!isMobile ? renderSearchTypeTags() : null}
        </Stack>
    );
};

export default SearchTextField;
