import React from "react";
import MyOfferList from "../../organisms/MyOfferList";
import { Container, Stack } from "@mui/material";
import { useParams } from "react-router";
import GlobalHeader from "../../organisms/GlobalHeader";
import Footer from "../../organisms/Footer";

const TmpOfferListPage = () => {
    const params = useParams();

    return (
        <Stack direction="column" sx={{ minHeight: "100vh" }}>
            <GlobalHeader color="#7959f2" />
            <Container maxWidth="lg" sx={{ flex: 1 }}>
                <MyOfferList hash={params.hash} />
            </Container>
            <Footer />
        </Stack>
    );
};

export default TmpOfferListPage;
