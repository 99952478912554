import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SIGNUP_STEP } from "../../../constants/constants";
import VerifyMobile from "./VerifyMobile";
import SetPassword from "./SetPassword";
import SignIn from "./SignIn";
import zigupLogo from "assets/images/logo-zigup-color.png";
import Typo from "components/atoms/Typo";
import ReactGA from "react-ga4";

const SignInModal = ({
    open,
    closeModal,
    initialStep,
    handleClose,
    requestPath,
}) => {
    const [signUpStep, setSignUpStep] = useState({
        step: SIGNUP_STEP.SIGN_IN,
        params: {},
    });
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const getStyle = () => {
        return {
            content: {
                width: "80vw",
                minWidth: "300px",
                maxWidth: "500px",
                height: "max-content",
                minHeight: "370px",
                // maxHeight: "450px",
                // maxHeight: "100vh",
                backgroundColor: "white",
                border: "0",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                zIndex: 99,
            },
            overlay: {
                zIndex: 98,
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
        };
    };

    useEffect(() => {
        //배경 안움직이게 만드는 코드
        if (!open) return;
        document.body.style.cssText = `
          margin:0px;
          position: fixed; 
          top: -${window.scrollY}px;
          overflow-y: scroll;
          overflow-x: hidden;
          width: 100%;`;

        ReactGA.event({
            category: "Button",
            action: "signin_modal_opened",
            label: "sign_in",
        });

        return () => {
            ReactGA.event({
                category: "Button",
                action: "signin_modal_closed",
                label: "sign_in",
            });
            const scrollY = document.body.style.top;
            document.body.style.cssText = `margin:0px;`;
            window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
        };
    }, [open]);

    useEffect(() => {
        return () => setSignUpStep({ step: SIGNUP_STEP.SIGN_IN, params: null });
    }, [open]);

    const renderByStep = () => {
        const step = signUpStep.step ?? initialStep;
        switch (step) {
            case SIGNUP_STEP.SET_PASSWORD:
            case SIGNUP_STEP.SIGN_UP:
            case SIGNUP_STEP.FORGOT_PASSWORD:
                return (
                    <VerifyMobile
                        title={(title) => setTitle(title)}
                        mobileNumber={signUpStep.params?.mobileNumber}
                        nextStep={step}
                        onHandleStep={(step) => {
                            setSignUpStep(step);
                        }}
                        onHandleClose={(result) => {
                            doClose();
                            handleClose({
                                ...result,
                                requestPath: requestPath,
                            });
                        }}
                    />
                );
            case SIGNUP_STEP.SET_PASSWORD_AFTER_VERIFY:
                return (
                    <SetPassword
                        title={(title) => setTitle(title)}
                        setDescription={setDescription}
                        mobileNumber={signUpStep.params?.mobileNumber}
                        previousStep={signUpStep.params?.previousStep}
                        onHandleStep={(step) => setSignUpStep(step)}
                        onHandleClose={(result) => {
                            doClose();
                            handleClose({
                                ...result,
                                requestPath: requestPath,
                            });
                        }}
                    />
                );
            case SIGNUP_STEP.SIGN_IN:
                return (
                    <SignIn
                        title={(title) => setTitle(title)}
                        onHandleStep={(step) => setSignUpStep(step)}
                        onHandleClose={(result) => {
                            doClose();
                            handleClose({
                                ...result,
                                requestPath: requestPath,
                            });
                        }}
                    />
                );
        }
    };

    const doClose = () => {
        closeModal();
    };

    return (
        <Modal
            isOpen={open}
            style={getStyle()}
            contentLabel="signup Modal"
            onRequestClose={doClose}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            ariaHideApp={false}
        >
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                style={{ width: "100%", display: "flex" }}
            >
                <Stack
                    alignItems="flex-end"
                    style={{
                        width: "100%",
                    }}
                >
                    <CloseIcon
                        style={{ color: "gray", cursor: "pointer" }}
                        onClick={doClose}
                    />
                </Stack>
                <Stack
                    alignItems="center"
                    justifyContent="space-between"
                    style={{
                        width: "100%",
                    }}
                >
                    <Stack
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={0}
                    >
                        <img
                            src={zigupLogo}
                            alt="ZigupLogo"
                            height="50px"
                            width="auto"
                        />
                        <Stack
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            spacing={1}
                            sx={{ margin: "2rem" }}
                        >
                            <Typo.Body1
                                style={{
                                    fontSize: "1.25rem",
                                }}
                            >
                                {title}
                            </Typo.Body1>
                            {description ? (
                                <Typo.Body1
                                    style={{
                                        fontSize: "0.875rem",
                                        color: "#646464",
                                        textAlign: "center",
                                    }}
                                >
                                    {description}
                                </Typo.Body1>
                            ) : null}
                        </Stack>
                    </Stack>
                    {open ? renderByStep() : null}
                </Stack>
            </Stack>
        </Modal>
    );
};

export default SignInModal;
