import styled from "styled-components";
import Colors from "themes/colors";
import Text from "components/atoms/Text";

function getRootFontSize() {
    const defaultSize = 12.0;
    return defaultSize;
}

const RootFontSize = getRootFontSize();

const H1 = styled("h1")`
    font-family: Lato;
    font-weight: 700;
    word-break: keep-all;
    margin: 0;
`;

const H1Regular = styled("h1")`
    font-family: Lato;
    font-weight: 400;
    word-break: keep-all;
    margin: 0;
`;

const H2 = styled("h2")`
    font-family: Lato;
    font-weight: 700;
    word-break: keep-all;
    margin: 0;
`;

const H2Regular = styled("h2")`
    font-family: Lato;
    font-weight: 400;
    word-break: keep-all;
    margin: 0;
`;

const H3 = styled("h3")`
    font-family: Lato;
    font-weight: 700;
    word-break: keep-all;
    margin: 0;
`;
const H3Regular = styled("h3")`
    font-family: Lato;
    font-weight: 400;
    word-break: keep-all;
    margin: 0;
`;

const H4 = styled("h4")`
    font-family: Lato;
    font-weight: 700;
    word-break: keep-all;
    margin: 0;
`;
const H4Regular = styled("h4")`
    font-family: Lato;
    font-weight: 400;
    word-break: keep-all;
    margin: 0;
`;

const H5 = styled("h5")`
    font-family: Lato;
    font-weight: 700;
    word-break: keep-all;
    margin: 0;
`;
const H5Regular = styled("h5")`
    font-family: Lato;
    font-weight: 400;
    word-break: keep-all;
    margin: 0;
`;

const H6 = styled("h6")`
    font-family: Lato;
    font-weight: 700;
    word-break: keep-all;
    margin: 0;
`;
const H6Regular = styled("h6")`
    font-family: Lato;
    font-weight: 400;
    word-break: keep-all;
    margin: 0;
`;

const H7 = styled(Text)`
    font-size: ${1.5 * RootFontSize}px;
    font-family: Lato;
    font-weight: 700;
    word-break: keep-all;
`;

const Sub1 = styled(Text)`
    font-size: ${1.33 * RootFontSize}px;
    font-family: Roboto;
    font-weight: 500;
    word-break: keep-all;
`;

const Sub2 = styled(Text)`
    font-size: ${1.17 * RootFontSize}px;
    font-family: Roboto;
    font-weight: 500;
    word-break: keep-all;
`;

const Body1 = styled(Text)`
    font-size: ${1.33 * RootFontSize}px;
    font-family: Roboto;
    font-weight: 400;
    color: ${Colors.black};
    word-break: keep-all;
`;

const Body1Bold = styled(Text)`
    font-size: ${1.33 * RootFontSize}px;
    font-family: Roboto;
    font-weight: 700;
    color: ${Colors.black};
    word-break: keep-all;
`;

const Body2 = styled(Text)`
    font-size: ${1.17 * RootFontSize}px;
    font-family: Roboto;
    font-weight: 400;
    color: ${Colors.black};
    word-break: keep-all;
`;

const Body2Bold = styled(Text)`
    font-size: ${1.17 * RootFontSize}px;
    font-family: Roboto;
    font-weight: 700;
    color: ${Colors.black};
    word-break: keep-all;
`;

const Button1 = styled(Text)`
    font-size: ${1.17 * RootFontSize}px;
    font-family: Lato;
    font-weight: 400;
    color: ${Colors.black};
    word-break: keep-all;
`;

const Button2 = styled(Text)`
    font-size: ${1.17 * RootFontSize}px;
    font-family: Roboto;
    font-weight: 400;
    color: ${Colors.black};
    word-break: keep-all;
`;

const Caption = styled(Text)`
    font-size: ${RootFontSize}px;
    font-family: Roboto;
    font-weight: 400;
    color: ${Colors.black};
    word-break: keep-all;
`;

const Overline = styled(Text)`
    font-size: ${RootFontSize}px;
    font-family: Lato;
    font-weight: 400;
    color: ${Colors.black};
    word-break: keep-all;
`;

const Underlined = (component, color) => {
    return styled(component)`
        color: ${color};
        text-decoration: underline;
        text-decoration-color: ${color};
    `;
};

const Typo = {
    H1,
    H1Regular,
    H2,
    H2Regular,
    H3,
    H3Regular,
    H4,
    H4Regular,
    H5,
    H5Regular,
    H6,
    H6Regular,
    H7,
    Sub1,
    Sub2,
    Body1,
    Body1Bold,
    Body2,
    Body2Bold,
    Button1,
    Button2,
    Caption,
    Overline,
    Underlined,
    RootFontSize,
};

export default Typo;
