import { createTheme } from "@mui/material/styles";
import Colors from "./colors";

export const zigup = createTheme({
    palette: {
        zigup: {
            main: Colors.purple,
        },
    },
    components: {
        MuiChip: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: Colors.purple,
                },
                colorSecondary: {
                    backgroundColor: Colors.light_grey,
                },
            },
        },
    },
});

export const lightPurple = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: Colors.outline_2,
                },
                label: {
                    color: Colors.header_purple,
                },
            },
        },
    },
});

export const lightPurpleWithBorder = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                root: {
                    borderColor: Colors.purple,
                    borderWidth: 1,
                    borderStyle: "solid",
                },
                colorPrimary: {
                    backgroundColor: Colors.outline_2,
                },
                label: {
                    color: Colors.header_purple,
                    textAlign: "center",
                    verticalAlign: "middle",
                },
            },
        },
    },
});
