import Typo from "components/atoms/Typo";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import Colors from "themes/colors";

const CvItem = ({ file, handleDelete, type, handleSelected }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [hover, setHover] = useState(false);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeletePressed = async () => {
        handleClose();
        handleDelete(file._id);
    };

    const handleSelectPressed = async () => {
        handleSelected(file);
    };

    return (
        <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            style={{
                width: "100%",
                borderRadius: 8,
                borderWidth: 1,
                borderColor: Colors.light_grey_3,
                borderStyle: "solid",
                boxSizing: "border-box",
            }}
        >
            <Stack
                direction={"row"}
                onClick={() => handleSelectPressed()}
                spacing={2}
                padding={2}
                style={{
                    flex: 1,
                    display: "flex",
                    whiteSpace: "nowrap",
                    height: "100%",
                    cursor: "pointer",
                }}
            >
                {type === "selector" ? (
                    <input
                        type="radio"
                        name={"cv"}
                        style={{
                            cursor: "pointer",
                            width: "1rem",
                            height: "1rem",
                        }}
                    />
                ) : null}
                <Typo.Body1Bold
                    style={{
                        WebkitLineClamp: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        wordBreak: "break-all",
                    }}
                >
                    {file.name}
                </Typo.Body1Bold>
            </Stack>

            <Button
                id="cv-menu-button"
                aria-controls={open ? "cv-delete-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                onMouseEnter={() => {
                    setHover(true);
                }}
                onMouseLeave={() => {
                    setHover(false);
                }}
                style={{
                    backgroundColor: hover
                        ? "rgba(0, 0, 0, 0.1)"
                        : "transparent",
                }}
            >
                <MoreHorizIcon
                    sx={{ color: Colors.light_grey_3, fontSize: 16 }}
                />
            </Button>
            <Menu
                id="member-menu"
                aria-labelledby="member-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem onClick={() => handleDeletePressed()}>
                    <Typo.Body1>DELETE</Typo.Body1>
                </MenuItem>
            </Menu>
        </Stack>
    );
};
export default CvItem;
