import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import Text from "../../atoms/Text";
import Colors from "themes/colors";
import { Logger } from "utils";
import { salaryAdjoiningList } from "communication/salary";
import SalaryCard from "./SalaryCard";

const SalaryAdjointList = (props) => {
    const { id, isMobile } = props;
    const POST_COUNT = 4;
    const [isLoading, setIsLoading] = useState(true);
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        if (!id) return;
        fetchAdjointData();
    }, [id]);

    const fetchAdjointData = async () => {
        try {
            setIsLoading(true);
            const res = await salaryAdjoiningList(id);
            setPosts([...res.salaries]);
        } catch (e) {
            Logger.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    if (!id || posts === undefined || isLoading) {
        return (
            <Stack justifyContent="center" alignItems="center">
                <Grid container spacing={2}>
                    {new Array(POST_COUNT).fill()?.map((post, index) => (
                        <Grid
                            item
                            xs={isMobile ? 12 : 6}
                            key={index + "recommend_tmp"}
                        >
                            <SalaryCard />
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        );
    }
    if (posts.length < 1) {
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                style={{ height: "20vh" }}
            >
                <Text style={{ color: Colors.light_grey }}>
                    No other salary breakups
                </Text>
            </Stack>
        );
    } else {
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                    marginBottom: "3rem",
                }}
            >
                <Grid container spacing={2}>
                    {posts?.map((post, index) => (
                        <Grid
                            item
                            xs={isMobile ? 12 : 6}
                            key={index + "recommend"}
                        >
                            <SalaryCard salary={post} />
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        );
    }
};

export default SalaryAdjointList;
