import React, { useContext, useEffect, useRef, useState } from "react";
import Text from "components/atoms/Text";
import { useMediaQuery } from "react-responsive";
import Stack from "@mui/material/Stack";
import Colors from "themes/colors";
import { useLocation, useNavigate } from "react-router";
import NormalTextField from "components/atoms/NormalTextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { isRequired } from "utils/validator";
import InputAdornment from "@mui/material/InputAdornment";
import styled from "styled-components";
import SkillSetAutocomplete from "components/organisms/SkillSetAutocomplete";
import Checkbox from "@mui/material/Checkbox";
import TechnicalAreaSelector from "components/organisms/TechnicalAreaSelector";
import PreferenceLocationAutoComplete from "../../organisms/PreferenceLocationAutoComplete";
import { EXPERIENCE_TYPE, YOE } from "../../../constants/constants";
import NavigationGuard from "../../organisms/NavigationGuard";
import { useStateCallback } from "../../atoms/useStateCallback";
import Education from "../../organisms/Education";
import { signUp } from "../../../communication/membership";
import { Container } from "@mui/material";
import CompanyAutocomplete from "../../organisms/CompanyAutoComplete";
import NoticePeriodSelector from "components/organisms/NoticePeriodSelector";
import { AuthContext } from "../../../context/AuthContext";
import ZigupButton from "../../atoms/ZigupButton";
import ReactGA from "react-ga4";

const InputTitleContainer = styled.div`
    align-items: flex-start;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 2px;
`;

const SignUpSecondPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const [company, setCompany] = useState("");
    const [designation, setDesignation] = useState("");
    const [experience, setExperience] = useState("");
    const [mainSkillSet, setMainSkillSet] = useState([]);
    const [subSkillSet, setSubSkillSet] = useState([]);
    const [preferredLocations, setPreferredLocations] = useState([]);
    const [salary, setSalary] = useState({ current: "" });
    const [noticePeriod, setNoticePeriod] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [allowJobSuggestion, setAllowJobSuggestion] = useState(true);
    const agreeReceiveCheckBoxRef = useRef();
    const [techArea, setTechArea] = useState({
        area: { value: "" },
        role: { value: [] },
    });
    const [techAreaOthersRole, setTechAreaOthersRole] = useState("");
    const [activeGuard, setActiveGuard] = useStateCallback(true);
    const [educationCourse, setEducationCourse] = useState("");
    const [educationSpecialization, setEducationSpecialization] = useState("");
    const [educationUniversity, setEducationUniversity] = useState("");
    const { login } = useContext(AuthContext);

    const scrollToTop = () => window.scrollTo(0, 0);

    useEffect(() => {
        if (!state) navigate("/");
        scrollToTop();
        ReactGA.event({
            category: "SignUp",
            action: "sign_up_second_page_start",
            label: "sign_up",
        });
    }, [state]);

    useEffect(() => {
        const defaultCheck =
            mainSkillSet.length > 0 &&
            (techArea?.area?.value === "others"
                ? techAreaOthersRole
                : techArea?.role?.value.length) &&
            preferredLocations.length > 0;
        const check =
            state.isExperienced === EXPERIENCE_TYPE.EXPERIENCED
                ? defaultCheck &&
                  isRequired(company).valid &&
                  isRequired(designation).valid &&
                  isRequired(experience).valid &&
                  isRequired(salary.current).valid
                : defaultCheck &&
                  isRequired(educationCourse).valid &&
                  isRequired(educationSpecialization).valid &&
                  isRequired(educationUniversity).valid;

        setIsValid(check);
        return () => {
            setIsValid(false);
        };
    }, [
        company,
        designation,
        experience,
        mainSkillSet,
        salary,
        noticePeriod,
        techArea,
        techAreaOthersRole,
        educationCourse,
        educationSpecialization,
        educationUniversity,
        preferredLocations,
    ]);

    const doSignUp = async () => {
        setActiveGuard(false, async () => {
            let params = {
                phoneNumber: state.mobileNumber,
                password: state.password,
                name: state.name,
                isExperienced: state.isExperienced,
                customInfo:
                    techArea.area.value === "others"
                        ? {
                              area: {
                                  custom: {
                                      key: "others",
                                      value: techAreaOthersRole,
                                  },
                              },
                          }
                        : techArea,
                skillset: { main: mainSkillSet, sub: subSkillSet },
                preferred: { locations: preferredLocations },
            };

            if (state.isExperienced === EXPERIENCE_TYPE.EXPERIENCED) {
                params = {
                    ...params,
                    company,
                    designation,
                    relevantExperience: experience,
                    currentSalary: salary.current,
                };
            } else {
                params = {
                    ...params,
                    education: {
                        course: { value: educationCourse },
                        specialization: { value: educationSpecialization },
                        university: { value: educationUniversity },
                    },
                };
            }
            try {
                setIsLoading(true);
                ReactGA.event({
                    category: "SignUp",
                    action: "sign_up_second_page_done",
                    label: "sign_up",
                });
                const res = await signUp(params);
                if (res) {
                    ReactGA.event({
                        category: "SignUp",
                        action: "sign_up_done",
                        label: "sign_up",
                    });
                    login({
                        phoneNumber: state.mobileNumber.replace(/(-)/g, ""),
                        password: state.password,
                    }).then((isLogin) => {
                        if (isLogin) {
                            navigate(state.requestPath, { replace: true });
                        }
                    });
                } else {
                    alert("Failed to sign up. please try again");
                }
            } catch (e) {
                switch (e.response?.status) {
                    case 409:
                        navigate("./../done", {
                            state: { result: "duplicate" },
                        });
                        break;
                    default:
                        alert("Failed to Sign up. please try again");
                        break;
                }
            } finally {
                setIsLoading(false);
            }
        });
    };

    const renderExperience = () => {
        return (
            <>
                <CompanyAutocomplete
                    value={company}
                    setValue={setCompany}
                    isMobile={isMobile}
                    style={{ marginBottom: "1rem" }}
                    title="Current/Latest Company"
                />
                <NormalTextField
                    value={designation}
                    onChange={(event) => setDesignation(event.target.value)}
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    title="Designation"
                    placeholder="Your designation"
                />
                <div
                    style={{
                        marginBottom: "1rem",
                        width: "100%",
                    }}
                >
                    <InputTitleContainer>
                        <Text style={{ fontSize: 12 }}>
                            Years of relevant experience
                        </Text>
                    </InputTitleContainer>
                    <FormControl sx={{ width: "100%" }}>
                        <Select
                            id="experience-selector"
                            value={experience}
                            onChange={(event) =>
                                setExperience(event.target.value)
                            }
                            fullWidth={true}
                        >
                            {YOE.map((yoe) => (
                                <MenuItem
                                    key={"yoe" + yoe.value}
                                    value={"" + yoe.value}
                                >
                                    {yoe.value === 0 ? (
                                        <em>{yoe.text}</em>
                                    ) : (
                                        yoe.text
                                    )}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <NormalTextField
                    value={salary.current}
                    onChange={(event) =>
                        setSalary({ ...salary, current: event.target.value })
                    }
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    title="Current salary"
                    placeholder="e.g. 10"
                    type="number"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <Text style={{ color: Colors.purple_blue }}>
                                    Lakh per Annum
                                </Text>
                            </InputAdornment>
                        ),
                    }}
                />
            </>
        );
    };

    const renderFresher = () => {
        return (
            <Education
                isMobile={isMobile}
                educationCourse={educationCourse}
                setEducationCourse={setEducationCourse}
                educationSpecialization={educationSpecialization}
                setEducationSpecialization={setEducationSpecialization}
                educationUniversity={educationUniversity}
                setEducationUniversity={setEducationUniversity}
            />
        );
    };

    const renderInputs = () => {
        switch (state.isExperienced) {
            case EXPERIENCE_TYPE.FRESHER:
                return renderFresher();
            case EXPERIENCE_TYPE.EXPERIENCED:
                return renderExperience();
        }
    };

    return (
        <Container maxWidth="sm" sx={{ minHeight: "60vh" }}>
            <Stack
                direction="column"
                style={{ margin: "1rem" }}
                justifyContent="center"
                alignItems="center"
            >
                <NavigationGuard when={activeGuard} />
                <Stack
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{ width: "100%" }}
                >
                    <Text style={{ fontSize: 24, marginBottom: 16 }}>
                        Additional Information ( 2 / 2 )
                    </Text>
                </Stack>
                {renderInputs()}
                <TechnicalAreaSelector
                    isMobile={isMobile}
                    techArea={techArea}
                    setTechArea={setTechArea}
                    techAreaOthersRole={techAreaOthersRole}
                    setTechAreaOthersRole={setTechAreaOthersRole}
                />
                <SkillSetAutocomplete
                    value={mainSkillSet}
                    setValue={setMainSkillSet}
                    isMobile={isMobile}
                    maxCount={3}
                    title={"Main Skillsets (Max 3)"}
                />
                <SkillSetAutocomplete
                    value={subSkillSet}
                    setValue={setSubSkillSet}
                    isMobile={isMobile}
                    title={"Sub Skillsets (Optional)"}
                />
                <PreferenceLocationAutoComplete
                    value={preferredLocations}
                    setValue={setPreferredLocations}
                    isMobile={isMobile}
                />
                {state.isExperienced === EXPERIENCE_TYPE.EXPERIENCED ? (
                    <NoticePeriodSelector
                        title="Official Notice Period (Optional)"
                        noticePeriod={noticePeriod}
                        setNoticePeriod={setNoticePeriod}
                        getLastWorkingDay={false}
                    />
                ) : null}
                <ZigupButton
                    id={"sign-up-btn"}
                    buttonText={"Sign Up"}
                    buttonTextSize={"1.5rem"}
                    isLoading={isLoading}
                    disabled={!isValid || isLoading}
                    containerStyle={{ marginTop: "2rem" }}
                    onClickHandler={doSignUp}
                />
                <Stack
                    id="jd-agree-check-btn"
                    direction="row"
                    spacing={1}
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{
                        width: "100%",
                        cursor: "pointer",
                        marginLeft: "-1rem",
                        marginBottom: "1rem",
                    }}
                    onClick={() => {
                        agreeReceiveCheckBoxRef.current?.click();
                    }}
                >
                    <Checkbox
                        checked={!!allowJobSuggestion}
                        onChange={(e) => {
                            setAllowJobSuggestion(e.target.checked);
                        }}
                        inputRef={agreeReceiveCheckBoxRef}
                        style={{
                            pointerEvents: "none",
                        }}
                    />
                    <Text
                        style={
                            isMobile
                                ? { fontSize: "0.6rem", fontColor: "#646464" }
                                : { fontSize: "1rem", fontColor: "#646464" }
                        }
                    >
                        Receive job offers via Whatsapp
                    </Text>
                </Stack>
            </Stack>
        </Container>
    );
};

export default SignUpSecondPage;
