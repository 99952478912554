import React, { useState, useEffect, useContext } from "react";
import NormalTextField from "components/atoms/NormalTextField";
import InputMask from "react-input-mask";
import { Collapse, InputAdornment, Stack } from "@mui/material";
import Text from "components/atoms/Text";
import ZigupButton from "components/atoms/ZigupButton";
import Colors from "themes/colors";
import { requestOTP, verifyOTP } from "communication/membership";
import { Logger } from "utils";
import ReactGA from "react-ga4";
import { AlertContext } from "context/AlertContext";
import { ALERT_TYPE } from "constants/constants";
import { isPhoneNumber } from "utils/validator";

const MobileTextField = ({
    onChange,
    disabled,
    value = "",
    needOTP = false,
    onPress,
    isOtpValid,
    setIsOtpValid,
    containerStyle,
    showTitle,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [requestOTPAvailable, setRequestOTPAvailable] = useState(true);
    const [showOtpTextField, setShowOtpTextField] = useState(false);

    const [isMobileValid, setIsMobileValid] = useState(false);
    const [otpSubmitCnt, setOtpSubmitCnt] = useState(0);

    const [alertMessage, setAlertMessage] = useState({
        text: "",
        color: "#aaaaaa",
    });

    const [otp, setOTP] = useState("");
    const { openAlert } = useContext(AlertContext);

    useEffect(() => {
        setIsMobileValid(isPhoneNumber(value).valid);
    }, [value]);

    useEffect(() => {
        if (!otpSubmitCnt) return;
        setRequestOTPAvailable(false);
        setAlertMessage({
            text: "Kindly wait for 10 seconds to receive OTP",
            color: Colors.text_grey,
        });
        setTimeout(
            () => {
                setRequestOTPAvailable(true);
                setAlertMessage({ text: "", color: null });
            },
            otpSubmitCnt >= 5 ? 1000 * 60 * 10 : 10000
        );
    }, [otpSubmitCnt]);

    useEffect(() => {
        if (isOtpValid) {
            setAlertMessage({
                text: "Verified",
                color: Colors.success,
            });
        }
    }, [isOtpValid]);

    const sendOTP = async () => {
        if (!requestOTPAvailable) return;
        if (otpSubmitCnt >= 5) {
            openAlert({
                text: "Cannot send more OTP",
                alertType: ALERT_TYPE.ERROR,
            });
            return;
        }
        try {
            setIsLoading(true);
            setShowOtpTextField(true);
            setOtpSubmitCnt(otpSubmitCnt + 1);
            ReactGA.event({
                category: "Button",
                action: "request_otp",
                label: "sign_in",
            });
            openAlert({
                text: "Successfully sent OTP!",
                alertType: ALERT_TYPE.SUCCESS,
            });
            const res = await requestOTP({
                phoneNumber: value.replace(/(-)/g, ""),
            });
            if (res) {
                setIsRequestOTP(true);
            }
        } catch (e) {
            Logger.log("e", e);
        } finally {
            setIsLoading(false);
        }
    };

    const submitOTP = async () => {
        try {
            setIsLoading(true);
            ReactGA.event({
                category: "Button",
                action: "verify_otp_click",
                label: "sign_in",
            });
            const res = await verifyOTP({
                phoneNumber: value.replace(/(-)/g, ""),
                otp: otp,
            });

            if (res) {
                setShowOtpTextField(false);
                setIsOtpValid(true);
            }
        } catch (e) {
            openAlert({
                text: "OTP is not correct. Please try again",
                alertType: ALERT_TYPE.ERROR,
            });
            setAlertMessage({ text: "Wrong OTP", color: Colors.alert });
            setIsOtpValid(false);
            Logger.log("e", e);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Stack
            direction="column"
            sx={{
                width: "100%",
                marginBottom: !needOTP && showOtpTextField ? "0.5rem" : "1rem",
                ...containerStyle,
            }}
            spacing={1}
        >
            <InputMask
                mask={
                    value.replace(/(-)/g, "").startsWith("010") ||
                    value.replace(/(-)/g, "").startsWith("55555")
                        ? "999-9999-9999"
                        : "99999-99999"
                }
                value={value}
                disabled={disabled}
                onChange={(event) => onChange(event.target.value)}
                maskChar=""
            >
                {() => (
                    <Stack
                        direction="row"
                        alignItems="flex-start"
                        spacing={2}
                        sx={{ width: "100%" }}
                    >
                        <NormalTextField
                            title={showTitle ? "Mobile" : null}
                            fullWidth
                            placeholder="e.g. 99999-99999"
                            disabled={
                                disabled || isOtpValid || showOtpTextField
                            }
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    if (needOTP) sendOTP();
                                    if (onPress) onPress();
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Stack
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Text
                                                style={{
                                                    color: "gray",
                                                    fontSize: 14,
                                                }}
                                            >
                                                +91
                                            </Text>
                                        </Stack>
                                    </InputAdornment>
                                ),
                            }}
                            alertMessage={alertMessage.text}
                            alertMessageColor={alertMessage.color}
                        />
                        {needOTP ? (
                            <ZigupButton
                                id="otp-btn"
                                disabled={
                                    !isMobileValid ||
                                    !requestOTPAvailable ||
                                    isOtpValid
                                }
                                buttonText={
                                    isOtpValid
                                        ? "Verified"
                                        : otpSubmitCnt
                                        ? "Re-send OTP"
                                        : "Send OTP"
                                }
                                containerStyle={{
                                    width: "200px",
                                    marginTop: "1rem",
                                }}
                                onClickHandler={sendOTP}
                            />
                        ) : null}
                    </Stack>
                )}
            </InputMask>
            {needOTP ? (
                <Collapse in={showOtpTextField}>
                    <Stack
                        direction="row"
                        alignItems="flex-end"
                        spacing={2}
                        sx={{ width: "100%" }}
                    >
                        <NormalTextField
                            title="OTP"
                            fullWidth
                            value={otp}
                            placeholder="6 digits"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    submitOTP();
                                }
                            }}
                            onChange={(e) => {
                                setOTP(e.target.value);
                            }}
                            disabled={disabled}
                            inputProps={{ maxLength: 6 }}
                        />
                        <ZigupButton
                            id="otp-validation"
                            buttonText={"Verify"}
                            containerStyle={{ width: "200px" }}
                            onClickHandler={submitOTP}
                        />
                    </Stack>
                </Collapse>
            ) : null}
        </Stack>
    );
};

export default MobileTextField;
