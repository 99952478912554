import React from "react";
import Text from "components/atoms/Text";
import Stack from "@mui/material/Stack";
import Colors from "themes/colors";
import { getMobileOperatingSystem } from "utils/getMobileOperatingSystem";
import { useNavigate } from "react-router";
import ZigupButton from "../atoms/ZigupButton";

const Done = (props) => {
    const { isMobile, pageText } = props;
    const navigate = useNavigate();
    const sectionStyle = {
        marginTop: "2rem",
        padding: "1rem",
        width: "100%",
        height: "max-content",
        display: "flex",
    };

    const ReceiveMoreButtonHandler = () => {
        switch (getMobileOperatingSystem()) {
            case "Android":
                window.open(
                    "https://play.google.com/store/apps/details?id=in.zigup&hl=en&gl=US"
                );
                break;
            case "iOS":
                window.open("https://apps.apple.com/kr/app/zigup/id1562615317");
                break;
            default:
                navigate("/");
                break;
        }
    };

    return (
        <Stack
            direction="column"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
        >
            <Stack
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                spacing={isMobile ? 6 : 12}
                style={sectionStyle}
            >
                <Text style={{ color: Colors.purple_blue, fontSize: "3rem" }}>
                    {pageText.title}
                </Text>
                <Text style={{ whiteSpace: "pre-line", fontSize: "1rem" }}>
                    {pageText.desc[0]}
                    {pageText.desc[1]}
                    {pageText.email ? (
                        <>
                            <span style={{ color: Colors.blue }}>
                                {" "}
                                {`${pageText.email}`}
                            </span>
                            {pageText.desc[2]}
                        </>
                    ) : null}
                </Text>
            </Stack>
            <ZigupButton
                id="receive-more-btn"
                buttonText={isMobile ? "Receive more offers" : "Home"}
                buttonTextSize={"1rem"}
                containerStyle={{ width: "100%" }}
                onClickHandler={ReceiveMoreButtonHandler}
            />
        </Stack>
    );
};

export default Done;
