import React, { useContext, useEffect, useState } from "react";
import Text from "components/atoms/Text";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Skeleton,
    Stack,
} from "@mui/material";
import { Send } from "react-feather";
import { timeFromNow } from "utils/DateUtil";
import styled from "styled-components";
import SkillTag from "components/atoms/SkillTag";
import { useMediaQuery } from "react-responsive";
import commentIcon from "assets/icons/icon-comment.png";
import viewIcon from "assets/icons/icon-view.png";
import authorIcon from "assets/icons/person-lined-circle.png";
import Colors from "themes/colors";
import Comment from "components/organisms/Comment";
import ConfirmModal from "components/organisms/ConfirmModal";
import { useNavigate, useParams, useLocation } from "react-router";
import { detail, insight } from "../../../communication/salary";
import Typo from "../../atoms/Typo";
import RecommendSalaries from "../../organisms/salary/RecommendSalaries";
import ViewedCompanies from "../../organisms/salary/ViewedCompanies";
import Modal from "react-modal";
import CompanyLogo from "../../atoms/CompanyLogo";
import { FORUM_OBJECT_TYPE, FORUM_POST_TYPE } from "constants/constants";
import Comments from "../../organisms/salary/Comments";
import upvoteIcon from "assets/icons/icon-upvote-lined.png";
import dmIcon from "assets/icons/dm@2x.png";
import viewableFalseImg from "assets/images/illust-viewable-false.png";
import { Helmet } from "react-helmet-async";
import DownloadAppModal from "../../organisms/DownloadAppModal";
import { showSignUpModal } from "../../organisms/GlobalHeader";
import { AuthContext } from "../../../context/AuthContext";
import ShareMenu from "../../organisms/ShareMenu";
import ViewedCompanyList from "components/organisms/ViewedCompanyList";
import ReactGA from "react-ga4";
import ItemCard from "components/organisms/my/ItemCard";
import JdsByRole from "components/organisms/jd/JdsByRole";
import SalaryAdjointList from "components/organisms/salary/SalaryAdjointList";
import { capitalize, getRole } from "utils/TextUtils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Logger } from "utils";
import SalaryDetailInsightGraph from "components/organisms/salary/SalaryDetailInsightGraph";
import ContributeZigup from "components/organisms/ContributeZigup";

const RowContainer = styled(Stack)`
    flex-direction: row;
    align-items: center;
`;

const TagsContainer = styled(Stack)`
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 4px;
`;

const CountText = styled(Text)`
    margin-right: 16px;
    color: #646464;
`;

const ViewedCompanyModalContainer = styled(Stack)`
    background-color: white;
    margin: 1rem;
    align-items: center;
    justify-content: space-evenly;
`;

const ViewedCompanyModalContentsContainer = styled(Stack)`
    width: 100%;
    background-color: white;
    align-items: center;
    padding: 1rem;
    justify-content: center;
`;

const ViewedCompanyModalButtonContainer = styled(Stack)`
    width: 100%;
    background-color: white;
    padding: 1rem;
    align-items: center;
    border-top-color: #e5e5e5;
    border-top-width: 1px;
    justify-content: center;
    cursor: pointer;
`;

const ViewedCompaniesContainer = styled(Stack)`
    align-items: flex-start;
    justify-content: center;
    background-color: #f2f1f3;
    width: 90%;
    margin-bottom: 16px;
    padding: 12px;
`;

const CompanyText = styled(Text)`
    font-size: 16px;
    margin-left: 12px;
    color: #1f1534;
`;

const SalaryDetailPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userState } = useContext(AuthContext);
    const params = useParams();
    const MAX_SIMILAR_SALARY_COUNT = 8;
    const [salary, setSalary] = useState({});
    const [salaryInsight, setSalaryInsight] = useState({});
    const [pageText, setPageText] = useState({
        commentLabel: "Comment",
        no_comment: "No comment",
        unable_comment_placeholder: "You can't add a comment to notice",
        placeholder: "add a comment",
        load_more: "Load more",
        thread_load_more: "View previous replies",
        delete_comment: "Deleted comment",
        no_company_univ_placeholder:
            "You can add comment after adding your company/college at My Profile",
        content_questions: {
            route: "How did you apply for the job?",
            interviewRounds: "What were the interview rounds you had?",
            preparations: "What were your preparations to get this offer?",
            questions:
                "What kind of questions were you asked in the interview?",
            culture: "If joined, how is actual work & culture like?",
            negotiation: "How was your salary negotiation process?",
        },
    });
    // const [showMoreAboutAuthor, setShowMoreAboutAuthor] = useState(true);
    const [isApplyModalVisible, setIsApplyModalVisible] = useState(false);
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const [totalCommentCount, setTotalCommentCount] = useState(0);
    const [viewable, setViewable] = useState(true);
    const [confirmModalState, setConfirmModalState] = useState({
        open: false,
        title: "",
        content: "",
        image: {
            src: "",
            alt: "",
        },
        button: {
            confirm: {
                text: "",
                id: "",
                handle: () => undefined,
            },
            reject: {
                text: "",
                id: "",
                handle: () => undefined,
            },
        },
        closeModal: () => undefined,
        closeOnEsc: true,
        closeOnOverlayClick: true,
    });
    const [
        isViewedFullCompanyModalVisible,
        setIsViewedFullCompanyModalVisible,
    ] = useState(false);
    const [isViewedCompanyModalVisible, setIsViewedCompanyModalVisible] =
        useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const openConfirmModal = (params) => {
        setConfirmModalState(params);
    };

    const closeConfirmModal = () => {
        setConfirmModalState({
            ...confirmModalState,
            open: false,
        });
    };

    const handleConfirmShareSalaryPressed = () => {
        closeConfirmModal();
        userState.auth ? navigate("/salary/add") : showSignUpModal("");
    };

    const handleRejectShareCompensationPressed = () => {
        closeConfirmModal();
        navigate("/");
    };

    useEffect(() => {
        ReactGA.event({
            category: "Salary",
            action: "page_view_salary",
            label: "salary",
        });
        fetchData(userState.auth);
    }, [userState.auth]);

    useEffect(() => {
        if (!viewable && !isLoading) {
            openConfirmModal({
                open: true,
                title: "Sign up to view the whole data",
                image: {
                    src: viewableFalseImg,
                    alt: "share salary image",
                },
                button: {
                    confirm: {
                        text: userState.auth
                            ? "Share Offer data"
                            : "SIGN UP AND ADD OFFER DATA",
                        id: "login-signup-btn",
                        handle: handleConfirmShareSalaryPressed,
                    },
                },
                closeModal: closeConfirmModal,
                closeOnEsc: false,
                closeOnOverlayClick: false,
            });
        }
        return () => closeConfirmModal();
    }, [viewable, userState.auth, isLoading]);

    useEffect(() => {
        fetchInsightData();
    }, [salary]);

    const fetchData = async (isAuth) => {
        try {
            setIsLoading(true);
            const res = await detail(params.id, isAuth);
            setSalary(res.salary);
            setViewable(res.viewable);
        } catch (e) {
            navigate("/");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchInsightData = async () => {
        try {
            if (!salary?.role) return;
            const res = await insight({ role: salary.role });
            if (res.salaryInsight) setSalaryInsight(res.salaryInsight);
        } catch (e) {
            Logger.error(e);
        }
    };

    const getString = (text) => {
        if (text === undefined) return;
        if (text.substr(text.length - 5, 5) === "<br/>") {
            text = text.substr(0, text.length - 5);
        }
        const tmp = text.replace(/<br[/]>/g, "\n");
        return " - " + tmp;
    };

    const getFaqs = () => {
        const role = salary?.role ? capitalize(getRole(salary?.role)) : "";
        const designation = salary?.title ? capitalize(salary?.title) : "";

        const _faqs = [];
        const questions = [];

        for (const [index, _] of new Array(5).fill("").entries()) {
            try {
                let question = "";
                switch (index) {
                    case 0:
                        question = `What is the salary of ${designation}(${role}) in India?`;
                        break;

                    case 1:
                        question = `What is the minimum salary for ${designation}(${role}) in India?`;
                        break;

                    case 2:
                        question = `What is the highest salary for ${designation}(${role}) in India?`;
                        break;

                    case 3:
                        question = `Where are this role paid ${designation}(${role}) in India?`;
                        break;

                    case 4:
                        question = `What is the monthly take home salary of ${designation}(${role})?`;
                        break;
                }
                questions.push(question);
            } catch (e) {
                let question = "";
                switch (index) {
                    case 0:
                        question = `What is the salary of this role in India?`;
                        break;

                    case 1:
                        question = `What is the minimum salary for this role in India?`;
                        break;

                    case 2:
                        question = `What is the highest salary for this role in India?`;
                        break;

                    case 3:
                        question = `Where are this role paid the most in India?`;
                        break;

                    case 4:
                        question = `What is the monthly take home salary of this role?`;
                        break;
                }
                questions.push(question);
            }
        }
        for (const [index, question] of questions.entries()) {
            try {
                const yoe = salary?.yearsOfExperience;
                const expIndex = yoe < 3 ? 0 : yoe < 6 ? 1 : yoe < 11 ? 2 : 3;
                const {
                    id,
                    data: { average, std, min, max, count },
                } = salaryInsight.salaryStatisticsByExp[expIndex];
                let answer = "";
                switch (index) {
                    case 0:
                        answer = `The average salary for ${designation}(${role}) with ${id} is approximately ${(
                            average || 0
                        ).toFixed(
                            1
                        )} LPA(Lakh per Annum) with standard deviation of ${(
                            std || 0
                        ).toFixed(1)} LPA(Lakh per Annum).`;
                        break;
                    case 1:
                        answer = `There is no minimum salary for ${designation}(${role}) in India, however, the lowest salary for  ${designation}(${role}) with ${id} of Experience is approximately ${(
                            min || 0
                        ).toFixed(
                            1
                        )} LPA(Lakh per Annum). You can check out more salary ranges by clicking a node in the above Salary Insight Graphs.`;
                        break;
                    case 2:
                        answer = `The highest salary for ${designation}(${role}) with ${id} of Experience is ${(
                            max || 0
                        ).toFixed(
                            1
                        )} LPA (Lakh per Annum) from Top Product Based Company. You can check out more salary ranges by clicking a node in the above Salary Insight Graphs.`;
                        break;
                    case 3:
                        answer =
                            "We're still collecting data in order to provide you with accurate information.";
                        break;
                    case 4:
                        answer = `In India, ${designation}(${role}) can expect to earn an average take-home pay of INR ${(
                            (average / 12 || 0) * 100000
                        ).toFixed(
                            1
                        )}. However, this is dependent on experience and company. The monthly salary can range between INR ${(
                            (min / 12 || 0) * 100000
                        ).toFixed(1)} and INR ${(
                            (max / 12 || 0) * 100000
                        ).toFixed(1)}.`;
                        break;
                }
                _faqs.push({
                    question,
                    answer,
                });
            } catch (e) {
                _faqs.push({
                    question,
                    answer: "We're still collecting data in order to provide you with accurate information.",
                });
            }
        }
        return _faqs;
    };

    const onApplyClicked = () => {
        setIsApplyModalVisible(true);
    };

    const onViewedCompanyListPressed = () => {
        //todo 내 post 인 경우 처리 해야 됨
        // const isMine = !!compensation?.isMine;
        // if (isMine) {
        //     setIsViewedFullCompanyModalVisible(true);
        // } else {
        //     setIsViewedCompanyModalVisible(true);
        // }
        setIsViewedCompanyModalVisible(true);
    };

    const onModalPressHandler = () => {
        setIsViewedCompanyModalVisible(false);
        setIsViewedFullCompanyModalVisible(false);
        setIsApplyModalVisible(false);
    };

    const renderSeparator = (thickness = 1) => {
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                style={{
                    width: "100%",
                    height: thickness,
                    backgroundColor: Colors.light_grey,
                }}
            />
        );
    };

    const renderTitle = () => {
        return (
            <Stack direction="row" justifyContent="space-between">
                <Typo.H1Regular
                    style={{
                        fontSize: "1.5rem",
                        marginBottom: "1rem",
                    }}
                >
                    {isLoading || !salary ? (
                        <Skeleton
                            variant="text"
                            animation="wave"
                            width={isMobile ? 200 : 600}
                            height={30}
                        />
                    ) : (
                        `${salary.company} | ${salary.title} | ${salary.yearsOfExperience}year(s)`
                    )}
                </Typo.H1Regular>
                <Stack justifyContent={"center"} alignItems={"center"}>
                    <ShareMenu url={window.location.href} iconSize={"1.2rem"} />
                </Stack>
            </Stack>
        );
    };

    const renderSubTitle = () => {
        return (
            <Stack
                direction="row"
                style={{
                    marginBottom: "1rem",
                }}
            >
                {isLoading || !salary ? (
                    <Skeleton variant="text" animation="wave" width={200} />
                ) : (
                    <Text
                        style={{
                            fontSize: "1rem",
                            color: Colors.anonymous_grey,
                            margin: 0,
                        }}
                    >
                        {salary.writer?.displayName}
                        {"  |  "}
                        {timeFromNow(salary?.createdAt)}
                    </Text>
                )}
            </Stack>
        );
    };

    const renderContents = () => {
        return (
            <Stack direction={"column"} spacing={2}>
                <Stack justifyContent={"flex-start"} style={{ width: "100%" }}>
                    <Typo.H3
                        style={{
                            fontFamily: "Roboto",
                            fontSize: "1rem",
                            margin: 0,
                            fontWeight: 500,
                        }}
                    >
                        Detailed Salary Breakup
                    </Typo.H3>
                </Stack>
                {isLoading || !salary ? (
                    <Stack direction={"column"} spacing={2}>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width="100%"
                            height={isMobile ? "50vh" : 250}
                            sx={{
                                backgroundColor: Colors.salary_text_background,
                            }}
                        />
                        <div style={{ height: 100 }} />
                    </Stack>
                ) : (
                    <Stack direction={"column"} spacing={2}>
                        <Stack
                            style={{
                                padding: "1rem",
                                backgroundColor: Colors.salary_text_background,
                                fontFamily: "Roboto",
                                fontWeight: 400,
                                fontSize: "1rem",
                                lineHeight: "1.5rem",
                            }}
                            justifyContent="flex-start"
                            alignItems="start"
                            dangerouslySetInnerHTML={{
                                __html: salary?.organizedContent,
                            }}
                        />
                        {salary?.route ? (
                            <Stack direction={"column"} spacing={1}>
                                <Typo.Sub1>
                                    {pageText.content_questions.route}
                                </Typo.Sub1>
                                <Text>{getString(salary.route)}</Text>
                            </Stack>
                        ) : null}
                        {salary?.interviewRounds ? (
                            <Stack direction={"column"} spacing={1}>
                                <Typo.Sub1>
                                    {pageText.content_questions.interviewRounds}
                                </Typo.Sub1>
                                <Text>{getString(salary.interviewRounds)}</Text>
                            </Stack>
                        ) : null}
                        {salary?.preparations ? (
                            <Stack direction={"column"} spacing={1}>
                                <Typo.Sub1>
                                    {pageText.content_questions.preparations}
                                </Typo.Sub1>
                                <Text>{getString(salary.preparations)}</Text>
                            </Stack>
                        ) : null}
                        {salary?.questions ? (
                            <Stack direction={"column"} spacing={1}>
                                <Typo.Sub1>
                                    {pageText.content_questions.questions}
                                </Typo.Sub1>
                                <Text>{getString(salary.questions)}</Text>
                            </Stack>
                        ) : null}
                        {salary?.culture ? (
                            <Stack direction={"column"} spacing={1}>
                                <Typo.Sub1>
                                    {pageText.content_questions.culture}
                                </Typo.Sub1>
                                <Text>{getString(salary.culture)}</Text>
                            </Stack>
                        ) : null}
                        {salary?.negotiation ? (
                            <Stack direction={"column"} spacing={1}>
                                <Typo.Sub1>
                                    {pageText.content_questions.negotiation}
                                </Typo.Sub1>
                                <Text>{getString(salary.negotiation)}</Text>
                            </Stack>
                        ) : null}
                    </Stack>
                )}
            </Stack>
        );
    };

    const renderUserInfo = () => {
        return (
            <Stack
                direction="column"
                style={{
                    width: "100%",
                }}
                spacing={1}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={0.5}
                >
                    <img
                        src={authorIcon}
                        alt="authorIcon"
                        width={isMobile ? 18 : 24}
                        height={isMobile ? 18 : 24}
                    />
                    <Text
                        style={{
                            color: "#433f4a",
                            fontSize: "1rem",
                        }}
                    >
                        Author's technical area
                    </Text>
                </Stack>
                {isLoading ? (
                    <TagsContainer>
                        <Skeleton variant="rectangular" width={120} />
                    </TagsContainer>
                ) : (
                    <TagsContainer>
                        {salary?.role ? <SkillTag skill={salary.role} /> : null}
                        {salary?.skillset?.map((item, index) => (
                            <SkillTag key={item} skill={item} />
                        ))}
                    </TagsContainer>
                )}
            </Stack>
        );
    };

    const renderDirectMessage = () => {
        const show = !salary?.isMine;
        return show ? (
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                    backgroundColor: Colors.salary_text_background,
                    borderRadius: 4,
                    cursor: "pointer",
                    width: "100%",
                    minHeight: "3rem",
                }}
                onClick={onApplyClicked}
            >
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Text style={{ color: "#333333", marginRight: 2 }}>DM</Text>
                    <div>
                        <Send
                            size={12}
                            color={"#333333"}
                            style={{ marginRight: 4 }}
                        />
                    </div>
                </Stack>
            </Stack>
        ) : null;
    };

    const renderCount = () => {
        return (
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{
                    width: "100%",
                    height: "3rem",
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{
                        flexWrap: "wrap",
                    }}
                    spacing={2}
                >
                    {/* <UpvoteButton
                        item={compensation}
                        setItem={setCompensation}
                        isMobile={isMobile}
                    /> */}
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={0.5}
                    >
                        <img
                            src={upvoteIcon}
                            alt="upvoteIcon"
                            width={isMobile ? 16 : 24}
                            height={isMobile ? 16 : 24}
                        />
                        <CountText>
                            {typeof salary.upvoteCount === "number"
                                ? salary.upvoteCount
                                : "-"}
                        </CountText>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={0.5}
                    >
                        <img
                            src={viewIcon}
                            alt="viewIcon"
                            width={isMobile ? 16 : 24}
                            height={isMobile ? 16 : 24}
                        />
                        <CountText>
                            {typeof salary.viewCount === "number"
                                ? salary.viewCount
                                : "-"}
                        </CountText>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={0.5}
                    >
                        <img
                            src={commentIcon}
                            alt="commentIcon"
                            width={isMobile ? 16 : 24}
                            height={isMobile ? 16 : 24}
                        />
                        <CountText>
                            {typeof salary.commentCount === "number"
                                ? salary.commentCount
                                : "-"}
                        </CountText>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={0.5}
                    >
                        <img
                            src={dmIcon}
                            alt="dmIcon"
                            width={isMobile ? 16 : 24}
                            height={isMobile ? 16 : 24}
                        />
                        <CountText>
                            {typeof salary.dmCount === "number"
                                ? salary.dmCount
                                : "-"}
                        </CountText>
                    </Stack>
                    <ViewedCompanyList
                        item={salary}
                        onPress={onViewedCompanyListPressed}
                        isMobile={isMobile}
                    />
                </Stack>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        marginLeft: "3rem",
                        height: "100%",
                        maxWidth: "40%",
                        flex: 1,
                    }}
                >
                    {isMobile ? null : renderDirectMessage()}
                </Stack>
            </Stack>
        );
    };

    const renderItem = ({ item, index }) => (
        <Stack style={{ flex: 1 }}>
            <Comment
                objectType={FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFR}
                navigation={props.navigation}
                viewType={COMMENT_VIEW_TYPE.COMMENT}
                comment={item}
                updateCommentData={updateCommentData}
                isNotice={!!salary?.isNotice}
                eventHandler={commentEventHandler}
                setBackgroundColor={
                    isEditingComment
                        ? item.isEditing
                            ? "transparent"
                            : "rgba(0,0,0,0.05)"
                        : "transparent"
                }
                isFirstComment={index === 0}
                setDisableDots={isEditingComment ? !item.isEditing : false}
                requiredCredit={salary?.isMine ? 0 : null}
            />
        </Stack>
    );

    const renderViewedFullCompanyModal = () => {
        return (
            <Modal
                isOpen={isViewedFullCompanyModalVisible}
                style={{ margin: 0 }}
                contentLabel="renderViewedFullCompanyModal Modal"
                onRequestClose={() => setIsViewedFullCompanyModalVisible(false)}
                shouldCloseOnOverlayClick
                shouldCloseOnEsc
                ariaHideApp={false}
            >
                <ViewedCompanies
                    postId={salary._id}
                    postType={FORUM_POST_TYPE.SALARY}
                    onGoBack={() => setIsViewedFullCompanyModalVisible(false)}
                />
            </Modal>
        );
    };

    const renderDownloadAppModal = () => {
        return (
            <DownloadAppModal
                open={isApplyModalVisible}
                close={() => setIsApplyModalVisible(false)}
            />
        );
    };

    const renderViewedCompanyModal = () => {
        const customModalStyles = {
            content: {
                width: "max-content",
                maxWidth: "90%",
                height: "max-content",
                maxHeight: "60%",
                backgroundColor: "white",
                paddingLeft: "0",
                paddingRight: "0",
                border: "0",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
            },
            overlay: {
                top: "0",
                left: "0",
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
        };
        return (
            <Modal
                isOpen={isViewedCompanyModalVisible}
                style={customModalStyles}
                contentLabel="Applicant Modal"
                onRequestClose={onModalPressHandler}
                shouldCloseOnOverlayClick
                shouldCloseOnEsc
                ariaHideApp={false}
            >
                <ViewedCompanyModalContainer>
                    <ViewedCompanyModalContentsContainer>
                        <ViewedCompaniesContainer>
                            <RowContainer
                                style={{
                                    justifyContent: "center",
                                }}
                            >
                                <div
                                    style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginRight: 4,
                                    }}
                                >
                                    <img
                                        src={viewIcon}
                                        alt="viewIcon"
                                        width={isMobile ? 18 : 24}
                                        height={isMobile ? 18 : 24}
                                    />
                                </div>
                                <Text
                                    style={{
                                        color: "#1f1534",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    An employer list of those who viewed the
                                    offer post
                                </Text>
                            </RowContainer>
                            {salary.companiesOfViewer?.map((item, index) => (
                                <RowContainer
                                    key={index}
                                    style={{ marginTop: 12 }}
                                >
                                    <CompanyLogo
                                        style={{
                                            width: 36,
                                            height: 36,
                                            borderRadius: 8,
                                            backgroundColor: "#f6f6f6",
                                        }}
                                        source={item.logoURL}
                                    />
                                    <CompanyText>{item.name}</CompanyText>
                                </RowContainer>
                            ))}
                            {salary.companiesOfViewerLength > 3 ? (
                                <Text
                                    style={{
                                        color: "#646464",
                                        fontSize: 16,
                                        marginLeft: 48,
                                        height: 36,
                                        textAlignVertical: "center",
                                    }}
                                >
                                    and {salary.companiesOfViewerLength - 3}{" "}
                                    more ...
                                </Text>
                            ) : null}
                        </ViewedCompaniesContainer>
                        <Text style={{ color: "#1f1534", fontSize: 16 }}>
                            The full list is accessible only to the authors.
                        </Text>
                    </ViewedCompanyModalContentsContainer>
                    <ViewedCompanyModalButtonContainer
                        onClick={onModalPressHandler}
                    >
                        <Text style={{ color: "#6837f2" }}>GOT IT</Text>
                    </ViewedCompanyModalButtonContainer>
                </ViewedCompanyModalContainer>
            </Modal>
        );
    };

    const renderRightSide = () => {
        return (
            <Stack direction="column" spacing={8} sx={{ flex: 1 }}>
                {renderRecommendCompensation()}
                {renderSameRoleJdList()}
            </Stack>
        );
    };

    const renderRecommendCompensation = () => {
        return (
            <Stack direction={"column"} spacing={2}>
                <Stack justifyContent={"flex-start"} style={{ width: "100%" }}>
                    <Typo.H3Regular
                        style={{
                            fontSize: "1rem",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                        }}
                    >
                        Similar Salary Breakups to this post
                    </Typo.H3Regular>
                </Stack>
                <RecommendSalaries
                    id={salary._id}
                    isMobile={isMobile}
                    maxCount={MAX_SIMILAR_SALARY_COUNT}
                />
            </Stack>
        );
    };

    const renderSameRoleJdList = () => {
        return (
            <Stack
                direction={"column"}
                style={{
                    flex: 1,
                    marginBottom: isMobile ? "1rem" : null,
                }}
                spacing={2}
            >
                <Stack justifyContent={"flex-start"} style={{ width: "100%" }}>
                    <Typo.H3Regular
                        style={{
                            fontSize: "1rem",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                        }}
                    >
                        Apply for jobs
                    </Typo.H3Regular>
                </Stack>
                <JdsByRole role={salary?.role} isMobile={true} />
            </Stack>
        );
    };

    const renderComments = () => {
        return (
            <Stack
                spacing={2}
                style={{
                    width: "100%",
                }}
            >
                <Stack
                    justifyContent={"flex-start"}
                    style={{
                        width: "100%",
                        marginBottom: "2rem",
                    }}
                >
                    <Typo.Body1 style={{ fontSize: "1rem", fontWeight: 500 }}>
                        Comments
                    </Typo.Body1>
                </Stack>

                <Stack
                    justifyContent={"flex-start"}
                    sx={{ width: "100%", minHeight: "20vh" }}
                >
                    {salary._id ? (
                        <Comments
                            id={salary._id}
                            isNotice={false}
                            objectType={FORUM_OBJECT_TYPE.SALARY}
                        />
                    ) : null}
                </Stack>
            </Stack>
        );
    };

    const renderContribute = () => {
        return (
            <ContributeZigup
                title="Contribute to Zigup Community"
                description="Don't worry. Your details will be shared anonymously"
            />
        );
    };

    const renderGraph = () => {
        let title = "";
        if (!Object.keys(salary).length || !Object.keys(salaryInsight).length) {
            title = "Salary Insights";
        } else {
            const yoe = salary?.yearsOfExperience;
            const index = yoe < 3 ? 0 : yoe < 6 ? 1 : yoe < 11 ? 2 : 3;
            title = `Salary Insights for ${getRole(salary.role)}${
                salaryInsight.salary && salaryInsight.salary.length
                    ? `, ${salaryInsight.salary[index].id}`
                    : ""
            }`;
        }

        return (
            <Stack
                spacing={2}
                style={{
                    width: "100%",
                }}
            >
                <Stack
                    justifyContent={"flex-start"}
                    style={{
                        width: "100%",
                    }}
                    spacing={1}
                >
                    <Typo.H2Regular
                        style={{
                            fontSize: "1rem",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                        }}
                    >
                        {title}
                    </Typo.H2Regular>
                </Stack>
                <SalaryDetailInsightGraph
                    salary={salary}
                    salaryInsight={salaryInsight}
                    auth={!!userState.auth}
                />
            </Stack>
        );
    };

    const renderFaqs = () => {
        const role = getRole(salary?.role);
        const _faqs = getFaqs();
        return (
            <Stack
                spacing={2}
                style={{
                    width: "100%",
                }}
            >
                <Stack
                    justifyContent={"flex-start"}
                    style={{
                        width: "100%",
                    }}
                    spacing={1}
                >
                    <Typo.H2Regular
                        style={{
                            fontSize: "1rem",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                        }}
                    >
                        {`${salary?.title ? capitalize(salary?.title) : ""} (${
                            salary?.role ? capitalize(role) : ""
                        }) Salary FAQs`}
                    </Typo.H2Regular>
                </Stack>
                <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={0}
                    sx={{
                        width: "100%",
                    }}
                >
                    {_faqs.map((item, index) => {
                        return (
                            <Accordion
                                key={"faq_" + index}
                                disableGutters={true}
                                sx={{
                                    border: `1px ${Colors.outline_2} solid;`,
                                    elevation: 0,
                                    zIndex: 0,
                                    width: "100%",
                                    boxShadow: "none",
                                    padding: "0.5rem",
                                    boxSizing: "border-box",
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Text
                                        style={{
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "0.875rem",
                                        }}
                                    >
                                        {item.question}
                                    </Text>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Text
                                        style={{
                                            fontFamily: "Roboto",
                                            fontSize: "0.875rem",
                                            color: Colors.salary_text_grey,
                                            whiteSpace: "pre-line",
                                        }}
                                    >
                                        {item.answer}
                                    </Text>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </Stack>
            </Stack>
        );
    };

    const renderAdjointSalaryList = () => {
        return (
            <Stack
                direction="column"
                style={{
                    flex: 1,
                    marginBottom: "2rem",
                    width: "100%",
                }}
                spacing={2}
            >
                <Stack justifyContent={"flex-start"} style={{ width: "100%" }}>
                    <Typo.H3Regular
                        style={{
                            fontSize: "1rem",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                        }}
                    >
                        Other Salary Breakups
                    </Typo.H3Regular>
                </Stack>
                <SalaryAdjointList id={salary._id} isMobile={isMobile} />
            </Stack>
        );
    };

    const getSEOTitle = () => {
        return Object.keys(salary).length
            ? salary.company +
                  " | " +
                  salary.title +
                  " | " +
                  salary.yearsOfExperience +
                  "year(s) - Zigup"
            : "Zigup Salaries";
    };

    const getSEODescription = () => {
        const targetUrls = [
            "6205f277b1c324956a4ec823",
            "61d3dbab932d395c49013b55",
            "62301b19a40df396c7434755",
            "61d3ffba965dbe5beaee1700",
            "624891e8bead0c26860ae49b",
            "61e776818673b0f50b1de593",
            "62029188ae61f6cdd5b6704c",
            "61e26bccb737128db31c3d4c",
            "61d44cffe578105bf08e92af",
            "6232b62b53451796719a2cf4",
            "62c16ed4653f6dd3cba274bb",
            "61d7e43e2ab8a99f5e4160bf",
            "62d0c3140e7589eb14afdf97",
            "626b5db5329daa6dad1118dd",
            "6255500a9ce37d435fa02df5",
            "61bb59075c0fea03b1d80de8",
            "61cb0326faa4fdb698614ccb",
            "62bef9ae7f40bed63930c172",
            "61cabc52bb3738b719a6921b",
            "61d15d1aeffbaa20ccfa59cd",
            "62ecc731d1499e1b7ef027c3",
            "61e51136472b808d97091640",
            "61d189ea1aea58210a9feac8",
            "61f63d9ffdcaca0273d31030",
            "61bb4462706aaf0358dc0416",
            "61c71925e905094caa04b564",
            "62ce6889f88798b19e5c3ba5",
            "61efb07be604a36f4282d0a3",
            "61f8d21692a2020251d4bb62",
            "61c2ccc702cce74d4aadc08f",
            "61dd2cf3bc9c539ee309778d",
            "61ff93a83964f6acbc31b443",
            "61c2cb4d194dcc4d3399f42b",
            "6204d52335858194e31d739e",
            "62c2465ca786abd59e428ddf",
            "61d9eadb80a4139f80c57197",
            "61f8f9a63decd10258688960",
            "61e6ebc1e5124bf4b28d10a5",
            "61c01671b9685351ce406d43",
            "61ebdd533b285a6f3cf13267",
            "61f8e36ab54076027a5f812d",
            "61c180f602cce74d4aadbc15",
            "61e1eea2472b808d97091339",
            "61c74e8c8ce1334c84003905",
            "61f6e0d58cf5fb02355ce5fe",
            "61cb26887759c8b73bed8e97",
            "61cc1a3fbb3738b719a6933a",
            "625a7dcbc24fcbe6741afa47",
            "61d9c9e28b2cc59f42121c3a",
            "626784a48c963e616c9d26e3",
            "6211d01727b56f3d5cc170f9",
            "626ff9863cf811dd1453b70d",
            "61dc6046f524389f3cb4cef7",
            "61eb97bde632f26f98aaecb3",
            "61d9eadb80a4139f80c57197",
            "61fad36d15ebcd69e50df0ca",
            "61e064ac52b9895fbb7d363d",
            "626b84e177817a8caa1eb4b6",
            "61dc8d15bc9c539ee3097727",
            "62b5f354a4d225d64b3f93db",
            "61c69b76d384c24ca86dd28a",
            "62ddf96921c0f50ad71010f5",
            "61e732f26a786af4b8740bda",
            "62a759d9215d8f8a7584f02d",
            "61c03ed6194dcc4d3399ecda",
            "6263e6ff60c7d261d460bcb1",
            "62426c62f586e7def679c702",
            "61d5105124fc4e7058649523",
            "6278add72d2a011fa30b5391",
            "61f6a170b54076027a5f7e22",
            "61c1e14b194dcc4d3399f149",
            "61c48ef78d49f917bfb5ecea",
            "61c3601402cce74d4aadc3df",
            "61db283d9ade879f64c5037b",
            "61cbf3f27160ceb735d44f40",
            "6245ab531e02b2056747375e",
            "61f13038e632f26f98aaf274",
            "62dffb4336a2d237798048be",
            "61c6c3982465e54cc822d186",
            "62dc391a2826b8e96d6719de",
            "61e028e985b5d0600002e85c",
            "62301c5274564c9699c34a46",
            "61f3fa8bb54076027a5f7aea",
            "61cf6cd8ed6b64dea5507d7c",
            "61baa54d8dad64751fe97159",
            "61c02113030ca153482f5f51",
            "61e45b30a178868d38ca6280",
            "61e06f651dfe165ffa6f0500",
            "62d2159fece54e097c35ebc0",
            "61dc6f4b746eb2fa2dbd425c",
            "61e128c0507e938d3e083668",
            "629bf6369e806f28ef39b4fb",
            "61d28cf23cc69342c68b1c86",
            "61e6abe732ab96f54f48a02d",
            "61fa973a58954b69ebc21639",
            "61c80f5567100c4c4d53f213",
            "62f1e4e86d5f9d555984213f",
            "61fcc6db2255b05d7f2be427",
            "61c01680b9685351ce406d54",
            "61c6a2094cf8584cce3c62ba",
            "61ebcf78007fa76f97cbea3f",
            "62652d1dacca192578dc8608",
            "61d682829ade879f64c4feb8",
            "61c743df67100c4c4d53f18e",
            "61d727b880a4139f80c56f6f",
            "6270b10a6ef8a2ab375e2a8d",
            "62b74896d5f2e7d6069867f0",
            "61d0e2431aea58210a9fe9f5",
            "61c8000053a0864c53e3eb70",
            "61e04f5c85b5d0600002e896",
            "61c020e8030ca153482f5f22",
            "61c80f0267100c4c4d53f212",
            "6259823077142d8222878d3e",
            "61ca80cc3620edb69e29b885",
            "62da2d55a6512c98d283ca3e",
            "62c5d2162c5ce224af93b31d",
            "61df1d52d971e09f86db57b5",
            "61d15941effbaa20ccfa59ca",
            "61cbd94eae850cb6f73bf050",
            "629893d040f094ed64bfa084",
            "62d154e56fb7cd08f1965185",
            "61e0135d1dfe165ffa6f0466",
            "61ecf21b324dcc6fb5ef5561",
            "61c1d00b02cce74d4aadbd5a",
            "62329bb033886d96775fdb67",
            "62488d2ba761db262576c143",
            "61d9629ad971e09f86db51d0",
            "61cf98a7b8b0cddec7090c71",
            "62a759196af9f38a30d22c6b",
            "61c020e5030ca153482f5f1f",
            "61c7001cd384c24ca86dd2e7",
            "61fac0d8c962b569c35e4144",
            "627e4c331e1f0e4d5a8269f2",
            "61f9fc5e15ebcd69e50def4d",
            "61c2a286194dcc4d3399f34d",
            "62de37354f05c11ad63d1c54",
            "6230c93fa5a90c96937d05f0",
            "61ea29dfe604a36f4282caa5",
            "621b02aa2cfd5ba01ec01dab",
            "629e4205c4f716288946ebd3",
            "61e26bfdb737128db31c3d4e",
            "62dc09c304ff0fe9a32446ba",
            "6227cfdca5a90c96937d033d",
            "61c7fc748ce1334c8400396e",
            "61cc1ae675f6dbde5271cfb7",
            "61d7bd2dd971e09f86db50ae",
            "6205d8b5b1c324956a4ec7dc",
            "61c020ee030ca153482f5f28",
            "61c01690b9685351ce406d65",
            "61bc574c63e64b1a15227705",
            "61f7688043a99f01c24232ed",
            "62d8f66b92750e70ed9c5d9c",
            "61db0d789ade879f64c50354",
            "61c016a8b9685351ce406d7f",
            "61d16d2d1aea58210a9fea56",
            "61d00b9544d4d92073be2ac9",
            "61f4504a805fb501c8146b83",
            "62be5b1c9d8f02b5336ffe7d",
            "61d33d833cc69342c68b1e03",
            "623e0be1d26cf5ecb8e0f78f",
            "61e56722977d438ddbe7c3fc",
            "61ba2065f147e9626b56852f",
            "628de3defdb17f19c21fe27b",
            "61c22b3a02cce74d4aadbed4",
            "62016f3c1e3eaa97248f625e",
            "61c4a8f509ae801803c6aef4",
            "61f57bad3decd102586884fa",
            "61c4732309ae801803c6ae1c",
            "61cad3b97160ceb735d44e76",
            "61e99d1df20cf85e70286467",
            "61fa42e258954b69ebc2158d",
            "62d117c5e37557ea6c8d6a71",
            "61c34bce02cce74d4aadc365",
            "623063f863c0ac96c1735641",
            "61dd4473746eb2fa2dbd4300",
            "61d009741aea58210a9fe8df",
            "61b9989df147e9626b56836d",
            "61f2df3e7e53ab6f6c6f2627",
            "61f8e36ab54076027a5f812d",
            "61c923fa67100c4c4d53f2e7",
            "61c4c6718d49f917bfb5ee3a",
            "61c5561167100c4c4d53efee",
            "6212140718db393d3995275e",
            "61c19a88194dcc4d3399f036",
            "62dda211befbdbe94aba25d4",
            "61ee95a79791126fbb654096",
            "61e25120977d438ddbe7c0af",
            "62698389d1a11bd4e07c266b",
            "62422a51ec7059de75562fd4",
            "61c145c902cce74d4aadbb3a",
            "61e445acb297338db964539d",
            "61d16ffc1aea58210a9feaa9",
            //test
            "62e101aa11f99a9ecc88580e",
        ];
        if (!Object.keys(salary).length) return "Zigup Salary Detail Breakup";

        const oldText =
            salary?.company +
            " | " +
            salary?.title +
            " | " +
            salary?.yearsOfExperience +
            "year(s)" +
            salary?.organizedContent
                .replace(/<br\s*[\/]?>/g, " / ")
                .replace(/<[^>]*>?/g, "") +
            " / " +
            "Skillset: " +
            salary?.skillset.join(", ");

        const newText = `${salary?.company} | ${salary?.title} | Salary: ${
            salary?.totalCompensations
        }(LPA) / ${salary?.yearsOfExperience}year(s) / ${salary.organizedContent
            .split("<br/>")
            .filter((item) => !item.includes("Salary"))
            .join(" / ")} / Skillset: ${salary?.skillset.join(", ")}`;

        return targetUrls.includes(salary?._id) ? newText : oldText;
    };

    const getSEOKeywords = () => {
        const keywords = [
            "zigup",
            "salaries",
            "salary",
            "offer",
            "offers",
            "compensation",
            "breakup",
            "breakdown",
            "example",
        ];
        if (!salary) return keywords.join(", ");
        keywords.push([
            salary?.company,
            salary?.title,
            salary?.yearsOfExperience + " year(s)",
        ]);
        keywords.push(salary?.skillset);

        return keywords.join(", ");
    };

    const getFaqJsonLd = () => {
        const mainEntity = [];
        for (const faq of getFaqs()) {
            mainEntity.push({
                "@type": "Question",
                name: faq.question,
                acceptedAnswer: {
                    "@type": "Answer",
                    text: faq.answer,
                },
            });
        }
        const data = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: mainEntity,
        };
        return JSON.stringify(data);
    };

    const getSEOHelmet = () => {
        return (
            <Helmet>
                <title>{getSEOTitle()}</title>
                <meta
                    name="description"
                    property="description"
                    content={getSEODescription()}
                />
                <meta
                    name="keywords"
                    property="keywords"
                    content={getSEOKeywords()}
                />
                <meta content={getSEOTitle()} property="og:title" />
                <meta property="og:description" content={getSEODescription()} />
                <meta
                    content={"https://zigup.in/salary/" + salary.seoURL}
                    name="url"
                    property="og:url"
                />
                <meta
                    content="https://hire.zigup.in/static/media/logo-horizontal.4663103fd0a4bca8ea17.png"
                    property="og:image"
                />
                <link
                    rel="canonical"
                    href={"https://zigup.in/salary/" + salary.seoURL}
                />
                {salary.seoURL &&
                !location.pathname.includes(encodeURI(salary.seoURL)) ? (
                    <meta
                        httpEquiv="refresh"
                        content={`0; url=/salary/${salary.seoURL}`}
                    />
                ) : null}
                {salary.seoURL &&
                !location.pathname.includes(encodeURI(salary.seoURL)) ? (
                    <meta name="robots" content="noindex" />
                ) : null}
                <script type="application/ld+json">{getFaqJsonLd()}</script>
            </Helmet>
        );
    };

    return (
        <>
            {getSEOHelmet()}
            <Container
                maxWidth="lg"
                sx={{
                    minHeight: "100vh",
                    padding: "2rem 1rem",
                    boxSizing: "border-box",
                }}
            >
                <Stack
                    direction="column"
                    spacing={8}
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Stack
                        sx={{
                            width: "100%",
                        }}
                    >
                        {renderTitle()}
                        {renderSubTitle()}
                        <Stack
                            direction={"row"}
                            spacing={2}
                            sx={{ width: "100%" }}
                        >
                            <Stack
                                spacing={8}
                                sx={{ flex: 2, display: "flex", width: "100%" }}
                            >
                                <Stack
                                    sx={
                                        viewable
                                            ? {}
                                            : {
                                                  filter: "blur(10px)",
                                                  pointerEvents: "none",
                                                  userSelect: "none",
                                                  msUserSelect: "none",
                                              }
                                    }
                                    spacing={3}
                                >
                                    {renderContents()}
                                    {renderUserInfo()}
                                    {isMobile ? renderDirectMessage() : null}
                                    {renderCount()}
                                </Stack>
                                {renderComments()}
                                {renderContribute()}
                                {renderGraph()}
                                {renderFaqs()}
                            </Stack>
                            {isMobile ? null : renderRightSide()}
                        </Stack>
                    </Stack>
                    {isMobile ? renderRightSide() : null}
                    {renderAdjointSalaryList()}
                    <ConfirmModal
                        open={confirmModalState.open}
                        title={confirmModalState.title}
                        content={confirmModalState.content}
                        image={confirmModalState.image}
                        button={confirmModalState.button}
                        containerStyle={{
                            maxWidth: 600,
                            width: isMobile ? "80vw" : "40vw",
                        }}
                        titleStyle={{
                            fontFamily: "Lato",
                            fontWeight: 700,
                            fontSize: "2.125rem",
                        }}
                        titlePosition={"bottom"}
                        imageStyle={{ width: "100%" }}
                        closeModal={confirmModalState.closeModal}
                        closeOnOverlayClick={
                            confirmModalState.closeOnOverlayClick
                        }
                        closeOnEsc={confirmModalState.closeOnEsc}
                    />
                    {renderViewedCompanyModal()}
                    {renderViewedFullCompanyModal()}
                    {renderDownloadAppModal()}
                </Stack>
            </Container>
        </>
    );
};

export default SalaryDetailPage;
