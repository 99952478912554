import React, { useEffect } from "react";
import Modal from "react-modal";
import Colors from "themes/colors";
import { Stack } from "@mui/material";
import Text from "components/atoms/Text";
import { useMediaQuery } from "react-responsive";
import Typo from "components/atoms/Typo";
import ZigupButton from "../atoms/ZigupButton";

const ConfirmModal = ({
    isLoading,
    open,
    title,
    titleStyle,
    content,
    contentStyle,
    image,
    button,
    containerStyle,
    closeModal,
    imageStyle,
    titlePosition = "top",
    closeOnOverlayClick = true,
    closeOnEsc = true,
    loginButtonState = { show: false, onClickHandler: () => undefined },
    smallSize = false,
}) => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const customModalStyles = {
        content: {
            width: "max-content",
            minWidth: "20vw",
            maxWidth: isMobile ? "80vw" : smallSize ? "20vw" : "30vw",
            height: "max-content",
            minHeight: "15vh",
            // maxHeight: isMobile ? "80vh" : "70vh",
            backgroundColor: "white",
            padding: "1rem",
            border: "0",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            ...containerStyle,
        },
        overlay: {
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 10,
        },
        textWrapper: {
            width: "100%",
            justifyContent: "space-evenly",
            alignItems: "center",
            wordBreak: "break-all",
        },
        textTitle: {
            fontSize: "1.1rem",
            fontFamily: "Roboto",
            fontWeight: 700,
            marginBottom: 0,
            marginRight: "1.3rem",
            marginLeft: "1.3rem",
            textAlign: "center",
            ...titleStyle,
        },
        textContent: {
            fontSize: "1rem",
            whiteSpace: "pre-line",
            ...contentStyle,
        },
        buttonWrapper: {
            width: "100%",
            justifyContent: "space-evenly",
        },
        button: {
            width: "100%",
            height: "3rem",
            marginTop: "1rem",
            ...button.style,
        },
    };

    useEffect(() => {
        //배경 안움직이게 만드는 코드
        if (!open) return;
        document.body.style.cssText = `
          margin:0px;
          position: fixed;
          top: -${Window.scrollY}px;
          overflow-y: scroll;
          overflow-x: hidden;
          width: 100%;`;
        return () => {
            const scrollY = document.body.style.top;
            document.body.style.cssText = `margin:0px;`;
            window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
        };
    }, [open]);

    return (
        <Modal
            isOpen={open}
            style={customModalStyles}
            contentLabel="Applicant Modal"
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={closeOnOverlayClick}
            shouldCloseOnEsc={closeOnEsc}
            ariaHideApp={false}
        >
            <Stack
                direction="column"
                spacing={2}
                style={{
                    width: "max-content",
                    height: "max-content",
                    justifyContent: "flex-start",
                    alignItems: "center",
                }}
            >
                {title && titlePosition === "top" ? (
                    <Stack
                        style={{
                            ...customModalStyles.textWrapper,
                        }}
                    >
                        <Typo.H4
                            style={{
                                ...customModalStyles.textTitle,
                            }}
                        >
                            {title}
                        </Typo.H4>
                    </Stack>
                ) : null}
                {content ? (
                    <Stack
                        style={{
                            ...customModalStyles.textWrapper,
                            flex: 1,
                        }}
                    >
                        <Text
                            style={{
                                ...customModalStyles.textContent,
                                marginBottom: 0,
                            }}
                        >
                            {content}
                        </Text>
                    </Stack>
                ) : null}
                {image ? (
                    <Stack
                        style={{
                            ...customModalStyles.textWrapper,
                            flex: 2,
                        }}
                    >
                        <img
                            src={image.src}
                            alt={image.alt}
                            width="80%"
                            height="auto"
                            style={{ ...imageStyle }}
                        />
                    </Stack>
                ) : null}
                {title && titlePosition === "bottom" ? (
                    <Stack
                        style={{
                            ...customModalStyles.textWrapper,
                        }}
                    >
                        <Typo.H4
                            style={{
                                ...customModalStyles.textTitle,
                            }}
                        >
                            {title}
                        </Typo.H4>
                    </Stack>
                ) : null}
                {loginButtonState.show ? (
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            width: "100%",
                        }}
                    >
                        <ZigupButton
                            id={"login-on-apply-detail-btn"}
                            buttonText={"SIGN UP AND APPLY"}
                            containerStyle={{
                                padding: "0.5rem",
                                borderRadius: 4,
                                backgroundColor: Colors.button_background_404,
                            }}
                            buttonTextSize={"1.2rem"}
                            onClickHandler={loginButtonState.onClickHandler}
                        />
                    </Stack>
                ) : null}

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    style={customModalStyles.buttonWrapper}
                >
                    {button.reject ? (
                        <ZigupButton
                            id={button.reject.id || "reject-btn"}
                            buttonText={
                                button.reject.text
                                    ? button.reject.text
                                    : "CANCEL"
                            }
                            disabled={isLoading}
                            buttonTextSize={"1rem"}
                            buttonTextStyle={button.reject.textStyle}
                            onClickHandler={button.reject.handle}
                            containerStyle={{
                                ...customModalStyles.button,
                                ...button.reject.style,
                            }}
                        />
                    ) : null}
                    <ZigupButton
                        id={button.confirm?.id || "confirm-btn"}
                        buttonText={
                            button.confirm.text
                                ? button.confirm.text
                                : "CONFIRM"
                        }
                        buttonTextSize={"1rem"}
                        buttonTextStyle={button.confirm.textStyle}
                        onClickHandler={button.confirm.handle}
                        containerStyle={{
                            ...customModalStyles.button,
                            ...button.confirm.style,
                        }}
                    />
                </Stack>
            </Stack>
        </Modal>
    );
};

export default ConfirmModal;
