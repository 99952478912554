import React from "react";
import { Stack } from "@mui/material";
import Typo from "components/atoms/Typo";
import Colors from "themes/colors";
import ZigupButton from "../../atoms/ZigupButton";
import { useMediaQuery } from "react-responsive";

const ItemCard = ({
    id,
    content,
    buttonText,
    onClickHandler,
    containerStyle,
}) => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    return (
        <Stack
            direction="column"
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
            sx={{
                backgroundColor: "white",
                padding: "2.25rem 1rem",
                boxSizing: "border-box",
                ...containerStyle,
            }}
        >
            <Stack>
                <Typo.Body1
                    style={{
                        textAlign: "center",
                        whiteSpace: "pre-line",
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        lineHeight: "1.5rem",
                    }}
                >
                    {content}
                </Typo.Body1>
            </Stack>
            <Stack>
                <ZigupButton
                    id={id ?? "item-card-btn"}
                    buttonText={buttonText}
                    containerStyle={{
                        width: "max-content",
                        height: "max-content",
                        minWidth: "10rem",
                        maxWidth: isMobile ? "15rem" : "max-content",
                        padding: "0.75rem 1.5rem",
                        borderRadius: 4,
                        borderWidth: 0.5,
                        borderStyle: "solid",
                        borderColor: Colors.faq_text,
                        backgroundColor: "transparent",
                    }}
                    buttonTextSize={"1rem"}
                    buttonTextStyle={{
                        color: Colors.faq_text,
                        textAlign: "center",
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: "1rem",
                    }}
                    onClickHandler={onClickHandler}
                />
            </Stack>
        </Stack>
    );
};
export default ItemCard;
