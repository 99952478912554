import * as Api from "./api";
import { get } from "./process";

export async function companyList(params) {
    return get(Api.Urls.company.list, params);
}

export async function companyDetail(id, params) {
    return get(Api.Urls.company.detail(id), params);
}

export async function companyInsight(params) {
    return get(Api.Urls.company.insight, params);
}

export async function companyCuratedList(params) {
    return get(Api.Urls.company.curatedList, params);
}
