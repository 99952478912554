import React, { useEffect, useState } from "react";
import Text from "components/atoms/Text";
import { Container, Skeleton, Stack } from "@mui/material";
import { Send } from "react-feather";
import { timeFromNow } from "utils/DateUtil";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import commentIcon from "assets/icons/icon-comment.png";
import viewIcon from "assets/icons/icon-view.png";
import Colors from "themes/colors";
import ConfirmModal from "components/organisms/ConfirmModal";
import { useOutletContext } from "react-router";
import Typo from "../../atoms/Typo";
import { FORUM_OBJECT_TYPE } from "constants/constants";
import Comments from "../../organisms/salary/Comments";
import upvoteIcon from "assets/icons/icon-upvote-lined.png";
import dmIcon from "assets/icons/dm@2x.png";
import { Helmet } from "react-helmet-async";
import DownloadAppModal from "../../organisms/DownloadAppModal";
import ShareMenu from "../../organisms/ShareMenu";
import { discussionDetail } from "communication/forum";
import ForumDetailAdjointList from "components/organisms/forum/ForumDetailAdjointList";
import { Logger } from "utils";

const CountText = styled(Text)`
    margin-right: 16px;
    color: #646464;
`;

const ForumDiscussionDetailPage = () => {
    const [post, setPost] = useState({});
    const { postId } = useOutletContext();
    const [pageText, setPageText] = useState({
        commentLabel: "Comment",
        no_comment: "No comment",
        unable_comment_placeholder: "You can't add a comment to notice",
        placeholder: "add a comment",
        load_more: "Load more",
        thread_load_more: "View previous replies",
        delete_comment: "Deleted comment",
        no_company_univ_placeholder:
            "You can add comment after adding your company/college at My Profile",
        content_questions: {
            route: "How did you apply for the job?",
            interviewRounds: "What were the interview rounds you had?",
            preparations: "What were your preparations to get this offer?",
            questions:
                "What kind of questions were you asked in the interview?",
            culture: "If joined, how is actual work & culture like?",
            negotiation: "How was your salary negotiation process?",
        },
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showMoreAboutAuthor, setShowMoreAboutAuthor] = useState(true);
    const [isApplyModalVisible, setIsApplyModalVisible] = useState(false);
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const [confirmModalState, setConfirmModalState] = useState({
        open: false,
        title: "",
        content: "",
        image: {
            src: "",
            alt: "",
        },
        button: {
            confirm: {
                text: "",
                id: "",
                handle: () => undefined,
            },
            reject: {
                text: "",
                id: "",
                handle: () => undefined,
            },
        },
        closeModal: () => undefined,
        closeOnEsc: true,
        closeOnOverlayClick: true,
    });
    const [isLoading, setIsLoading] = useState(true);

    const openConfirmModal = (params) => {
        setConfirmModalState(params);
    };

    const closeConfirmModal = () => {
        setConfirmModalState({
            ...confirmModalState,
            open: false,
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const res = await discussionDetail(postId);
            setPost({ ...res });
        } catch (e) {
            Logger.error(e);
            // navigate("/");
        } finally {
            setIsLoading(false);
        }
    };

    const onApplyClicked = () => {
        setIsApplyModalVisible(true);
    };

    const onModalPressHandler = () => {
        setIsApplyModalVisible(false);
    };

    const renderType = () => {
        return (
            <Text
                style={{
                    color: Colors.forum_discussion,
                    textDecorationLine: "underline",
                    size: "0.75rem",
                }}
            >
                #Discussion
            </Text>
        );
    };

    const renderTitle = () => {
        return (
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typo.H1Regular
                    style={{
                        fontSize: "1.5rem",
                        marginTop: "0.5rem",
                        marginBottom: "0.5rem",
                    }}
                >
                    {post?.title ? (
                        post.title
                    ) : (
                        <Skeleton variant="text" width={400} />
                    )}
                </Typo.H1Regular>
                <ShareMenu url={window.location.href} iconSize={"1.2rem"} />
            </Stack>
        );
    };

    const renderSubTitle = () => {
        return (
            <Typo.H2
                style={{
                    fontSize: "1rem",
                    color: Colors.anonymous_grey,
                    margin: 0,
                    marginBottom: "0.25rem",
                    fontFamily: "Roboto",
                    fontWeight: 400,
                }}
            >
                {post?.writer
                    ? `${post.writer?.displayName} | ${timeFromNow(
                          post.createdAt
                      )}`
                    : ""}
            </Typo.H2>
        );
    };

    const renderContents = () => {
        return (
            <Stack
                direction={"column"}
                spacing={2}
                sx={{ marginTop: "2rem", marginBottom: "2rem" }}
            >
                <Stack justifyContent="flex-start" alignItems="start">
                    <Typo.Body2
                        style={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "1rem",
                            lineHeight: "1.5rem",
                            wordBreak: "break-all",
                            width: "100%",
                        }}
                        skeletonCount={4}
                    >
                        {post.content}
                    </Typo.Body2>
                </Stack>
            </Stack>
        );
    };

    const renderButton = () => {
        const show = !post.isMine;
        return show ? (
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                    backgroundColor: Colors.salary_text_background,
                    borderRadius: 4,
                    cursor: "pointer",
                    width: "100%",
                    minHeight: "3rem",
                }}
                onClick={onApplyClicked}
            >
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Text style={{ color: "#333333", marginRight: 2 }}>DM</Text>
                    <div>
                        <Send
                            size={12}
                            color={"#333333"}
                            style={{ marginRight: 4 }}
                        />
                    </div>
                </Stack>
            </Stack>
        ) : null;
    };

    const renderCount = () => {
        return (
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{
                    width: "100%",
                    height: "3rem",
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{
                        flexWrap: "wrap",
                    }}
                    spacing={2}
                >
                    {/* <UpvoteButton
                        item={compensation}
                        setItem={setCompensation}
                        isMobile={isMobile}
                    /> */}
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={0.5}
                    >
                        <img
                            src={upvoteIcon}
                            alt="upvoteIcon"
                            width={isMobile ? 16 : 24}
                            height={isMobile ? 16 : 24}
                        />
                        <CountText>
                            {typeof post.upvoteCount === "number"
                                ? post.upvoteCount
                                : "-"}
                        </CountText>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={0.5}
                    >
                        <img
                            src={viewIcon}
                            alt="viewIcon"
                            width={isMobile ? 16 : 24}
                            height={isMobile ? 16 : 24}
                        />
                        <CountText>
                            {typeof post.viewCount === "number"
                                ? post.viewCount
                                : "-"}
                        </CountText>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={0.5}
                    >
                        <img
                            src={commentIcon}
                            alt="commentIcon"
                            width={isMobile ? 16 : 24}
                            height={isMobile ? 16 : 24}
                        />
                        <CountText>
                            {typeof post.commentCount === "number"
                                ? post.commentCount
                                : "-"}
                        </CountText>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={0.5}
                    >
                        <img
                            src={dmIcon}
                            alt="dmIcon"
                            width={isMobile ? 16 : 24}
                            height={isMobile ? 16 : 24}
                        />
                        <CountText>
                            {typeof post.dmCount === "number"
                                ? post.dmCount
                                : "-"}
                        </CountText>
                    </Stack>
                </Stack>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        marginLeft: "3rem",
                        height: "100%",
                        maxWidth: "40%",
                        flex: 1,
                    }}
                >
                    {isMobile ? null : renderButton()}
                </Stack>
            </Stack>
        );
    };

    const renderComments = () => {
        return (
            <Stack
                spacing={2}
                style={{
                    marginTop: "4rem",
                    width: "100%",
                }}
            >
                <Stack
                    justifyContent={"flex-start"}
                    style={{
                        width: "100%",
                    }}
                >
                    <Typo.Body1
                        style={{
                            fontSize: "1rem",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                        }}
                    >
                        Comments
                    </Typo.Body1>
                </Stack>
                <Stack justifyContent={"flex-start"} style={{ width: "100%" }}>
                    {post._id ? (
                        <Comments
                            id={post._id}
                            isNotice={false}
                            objectType={FORUM_OBJECT_TYPE.DISCUSSION}
                        />
                    ) : null}
                </Stack>
            </Stack>
        );
    };

    const renderAdjointPosts = () => {
        return (
            <Stack
                direction="column"
                style={{
                    flex: 1,
                    marginBottom: "1rem",
                    width: "100%",
                }}
                spacing={2}
            >
                <Stack justifyContent={"flex-start"} style={{ width: "100%" }}>
                    <Typo.Body1
                        style={{
                            fontSize: "1rem",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                        }}
                    >
                        People also checked these posts
                    </Typo.Body1>
                </Stack>
                {post._id ? (
                    <ForumDetailAdjointList
                        id={post._id}
                        isMobile={isMobile}
                        type={post.type}
                    />
                ) : null}
            </Stack>
        );
    };

    const renderPostDetail = () => {
        return (
            <Stack
                direction="column"
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
            >
                <Stack
                    style={{
                        margin: "1rem",
                        paddingTop: "2rem",
                        width: "100%",
                    }}
                >
                    {renderType()}
                    {renderTitle()}
                    {renderSubTitle()}
                    <Stack direction={"row"} spacing={2}>
                        <Stack style={{ flex: 7 }}>
                            {renderContents()}
                            {isMobile ? renderButton() : null}
                            {renderCount()}
                            {renderComments()}
                        </Stack>
                        {isMobile ? null : (
                            <Stack
                                style={{
                                    flex: 5,
                                }}
                            />
                        )}
                    </Stack>
                </Stack>
                {renderAdjointPosts()}
                <ConfirmModal
                    open={confirmModalState.open}
                    title={confirmModalState.title}
                    content={confirmModalState.content}
                    image={confirmModalState.image}
                    button={confirmModalState.button}
                    closeModal={confirmModalState.closeModal}
                    closeOnOverlayClick={confirmModalState.closeOnOverlayClick}
                    closeOnEsc={confirmModalState.closeOnEsc}
                />
                <DownloadAppModal
                    open={isApplyModalVisible}
                    close={() => setIsApplyModalVisible(false)}
                />
            </Stack>
        );
    };

    const getSEOTitle = () => {
        return Object.keys(post).length
            ? `${post.title} - Forum - Discussion by Zigup`
            : "Forum - Discussion by Zigup";
    };

    const getSEODescription = () => {
        return Object.keys(post).length
            ? post.content.length > 155
                ? post.content.substr(0, 152) + "..."
                : post.content.substr(0, 155)
            : "Forum - Discussion by Zigup";
    };

    const getSEOKeywords = () => {
        const keywords = [
            "zigup",
            "discussion",
            "discussions",
            "question",
            "query",
        ];
        if (!post) return keywords.join(", ");
        keywords.push([
            post.company,
            post.title,
            post.yearsOfExperience + " year(s)",
        ]);
        keywords.push(post.skillset);

        return keywords.join(", ");
    };

    const getSEOHelmet = () => {
        const url = "https://zigup.in/forum/discussion" + post.seoURL;
        return (
            <Helmet>
                <title>{getSEOTitle()}</title>
                <meta
                    name="description"
                    property="description"
                    content={getSEODescription()}
                />
                <meta
                    name="keywords"
                    property="keywords"
                    content={getSEOKeywords()}
                />
                <meta content={getSEOTitle()} property="og:title" />
                <meta property="og:description" content={getSEODescription()} />
                <meta content={url} name="url" property="og:url" />
                <meta
                    content="https://hire.zigup.in/static/media/logo-horizontal.4663103fd0a4bca8ea17.png"
                    property="og:image"
                />
                <link rel="canonical" href={url} />
                {location.pathname.includes(post.seoURL) ? null : (
                    <meta name="robots" content="noindex" />
                )}
            </Helmet>
        );
    };

    return (
        <>
            {getSEOHelmet()}
            <Container maxWidth="lg" sx={{ minHeight: "100vh" }}>
                {renderPostDetail()}
            </Container>
        </>
    );
};

export default ForumDiscussionDetailPage;
