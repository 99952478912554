import React, { useEffect, useState } from "react";
import Text from "components/atoms/Text";
import { Container, Skeleton, Stack } from "@mui/material";
import { Send } from "react-feather";
import { timeFromNow } from "utils/DateUtil";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import commentIcon from "assets/icons/icon-comment.png";
import viewIcon from "assets/icons/icon-view.png";
import authorIcon from "assets/icons/person-lined-circle.png";
import Colors from "themes/colors";
import ConfirmModal from "components/organisms/ConfirmModal";
import { useOutletContext } from "react-router";
import Typo from "../../atoms/Typo";
import { ExperienceType, FORUM_OBJECT_TYPE } from "constants/constants";
import Comments from "../../organisms/salary/Comments";
import upvoteIcon from "assets/icons/icon-upvote-lined.png";
import dmIcon from "assets/icons/dm@2x.png";
import { Helmet } from "react-helmet-async";
import DownloadAppModal from "../../organisms/DownloadAppModal";
import ShareMenu from "../../organisms/ShareMenu";
import locationIcon from "assets/icons/profile/icon-location.png";
import skillIcon from "assets/icons/profile/icon-skills.png";
import companyIcon from "assets/icons/profile/company@2x.png";
import experienceIcon from "assets/icons/profile/icon-experience.png";
import universityIcon from "assets/icons/profile/university@2x.png";
import { lfrDetail } from "communication/forum";
import WhiteTag from "components/atoms/WhiteTag";
import ForumDetailAdjointList from "components/organisms/forum/ForumDetailAdjointList";
import { Logger } from "utils";

const CountText = styled(Text)`
    margin-right: 16px;
    color: #646464;
`;

const ForumLFRDetailPage = () => {
    const [post, setPost] = useState({});
    const { postId } = useOutletContext();
    const [pageText, setPageText] = useState({
        commentLabel: "Comment",
        no_comment: "No comment",
        unable_comment_placeholder: "You can't add a comment to notice",
        placeholder: "add a comment",
        load_more: "Load more",
        thread_load_more: "View previous replies",
        delete_comment: "Deleted comment",
        no_company_univ_placeholder:
            "You can add comment after adding your company/college at My Profile",
        content_questions: {
            route: "How did you apply for the job?",
            interviewRounds: "What were the interview rounds you had?",
            preparations: "What were your preparations to get this offer?",
            questions:
                "What kind of questions were you asked in the interview?",
            culture: "If joined, how is actual work & culture like?",
            negotiation: "How was your salary negotiation process?",
        },
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showMoreAboutAuthor, setShowMoreAboutAuthor] = useState(true);
    const [isApplyModalVisible, setIsApplyModalVisible] = useState(false);
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const [confirmModalState, setConfirmModalState] = useState({
        open: false,
        title: "",
        content: "",
        image: {
            src: "",
            alt: "",
        },
        button: {
            confirm: {
                text: "",
                id: "",
                handle: () => undefined,
            },
            reject: {
                text: "",
                id: "",
                handle: () => undefined,
            },
        },
        closeModal: () => undefined,
        closeOnEsc: true,
        closeOnOverlayClick: true,
    });
    const [isLoading, setIsLoading] = useState(true);

    const openConfirmModal = (params) => {
        setConfirmModalState(params);
    };

    const closeConfirmModal = () => {
        setConfirmModalState({
            ...confirmModalState,
            open: false,
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const res = await lfrDetail(postId);
            setPost({ ...res });
        } catch (e) {
            Logger.error(e);
            // navigate("/");
        } finally {
            setIsLoading(false);
        }
    };

    const onApplyClicked = () => {
        setIsApplyModalVisible(true);
    };

    const onModalPressHandler = () => {
        setIsApplyModalVisible(false);
    };

    const renderSeparator = (thickness = 1) => {
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                style={{
                    width: "100%",
                    height: thickness,
                    backgroundColor: Colors.light_purple,
                }}
            />
        );
    };

    const renderType = () => {
        return (
            <Text
                style={{
                    color: Colors.forum_lfr,
                    textDecorationLine: "underline",
                    size: "0.75rem",
                }}
            >
                #Looking for referrers
            </Text>
        );
    };

    const renderTitle = () => {
        return (
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typo.H1Regular
                    style={{
                        fontSize: "1.5rem",
                        marginTop: "0.5rem",
                        marginBottom: "0.5rem",
                    }}
                >
                    {post?.title ? (
                        post.title
                    ) : (
                        <Skeleton variant="text" width={400} />
                    )}
                </Typo.H1Regular>
                <ShareMenu url={window.location.href} iconSize={"1.2rem"} />
            </Stack>
        );
    };

    const renderSubTitle = () => {
        return (
            <Typo.H2
                style={{
                    fontSize: "1rem",
                    color: Colors.anonymous_grey,
                    margin: 0,
                    marginBottom: "0.25rem",
                    fontFamily: "Roboto",
                    fontWeight: 400,
                }}
            >{`${post.writer?.displayName} | ${timeFromNow(
                post.createdAt
            )}`}</Typo.H2>
        );
    };

    const renderContents = () => {
        return (
            <Stack
                direction={"column"}
                spacing={2}
                sx={{ marginTop: "2rem", marginBottom: "2rem" }}
            >
                <Stack justifyContent="flex-start" alignItems="start">
                    <Typo.Body2
                        style={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "1rem",
                            lineHeight: "1.5rem",
                            wordBreak: "break-all",
                            width: "100%",
                        }}
                        skeletonCount={4}
                    >
                        {post.content}
                    </Typo.Body2>
                </Stack>
            </Stack>
        );
    };

    const renderUserInfo = () => {
        if (post?.type !== FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFR) return null;
        return (
            <>
                <Stack style={{ marginTop: "2rem" }}>
                    {renderSeparator(1)}
                </Stack>
                <Stack
                    direction="column"
                    style={{
                        marginTop: "2rem",
                        marginBottom: "2rem",
                        width: "100%",
                        height: "100%",
                    }}
                    spacing={1}
                >
                    <Stack direction={"row"}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            spacing={1}
                            style={{
                                flex: 1,
                                height: "max-content",
                            }}
                        >
                            <img
                                src={authorIcon}
                                alt="authorIcon"
                                width={isMobile ? 18 : 24}
                                height={isMobile ? 18 : 24}
                            />
                            <Typo.Body2
                                style={{
                                    color: Colors.header_black,
                                    fontSize: "0.875rem",
                                }}
                            >
                                About Author
                            </Typo.Body2>
                        </Stack>
                        {post?.writer?.isExperienced ===
                        ExperienceType.EXPERIENCED ? (
                            <Stack
                                direction={"column"}
                                spacing={0.5}
                                style={{ flex: 2 }}
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    spacing={1}
                                >
                                    <img
                                        src={companyIcon}
                                        alt="authorIcon"
                                        width={isMobile ? 18 : 24}
                                        height={isMobile ? 18 : 24}
                                    />
                                    <Typo.Body2
                                        style={{
                                            color: Colors.header_black,
                                            fontSize: "0.875rem",
                                        }}
                                    >
                                        {post.company}
                                    </Typo.Body2>
                                </Stack>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    spacing={1}
                                >
                                    <img
                                        src={experienceIcon}
                                        alt="authorIcon"
                                        width={isMobile ? 18 : 24}
                                        height={isMobile ? 18 : 24}
                                    />
                                    <Typo.Body2
                                        style={{
                                            color: Colors.header_black,
                                            fontSize: "0.875rem",
                                        }}
                                    >
                                        {post.writer?.yearsOfExperience} year(s)
                                    </Typo.Body2>
                                </Stack>
                            </Stack>
                        ) : (
                            <Stack direction={"column"} style={{ flex: 2 }}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    spacing={0.5}
                                >
                                    <img
                                        src={universityIcon}
                                        alt="authorIcon"
                                        width={isMobile ? 18 : 24}
                                        height={isMobile ? 18 : 24}
                                    />
                                    <Typo.Body2
                                        style={{
                                            color: Colors.header_black,
                                            fontSize: "0.875rem",
                                        }}
                                    >
                                        {post.writer?.university || "No data"}
                                    </Typo.Body2>
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                    <Stack direction={"row"}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            spacing={1}
                            style={{ flex: 1 }}
                        >
                            <img
                                src={locationIcon}
                                alt="authorIcon"
                                width={isMobile ? 18 : 24}
                                height={isMobile ? 18 : 24}
                            />
                            <Typo.Body2
                                style={{
                                    color: Colors.header_black,
                                    fontSize: "0.875rem",
                                }}
                            >
                                Preferred location(s)
                            </Typo.Body2>
                        </Stack>
                        <Stack direction={"column"} style={{ flex: 2 }}>
                            <Stack
                                direction={"row"}
                                sx={{
                                    flexWrap: "wrap",
                                    flexGrow: 1,
                                }}
                            >
                                {post.writer?.locations?.map((item, index) => (
                                    <WhiteTag
                                        key={item + "location"}
                                        item={item}
                                    />
                                ))}
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            spacing={1}
                            style={{ flex: 1 }}
                        >
                            <img
                                src={skillIcon}
                                alt="authorIcon"
                                width={isMobile ? 18 : 24}
                                height={isMobile ? 18 : 24}
                            />
                            <Typo.Body2
                                style={{
                                    color: Colors.header_black,
                                    fontSize: "0.875rem",
                                }}
                            >
                                Main skills
                            </Typo.Body2>
                        </Stack>
                        <Stack direction={"column"} style={{ flex: 2 }}>
                            <Stack
                                direction={"row"}
                                sx={{
                                    flexWrap: "wrap",
                                    flexGrow: 1,
                                }}
                            >
                                {post.writer?.skillset?.main.map(
                                    (item, index) => (
                                        <WhiteTag
                                            key={item + "main" + index}
                                            item={item}
                                        />
                                    )
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            spacing={1}
                            style={{
                                flex: 1,
                                height: "max-content",
                            }}
                        >
                            <img
                                src={skillIcon}
                                alt="authorIcon"
                                width={isMobile ? 18 : 24}
                                height={isMobile ? 18 : 24}
                            />
                            <Typo.Body2
                                style={{
                                    color: Colors.header_black,
                                    fontSize: "0.875rem",
                                }}
                            >
                                Sub skills
                            </Typo.Body2>
                        </Stack>
                        <Stack style={{ flex: 2 }}>
                            <Stack
                                direction={"row"}
                                sx={{
                                    flexWrap: "wrap",
                                    flexGrow: 1,
                                }}
                            >
                                {post.writer?.skillset?.sub.map(
                                    (item, index) => (
                                        <WhiteTag
                                            key={item + "sub" + index}
                                            item={item}
                                        />
                                    )
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </>
        );
    };

    const renderButton = () => {
        const show = !post.isMine;
        return show ? (
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                    backgroundColor: Colors.salary_text_background,
                    borderRadius: 4,
                    cursor: "pointer",
                    width: "100%",
                    minHeight: "3rem",
                }}
                onClick={onApplyClicked}
            >
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Text style={{ color: "#333333", marginRight: 2 }}>
                        I can refer you
                    </Text>
                    <div>
                        <Send
                            size={12}
                            color={"#333333"}
                            style={{ marginRight: 4 }}
                        />
                    </div>
                </Stack>
            </Stack>
        ) : null;
    };

    const renderCount = () => {
        return (
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{
                    width: "100%",
                    height: "3rem",
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{
                        flexWrap: "wrap",
                    }}
                    spacing={2}
                >
                    {/* <UpvoteButton
                        item={compensation}
                        setItem={setCompensation}
                        isMobile={isMobile}
                    /> */}
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={0.5}
                    >
                        <img
                            src={upvoteIcon}
                            alt="upvoteIcon"
                            width={isMobile ? 16 : 24}
                            height={isMobile ? 16 : 24}
                        />
                        <CountText>
                            {typeof post.upvoteCount === "number"
                                ? post.upvoteCount
                                : "-"}
                        </CountText>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={0.5}
                    >
                        <img
                            src={viewIcon}
                            alt="viewIcon"
                            width={isMobile ? 16 : 24}
                            height={isMobile ? 16 : 24}
                        />
                        <CountText>
                            {typeof post.viewCount === "number"
                                ? post.viewCount
                                : "-"}
                        </CountText>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={0.5}
                    >
                        <img
                            src={commentIcon}
                            alt="commentIcon"
                            width={isMobile ? 16 : 24}
                            height={isMobile ? 16 : 24}
                        />
                        <CountText>
                            {typeof post.commentCount === "number"
                                ? post.commentCount
                                : "-"}
                        </CountText>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={0.5}
                    >
                        <img
                            src={dmIcon}
                            alt="dmIcon"
                            width={isMobile ? 16 : 24}
                            height={isMobile ? 16 : 24}
                        />
                        <CountText>
                            {typeof post.dmCount === "number"
                                ? post.dmCount
                                : "-"}
                        </CountText>
                    </Stack>
                </Stack>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        marginLeft: "3rem",
                        height: "100%",
                        maxWidth: "40%",
                        flex: 1,
                    }}
                >
                    {isMobile ? null : renderButton()}
                </Stack>
            </Stack>
        );
    };

    const renderComments = () => {
        return (
            <Stack
                spacing={2}
                style={{
                    marginTop: "4rem",
                    width: "100%",
                }}
            >
                <Stack
                    justifyContent={"flex-start"}
                    style={{
                        width: "100%",
                    }}
                >
                    <Typo.Body1
                        style={{
                            fontSize: "1rem",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                        }}
                    >
                        Comments
                    </Typo.Body1>
                </Stack>
                <Stack justifyContent={"flex-start"} style={{ width: "100%" }}>
                    {post._id ? (
                        <Comments
                            id={post._id}
                            isNotice={false}
                            objectType={FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFR}
                        />
                    ) : null}
                </Stack>
            </Stack>
        );
    };

    const renderAdjointPosts = () => {
        return (
            <Stack
                direction="column"
                style={{
                    flex: 1,
                    marginBottom: "1rem",
                    width: "100%",
                }}
                spacing={2}
            >
                <Stack justifyContent={"flex-start"} style={{ width: "100%" }}>
                    <Typo.Body1
                        style={{
                            fontSize: "1rem",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                        }}
                    >
                        People also checked these posts
                    </Typo.Body1>
                </Stack>
                {post._id ? (
                    <ForumDetailAdjointList
                        id={post._id}
                        isMobile={isMobile}
                        type={post.type}
                    />
                ) : null}
            </Stack>
        );
    };

    const renderPostDetail = () => {
        return (
            <Stack
                direction="column"
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
            >
                <Stack
                    style={{
                        margin: "1rem",
                        paddingTop: "2rem",
                        width: "100%",
                    }}
                >
                    {renderType()}
                    {renderTitle()}
                    {renderSubTitle()}
                    <Stack direction={"row"} spacing={2}>
                        <Stack style={{ flex: 7 }}>
                            <Stack style={{ marginBottom: "2.25rem" }}>
                                {renderContents()}
                                {renderUserInfo()}
                            </Stack>
                            {isMobile ? renderButton() : null}
                            {renderCount()}
                            {renderComments()}
                        </Stack>
                        {isMobile ? null : (
                            <Stack
                                style={{
                                    flex: 5,
                                }}
                            />
                        )}
                    </Stack>
                </Stack>
                {renderAdjointPosts()}
                <ConfirmModal
                    open={confirmModalState.open}
                    title={confirmModalState.title}
                    content={confirmModalState.content}
                    image={confirmModalState.image}
                    button={confirmModalState.button}
                    closeModal={confirmModalState.closeModal}
                    closeOnOverlayClick={confirmModalState.closeOnOverlayClick}
                    closeOnEsc={confirmModalState.closeOnEsc}
                />
                <DownloadAppModal
                    open={isApplyModalVisible}
                    close={() => setIsApplyModalVisible(false)}
                />
            </Stack>
        );
    };

    const getSEOTitle = () => {
        return Object.keys(post).length
            ? `${post.title} - Forum - Looking for Referral by Zigup`
            : "Forum - Looking for Referral by Zigup";
    };

    const getSEODescription = () => {
        return Object.keys(post).length
            ? post.content.length > 155
                ? post.content.substr(0, 152) + "..."
                : post.content.substr(0, 155)
            : "Forum - Looking for Referral by Zigup";
    };

    const getSEOKeywords = () => {
        const keywords = [
            "zigup",
            "job opportunity",
            "referral",
            "employee referral",
            "career",
            "job switching",
            "internal",
        ];

        return keywords.join(", ");
    };

    const getSEOHelmet = () => {
        const url =
            "https://zigup.in/forum/looking-for-referral/" + post.seoURL;
        return (
            <Helmet>
                <title>{getSEOTitle()}</title>
                <meta
                    name="description"
                    property="description"
                    content={getSEODescription()}
                />
                <meta
                    name="keywords"
                    property="keywords"
                    content={getSEOKeywords()}
                />
                <meta content={getSEOTitle()} property="og:title" />
                <meta property="og:description" content={getSEODescription()} />
                <meta content={url} name="url" property="og:url" />
                <meta
                    content="https://hire.zigup.in/static/media/logo-horizontal.4663103fd0a4bca8ea17.png"
                    property="og:image"
                />
                <link rel="canonical" href={url} />
                {location.pathname.includes(post.seoURL) ? null : (
                    <meta name="robots" content="noindex" />
                )}
            </Helmet>
        );
    };

    return (
        <>
            {getSEOHelmet()}
            <Container maxWidth="lg" sx={{ minHeight: "100vh" }}>
                {renderPostDetail()}
            </Container>
        </>
    );
};

export default ForumLFRDetailPage;
