import { Paper, Stack } from "@mui/material";
import CompanyLogo from "components/atoms/CompanyLogo";
import Text from "components/atoms/Text";
import React from "react";
import styled from "styled-components";
import Colors from "themes/colors";

const HoverWrapper = styled.div`
    border-radius: 4px;
    &:hover {
        background-color: ${Colors.background_2};
    }
`;

const CompanyCard = (props) => {
    const { company, style, isMobile } = props;
    return company ? (
        <a
            href={`/company/${company.seoURL || company._id}`}
            style={{ textDecoration: "none", color: "black" }}
        >
            <Paper
                elevation={0}
                sx={{
                    height: "max-content",
                    cursor: "pointer",
                    boxSizing: "border-box",
                    borderRadius: "0.25rem",
                    ...style,
                }}
            >
                <HoverWrapper>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={1}
                    >
                        <CompanyLogo
                            source={company.logoURL}
                            height={isMobile ? 40 : 80}
                            width={isMobile ? 40 : 80}
                        />
                        <Text
                            style={{
                                color: Colors.search_card_black,
                                fontSize: isMobile ? "0.875rem" : "1rem",
                                fontWeight: 500,
                                fontFamily: "Roboto",
                                flexWrap: "wrap",
                                wordBreak: "break-all",
                            }}
                        >
                            {company.name}
                        </Text>
                    </Stack>
                </HoverWrapper>
            </Paper>
        </a>
    ) : (
        <Text>No. data</Text>
    );
};

export default CompanyCard;
