import * as Api from "./api";
import { post, get } from "./process";

export async function detail(referralId) {
    return await get(Api.Urls.referral.detail(referralId), Api.Types.Simple);
}

export async function apply(params) {
    return await post(Api.Urls.referral.apply, params, Api.Types.FileUpload);
}
