import Logger from "./logger";
import dateformat from "dateformat";

export const timeFromNow = (writeTime) => {
    const now = new Date();
    const betweenTime = Math.floor((now.getTime() - writeTime) / 1000 / 60);
    // if (betweenTime < 1) return "just before";
    if (betweenTime < 60) {
        return betweenTime + "min";
    }

    const betweenTimeHour = Math.floor(betweenTime / 60);
    if (betweenTimeHour < 24) {
        return betweenTimeHour + "h";
    }

    const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
    if (betweenTimeDay < 30) {
        return betweenTimeDay + "d";
    }

    if (betweenTimeDay < 365) {
        return Math.floor(betweenTime / 60 / 24 / 30) + "mon";
    }

    const betweenTimeYear = Math.floor(betweenTime / 60 / 24 / 365);
    if (betweenTimeDay >= 365) {
        return betweenTimeYear + "y";
    }
};

export const getFullDate = (timestamp) => {
    try {
        if (!timestamp) return "";
        const date = new Date(timestamp);
        return dateformat(date, "yyyy-mm-dd");
    } catch (err) {
        Logger.error(err);
    }
    return "";
};
