import React, { useContext, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import Typo from "components/atoms/Typo";
import Colors from "themes/colors";
import UserProfileImage from "../../atoms/UserProfileImage";
import FileUploadButton from "../FileUploadButton";
import { useMediaQuery } from "react-responsive";
import { setMyProfileImage } from "../../../communication/membership";
import { CDN } from "../../../communication/process";
import EditIcon from "@mui/icons-material/Edit";
import { AuthContext } from "../../../context/AuthContext";
import { Logger } from "utils";

const UserInformation = ({ userInfo }) => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const [fileUploading, setFileUploading] = useState(false);
    const [file, setFile] = useState(undefined);
    const { refreshUserInfo } = useContext(AuthContext);

    const onEditProfileImage = async () => {
        if (fileUploading) return;
        // const callback = {
        //     onUploadProgress: (progressEvent) => {
        //         setUploadProgress(
        //             Math.round(
        //                 (progressEvent.loaded * 100) / progressEvent.total
        //             )
        //         );
        //     },
        // };
        //
        try {
            setFileUploading(true);
            const formData = new FormData();
            formData.append("file", file);
            const res = await setMyProfileImage(formData);
            if (res) {
                refreshUserInfo();
            }
        } catch (e) {
            Logger.error("fail to profile image", e);
        } finally {
            setFileUploading(false);
        }
    };

    useEffect(async () => {
        if (file) await onEditProfileImage();
    }, [file]);

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={4}
            padding={8}
            margin={1}
            style={{
                backgroundColor: "white",
                flex: 1,
                boxSizing: "border-box",
            }}
        >
            <Stack
                justifyContent="center"
                alignItems="center"
                style={{
                    position: "relative",
                }}
            >
                <UserProfileImage
                    source={CDN(userInfo.profileImage)}
                    style={{
                        width: "8rem",
                        height: "8rem",
                        borderRadius: "4rem",
                    }}
                />
                <FileUploadButton
                    isMobile={isMobile}
                    value={file}
                    setValue={setFile}
                    buttonStyle={{
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                        transform: "translate(0, 50%)",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: Colors.primary_purple,
                        border: "1px solid #7959f2",
                        height: "2rem",
                        width: "2rem",
                        minHeight: "1rem",
                        borderRadius: 24,
                    }}
                    buttonIcon={
                        <EditIcon
                            sx={{
                                color: Colors.white,
                                fontSize: "1rem",
                            }}
                        />
                    }
                    hiddenFileName={true}
                    acceptType={"image/*"}
                />
            </Stack>
            <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={2}
            >
                <Typo.Body1
                    style={{
                        fontSize: "1.5rem",
                        fontFamily: "Lato",
                        fontWeight: 700,
                    }}
                >
                    {userInfo.name}
                </Typo.Body1>
                <Typo.Body1
                    style={{ fontSize: "1rem", color: Colors.light_grey_3 }}
                >
                    {userInfo.designation} at {userInfo.company}
                </Typo.Body1>
            </Stack>
            <Stack
                direction={"row"}
                justifyContent={"space-around"}
                alignItems="center"
                spacing={2}
                style={{ width: "100%" }}
            >
                <Stack>
                    <Typo.Body1 style={{ color: Colors.text_grey }}>
                        Anonymous name{" "}
                        <span style={{ color: Colors.search_card_black }}>
                            {userInfo.anonymousName}
                        </span>
                    </Typo.Body1>
                </Stack>
                <Stack>
                    <Typo.Body1 style={{ color: Colors.text_grey }}>
                        invitation code{" "}
                        <span style={{ color: Colors.search_card_black }}>
                            {userInfo.invitationCode}
                        </span>
                    </Typo.Body1>
                </Stack>
            </Stack>
        </Stack>
    );
};
export default UserInformation;
