import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { Logger } from "../../../utils";
import Stack from "@mui/material/Stack";
import Typo from "../../atoms/Typo";
import { FORUM_OBJECT_TYPE } from "../../../constants/constants";
import ForumCard from "../../organisms/forum/ForumCard";
import QueryString from "qs";
import Colors from "themes/colors";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router";
import ForumCategory from "../../organisms/forum/ForumCategory";
import {
    allList,
    discussionList,
    lfcList,
    lfrList,
} from "../../../communication/forum";
import SearchTextField from "components/atoms/SearchTextField";
import GlobalHeader from "components/organisms/GlobalHeader";
import Footer from "components/organisms/Footer";
import { createSearchParams } from "react-router-dom";
import Text from "components/atoms/Text";
import { Helmet } from "react-helmet-async";
import { capitalize } from "utils/TextUtils";

const ForumListPage = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [post, setPost] = useState({
        pagination: {
            hasNext: false,
            lastIndex: 0,
            totalCount: 0,
        },
        data: [],
    });
    const {
        type,
        keyword = "",
        page,
    } = QueryString.parse(location.search, {
        ignoreQueryPrefix: true,
    });
    const [paginationPage, setPaginationPage] = useState(1);
    const limit = 20;

    useEffect(async () => {
        window.scrollTo(0, 0);
        if (
            ![
                FORUM_OBJECT_TYPE.ALL,
                FORUM_OBJECT_TYPE.DISCUSSION,
                FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFC,
                FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFR,
            ].includes(type)
        ) {
            navigate("/forum/list?type=all");
            return;
        }
        setPaginationPage(Number(page || 1));
        setPost(undefined);
        await fetchData(type);
    }, [type, page, keyword]);

    const fetchDiscussionList = async (lastIndex) => {
        try {
            const res = await discussionList({
                keyword,
                limit,
                lastIndex,
            });
            setPost({
                pagination: res.pagination,
                data: res.discussions,
            });
        } catch (e) {
            Logger.error("fetch failed", e);
        }
    };

    const fetchLfcList = async (lastIndex) => {
        try {
            const res = await lfcList({
                keyword,
                limit,
                lastIndex,
            });
            setPost({
                pagination: res.pagination,
                data: res.forumReferrals,
            });
        } catch (e) {
            Logger.error("fetch failed", e);
        }
    };

    const fetchLfrList = async (lastIndex) => {
        try {
            const res = await lfrList({
                keyword,
                limit,
                lastIndex,
            });
            setPost({
                pagination: res.pagination,
                data: res.forumReferrals,
            });
        } catch (e) {
            Logger.error("fetch failed", e);
        }
    };

    const fetchAllList = async (lastIndex) => {
        try {
            const res = await allList({
                limit,
                lastIndex,
            });
            setPost({
                pagination: res.pagination,
                data: res.discussionAndForumReferrals,
            });
        } catch (e) {
            Logger.error("fetch failed", e);
        }
    };

    const fetchData = async (type) => {
        const lastIndex = Number(page) === 1 ? 0 : (page - 1) * limit - 1;
        try {
            setIsLoading(true);
            switch (type) {
                case FORUM_OBJECT_TYPE.ALL:
                    await fetchAllList(lastIndex);
                    break;
                case FORUM_OBJECT_TYPE.DISCUSSION:
                    await fetchDiscussionList(lastIndex);
                    break;
                case FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFR:
                    await fetchLfrList(lastIndex);
                    break;
                case FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFC:
                    await fetchLfcList(lastIndex);
                    break;
                default:
                    break;
            }
        } catch (e) {
            navigate(-1);
        } finally {
            setIsLoading(false);
        }
    };

    const onPageChanged = async (event, value) => {
        setPaginationPage(Number(value || 1));
        if (!type) {
            navigate(`/forum/list/?type=discussion`);
            return;
        }
        const searchParams = { type, page: value };
        if (keyword) searchParams.keyword = keyword;
        navigate({
            pathname: "/forum/list/",
            search: createSearchParams(searchParams).toString(),
        });
    };

    const renderSearchBar = () => {
        return (
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                    width: "100%",
                    backgroundColor: "#f0f1f5",
                }}
            >
                <SearchTextField
                    placeholder={
                        isMobile
                            ? "Company, Job title, Tech stack"
                            : "Search with Company, Job title, Tech stack"
                    }
                    defaultType={type}
                    prevSearchKeyword={keyword}
                />
            </Stack>
        );
    };

    const renderForumList = () => {
        const _emptyArr = new Array(limit).fill();
        return (
            <Stack
                direction={"column"}
                style={{
                    width: "100%",
                    marginBottom: "2rem",
                }}
            >
                <Stack spacing={1}>
                    <ForumCategory selectItem={type} keyword={keyword} />
                    <Text
                        style={{
                            color: Colors.text_grey,
                            marginBottom: "1rem",
                        }}
                    >
                        {post === undefined || isLoading
                            ? ""
                            : `${post?.pagination.totalCount} Results`}
                    </Text>
                </Stack>
                {post === undefined || isLoading ? (
                    <Grid container spacing={2}>
                        {_emptyArr.map((item, index) => (
                            <Grid
                                item
                                xs={isMobile ? 12 : 6}
                                key={index + "_forum_tmp"}
                            >
                                <ForumCard />
                            </Grid>
                        ))}
                    </Grid>
                ) : post.data.length > 0 ? (
                    <Grid container spacing={2}>
                        {post.data.map((item, index) => (
                            <Grid
                                item
                                xs={isMobile ? 12 : 6}
                                key={index + "_forum"}
                            >
                                <ForumCard item={item} />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        style={{ height: "50vh", width: "100%" }}
                    >
                        <Typo.H2 style={{ fontSize: "1.5rem" }}>
                            No post
                        </Typo.H2>
                    </Stack>
                )}
                {!!post && !isLoading && post.pagination.totalCount > 0 ? (
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        style={{ marginTop: "2rem" }}
                    >
                        <Pagination
                            count={Math.ceil(
                                post.pagination.totalCount / limit
                            )}
                            page={paginationPage}
                            siblingCount={isMobile ? 1 : 3}
                            onChange={onPageChanged}
                        />
                    </Stack>
                ) : null}
            </Stack>
        );
    };

    const getSEOTitle = () => {
        const _keyword = capitalize(keyword);
        return _keyword
            ? `${_keyword} Discussion, Employee Referral - Zigup`
            : "Forum Discussion / Employee Referral by Zigup";
    };

    const getSEODescription = () => {
        const _keyword = capitalize(keyword);
        return _keyword
            ? `Find discussions and employee referral for ${_keyword} India. Sign Up for Better Offers!`
            : "Find discussions and employee referrals in India. Sign Up for Better Offers!";
    };

    const getSEOKeywords = () => {
        const _keyword = capitalize(keyword);
        return _keyword
            ? `${_keyword} Discussions, ${_keyword} Referral, ${_keyword} Employee Referral, Looking for ${_keyword} Candidates, Looking for ${_keyword} Referral`
            : "Discussions, Referral, Employee Referral, Looking for Candidates, Looking for Referral";
    };

    const getSEOHelmet = () => {
        return (
            <Helmet>
                <title>{getSEOTitle()}</title>
                <meta
                    name="description"
                    property="description"
                    content={getSEODescription()}
                />
                <meta
                    name="keywords"
                    property="keywords"
                    content={getSEOKeywords()}
                />
                <meta content={getSEOTitle()} property="og:title" />
                <meta property="og:description" content={getSEODescription()} />
                <meta
                    content="https://hire.zigup.in/static/media/logo-horizontal.4663103fd0a4bca8ea17.png"
                    property="og:image"
                />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
        );
    };

    return (
        <>
            {getSEOHelmet()}
            <GlobalHeader />
            {renderSearchBar()}
            <Container maxWidth="lg" sx={{ marginTop: "2rem" }}>
                {renderForumList()}
            </Container>
            <Footer />
        </>
    );
};

export default ForumListPage;
