import React from "react";
import { Stack } from "@mui/material";
import Typo from "components/atoms/Typo";
import ZigupButton from "../../atoms/ZigupButton";
import styled from "styled-components";
import ProfileCheck from "../../atoms/ProfileCheck";
import { isRequired } from "../../../utils/validator";
import experiencedIcon from "assets/icons/icon-experienced.png";
import studentIcon from "assets/icons/icon-student.png";
import { EXPERIENCE_TYPE } from "../../../constants/constants";
import { useNavigate } from "react-router";
import Colors from "themes/colors";
import Text from "components/atoms/Text";

const ItemContainer = styled(Stack)`
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

const SubjectText = styled(Text)`
    font-size: 0.75rem;
    color: ${Colors.light_grey_3};
    font-family: Roboto;
    font-weight: 400;
`;

const BasicInformation = ({ userInfo }) => {
    const navigate = useNavigate();
    return (
        <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
            padding={2}
            margin={1}
            style={{ backgroundColor: "white" }}
        >
            <ItemContainer>
                <Stack>
                    <Typo.Body1Bold
                        style={{
                            fontSize: "1rem",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                        }}
                    >
                        My basic information
                    </Typo.Body1Bold>
                </Stack>
                <Stack>
                    <ZigupButton
                        id={"basic-information-edit-btn"}
                        buttonText={"EDIT"}
                        containerStyle={{
                            width: "max-content",
                            height: "max-content",
                            backgroundColor: "transparent",
                        }}
                        buttonTextSize={"1rem"}
                        buttonTextStyle={{ color: "#646464" }}
                        onClickHandler={() =>
                            navigate("edit/basic", {
                                state: { userInfo: userInfo },
                            })
                        }
                    />
                </Stack>
            </ItemContainer>
            <Stack
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                spacing={2}
                style={{ width: "100%" }}
            >
                <img
                    src={
                        userInfo.isExperienced === EXPERIENCE_TYPE.EXPERIENCED
                            ? experiencedIcon
                            : studentIcon
                    }
                    alt="computer-image"
                    width="40"
                    height="40"
                />
                <Typo.Body1Bold style={{ fontWeight: 500 }}>
                    {userInfo.isExperienced === EXPERIENCE_TYPE.EXPERIENCED
                        ? "Experienced Worker"
                        : "Fresher"}
                </Typo.Body1Bold>
            </Stack>
            <ItemContainer>
                <SubjectText>Fresher/ Experienced</SubjectText>
                <ProfileCheck
                    checked={isRequired(userInfo.isExperienced + "").valid}
                />
            </ItemContainer>
            <ItemContainer>
                <SubjectText>Full name</SubjectText>
                <ProfileCheck checked={isRequired(userInfo.name).valid} />
            </ItemContainer>
            <ItemContainer>
                <SubjectText>linkedIn profile</SubjectText>
                <ProfileCheck
                    checked={isRequired(userInfo.linkedinLink).valid}
                />
            </ItemContainer>
            <ItemContainer>
                <SubjectText>eMail address</SubjectText>
                <ProfileCheck checked={isRequired(userInfo.email).valid} />
            </ItemContainer>
            <ItemContainer>
                <SubjectText>Current salary</SubjectText>
                <ProfileCheck
                    checked={isRequired(userInfo.currentSalary + "").valid}
                />
            </ItemContainer>
            <ItemContainer>
                <SubjectText>Expect salary</SubjectText>
                <ProfileCheck
                    checked={isRequired(userInfo.desiredSalary + "").valid}
                />
            </ItemContainer>
            <ItemContainer>
                <SubjectText>Notice period</SubjectText>
                <ProfileCheck
                    checked={isRequired(userInfo.noticePeriod + "").valid}
                />
            </ItemContainer>
        </Stack>
    );
};
export default BasicInformation;
