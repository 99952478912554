import React from "react";
import styled from "styled-components";
import { FormControl, MenuItem, Select } from "@mui/material";
import Text from "components/atoms/Text";
import { YOE } from "constants/constants";

const InputTitleContainer = styled.div`
    align-items: flex-start;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 2px;
`;

const YearSelector = (props) => {
    const { id, title, value, setValue } = props;
    return (
        <div
            style={{
                marginBottom: "1rem",
                width: "100%",
            }}
        >
            <InputTitleContainer>
                <Text style={{ fontSize: 12 }}>
                    {title || "Years of relevant experience"}
                </Text>
            </InputTitleContainer>
            <FormControl sx={{ width: "100%" }}>
                <Select
                    id={id || "experience-selector"}
                    value={value}
                    onChange={(event) => setValue(event.target.value)}
                    fullWidth={true}
                >
                    {YOE.map((yoe) => (
                        <MenuItem
                            key={"yoe" + yoe.value}
                            value={"" + yoe.value}
                        >
                            {yoe.value === 0 ? <em>{yoe.text}</em> : yoe.text}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default YearSelector;
