import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import { recommendJdListById } from "communication/jd";
import Text from "../../atoms/Text";
import Colors from "themes/colors";
import { Logger } from "utils";
import JdCard from "./JdCard";

const RecommendJds = ({ id, isMobile, maxCount = 10 }) => {
    const [jds, setJds] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const _emptyArr = new Array(maxCount).fill();

    useEffect(async () => {
        try {
            setIsLoading(true);
            const res = await recommendJdListById(id, {
                limit: maxCount,
            });
            setJds(res.jds || []);
            setIsLoading(false);
        } catch (e) {
            setJds([]);
            setIsLoading(false);
            Logger.log("e", e);
        }
    }, []);

    if (jds === undefined) {
        return (
            <Stack justifyContent="center" alignItems="center">
                <Grid container spacing={2}>
                    {_emptyArr?.map((_, index) => (
                        <Grid
                            item
                            xs={isMobile ? 12 : 6}
                            key={index + "recommend_tmp"}
                        >
                            <JdCard />
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        );
    }
    if (jds.length < 1) {
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                style={{ height: "20vh" }}
            >
                <Text style={{ color: Colors.light_grey }}>
                    No recommended posts yet
                </Text>
            </Stack>
        );
    } else {
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                    marginBottom: "3rem",
                }}
            >
                {isLoading ? (
                    <Grid container spacing={2}>
                        {_emptyArr?.map((_, index) => (
                            <Grid
                                item
                                xs={isMobile ? 12 : 6}
                                key={index + "recommend"}
                            >
                                <JdCard />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        {jds?.map((jd, index) => (
                            <Grid
                                item
                                xs={isMobile ? 12 : 6}
                                key={index + "recommend"}
                            >
                                <JdCard jd={jd} />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Stack>
        );
    }
};

export default RecommendJds;
