import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { Stack } from "@mui/material";
import Typo from "components/atoms/Typo";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import SearchTextField from "components/atoms/SearchTextField";
import GlobalHeader from "components/organisms/GlobalHeader";
import Footer from "components/organisms/Footer";
import SearchCard from "components/organisms/search/SearchCard";
import JdCard from "components/organisms/jd/JdCard";
import TrendingSalaries from "../organisms/salary/TrendingSalaries";
import { jdList } from "communication/jd";
import { Helmet } from "react-helmet-async";
import ViewMoreButton from "components/organisms/ViewMoreButton";
import Text from "components/atoms/Text";
import { AuthContext } from "context/AuthContext";
import { showSignUpModal } from "components/organisms/GlobalHeader";
import ForumMainPosts from "../organisms/forum/ForumMainPosts";
import amazonLogo from "assets/images/main/amazon-horizontal.png";
import googleLogo from "assets/images/main/google-horizontal.png";
import microsoftLogo from "assets/images/main/microsoft-horizontal.png";
import flipkartLogo from "assets/images/main/flipkart-horizontal.png";
import walmartLogo from "assets/images/main/walmart-horizontal.png";
import oracleLogo from "assets/images/main/oracle-horizontal.png";
import { getKeywords } from "communication/main";
import CompanyLongCard from "components/organisms/company/CompanyLongCard";

const MainPage = () => {
    const JDS_COUNT = 4;
    const KEYWORD_JOB_COUNT = 6;
    const KEYWORD_SKILL_COUNT = 10;
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const navigate = useNavigate();
    const { userState } = useContext(AuthContext);
    const [jds, setJds] = useState([]);
    const [keywordCountMap, setKeywordCountMap] = useState({
        companiesWithCount: [],
        jobTitleAndCounts: [
            { title: "Product Manager" },
            { title: "Data Engineer" },
            { title: "SDET" },
            { title: "Data Analyst" },
            { title: "Data Scientist" },
            { title: "SDE" },
        ],
        keySkillsAndCounts: [
            { skill: "Angular" },
            { skill: "React" },
            { skill: "Java" },
            { skill: "DevOps" },
            { skill: "Python" },
            { skill: "Golang" },
            { skill: ".NET" },
            { skill: "Android" },
            { skill: "Uber" },
            { skill: "AWS" },
        ],
    });

    const popularCompanies = [
        {
            name: "Amazon",
            longLogoURL: amazonLogo,
        },
        {
            name: "Flipkart",
            longLogoURL: flipkartLogo,
        },
        {
            name: "Oracle",
            longLogoURL: oracleLogo,
        },
        {
            name: "Google",
            longLogoURL: googleLogo,
        },
        {
            name: "Walmart",
            longLogoURL: walmartLogo,
        },
        {
            name: "Microsoft",
            longLogoURL: microsoftLogo,
        },
    ];

    useEffect(() => {
        getJds();
    }, []);

    useEffect(() => {
        setKeywords();
    }, []);

    const getJds = async () => {
        const res = await jdList({
            limit: JDS_COUNT,
            sort: "newest",
        });
        setJds([...res.jds]);
    };

    const setKeywords = async () => {
        const res = await getKeywords();
        setKeywordCountMap({ ...res.keywordCountMap });
    };

    const renderSearchBar = () => {
        return (
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                    height: "18rem",
                    width: "100%",
                    backgroundColor: "#7959f2",
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                }}
            >
                <Typo.H1
                    style={{
                        color: "white",
                        marginTop: 0,
                        textAlign: "center",
                        fontSize: isMobile ? 26 : 30,
                    }}
                >
                    Salary Breakups / Jobs for your career
                </Typo.H1>
                <SearchTextField
                    showTags={false}
                    placeholder={
                        isMobile
                            ? "Company, Job title, Tech stack"
                            : "Search with Company, Job title, Tech stack"
                    }
                />
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                    sx={{ marginTop: "1rem" }}
                >
                    <Text
                        style={{
                            color: "#ede9ff",
                        }}
                    >
                        Or, share yours with others
                    </Text>
                    <div
                        onClick={() => {
                            userState.auth
                                ? navigate("/salary/add")
                                : showSignUpModal("/salary/add");
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        <Text
                            style={{
                                textDecoration: "underline",
                                color: "#ffffff",
                            }}
                        >
                            + Share my salary
                        </Text>
                    </div>
                </Stack>
            </Stack>
        );
    };

    const renderPopularKeywords = () => {
        return (
            <Stack spacing={2} style={{ marginBottom: "2rem" }}>
                <Typo.H2
                    style={{
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "1rem",
                        margin: 0,
                    }}
                >
                    Salary Breakup by Companies, Roles & Skills
                </Typo.H2>
                <Grid container spacing={0}>
                    {keywordCountMap.companiesWithCount.length
                        ? keywordCountMap.companiesWithCount.map(
                              (company, index) => (
                                  <Grid
                                      item
                                      xs={isMobile ? 6 : 4}
                                      key={"popular_" + index}
                                      sx={{ paddingRight: 1, paddingBottom: 1 }}
                                  >
                                      <CompanyLongCard company={company} />
                                  </Grid>
                              )
                          )
                        : popularCompanies.map((company, index) => (
                              <Grid
                                  item
                                  xs={isMobile ? 6 : 4}
                                  key={"popular_" + index}
                                  sx={{ paddingRight: 1, paddingBottom: 1 }}
                              >
                                  <CompanyLongCard company={company} />
                              </Grid>
                          ))}
                </Grid>
                <Grid container columns={12} spacing={0}>
                    {keywordCountMap.jobTitleAndCounts.length
                        ? keywordCountMap.jobTitleAndCounts.map((jobTitle) => (
                              <Grid
                                  item
                                  xs={isMobile ? 6 : 2}
                                  key={jobTitle.title}
                                  sx={{ paddingRight: 1, paddingBottom: 1 }}
                              >
                                  <SearchCard
                                      keyword={jobTitle.title}
                                      data={jobTitle}
                                  />
                              </Grid>
                          ))
                        : new Array(KEYWORD_JOB_COUNT)
                              .fill()
                              .map((_, index) => (
                                  <Grid
                                      item
                                      xs={isMobile ? 6 : 2}
                                      key={index + "_skill_tmp"}
                                      sx={{ paddingRight: 1, paddingBottom: 1 }}
                                  >
                                      <SearchCard />
                                  </Grid>
                              ))}
                </Grid>
                <Grid container columns={12} spacing={0}>
                    {keywordCountMap.keySkillsAndCounts.length
                        ? keywordCountMap.keySkillsAndCounts.map((skillset) => (
                              <Grid
                                  item
                                  xs={isMobile ? 6 : 2}
                                  key={skillset.skill}
                                  sx={{ paddingRight: 1, paddingBottom: 1 }}
                              >
                                  <SearchCard
                                      keyword={skillset.skill}
                                      data={skillset}
                                  />
                              </Grid>
                          ))
                        : new Array(KEYWORD_SKILL_COUNT)
                              .fill()
                              .map((_, index) => (
                                  <Grid
                                      item
                                      xs={isMobile ? 6 : 2}
                                      key={index + "_skill_tmp"}
                                      sx={{ paddingRight: 1, paddingBottom: 1 }}
                                  >
                                      <SearchCard />
                                  </Grid>
                              ))}
                </Grid>
            </Stack>
        );
    };

    const renderTrendingSalaries = () => {
        return <TrendingSalaries />;
    };

    const renderJobs = () => {
        const _emptyJds = new Array(JDS_COUNT).fill();
        return (
            <Stack sx={{ marginBottom: "64px" }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ marginBottom: "0.5rem" }}
                >
                    <Typo.H2
                        style={{
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "1rem",
                        }}
                    >
                        Apply for Jobs
                    </Typo.H2>
                </Stack>
                <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                    {jds.length
                        ? jds.map((jd, index) => (
                              <Grid
                                  item
                                  xs={isMobile ? 12 : 6}
                                  key={index + "jd"}
                              >
                                  <JdCard jd={jd} />
                              </Grid>
                          ))
                        : _emptyJds.map((jd, index) => (
                              <Grid
                                  item
                                  xs={isMobile ? 12 : 6}
                                  key={index + "jd_temp"}
                              >
                                  <JdCard />
                              </Grid>
                          ))}
                </Grid>
                <ViewMoreButton href={"/jd/list"} />
            </Stack>
        );
    };

    const renderForums = () => {
        return (
            <Stack sx={{ marginBottom: "64px" }}>
                <ForumMainPosts />
            </Stack>
        );
    };

    const renderHelmet = () => {
        const data = {
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Zigup",
            url: "https://zigup.in/",
            logo: "https://hire.zigup.in/static/media/logo-horizontal.4663103fd0a4bca8ea17.png",
            image: "https://zigup.in/static/media/img-main.8f47a71da731a0b61a34.png",
            email: "contact@hyperhire.in",
            description:
                "Zigup is a anonymous career platform that allows you to apply for jobs at top product-based companies, share and explore information about salary breakups, job referrals, and interviews to help you advance your career.",
            address:
                "D-138, St. No -11, Jagjeet Nagar, North East Delhi, New Delhi, 110053 India",
            sameAs: [
                "https://www.facebook.com/jobs.zigup.in/",
                "https://www.linkedin.com/showcase/zigup/",
                "https://www.instagram.com/zigupindia/",
                "https://twitter.com/zigup_india",
            ],
            aggregateRating: {
                "@type": "AggregateRating",
                ratingValue: "4.8",
                ratingCount: "515",
                reviewCount: "515",
                bestRating: "5",
                worstRating: "1",
            },
            areaServed: "India",
        };
        const searchData = {
            "@context": "https://schema.org",
            "@type": "WebSite",
            url: "https://zigup.in/",
            potentialAction: {
                "@type": "SearchAction",
                target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                        "https://zigup.in/search?keyword={search_term_string}",
                },
                "query-input": "required name=search_term_string",
            },
        };
        return (
            <Helmet>
                <meta
                    name="description"
                    property="description"
                    content="Find Salary Breakups, Job Referrals & Product-Based Job Offers in India. Download the Zigup App Today!"
                />
                <script type="application/ld+json">
                    {JSON.stringify(data)}
                </script>
                <script type="application/ld+json">
                    {JSON.stringify(searchData)}
                </script>
            </Helmet>
        );
    };

    return (
        <>
            {renderHelmet()}
            <GlobalHeader color="#7959f2" />
            {renderSearchBar()}
            <Container maxWidth="lg" sx={{ marginTop: "2rem" }}>
                {renderPopularKeywords()}
                {renderTrendingSalaries()}
                {renderJobs()}
                {renderForums()}
            </Container>
            <Footer />
        </>
    );
};
export default MainPage;
