import styled from "styled-components";
import Colors from "themes/colors";
import { Paper, Stack } from "@mui/material";
import Typo from "components/atoms/Typo";
import { useMediaQuery } from "react-responsive";
import ZigupSpinner from "../ZigupSpinner";
import Text from "components/atoms/Text";

const HoverWrapper = styled.div`
    &:hover {
        background-color: ${Colors.background_2};
    }
`;

const CompanyLongCard = ({ company, style }) => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

    const renderCounts = () => {
        return (
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    position: "absolute",
                    bottom: 4,
                    right: 4,
                    boxSizing: "border-box",
                }}
            >
                <div />
                <a
                    href={`/company/${company.seoURL}`}
                    style={{
                        textDecoration: "none",
                        color: Colors.light_grey,
                    }}
                >
                    <Typo.Body2
                        style={{
                            fontSize: "0.6rem",
                            color: Colors.light_grey,
                        }}
                    >
                        {typeof company?.salaryCount === "number"
                            ? company?.salaryCount
                            : "-"}{" "}
                        posts
                    </Typo.Body2>
                </a>
            </Stack>
        );
    };

    return company ? (
        <Paper
            elevation={0}
            sx={{
                borderWidth: 1,
                borderColor: Colors.outline_2,
                borderStyle: "solid",
                position: "relative",
                justifyContent: "center",
            }}
        >
            <HoverWrapper>
                <a
                    href={`/company/${company.seoURL || company._id}`}
                    style={{ textDecoration: "none", color: "black" }}
                >
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        sx={{
                            minHeight: isMobile ? "70px" : "90px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            boxSizing: "border-box",
                            position: "relative",
                            ...style,
                        }}
                    >
                        {company?.longLogoURL ? (
                            isMobile ? (
                                <img
                                    src={company?.longLogoURL}
                                    alt={`${company?.name}Icon`}
                                    style={{
                                        maxWidth: "50%",
                                        maxHeight: "24px",
                                    }}
                                />
                            ) : (
                                <img
                                    src={company?.longLogoURL}
                                    alt={`${company?.name}Icon`}
                                    height="100%"
                                />
                            )
                        ) : (
                            <Stack
                                alignItems="center"
                                justifyContent="center"
                                sx={{ width: "100%", height: "100%" }}
                            >
                                <Text>{company.name}</Text>
                            </Stack>
                        )}
                        <div />
                    </Stack>
                </a>
                {renderCounts()}
            </HoverWrapper>
        </Paper>
    ) : (
        <Paper
            elevation={0}
            sx={{
                borderWidth: 1,
                borderColor: Colors.outline_2,
                borderStyle: "solid",
                position: "relative",
                justifyContent: "center",
                height: isMobile ? "70px" : "90px",
            }}
        >
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ height: "100%", width: "100%" }}
            >
                <ZigupSpinner size={"2rem"} />
            </Stack>
        </Paper>
    );
};

export default CompanyLongCard;
