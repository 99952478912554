import { Alert, Collapse } from "@mui/material";
import React, { createContext, useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ALERT_TYPE } from "constants/constants";

const AlertContext = createContext({
    showAlert: false,
    text: "",
    alertType: ALERT_TYPE.SUCCESS,
    openAlert: () => {},
});

const AlertProvider = ({ children }) => {
    const [showAlert, setShowAlert] = useState(false);
    const [text, setText] = useState("");
    const [alertType, setAlertType] = useState(ALERT_TYPE.SUCCESS);

    const openAlert = (props) => {
        if (showAlert) setShowAlert(false);
        setText(props.text);
        if (props.alertType) setAlertType(props.alertType);
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    };

    const closeAlert = () => {
        setShowAlert(false);
    };

    return (
        <AlertContext.Provider value={{ openAlert, closeAlert }}>
            {children}
            <Collapse in={showAlert}>
                <div
                    style={{
                        position: "fixed",
                        bottom: 10,
                        left: 10,
                        width: "70vw",
                        minWidth: "400px",
                        maxWidth: "600px",
                        zIndex: 1000,
                    }}
                >
                    <Alert
                        severity={alertType}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setShowAlert(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {text}
                    </Alert>
                </div>
            </Collapse>
        </AlertContext.Provider>
    );
};

export { AlertContext, AlertProvider };
