const Colors = {
    black: "#2d2c31",
    black_light: "#2d2c3199",
    search_card_black: "#2f2d35",
    header_black: "#2f2d35",
    header_purple: "#7959f2",
    primary_purple: "#7959f2",
    purple: "#6b4eff",
    purple_blue: "#6837f2",
    light_purple: "#e3ebff",
    white_tag_border: "#dedede",
    search_tag_border: "#c4c4c4",
    search_tag_background: "#7e7e7e",
    no_item_background: "#f5f5f5",
    grey: "#61646b",
    anonymous_grey: "#959595",
    salary_text_grey: "#646464",
    salary_text_background: "#f1f2f3",
    jd_text_grey: "#646464",
    jd_text_background: "#f1f2f3",
    light_grey: "#c0c0c0",
    light_grey_2: "#e8e9f3",
    light_grey_3: "#646464",
    disabled_grey: "#e3e3e3",
    blue: "#5956ff",
    success_purple: "#5956ff",
    faq_text: "#6b71ff",
    faq_border: "#6b71ff",
    outline: "#d4d6dd",
    outline_2: "#d7daff",
    button_disabled: "#d4d6dd",
    button_active: "#c1c8ff",
    button_background_404: "#876bf8",
    checkbox_disabled: "#c1c8ff",
    direct_hiring_background: "#ebedff",
    tag_background_default: "#f0f1f5",
    select_background_default: "#f0f1f5",
    default_color: "#f0f1f5",
    white: "#ffffff",
    background: "#f9f9fc",
    background_2: "#f8f9ff",
    background_black: "#1d1d1d",
    background_blue: "#3f4bd3",
    background_blue_2: "#5956ff",
    background_light_grey: "#e8e9f3",
    background_contribute_card: "#f8f8ff",
    success: "#27ae60",
    forum_discussion: "#27ae60",
    forum_lfc: "#2e86c1",
    forum_lfr: "#7959f2",
    text_grey: "#aaaaaa",
    footer: "#bababa",
    error: "#ff224a",
    alert: "#ff224a",
};

export default Colors;
