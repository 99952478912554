import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useNavigate, useParams } from "react-router";

const RedirectReferral = () => {
    const navigate = useNavigate();
    const params = useParams();
    useEffect(async () => {
        if (!params.id) navigate("/");
    }, [params]);

    return <Navigate to={`/forum/looking-for-candidate/${params.id}`} />;
};
export default RedirectReferral;
