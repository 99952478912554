import styled from "styled-components";
import Colors from "themes/colors";
import { Paper, Stack } from "@mui/material";
import Text from "components/atoms/Text";
import Typo from "components/atoms/Typo";
import SkillTag from "components/atoms/SkillTag";
import { timeFromNow } from "utils/DateUtil";
import upvoteIcon from "assets/icons/icon-upvote-lined.png";
import viewIcon from "assets/icons/icon-view.png";
import commentIcon from "assets/icons/icon-comment.png";
import dmIcon from "assets/icons/dm@3x.png";
import ZigupSpinner from "../ZigupSpinner";

const HoverWrapper = styled.div`
    border-radius: 4px;
    &:hover {
        background-color: ${Colors.background_2};
    }
`;

const SalaryCard = ({ salary, style, showWriter = true }) => {
    const renderCounts = () => {
        return (
            <Stack direction="row" spacing={2}>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <img
                        src={upvoteIcon}
                        alt="upvoteIcon"
                        width={20}
                        height={20}
                    />
                    <Text style={{ color: Colors.salary_text_grey }}>
                        {salary?.upvoteCount}
                    </Text>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <img src={viewIcon} alt="viewIcon" width={20} height={20} />
                    <Text style={{ color: Colors.salary_text_grey }}>
                        {salary?.viewCount}
                    </Text>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <img
                        src={commentIcon}
                        alt="commentIcon"
                        width={20}
                        height={20}
                    />
                    <Text style={{ color: Colors.salary_text_grey }}>
                        {salary?.commentCount}
                    </Text>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <img src={dmIcon} alt="dmIcon" width={20} height={20} />
                    <Text style={{ color: Colors.salary_text_grey }}>
                        {salary?.dmCount}
                    </Text>
                </Stack>
            </Stack>
        );
    };

    return salary ? (
        <a
            href={`/salary/${salary.seoURL || salary._id}`}
            style={{ textDecoration: "none", color: "black" }}
        >
            <Paper
                elevation={0}
                sx={{
                    height: "100%",
                    minHeight: showWriter ? "150px" : "120px",
                    cursor: "pointer",
                    borderWidth: 1,
                    borderColor: Colors.outline_2,
                    borderStyle: "solid",
                    boxSizing: "border-box",
                    borderRadius: "0.25rem",
                    ...style,
                }}
            >
                <HoverWrapper>
                    <Stack
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        spacing={1}
                        style={{ padding: "1rem" }}
                    >
                        <Typo.H4Regular
                            style={{
                                marginBottom: 4,
                                marginTop: 4,
                            }}
                        >
                            {salary?.company} | {salary?.title} |{" "}
                            {salary?.yearsOfExperience} year(s)
                        </Typo.H4Regular>
                        {showWriter ? (
                            <Typo.Body2
                                style={{ fontSize: "0.75rem", marginBottom: 4 }}
                            >
                                {salary?.writer.displayName} |{" "}
                                {timeFromNow(salary?.createdAt)}
                            </Typo.Body2>
                        ) : null}
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{ marginBottom: "16px", flexWrap: "wrap" }}
                        >
                            {salary?.role ? (
                                <SkillTag skill={salary.role} />
                            ) : null}
                            {salary?.skillset.length
                                ? salary?.skillset.map((skillset, index) => (
                                      <SkillTag
                                          key={salary?._id + index}
                                          skill={skillset}
                                      />
                                  ))
                                : null}
                        </Stack>
                        {renderCounts()}
                    </Stack>
                </HoverWrapper>
            </Paper>
        </a>
    ) : (
        <Paper
            elevation={0}
            sx={{
                height: "100%",
                minHeight: showWriter ? "150px" : "120px",
                cursor: "pointer",
                borderWidth: 1,
                borderColor: Colors.outline_2,
                borderStyle: "solid",
                boxSizing: "border-box",
                borderRadius: "0.25rem",
                ...style,
            }}
        >
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ height: "100%", width: "100%" }}
            >
                <ZigupSpinner size={"2rem"} />
            </Stack>
        </Paper>
    );
};

export default SalaryCard;
