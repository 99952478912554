import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Grid, Stack, Tooltip, tooltipClasses } from "@mui/material";
import Typo from "components/atoms/Typo";
import Footer from "components/organisms/Footer";
import GlobalHeader from "components/organisms/GlobalHeader";
import { useMediaQuery } from "react-responsive";
import SearchTextField from "components/atoms/SearchTextField";
import JdCard from "components/organisms/jd/JdCard";
import { jdList } from "communication/jd";
import { useLocation, useNavigate } from "react-router";
import QueryString from "qs";
import { SEARCH_TYPE } from "constants/constants";
import Pagination from "@mui/material/Pagination";
import Text from "components/atoms/Text";
import Colors from "themes/colors";
import { capitalize } from "utils/TextUtils";
import { Logger } from "utils";
import { Helmet } from "react-helmet-async";
import QuestionIcon from "assets/icons/question.svg";

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 150,
    },
});

const JdListPage = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const location = useLocation();
    const [jds, setJds] = useState({
        pagination: {
            hasNext: false,
            lastIndex: 0,
            totalCount: 0,
        },
        data: [],
    });
    const [title, setTitle] = useState("Jobs available in Zigup");
    const [isLoading, setIsLoading] = useState(true);
    const limit = 20;
    const _emptyArr = new Array(limit).fill();
    const { keyword, page } = QueryString.parse(location.search, {
        ignoreQueryPrefix: true,
    });
    const [paginationPage, setPaginationPage] = useState(1);

    useEffect(async () => {
        window.scrollTo(0, 0);

        setJds({
            pagination: {
                hasNext: false,
                lastIndex: 0,
                totalCount: 0,
            },
            data: [],
        });
        setPaginationPage(Number(page || 1));
        const lastIndex = Number(page) === 1 ? 0 : (page - 1) * limit - 1;
        try {
            setIsLoading(true);
            const res = await jdList({
                keyword,
                limit,
                lastIndex,
            });
            setJds({
                pagination: res.pagination,
                data: res.jds,
            });
        } catch (e) {
            Logger.error(e);
            // navigate(-1);
        } finally {
            setIsLoading(false);
        }
    }, [keyword, page]);

    useEffect(() => {
        if (keyword) {
            setTitle(`${capitalize(keyword)} Jobs in Zigup`);
        }
    }, [location, jds.pagination]);

    const onPageChanged = async (event, value) => {
        if (keyword) {
            navigate(`/jd/list/?keyword=${keyword}&page=${value}`);
        } else {
            navigate(`/jd/list/?page=${value}`);
        }
    };

    const renderSearchBar = () => {
        return (
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                    minHeight: "8rem",
                    width: "100%",
                    backgroundColor: "#f0f1f5",
                }}
            >
                <SearchTextField
                    placeholder={
                        isMobile
                            ? "Company, Job title, Tech stack"
                            : "Search with Company, Job title, Tech stack"
                    }
                    defaultType={SEARCH_TYPE.JOB.value}
                    prevSearchKeyword={keyword}
                />
            </Stack>
        );
    };

    const renderJdList = () => {
        return (
            <Stack direction="column" sx={{ marginBottom: "2rem" }}>
                <Stack spacing={1}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typo.H1Regular
                            style={{
                                fontSize: "1.25rem",
                            }}
                        >
                            {title}
                        </Typo.H1Regular>
                        <CustomWidthTooltip
                            title="Direct Hiring is for Jobs which can get fast feedback and hiring process by Zigup"
                            arrow
                            placement="top"
                        >
                            <img
                                src={QuestionIcon}
                                alt="QuestionIcon"
                                width={isMobile ? 16 : 24}
                                height={isMobile ? 16 : 24}
                            />
                        </CustomWidthTooltip>
                    </Stack>
                    <Text
                        style={{
                            color: Colors.text_grey,
                            marginBottom: "1rem",
                        }}
                    >
                        {jds.pagination.totalCount} Results
                    </Text>
                </Stack>
                <Grid container spacing={2}>
                    {isLoading
                        ? _emptyArr.map((_, index) => (
                              <Grid
                                  item
                                  xs={isMobile ? 12 : 6}
                                  key={index + "_jd_temp"}
                              >
                                  <JdCard />
                              </Grid>
                          ))
                        : jds.data.map((jd, index) => (
                              <Grid
                                  item
                                  xs={isMobile ? 12 : 6}
                                  key={index + "_jd"}
                              >
                                  <JdCard jd={jd} />
                              </Grid>
                          ))}
                </Grid>
                {jds.pagination.totalCount > 0 ? (
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        style={{ marginTop: "2rem" }}
                    >
                        <Pagination
                            count={Math.ceil(jds.pagination.totalCount / limit)}
                            page={paginationPage}
                            siblingCount={isMobile ? 1 : 3}
                            onChange={onPageChanged}
                        />
                    </Stack>
                ) : null}
            </Stack>
        );
    };

    const getSEOTitle = () => {
        const _keyword = capitalize(keyword);
        return _keyword
            ? `${_keyword} India Jobs & Career - Zigup`
            : "Jobs & Career for India by Zigup";
    };

    const getSEODescription = () => {
        const _keyword = capitalize(keyword);
        return _keyword
            ? `Get the latest jobs & career of ${_keyword} India, Find ${_keyword} jobs by roles, experience & skills. Sign Up for Better Offers!`
            : "Get the most recent job openings and career opportunities in India. Search for jobs based on roles, experience, and skills. Sign Up to Get Better Offers!";
    };

    const getSEOKeywords = () => {
        const _keyword = capitalize(keyword);
        return _keyword
            ? `${_keyword} career, ${_keyword} job opportunity, ${_keyword}jobs, ${_keyword} india jobs`
            : "career, job opportunity, jobs, india jobs";
    };

    const getSEOHelmet = () => {
        return (
            <Helmet>
                <title>{getSEOTitle()}</title>
                <meta
                    name="description"
                    property="description"
                    content={getSEODescription()}
                />
                <meta
                    name="keywords"
                    property="keywords"
                    content={getSEOKeywords()}
                />
                <meta content={getSEOTitle()} property="og:title" />
                <meta property="og:description" content={getSEODescription()} />
                <meta
                    content="https://hire.zigup.in/static/media/logo-horizontal.4663103fd0a4bca8ea17.png"
                    property="og:image"
                />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
        );
    };

    return (
        <>
            {getSEOHelmet()}
            <GlobalHeader />
            {renderSearchBar()}
            <Container maxWidth="lg" sx={{ marginTop: "2rem" }}>
                {renderJdList()}
            </Container>
            <Footer />
        </>
    );
};

export default JdListPage;
