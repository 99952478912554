import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ShareIcon from "@mui/icons-material/Share";
import Colors from "themes/colors";
import {
    FacebookShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
} from "react-share";

import { FacebookIcon, LinkedinIcon, WhatsappIcon } from "react-share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ShareMenu = ({ url = "https://zigup.in", iconSize = "1rem" }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="share-button"
                aria-controls={open ? "share-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <ShareIcon
                    sx={{ fontSize: iconSize, color: Colors.purple_blue }}
                />
            </Button>
            <Menu
                id="share-menu"
                aria-labelledby="share-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem onClick={handleClose}>
                    <FacebookShareButton url={url}>
                        <FacebookIcon size={24} />
                    </FacebookShareButton>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <LinkedinShareButton url={url}>
                        <LinkedinIcon size={24} />
                    </LinkedinShareButton>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <WhatsappShareButton url={url}>
                        <WhatsappIcon size={24} />
                    </WhatsappShareButton>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <div
                        onClick={() => {
                            navigator.clipboard
                                .writeText(url)
                                .then(() => toast("copied!"));
                        }}
                    >
                        <ContentCopyIcon
                            sx={{ color: Colors.purple_blue, fontSize: 24 }}
                        />
                    </div>
                </MenuItem>
            </Menu>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
            />
        </div>
    );
};
export default ShareMenu;
