import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useNavigate, useParams } from "react-router";

const RedirectCompensation = () => {
    const navigate = useNavigate();
    const params = useParams();
    useEffect(async () => {
        if (!params.id) navigate("/");
    }, [params]);

    return <Navigate to={`/salary/${params.id}`} />;
};
export default RedirectCompensation;
