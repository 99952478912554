import { Container, Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typo from "../atoms/Typo";
import ZigupSpinner from "./ZigupSpinner";
import React, { Suspense, useEffect, useState } from "react";
import OfferCard from "./offer/OfferCard";
import Text from "../atoms/Text";
import ZigupButton from "../atoms/ZigupButton";
import DownloadAppModal from "./DownloadAppModal";
import { useMediaQuery } from "react-responsive";
import offerImage from "assets/images/illust-offer.png";
import { fetchOffers, tmpOffersByClientData } from "../../communication/jd";
import OfferAuthProtectPage from "../pages/offer/OfferAuthProtectPage";
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";

const MyOfferList = ({ name = "you", hash }) => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const [isLoading, setIsLoading] = useState(true);
    const [offers, setOffers] = useState(undefined);
    const [isShowDownloadAppModal, setIsShowDownloadAppModal] = useState(false);
    const { getTmpLoginHash, setTmpLoginHash } = useContext(AuthContext);

    const fetchDataAndSetHash = async () => {
        try {
            const { applies } = hash
                ? await tmpOffersByClientData({ hash: hash })
                : await fetchOffers();
            setOffers(applies);
            if (!getTmpLoginHash() && hash) {
                setTmpLoginHash(hash);
            }
        } catch (e) {
            setOffers([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(async () => {
        await fetchDataAndSetHash();
        return () => {
            setOffers([]);
        };
    }, []);

    const handleCheckStatusPressed = () => {
        setIsShowDownloadAppModal(true);
    };
    return (
        <Stack sx={{ marginBottom: "2rem" }}>
            {offers === undefined || isLoading ? (
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: "50vh" }}
                >
                    <ZigupSpinner size={"2rem"} />
                </Stack>
            ) : offers.length > 0 ? (
                <Stack>
                    <Typo.H2 style={{ fontSize: "1.5rem" }}>
                        New job offers for {name}
                    </Typo.H2>
                    <Grid container spacing={2}>
                        {offers.map((offer, index) => (
                            <Grid
                                item
                                xs={isMobile ? 12 : 6}
                                key={index + "_offer"}
                            >
                                <Suspense fallback={null}>
                                    <OfferCard offer={offer} />
                                </Suspense>
                            </Grid>
                        ))}
                    </Grid>
                </Stack>
            ) : (
                <Container maxWidth="sm">
                    {hash ? (
                        <OfferAuthProtectPage isTmpPage={true} />
                    ) : (
                        <>
                            <Typo.H2 style={{ fontSize: "1.5rem" }}>
                                Job offers for {name}
                            </Typo.H2>
                            <Stack
                                direction={"column"}
                                justifyContent="center"
                                alignItems="center"
                                spacing={8}
                            >
                                <Stack
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ width: "100%" }}
                                >
                                    <img
                                        src={offerImage}
                                        alt={"offerImage"}
                                        width="100%"
                                        height="auto"
                                    />
                                </Stack>
                                <Text
                                    style={{
                                        fontFamily: "Roboto",
                                        fontWeight: 700,
                                        fontSize: "1rem",
                                    }}
                                >
                                    No new job offers received
                                </Text>
                                <ZigupButton
                                    id="offer-check-my-status-on-app-btn"
                                    buttonText="Check my status on App"
                                    containerStyle={{
                                        width: "90%",
                                    }}
                                    onClickHandler={handleCheckStatusPressed}
                                />
                                <DownloadAppModal
                                    open={isShowDownloadAppModal}
                                    close={() =>
                                        setIsShowDownloadAppModal(false)
                                    }
                                />
                            </Stack>
                        </>
                    )}
                </Container>
            )}
        </Stack>
    );
};
export default MyOfferList;
