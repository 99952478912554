import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { init, send } from "emailjs-com";

import KakaoImage from "assets/images/vc-kakao.png";
import SpringCampImage from "assets/images/vc-spring-camp.png";
import PressImage1 from "assets/images/press-room/pressroom1.jpg";
import PressImage2 from "assets/images/press-room/pressroom2.jpg";
import PressImage3 from "assets/images/press-room/pressroom3.jpg";
import PressImage4 from "assets/images/press-room/pressroom4.jpg";

import RapidoImage from "assets/images/logo-companies/trusted-by/rapido-300.png";
import ByjusImage from "assets/images/logo-companies/trusted-by/byjus.png";
import LivspaceImage from "assets/images/logo-companies/trusted-by/livspace-white.png";
import TopprImage from "assets/images/logo-companies/trusted-by/toppr-white.png";
import WingifyImage from "assets/images/logo-companies/trusted-by/wingify-300.png";
import BijakImage from "assets/images/logo-companies/trusted-by/bijak-300.png";
import HoopyImage from "assets/images/logo-companies/trusted-by/hoopy-white.png";
import CitymallImage from "assets/images/logo-companies/trusted-by/citymall-300.png";

import AryaagImage from "assets/images/logo-companies/on-black-bg/aryaag-whiteback-560.png";
import CarefiImage from "assets/images/logo-companies/on-black-bg/carefi-whiteback-560.png";
import FlamImage from "assets/images/logo-companies/on-black-bg/flam-whiteback-560.png";
import FutworkImage from "assets/images/logo-companies/on-black-bg/futwork-whiteback-560.png";
import GaoderaImage from "assets/images/logo-companies/on-black-bg/gaodera-whiteback-560.png";
import GobillionImage from "assets/images/logo-companies/on-black-bg/gobillion-whiteback-560.png";
import PlaytoImage from "assets/images/logo-companies/on-black-bg/playto-whiteback-560.png";
import SalaryboxImage from "assets/images/logo-companies/on-black-bg/salarybox-whiteback-560.png";
import SheevaImage from "assets/images/logo-companies/on-black-bg/sheeva-whiteback-560.png";
import SmartstaffImage from "assets/images/logo-companies/on-black-bg/smartstaff-whiteback-560.png";
import WorkindiaImage from "assets/images/logo-companies/on-black-bg/workindia-whiteback-560.png";
import ZeeveImage from "assets/images/logo-companies/on-black-bg/zeeve-whiteback-560.png";

import Text from "components/atoms/Text";
import Colors from "themes/colors";

import { useMediaQuery } from "react-responsive";
import { Logger } from "utils";

const BackgroundContainer = styled.div`
    width: 100vw;
    align-items: center;
    justify-content: center;
    display: flex;
`;

const HyperhireSection = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");

    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

    useEffect(() => {
        init(process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY);
    }, []);

    const onSubmit = async () => {
        const valid = name && email && company && message;
        if (!valid) {
            alert("please fill the information for the submit");
            return;
        }
        try {
            const myHeader = new Headers();
            myHeader.append(
                "Content-Type",
                "application/x-www-form-urlencoded"
            );
            const formData = new URLSearchParams();
            formData.append("channel", "C03BKCLF5JT");
            const txtMsg = `New Client from zigup.in\n>Name: ${name}\n>Email: ${email}\n>Company: ${company}\n>Phone: ${phoneNumber}\n>Message: ${message}`;
            formData.append("text", txtMsg);
            formData.append(
                "token",
                "xoxb-1500199372342-3401378928518-qzsiY3WQqTot2EfnQy8h5IMj"
            );
            const requestOptions = {
                method: "POST",
                headers: myHeader,
                body: formData,
                redirect: "follow",
            };
            fetch("https://slack.com/api/chat.postMessage", requestOptions)
                .then((response) => response.text())
                .then((result) => Logger.log(result))
                .catch((error) => Logger.log("error", error));

            const { status } = await send(
                "service_bjsf6rh",
                "template_3wxirz7",
                {
                    name,
                    email,
                    company,
                    phoneNumber,
                    message,
                }
            );
            if (status === 200) {
                alert(
                    "Submission has been completed. We will contact you within 1 working days"
                );
                setName("");
                setEmail("");
                setCompany("");
                setPhoneNumber("");
                setMessage("");
            }
        } catch (err) {
            Logger.error(err);
        }
    };

    return (
        <BackgroundContainer
            id="Hyperhire"
            style={{ backgroundColor: Colors.background_black }}
        >
            <Container
                maxWidth="lg"
                style={{
                    paddingHorizontal: isMobile ? 32 : 64,
                    paddingBottom: 64,
                }}
            >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "240px" }}
                >
                    <Text
                        style={{
                            color: "white",
                            fontSize: isMobile ? 32 : 48,
                            fontWeight: 700,
                        }}
                    >
                        Introducing Hyperhire
                    </Text>
                </Stack>
                {/* VC */}
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <Text
                        style={{
                            color: "white",
                            fontSize: isMobile ? 28 : 36,
                        }}
                    >
                        HR Tech startup, Hyperhire
                    </Text>
                    <Text
                        style={{
                            color: "white",
                            fontSize: isMobile ? 28 : 36,
                            fontWeight: 700,
                            marginBottom: isMobile ? 16 : 32,
                        }}
                    >
                        Backed by S.Korea VC
                    </Text>
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="flex-start"
                        sx={{ width: "100%" }}
                    >
                        <Stack
                            direction="column"
                            spacing={1}
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{ flex: 1 }}
                        >
                            <img
                                src={KakaoImage}
                                width="200"
                                height="auto"
                                alt="Kakao"
                            />
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    flex: 1,
                                    maxWidth: 300,
                                }}
                            >
                                <Stack
                                    direction="column"
                                    spacing={1}
                                    sx={{ flex: 1 }}
                                >
                                    <Text
                                        style={{
                                            color: "white",
                                            fontSize: isMobile ? 20 : 24,
                                            fontWeight: 700,
                                            paddingTop: 16,
                                        }}
                                    >
                                        Kakao Ventures
                                    </Text>
                                    <Text
                                        style={{
                                            color: Colors.text_grey,
                                            fontSize: isMobile ? 14 : 16,
                                        }}
                                    >
                                        No.1 messenger app and 1st generation
                                        startup in South Korea, KakaoTalk
                                    </Text>
                                    <a
                                        href="https://www.kakao.vc/"
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{
                                            color: "#4E3ee9",
                                            fontSize: isMobile ? 13 : 15,
                                            paddingTop: 8,
                                        }}
                                    >
                                        Visit
                                    </a>
                                </Stack>
                            </div>
                        </Stack>
                        <Stack
                            direction="column"
                            spacing={1}
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{ flex: 1 }}
                        >
                            <img
                                src={SpringCampImage}
                                width="200"
                                height="auto"
                                alt="SpringCamp"
                            />
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    maxWidth: 300,
                                }}
                            >
                                <Stack
                                    direction="column"
                                    spacing={1}
                                    sx={{ flex: 1 }}
                                >
                                    <Text
                                        style={{
                                            color: "white",
                                            fontSize: isMobile ? 20 : 24,
                                            fontWeight: 700,
                                            paddingTop: 16,
                                        }}
                                    >
                                        Spring Camp
                                    </Text>
                                    <Text
                                        style={{
                                            color: Colors.text_grey,
                                            fontSize: isMobile ? 14 : 16,
                                        }}
                                    >
                                        Where startups spring
                                    </Text>
                                    <a
                                        href="http://springcamp.co/"
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{
                                            color: "#4E3ee9",
                                            fontSize: isMobile ? 13 : 15,
                                            paddingTop: 8,
                                        }}
                                    >
                                        Visit
                                    </a>
                                </Stack>
                            </div>
                        </Stack>
                    </Stack>
                    <Text
                        style={{
                            width: "100%",
                            textAlign: "end",
                            color: "#929c9c",
                        }}
                    >
                        and more ...
                    </Text>
                </Stack>
                <div style={{ height: 60 }} />
                {/* Press */}
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <Text
                        style={{
                            color: "white",
                            fontSize: isMobile ? 24 : 30,
                        }}
                    >
                        Press room
                    </Text>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={2}
                        sx={{ width: "100%" }}
                    >
                        <Stack direction="column" spacing={2} sx={{ flex: 1 }}>
                            <img
                                width="100%"
                                height="auto"
                                src={PressImage1}
                                alt="PressImage1"
                            />
                            <Text
                                style={{
                                    color: "white",
                                    fontSize: 16,
                                    fontFamily: "inter",
                                }}
                            >
                                HR startup Hyperhire raises seed funding from
                                South Korea’s Springcamp
                            </Text>
                            <Text
                                style={{
                                    color: "#7a7a7a",
                                    fontFamily: "inter",
                                    fontSize: 12,
                                    lineHeight: 1.4,
                                }}
                            >
                                Human resources solutions platform Hyperhire has
                                raised $200,000 (Rs 1.39 crore at current
                                exchange rates) from South Korea-based Spring …
                            </Text>
                            <a
                                href="https://www.vccircle.com/hr-startup-hyperhire-raises-seed-funding-from-south-korea-s-springcamp"
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: "#4e3ee9", fontSize: 14 }}
                            >
                                View more
                            </a>
                        </Stack>
                        <Stack direction="column" spacing={2} sx={{ flex: 1 }}>
                            <img
                                width="100%"
                                height="auto"
                                src={PressImage2}
                                alt="PressImage2"
                            />
                            <Text
                                style={{
                                    color: "white",
                                    fontSize: 16,
                                    fontFamily: "inter",
                                }}
                            >
                                [Funding alert] B2B HR startup Hyperhire raises
                                $200K from SpringCamp
                            </Text>
                            <Text
                                style={{
                                    color: "#7a7a7a",
                                    fontFamily: "inter",
                                    fontSize: 12,
                                    lineHeight: 1.4,
                                }}
                            >
                                Hyperhire, a B2B HR solutions platform, on
                                Thursday, said it has received seed funding of …
                            </Text>
                            <a
                                href="https://yourstory.com/2019/10/startup-funding-hr-payroll-software-solutions-greytip-info-edge-megadelta-capital/amp"
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: "#4e3ee9", fontSize: 14 }}
                            >
                                View more
                            </a>
                        </Stack>
                        <Stack direction="column" spacing={2} sx={{ flex: 1 }}>
                            <img
                                width="100%"
                                height="auto"
                                src={PressImage3}
                                alt="PressImage3"
                            />
                            <Text
                                style={{
                                    color: "white",
                                    fontSize: 16,
                                    fontFamily: "inter",
                                }}
                            >
                                Funding in Indian startups this week
                            </Text>
                            <Text
                                style={{
                                    color: "#7a7a7a",
                                    fontFamily: "inter",
                                    fontSize: 12,
                                    lineHeight: 1.4,
                                }}
                            >
                                This week 17 Indian startups received funding,
                                of which 14 received a total sum of about $93
                                million. Among them, Ather Energy raised the
                                highest funding …
                            </Text>
                            <a
                                href="https://entrackr.com/2019/06/funding-indian-startups-week-may-june-2/"
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: "#4e3ee9", fontSize: 14 }}
                            >
                                View more
                            </a>
                        </Stack>
                        <Stack direction="column" spacing={2} sx={{ flex: 1 }}>
                            <img
                                width="100%"
                                height="auto"
                                src={PressImage4}
                                alt="PressImage4"
                            />
                            <Text
                                style={{
                                    color: "white",
                                    fontSize: 16,
                                    fontFamily: "inter",
                                }}
                            >
                                Funding Galore: Indian Startup Funding Of The
                                Week
                            </Text>
                            <Text
                                style={{
                                    color: "#7a7a7a",
                                    fontFamily: "inter",
                                    fontSize: 12,
                                    lineHeight: 1.4,
                                }}
                            >
                                This week, 15 startups raised $117.74 Mn funding
                                and three startup acquisitions took place in the
                                Indian startup ecosystem. (This funding report
                                is based …
                            </Text>
                            <a
                                href="https://inc42.com/buzz/top-indian-startup-funding-galore-106-may/"
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: "#4e3ee9", fontSize: 14 }}
                            >
                                View more
                            </a>
                        </Stack>
                    </Stack>
                </Stack>
                <div style={{ height: 60 }} />
                {/* 고객사 */}
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={0}
                >
                    <Text
                        style={{
                            color: "white",
                            fontSize: isMobile ? 24 : 30,
                        }}
                    >
                        Trusted by
                    </Text>
                    <Text
                        style={{
                            color: "white",
                            fontSize: isMobile ? 14 : 18,
                        }}
                    >
                        Zigup recruitment is trusted by product startups like
                    </Text>
                    <Grid container spacing={2} sx={{ paddingTop: "16px" }}>
                        <Grid item xs={isMobile ? 6 : 3}>
                            <div
                                style={{
                                    backgroundColor: "#f3d107",
                                    width: "100%",
                                    height: isMobile ? 240 : 276,
                                }}
                            >
                                <Stack
                                    direction="column"
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                    sx={{
                                        height: "100%",
                                        padding: "25px",
                                        boxSizing: "border-box",
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: isMobile ? 28 : 32,
                                            fontFamily: "Poppins",
                                            color: Colors.white,
                                            fontWeight: 700,
                                        }}
                                    >
                                        Rapido
                                    </Text>
                                    <img
                                        src={RapidoImage}
                                        alt="RapidoImage"
                                        height="80"
                                        width="auto"
                                    />
                                </Stack>
                            </div>
                        </Grid>
                        <Grid item xs={isMobile ? 6 : 3}>
                            <div
                                style={{
                                    backgroundColor: "#ab22eb",
                                    width: "100%",
                                    height: isMobile ? 240 : 276,
                                }}
                            >
                                <Stack
                                    direction="column"
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                    sx={{
                                        height: "100%",
                                        padding: "25px",
                                        boxSizing: "border-box",
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: isMobile ? 28 : 32,
                                            fontFamily: "Poppins",
                                            color: Colors.white,
                                            fontWeight: 700,
                                        }}
                                    >
                                        Byju's
                                    </Text>
                                    <img
                                        src={ByjusImage}
                                        alt="ByjusImage"
                                        width="50%"
                                        height="auto"
                                        style={{ marginBottom: 20 }}
                                    />
                                </Stack>
                            </div>
                        </Grid>
                        <Grid item xs={isMobile ? 6 : 3}>
                            <div
                                style={{
                                    backgroundColor: "#eb5860",
                                    width: "100%",
                                    height: isMobile ? 240 : 276,
                                }}
                            >
                                <Stack
                                    direction="column"
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                    sx={{
                                        height: "100%",
                                        padding: "25px",
                                        boxSizing: "border-box",
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: isMobile ? 28 : 32,
                                            fontFamily: "Poppins",
                                            color: Colors.white,
                                            fontWeight: 700,
                                        }}
                                    >
                                        Livspace
                                    </Text>
                                    <img
                                        src={LivspaceImage}
                                        alt="LivspaceImage"
                                        height="80"
                                        width="auto"
                                    />
                                </Stack>
                            </div>
                        </Grid>
                        <Grid item xs={isMobile ? 6 : 3}>
                            <div
                                style={{
                                    backgroundColor: "#37bbfc",
                                    width: "100%",
                                    height: isMobile ? 240 : 276,
                                }}
                            >
                                <Stack
                                    direction="column"
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                    sx={{
                                        height: "100%",
                                        padding: "25px",
                                        boxSizing: "border-box",
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: isMobile ? 28 : 32,
                                            fontFamily: "Poppins",
                                            color: Colors.white,
                                            fontWeight: 700,
                                        }}
                                    >
                                        Toppr
                                    </Text>
                                    <img
                                        src={TopprImage}
                                        alt="TopprImage"
                                        height="80"
                                        width="auto"
                                    />
                                </Stack>
                            </div>
                        </Grid>
                        <Grid item xs={isMobile ? 6 : 3}>
                            <div
                                style={{
                                    backgroundColor: "#d92728",
                                    width: "100%",
                                    height: isMobile ? 240 : 276,
                                }}
                            >
                                <Stack
                                    direction="column"
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                    sx={{
                                        height: "100%",
                                        padding: "25px",
                                        boxSizing: "border-box",
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: isMobile ? 28 : 32,
                                            fontFamily: "Poppins",
                                            color: Colors.white,
                                            fontWeight: 700,
                                        }}
                                    >
                                        Wingify
                                    </Text>
                                    <img
                                        src={WingifyImage}
                                        alt="WingifyImage"
                                        width="50%"
                                        height="auto"
                                        style={{ marginBottom: 20 }}
                                    />
                                </Stack>
                            </div>
                        </Grid>
                        <Grid item xs={isMobile ? 6 : 3}>
                            <div
                                style={{
                                    backgroundColor: "#2c8a4f",
                                    width: "100%",
                                    height: isMobile ? 240 : 276,
                                }}
                            >
                                <Stack
                                    direction="column"
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                    sx={{
                                        height: "100%",
                                        padding: "25px",
                                        boxSizing: "border-box",
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: isMobile ? 28 : 32,
                                            fontFamily: "Poppins",
                                            color: Colors.white,
                                            fontWeight: 700,
                                        }}
                                    >
                                        Bijak
                                    </Text>
                                    <img
                                        src={BijakImage}
                                        alt="BijakImage"
                                        height="80"
                                        width="auto"
                                    />
                                </Stack>
                            </div>
                        </Grid>
                        <Grid item xs={isMobile ? 6 : 3}>
                            <div
                                style={{
                                    backgroundColor: "#fff2b6",
                                    width: "100%",
                                    height: isMobile ? 240 : 276,
                                }}
                            >
                                <Stack
                                    direction="column"
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                    sx={{
                                        height: "100%",
                                        padding: "25px",
                                        boxSizing: "border-box",
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: isMobile ? 28 : 32,
                                            fontFamily: "Poppins",
                                            color: Colors.white,
                                            fontWeight: 700,
                                        }}
                                    >
                                        Hoopy
                                    </Text>
                                    <img
                                        src={HoopyImage}
                                        alt="HoopyImage"
                                        height="80"
                                        width="auto"
                                    />
                                </Stack>
                            </div>
                        </Grid>
                        <Grid item xs={isMobile ? 6 : 3}>
                            <div
                                style={{
                                    backgroundColor: "#eb5860",
                                    width: "100%",
                                    height: isMobile ? 240 : 276,
                                }}
                            >
                                <Stack
                                    direction="column"
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                    sx={{
                                        height: "100%",
                                        padding: "25px",
                                        boxSizing: "border-box",
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: isMobile ? 28 : 32,
                                            fontFamily: "Poppins",
                                            color: Colors.white,
                                            fontWeight: 700,
                                        }}
                                    >
                                        Citymall
                                    </Text>
                                    <img
                                        src={CitymallImage}
                                        alt="CitymallImage"
                                        height="80"
                                        width="auto"
                                    />
                                </Stack>
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{ height: 60 }} />
                    <Text
                        style={{
                            color: "white",
                            fontSize: isMobile ? 20 : 24,
                        }}
                    >
                        And more ...
                    </Text>
                    <Grid container rowSpacing={4} columnSpacing={1}>
                        <Grid item xs={isMobile ? 4 : 2}>
                            <img
                                src={AryaagImage}
                                width="100%"
                                height="auto"
                                alt="AryaagImage"
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 4 : 2}>
                            <img
                                src={GobillionImage}
                                width="100%"
                                height="auto"
                                alt="GobillionImage"
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 4 : 2}>
                            <img
                                src={ZeeveImage}
                                width="100%"
                                height="auto"
                                alt="ZeeveImage"
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 4 : 2}>
                            <img
                                src={GaoderaImage}
                                width="100%"
                                height="auto"
                                alt="GaoderaImage"
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 4 : 2}>
                            <img
                                src={FutworkImage}
                                width="100%"
                                height="auto"
                                alt="FutworkImage"
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 4 : 2}>
                            <img
                                src={CarefiImage}
                                width="100%"
                                height="auto"
                                alt="CarefiImage"
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 4 : 2}>
                            <img
                                src={WorkindiaImage}
                                width="100%"
                                height="auto"
                                alt="WorkindiaImage"
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 4 : 2}>
                            <img
                                src={PlaytoImage}
                                width="100%"
                                height="auto"
                                alt="PlaytoImage"
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 4 : 2}>
                            <img
                                src={FlamImage}
                                width="100%"
                                height="auto"
                                alt="FlamImage"
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 4 : 2}>
                            <img
                                src={SalaryboxImage}
                                width="100%"
                                height="auto"
                                alt="SalaryboxImage"
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 4 : 2}>
                            <img
                                src={SmartstaffImage}
                                width="100%"
                                height="auto"
                                alt="SmartstaffImage"
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 4 : 2}>
                            <img
                                src={SheevaImage}
                                width="100%"
                                height="auto"
                                alt="SheevaImage"
                            />
                        </Grid>
                    </Grid>
                </Stack>
                <div style={{ height: isMobile ? 60 : 120 }} />
                {/* Contact */}
                <Stack id="Demo" direction="row" spacing={2} sx={{ flex: 1 }}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        sx={{ width: "100%" }}
                    >
                        <Text
                            style={{
                                fontFamily: "Poppins",
                                fontSize: isMobile ? 20 : 38,
                                color: "white",
                            }}
                        >
                            Get in touch and
                        </Text>
                        <Text
                            style={{
                                fontFamily: "Poppins",
                                fontSize: isMobile ? 20 : 38,
                                color: "white",
                                fontWeight: 700,
                            }}
                        >
                            Maximize your hiring
                        </Text>
                    </Stack>
                    <Stack direction="column" sx={{ width: "100%" }}>
                        <TextField
                            onChange={(event) => {
                                setName(event.target.value);
                            }}
                            value={name}
                            placeholder="Name"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            sx={{
                                input: {
                                    color: Colors.text_grey,
                                    fontSize: isMobile ? 14 : 16,
                                    backgroundColor: "#303030",
                                    fontFamily: "Poppins",
                                },
                            }}
                        />
                        <TextField
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                            value={email}
                            placeholder="eMail"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            sx={{
                                input: {
                                    color: Colors.text_grey,
                                    fontSize: isMobile ? 14 : 16,
                                    backgroundColor: "#303030",
                                    fontFamily: "Poppins",
                                },
                            }}
                        />
                        <TextField
                            onChange={(event) => {
                                setCompany(event.target.value);
                            }}
                            value={company}
                            placeholder="Company"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            sx={{
                                input: {
                                    color: Colors.text_grey,
                                    fontSize: isMobile ? 14 : 16,
                                    backgroundColor: "#303030",
                                    fontFamily: "Poppins",
                                },
                            }}
                        />
                        <TextField
                            onChange={(event) => {
                                setPhoneNumber(event.target.value);
                            }}
                            value={phoneNumber}
                            placeholder="Phone Number"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            sx={{
                                input: {
                                    color: Colors.text_grey,
                                    fontSize: isMobile ? 14 : 16,
                                    backgroundColor: "#303030",
                                    fontFamily: "Poppins",
                                },
                            }}
                        />
                        <TextField
                            onChange={(event) => {
                                setMessage(event.target.value);
                            }}
                            value={message}
                            placeholder="Message"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            multiline
                            rows={4}
                            sx={{
                                marginBottom: 3,
                                backgroundColor: "#303030",
                            }}
                            inputProps={{
                                sx: {
                                    color: Colors.text_grey,
                                    fontSize: isMobile ? 14 : 16,
                                    fontFamily: "Poppins",
                                },
                            }}
                        />
                        <div
                            onClick={onSubmit}
                            style={{
                                borderRadius: 4,
                                backgroundColor: "#9e9e9e",
                                width: "100%",
                                height: 40,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                            }}
                        >
                            <Text
                                style={{
                                    color: "white",
                                    fontFamily: "Poppins",
                                    fontSize: isMobile ? 12 : 14,
                                }}
                            >
                                Send
                            </Text>
                        </div>
                    </Stack>
                </Stack>
            </Container>
        </BackgroundContainer>
    );
};

export default HyperhireSection;
