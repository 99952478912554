import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { isRequired } from "../../utils/validator";
import anonymousIcon from "assets/icons/avatar-anonymous.png";

const UserProfileImage = (props) => {
    const { source, style } = props;
    const [img, setImg] = useState(anonymousIcon);
    useEffect(() => {
        const valid = isRequired(source).valid;
        if (valid) {
            setImg(source);
        }
    }, [source]);

    return (
        <Stack
            style={{
                width: "8rem",
                height: "8rem",
                ...style,
            }}
        >
            <img
                src={img}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = anonymousIcon;
                }}
                alt="user-image"
                width={"100%"}
                height={"100%"}
                style={style}
            />
        </Stack>
    );
};

export default UserProfileImage;
