import { Stack, Paper } from "@mui/material";
import Typo from "components/atoms/Typo";
import styled from "styled-components";
import React from "react";
import { useMediaQuery } from "react-responsive";
import Colors from "themes/colors";

const HoverWrapper = styled.div``;

const ViewMoreButton = (props) => {
    const { href } = props;
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
                height: "max-content",
                width: "100%",
            }}
        >
            <a
                href={href}
                style={{
                    textDecoration: "none",
                    color: "black",
                    height: "100%",
                    width: "11rem",
                    cursor: "pointer",
                }}
            >
                <Paper
                    elevation={0}
                    sx={{
                        borderWidth: 1,
                        borderColor: Colors.outline_2,
                        borderStyle: "solid",
                        backgroundColor: Colors.direct_hiring_background,
                        padding: 0,
                    }}
                >
                    <HoverWrapper>
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                height: "48px",
                            }}
                        >
                            <Typo.H4
                                style={{
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                    color: Colors.purple_blue,
                                    textAlign: "center",
                                    fontSize: isMobile ? "0.8rem" : "1rem",
                                }}
                            >
                                View more
                            </Typo.H4>
                        </Stack>
                    </HoverWrapper>
                </Paper>
            </a>
        </Stack>
    );
};

export default ViewMoreButton;
