import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { Stack } from "@mui/material";
import Typo from "components/atoms/Typo";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import SearchTextField from "components/atoms/SearchTextField";
import { list } from "communication/salary";
import GlobalHeader from "components/organisms/GlobalHeader";
import Footer from "components/organisms/Footer";
import SalaryCard from "components/organisms/salary/SalaryCard";
import Colors from "themes/colors";
import Pagination from "@mui/material/Pagination";
import QueryString from "qs";
import { Helmet } from "react-helmet-async";
import { capitalize } from "utils/TextUtils";
import { SEARCH_TYPE } from "constants/constants";
import Text from "components/atoms/Text";

const SalaryListPage = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const [salaryData, setSalaryData] = useState({
        pagination: {
            hasNext: false,
            lastIndex: 0,
            totalCount: 0,
        },
        data: [],
    });
    const [isLoading, setIsLoading] = useState(true);
    const [title, setTitle] = useState("New salaries");

    const { keyword, page } = QueryString.parse(location.search, {
        ignoreQueryPrefix: true,
    });
    const [paginationPage, setPaginationPage] = useState(1);
    const limit = 20;

    useEffect(async () => {
        window.scrollTo(0, 0);

        setPaginationPage(Number(page || 1));
        const lastIndex = Number(page) === 1 ? 0 : (page - 1) * limit - 1;
        try {
            setIsLoading(true);
            const res = await list({
                keyword,
                limit,
                lastIndex,
            });
            setSalaryData({
                pagination: res.pagination,
                data: res.salaries,
            });
        } catch (e) {
            navigate(-1);
        } finally {
            setIsLoading(false);
        }
        return () => {
            setSalaryData({
                pagination: {
                    hasNext: false,
                    lastIndex: 0,
                    totalCount: 0,
                },
                data: [],
            });
        };
    }, [keyword, page]);

    useEffect(() => {
        if (keyword) {
            setTitle(`${capitalize(keyword)} India Salary Breakup`);
        }
    }, [location, salaryData.pagination]);

    const onPageChanged = async (event, value) => {
        if (keyword) {
            navigate(`/salary/list/?keyword=${keyword}&page=${value}`);
        } else {
            navigate(`/salary/list/?page=${value}`);
        }
    };

    const renderSearchBar = () => {
        return (
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                    width: "100%",
                    backgroundColor: "#f0f1f5",
                }}
            >
                <SearchTextField
                    placeholder={
                        isMobile
                            ? "Company, Job title, Tech stack"
                            : "Search with Company, Job title, Tech stack"
                    }
                    defaultType={SEARCH_TYPE.SALARY.value}
                    prevSearchKeyword={keyword}
                />
            </Stack>
        );
    };

    const renderSalaryList = () => {
        const _emptyArr = new Array(limit).fill();
        return (
            <Stack direction={"column"} sx={{ marginBottom: "2rem" }}>
                <Stack spacing={1}>
                    <Typo.H1Regular
                        style={{
                            fontSize: "1.25rem",
                        }}
                    >
                        {title}
                    </Typo.H1Regular>
                    <Text
                        style={{
                            color: Colors.text_grey,
                            marginBottom: "1rem",
                        }}
                    >
                        {salaryData.pagination.totalCount} Results
                    </Text>
                </Stack>
                <Grid container spacing={2}>
                    {isLoading
                        ? _emptyArr.map((_, index) => (
                              <Grid
                                  item
                                  xs={isMobile ? 12 : 6}
                                  key={index + "all_temp"}
                              >
                                  <SalaryCard />
                              </Grid>
                          ))
                        : salaryData.data.map((salary, index) => {
                              return (
                                  <Grid
                                      item
                                      xs={isMobile ? 12 : 6}
                                      key={index + "all"}
                                  >
                                      <SalaryCard salary={salary} />
                                  </Grid>
                              );
                          })}
                </Grid>
                {salaryData.pagination.totalCount > 0 ? (
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        style={{ marginTop: "2rem" }}
                    >
                        <Pagination
                            count={Math.ceil(
                                salaryData.pagination.totalCount / limit
                            )}
                            siblingCount={isMobile ? 1 : 3}
                            page={paginationPage}
                            onChange={onPageChanged}
                        />
                    </Stack>
                ) : null}
            </Stack>
        );
    };

    const getSEOTitle = () => {
        const _keyword = capitalize(keyword);
        return _keyword
            ? `${_keyword} India Salary Breakup - Zigup`
            : "Salary Breakup for India by Zigup";
    };

    const getSEODescription = () => {
        const _keyword = capitalize(keyword);
        return _keyword
            ? `Get the latest salary breakup of ${_keyword} India, Find ${_keyword} Salary Ranges by roles, experience & skills. Sign Up for Better Offers!`
            : "Get the latest salary breakup of India, Find Salary Ranges by roles, experience & skills. Sign Up for Better Offers!";
    };

    const getSEOKeywords = () => {
        const _keyword = capitalize(keyword);
        return _keyword
            ? `${_keyword}, india, salary, ${_keyword} india salary`
            : "india salary, india salary breakdown";
    };

    const getSEOHelmet = () => {
        return (
            <Helmet>
                <title>{getSEOTitle()}</title>
                <meta
                    name="description"
                    property="description"
                    content={getSEODescription()}
                />
                <meta
                    name="keywords"
                    property="keywords"
                    content={getSEOKeywords()}
                />
                <meta content={getSEOTitle()} property="og:title" />
                <meta property="og:description" content={getSEODescription()} />
                <meta
                    content="https://hire.zigup.in/static/media/logo-horizontal.4663103fd0a4bca8ea17.png"
                    property="og:image"
                />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
        );
    };

    return (
        <>
            {getSEOHelmet()}
            <GlobalHeader />
            {renderSearchBar()}
            <Container maxWidth="lg" sx={{ marginTop: "2rem" }}>
                {renderSalaryList()}
            </Container>
            <Footer />
        </>
    );
};
export default SalaryListPage;
