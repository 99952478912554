import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import GlobalHeader from "components/organisms/GlobalHeader";
import Footer from "components/organisms/Footer";

const ForumPage = () => {
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!params.id) {
            navigate("/forum/list?type=all");
        }
    }, [params]);

    return (
        <>
            <GlobalHeader />
            <Outlet
                context={{
                    postId: params.id,
                }}
            />
            <Footer />
        </>
    );
};
export default ForumPage;
