import { Paper, Stack } from "@mui/material";
import CompanyLogo from "components/atoms/CompanyLogo";
import SkillTag from "components/atoms/SkillTag";
import Text from "components/atoms/Text";
import Typo from "components/atoms/Typo";
import * as React from "react";
import styled from "styled-components";
import Colors from "themes/colors";
import ZigupSpinner from "../ZigupSpinner";
import directApplyIcon from "assets/icons/direct-apply4x.png";

const JdCardWrapper = styled.div`
    width: 100%;
    padding: 12px;
    cursor: pointer;
    padding: 16px;
    box-sizing: border-box;
    &:hover {
        background-color: ${Colors.background_2};
    }
`;
const JobNameWrapper = styled.div`
    flex: 1;
`;
const JobCompanyName = styled(Text)`
    font-size: 12px;
    color: #6756ac;
    margin-bottom: 4px;
`;
const JobExtraInfo = styled(Text)`
    margin-bottom: 8px;
    font-size: 12px;
    color: #646464;
`;

const JdCard = (props) => {
    const { jd } = props;

    const getLocation = () => {
        return jd?.locations?.join(", ") ?? "Location info is empty";
    };

    const getYear = () => {
        const returnText = " | ";
        if (jd?.fromYear && jd?.toYear) {
            if (jd?.toYear > 99) {
                return returnText + jd?.fromYear + "~ y";
            } else {
                return returnText + jd?.fromYear + "~" + jd?.toYear + "y";
            }
        } else if (jd?.fromYear) {
            return returnText + jd?.fromYear + "+ y";
        } else if (jd?.toYear) {
            return returnText + "0~" + jd?.toYear + "y";
        }
        return "";
    };

    const renderTags = () => {
        // tag가 2군데에 나뉘어서 찍혀서 그래.
        const _tags = jd?.customInfo?.role?.value || [];

        const _addTag = [];
        const _customTag = jd?.customInfo?.role?.custom?.value;
        if (_customTag) {
            _addTag[0] = jd?.customInfo?.role?.custom?.value;
        }

        // _allTags로 데이터를 취합해서 간다
        const _allTags = _tags.concat(_addTag);

        if (_allTags.length) {
            const _allTagsList = _allTags.map((tag) => (
                <SkillTag key={tag} skill={tag} />
            ));
            return (
                <Stack
                    direction="row"
                    sx={{
                        overflow: "hidden",
                        flexWrap: "wrap",
                        width: "100%",
                        display: "flex",
                    }}
                >
                    {_allTagsList}
                </Stack>
            );
        } else {
            return null;
        }
    };

    return jd ? (
        <a
            href={`/jd/${jd?.seoURL || jd?._id}`}
            style={{ textDecoration: "none", color: "black" }}
        >
            <Paper
                elevation={0}
                sx={{
                    width: "100%",
                    minHeight: "125px",
                    height: "100%",
                    borderWidth: 1,
                    borderColor: Colors.outline_2,
                    borderStyle: "solid",
                    borderRadius: "0.25rem",
                }}
            >
                <JdCardWrapper>
                    <Stack direction="column" spacing={2}>
                        {jd.isCompanyHireDirectly ? (
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-start"
                                spacing={0.5}
                            >
                                <img
                                    src={directApplyIcon}
                                    alt="directApplyIcon"
                                    width="16px"
                                    height="16px"
                                />
                                <Text
                                    style={{
                                        fontFamily: "Roboto",
                                        fontWeight: 400,
                                        fontSize: "0.75rem",
                                        color: Colors.purple_blue,
                                    }}
                                >
                                    Direct Hiring
                                </Text>
                            </Stack>
                        ) : null}
                        <Stack direction="row" spacing={2}>
                            <CompanyLogo
                                source={jd.companyData?.logoURL || ""}
                                height={80}
                                width={80}
                            />
                            <Stack
                                justifyContent="space-between"
                                alignItems="flex-start"
                                sx={{
                                    marginBottom: "6px",
                                    display: "flex",
                                    flex: 1,
                                }}
                            >
                                <JobNameWrapper>
                                    <Typo.H4Regular
                                        style={{
                                            marginBottom: 4,
                                            marginTop: 0,
                                        }}
                                    >
                                        {jd?.title || "Job Name"}
                                    </Typo.H4Regular>
                                </JobNameWrapper>
                                <JobCompanyName
                                    style={{ wordBreak: "break-word" }}
                                >
                                    {jd?.company || "Company"}
                                </JobCompanyName>
                                <JobExtraInfo>
                                    {getLocation()}
                                    {getYear()}
                                </JobExtraInfo>
                                {jd ? (
                                    renderTags()
                                ) : (
                                    <Stack
                                        direction="row"
                                        sx={{
                                            overflow: "hidden",
                                            flexWrap: "wrap",
                                            width: "100%",
                                            display: "flex",
                                        }}
                                    >
                                        <SkillTag skill={"backend"} />
                                    </Stack>
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                </JdCardWrapper>
            </Paper>
        </a>
    ) : (
        <Paper
            elevation={0}
            sx={{
                width: "100%",
                minHeight: "145px",
                height: "100%",
                borderWidth: 1,
                borderColor: Colors.outline_2,
                borderStyle: "solid",
                borderRadius: "0.25rem",
            }}
        >
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ height: "100%", width: "100%" }}
            >
                <ZigupSpinner size={"2rem"} />
            </Stack>
        </Paper>
    );
};

export default JdCard;
