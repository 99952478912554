import { Container, Grid, Stack, Tooltip, tooltipClasses } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import Typo from "components/atoms/Typo";
import Footer from "components/organisms/Footer";
import GlobalHeader from "components/organisms/GlobalHeader";
import React, { useContext, useEffect, useState } from "react";
import BannerWebImage from "assets/images/company/banner-job.png";
import BannerMobileImage from "assets/images/company/banner-job-mobile.png";
import Text from "components/atoms/Text";
import Colors from "themes/colors";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import ViewMoreButton from "components/organisms/ViewMoreButton";
import ItemCard from "components/organisms/my/ItemCard";
import JdCard from "components/organisms/jd/JdCard";
import SalaryCard from "components/organisms/salary/SalaryCard";
import ForumCard from "components/organisms/forum/ForumCard";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styled from "styled-components";
import ContributeZigup from "./../../organisms/ContributeZigup";
import { jdList } from "communication/jd";
import { Logger } from "utils";
import { list } from "communication/salary";
import { lfcList } from "communication/forum";
import { companyDetail, companyInsight } from "communication/company";
import CompanySalaryGraph from "components/organisms/company/CompanySalaryGraph";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";
import { AuthContext } from "context/AuthContext";

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 150,
    },
});

const CompanyDetailPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const { companyId } = useOutletContext();
    const { userState } = useContext(AuthContext);

    const [company, setCompany] = useState({});
    const [companyInsightData, setCompanyInsightData] = useState({});
    const [jds, setJds] = useState(undefined);
    const [salaries, setSalaries] = useState(undefined);
    const [forums, setForums] = useState(undefined);

    useEffect(() => {
        fetchCompanyData();
    }, [companyId]);

    useEffect(() => {
        if (!Object.keys(company).length) return;
        ReactGA.event({
            category: "Company",
            action: "page_view_company",
            label: "company",
        });
        fetchInsightData();
        fetchJDData();
        fetchSalaryData();
        fetchForumData();
    }, [company]);

    const fetchCompanyData = async () => {
        try {
            const res = await companyDetail(companyId);
            setCompany(res.company);
        } catch (e) {
            Logger.error(e);
            if (!alert("Cannot find a company with the given URL.")) {
                navigate("/company/list");
            }
        }
    };

    const fetchInsightData = async () => {
        try {
            const res = await companyInsight({ name: company.name });
            setCompanyInsightData(res.salaryInsight);
        } catch (e) {
            Logger.error(e);
        }
    };

    const fetchJDData = async () => {
        try {
            const res = await jdList({
                limit: 4,
                keyword: company.name,
                sort: "newest",
                searchByOnlyCompanyName: true,
            });
            setJds(res.jds);
        } catch (e) {
            Logger.error(e);
        }
    };

    const fetchSalaryData = async () => {
        try {
            const res = await list({
                limit: 4,
                keyword: company.name,
            });
            setSalaries(res.salaries);
        } catch (e) {
            Logger.error(e);
        }
    };

    const fetchForumData = async () => {
        try {
            const res = await lfcList({
                limit: 4,
                keyword: company.name,
            });
            setForums(res.forumReferrals);
        } catch (e) {
            Logger.error(e);
        }
    };

    const getFaqs = () => {
        const _faqs = [];
        const questions = [
            "· What is the starting salary of this company in India?",
            "· What is the highest salary at this company India?",
            "· Which role pays the most at this company India?",
            "· How to get employee referral for this company?",
        ];
        for (const [index, question] of questions.entries()) {
            try {
                let answer = "";
                switch (index) {
                    case 0:
                        const { average, std } =
                            companyInsightData.salaryStatisticsByOfferDate[0]
                                .data;
                        answer = `The average starting salary for Amazon in India with is approximately ${(
                            average || 0
                        ).toFixed(
                            1
                        )} LPA(Lakh per Annum) with standard deviation of ${(
                            std || 0
                        ).toFixed(1)} LPA (Lakh per Annum).`;
                        break;
                    case 1:
                        answer = `The highest salary for ${
                            company.name
                        } is approximately ${
                            companyInsightData?.salaryMax || "-"
                        } LPA (Lakh per Annum). You can check out more salary ranges by clicking a node in the above Salary Insight Graphs.`;
                        break;
                    case 2:
                        answer =
                            "We're still collecting data in order to provide you with accurate information.";
                        break;
                    case 3:
                        answer = `Getting a referral from an internal ${company.name} employee is the best way to stand out in the application process. You can check out our forum post for referrals or If you are unable to find any suitable company/position, you may upload a post in Forum asking for referrals.`;
                        break;
                    default:
                        answer =
                            "We're still collecting data in order to provide you with accurate information.";
                }
                _faqs.push({
                    question,
                    answer,
                });
            } catch (e) {
                _faqs.push({
                    question,
                    answer: "We're still collecting data in order to provide you with accurate information.",
                });
            }
        }

        return _faqs;
    };

    const renderTitle = () => {
        return (
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{ width: "100%" }}
                spacing={5}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ width: "100%" }}
                    spacing={1}
                >
                    <Typo.Button1
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/company/list")}
                    >
                        Company
                    </Typo.Button1>
                    <Typo.Button1>/</Typo.Button1>
                    <Typo.Button1>{company.name}</Typo.Button1>
                </Stack>
                <Typo.H1>Insights of {company.name}</Typo.H1>
            </Stack>
        );
    };

    const renderGraph = () => {
        return (
            <Stack
                direction="column"
                alignItems="center"
                justifyContent="flex-start"
                sx={{ width: "100%" }}
                spacing={2}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                >
                    <Text
                        style={{
                            fontSize: "1.25rem",
                            fontWeight: 500,
                            fontFamily: "Lato",
                        }}
                    >
                        {company.name} Salary Breakup Insights
                    </Text>
                    <CustomWidthTooltip
                        title="You can click the nodes below to navigate and get the detailed data"
                        arrow
                        placement="top"
                    >
                        <InfoOutlinedIcon sx={{ fontSize: "1.25rem" }} />
                    </CustomWidthTooltip>
                </Stack>
                <Stack
                    sx={{
                        width: "100%",
                        height: "max-content",
                    }}
                >
                    <CompanySalaryGraph
                        companySalaryInsight={companyInsightData}
                        auth={!!userState.auth}
                    />
                </Stack>
            </Stack>
        );
    };

    const renderBanner = () => {
        return (
            <Stack
                sx={{
                    backgroundImage: `url(${
                        isMobile ? BannerMobileImage : BannerWebImage
                    })`,
                    width: "100%",
                    height: isMobile ? "7rem" : "11.625rem",
                    backgroundSize: "cover",
                }}
            />
        );
    };

    const renderJobs = () => {
        return (
            <Stack sx={{ width: "100%" }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ marginBottom: "1rem", boxSizing: "border-box" }}
                >
                    <Typo.H3
                        style={{
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "1rem",
                        }}
                    >
                        Jobs at {company.name}
                    </Typo.H3>
                </Stack>

                {jds === undefined ? (
                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        {new Array(4).fill("").map((jd, index) => (
                            <Grid
                                item
                                xs={isMobile ? 12 : 6}
                                key={index + "jd_temp"}
                            >
                                <JdCard />
                            </Grid>
                        ))}
                    </Grid>
                ) : jds.length ? (
                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        {jds.map((jd, index) => (
                            <Grid
                                item
                                xs={isMobile ? 12 : 6}
                                key={index + "jd_temp"}
                            >
                                <JdCard jd={jd} />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            width: "100%",
                            height: "17.5rem",
                            backgroundColor: Colors.no_item_background,
                        }}
                    >
                        <Text>No jobs</Text>
                    </Stack>
                )}
                {jds?.length >= 4 ? (
                    <ViewMoreButton href={`/jd/list?keyword=${company.name}`} />
                ) : null}
            </Stack>
        );
    };

    const renderSalaries = () => {
        return (
            <Stack sx={{ width: "100%" }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ marginBottom: "1rem", boxSizing: "border-box" }}
                >
                    <Typo.H3
                        style={{
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "1rem",
                        }}
                    >
                        Latest salary breakups for {company.name}
                    </Typo.H3>
                </Stack>
                {salaries === undefined ? (
                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        {new Array(4).fill("").map((_, index) => (
                            <Grid
                                item
                                xs={isMobile ? 12 : 6}
                                key={index + "salary_temp"}
                            >
                                <SalaryCard />
                            </Grid>
                        ))}
                    </Grid>
                ) : salaries.length ? (
                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        {salaries.map((salary, index) => (
                            <Grid
                                item
                                xs={isMobile ? 12 : 6}
                                key={index + "salary_temp"}
                            >
                                <SalaryCard salary={salary} />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            width: "100%",
                            height: "17.5rem",
                            backgroundColor: Colors.no_item_background,
                        }}
                    >
                        <Text>No salary breakups</Text>
                    </Stack>
                )}
                {salaries?.length >= 4 ? (
                    <ViewMoreButton
                        href={`/salary/list?keyword=${company.name}`}
                    />
                ) : null}
            </Stack>
        );
    };

    const renderContribute = () => {
        return <ContributeZigup title="Contribute to Zigup Community" />;
    };

    const renderLFCs = () => {
        return (
            <Stack sx={{ width: "100%" }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ marginBottom: "1rem", boxSizing: "border-box" }}
                >
                    <Typo.H3
                        style={{
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "1rem",
                        }}
                    >
                        Referrals for {company.name}
                    </Typo.H3>
                </Stack>
                {forums === undefined ? (
                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        {new Array(4).fill("").map((_, index) => (
                            <Grid
                                item
                                xs={isMobile ? 12 : 6}
                                key={index + "forum_temp"}
                            >
                                <ForumCard />
                            </Grid>
                        ))}
                    </Grid>
                ) : forums.length ? (
                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        {forums.map((item, index) => (
                            <Grid
                                item
                                xs={isMobile ? 12 : 6}
                                key={index + "forum_temp"}
                            >
                                <ForumCard item={item} />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            width: "100%",
                            height: "17.5rem",
                            backgroundColor: Colors.no_item_background,
                        }}
                    >
                        <Text>No Employee Referrals</Text>
                    </Stack>
                )}
                {forums?.length >= 4 ? (
                    <ViewMoreButton href={"/forum/list?type=LFC"} />
                ) : null}
            </Stack>
        );
    };

    const renderFaqs = () => {
        const faqs = getFaqs();
        return (
            <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={8}
                sx={{
                    display: "flex",
                    width: "100%",
                    height: "max-content",
                    minHeight: "10rem",
                    boxSizing: "border-box",
                    padding: "4rem 1rem",
                    backgroundColor: Colors.background_contribute_card,
                }}
            >
                <Typo.H4
                    style={{
                        fontFamily: "Lato",
                        fontWeight: 500,
                        fontSize: "1.25rem",
                    }}
                >
                    FAQ
                </Typo.H4>
                <Container maxWidth="lg">
                    <Stack spacing={8}>
                        <Grid container spacing={8} sx={{ marginBottom: 4 }}>
                            {faqs.map((faq, index) => (
                                <Grid
                                    item
                                    xs={isMobile ? 12 : 6}
                                    key={index + "faq_temp"}
                                >
                                    <Stack
                                        direction="column"
                                        alignItems="flex-start"
                                        justifyContent="flex-start"
                                        spacing={2}
                                        sx={{
                                            display: "flex",
                                            flex: 1,
                                            width: "100%",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontSize: "1rem",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                            }}
                                        >
                                            {faq.question}
                                        </Text>
                                        <Text
                                            style={{
                                                fontFamily: "Roboto",
                                                fontSize: "1rem",
                                                color: Colors.light_grey_3,
                                                lineHeight: "1.5rem",
                                            }}
                                        >
                                            {faq.answer}
                                        </Text>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>
                </Container>
                <Text
                    style={{
                        fontFamily: "Roboto",
                        fontSize: "1rem",
                        color: Colors.primary_purple,
                        borderBottom: `1px ${Colors.primary_purple} solid`,
                        paddingBottom: "0.25rem",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        navigate("/faq");
                    }}
                >
                    Other FAQs -{">"}
                </Text>
            </Stack>
        );
    };

    const getSEOTitle = () => {
        return company?.name
            ? `${company?.name} India Salary Breakup - Zigup`
            : "Salary Breakup for India by Zigup";
    };

    const getSEODescription = () => {
        return company?.name
            ? `Get the latest salary breakup, jd, discussions, referrals of ${company.name} India, Find ${company.name} Salary Ranges by roles, experience & skills. Sign Up for Better Offers!`
            : "Get the latest salary breakup, jd, discussions, referrals of India, Find Salary Ranges by roles, experience & skills. Sign Up for Better Offers!";
    };

    const getSEOKeywords = () => {
        const keywords = [
            "zigup",
            "company",
            "salary",
            "offer",
            "offers",
            "compensation",
            "breakup",
            "breakdown",
            "jobs",
            "employee referral",
            "referral",
            "career",
            "faq",
            "example",
        ];
        if (!Object.keys(company).length) return keywords.join(", ");
        keywords.push([company?.name]);

        return keywords.join(", ");
    };

    const getFaqJsonLd = () => {
        const mainEntity = [];
        for (const faq of getFaqs()) {
            mainEntity.push({
                "@type": "Question",
                name: faq.question,
                acceptedAnswer: {
                    "@type": "Answer",
                    text: faq.answer,
                },
            });
        }
        const data = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: mainEntity,
        };
        return JSON.stringify(data);
    };

    const getSEOHelmet = () => {
        return (
            <Helmet>
                <title>{getSEOTitle()}</title>
                <meta
                    name="description"
                    property="description"
                    content={getSEODescription()}
                />
                <meta
                    name="keywords"
                    property="keywords"
                    content={getSEOKeywords()}
                />
                <meta content={getSEOTitle()} property="og:title" />
                <meta property="og:description" content={getSEODescription()} />
                <meta
                    content={"https://zigup.in/company/" + company.seoURL}
                    name="url"
                    property="og:url"
                />
                <meta
                    content="https://hire.zigup.in/static/media/logo-horizontal.4663103fd0a4bca8ea17.png"
                    property="og:image"
                />
                <link
                    rel="canonical"
                    href={"https://zigup.in/company/" + company.seoURL}
                />
                {company.seoURL &&
                !location.pathname.includes(encodeURI(company.seoURL)) ? (
                    <meta
                        httpEquiv="refresh"
                        content={`0; url=/company/${company.seoURL}`}
                    />
                ) : null}
                {company.seoURL &&
                !location.pathname.includes(encodeURI(company.seoURL)) ? (
                    <meta name="robots" content="noindex" />
                ) : null}
                <script type="application/ld+json">{getFaqJsonLd()}</script>
            </Helmet>
        );
    };

    return (
        <>
            {getSEOHelmet()}
            <GlobalHeader />
            <Container
                maxWidth="lg"
                sx={{
                    padding: "2rem 1rem",
                    boxSizing: "border-box",
                    minHeight: "100vh",
                }}
            >
                <Stack
                    direction="column"
                    spacing={4}
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    {renderTitle()}
                    {renderGraph()}
                    {renderBanner()}
                    {renderSalaries()}
                    {renderJobs()}
                    {renderContribute()}
                    {renderLFCs()}
                </Stack>
            </Container>
            {renderFaqs()}
            <Footer />
        </>
    );
};

export default CompanyDetailPage;
