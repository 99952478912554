import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import GlobalHeader from "components/organisms/GlobalHeader";
import Footer from "components/organisms/Footer";

const SignUpPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(async () => {
        if (!state) {
            navigate("/");
        } else {
            navigate("basic", {
                state,
            });
        }
    }, []);

    return (
        <>
            <GlobalHeader />
            <Outlet context={state} />
            <Footer />
        </>
    );
};
export default SignUpPage;
