import React from "react";
import { Stack } from "@mui/material";
import Typo from "components/atoms/Typo";
import ZigupButton from "../../atoms/ZigupButton";
import styled from "styled-components";
import Chip from "@mui/material/Chip";
import { ThemeProvider } from "@emotion/react";
import { lightPurpleWithBorder } from "themes/zigup";
import { useNavigate } from "react-router";
import Colors from "themes/colors";

const ItemContainer = styled(Stack)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const SkillContainer = styled(Stack)`
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
`;

const Preference = ({ userInfo }) => {
    const navigate = useNavigate();

    return (
        <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
            padding={2}
            margin={1}
            style={{ backgroundColor: "white" }}
        >
            <ItemContainer>
                <Stack>
                    <Typo.Body1
                        style={{
                            fontSize: "1rem",
                            color: Colors.light_grey_3,
                            fontFamily: "Roboto",
                            fontWeight: 400,
                        }}
                    >
                        Preference
                    </Typo.Body1>
                </Stack>
                <Stack>
                    <ZigupButton
                        id={"my-preference-edit-btn"}
                        buttonText={"EDIT"}
                        containerStyle={{
                            width: "max-content",
                            height: "max-content",
                            backgroundColor: "transparent",
                        }}
                        buttonTextSize={"1rem"}
                        buttonTextStyle={{ color: "#646464" }}
                        onClickHandler={() =>
                            navigate("edit/preference", {
                                state: { userInfo: userInfo },
                            })
                        }
                    />
                </Stack>
            </ItemContainer>
            <SkillContainer>
                {userInfo.preferred.locations.map((location, index) => {
                    return (
                        <ThemeProvider
                            theme={lightPurpleWithBorder}
                            key={index + location}
                        >
                            <Chip
                                label={location}
                                disabled={false}
                                style={{
                                    margin: "0.2rem",
                                }}
                                color={"primary"}
                            />
                        </ThemeProvider>
                    );
                })}
            </SkillContainer>
        </Stack>
    );
};
export default Preference;
