import React, { createContext, useState } from "react";

const LoadingContext = createContext({
    isLoading: false,
    setIsLoading: () => {},
});

const LoadingProvider = ({ children }) => {
    const [isLoading, setLoadingCurrent] = useState(false);
    const setIsLoading = (newLoadingStatus) => {
        // Logger.log("loading", isLoading, newLoadingStatus);
        // if (isLoading === newLoadingStatus) return;
        setLoadingCurrent(newLoadingStatus);
    };
    return (
        <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
            {/* {isLoading ? (
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "fixed",
                        zIndex: 1,
                    }}
                >
                    <SpinnerCircular
                        enabled={isLoading}
                        size={50}
                        color={Colors.purple}
                        secondaryColor={Colors.light_grey}
                        thickness={150}
                    />
                </div>
            ) : null} */}
            {children}
        </LoadingContext.Provider>
    );
};

export { LoadingContext, LoadingProvider };
