export const ThumbnailTypes = {
    Push: "124",
    Profile: "128",
    Attachment: "256",
};

export const Types = {
    Simple: "simple",
    WithoutToken: "without-token",
    WithAccessToken: "with-access-token",
    UserLog: "user-log",
    WithRefreshToken: "with-refresh-token",
    FileUpload: "file-upload",
    FileUploadWithAccessToken: "file-upload-with-access-token",
};

export const Urls = {
    prefix: "api/",
    service: "v1/service/_3",
    common: {
        companies: "v1/company/all",
    },
    jd: {
        list: "v1/jd",
        detail: (id) => `v1/jd/${id}/for-web-apply`,
        offers: "v1/apply/my/new",
        tmpOffers: (hash) => `v1/apply/my/new/tmp-auth-by/${hash}`,
        tmpOffersByClientData: "v1/apply/my/new/tmp-auth-by-client-data",
        apply: "v1/apply/interest/for-web-apply",
        skillSet: "v1/skillset/all",
        technicalArea: "v1/service/technical-area",
        jdRoleList: "v1/jd/list/same-role",
        recommend: (id) => `v1/jd/${id}/list/same-role`,
    },
    referral: {
        detail: (id) => `v1/forum-referral/${id}/for-web-apply`,
        apply: "v1/apply-lfc/from-web",
    },
    salary: {
        list: "v1/salary/list",
        detail: (id) => `v1/salary/${id}`,
        recommend: (id) => `v1/salary/${id}/recommend`,
        viewedCompanies: (id) => `v1/salary/${id}/companies_of_viewer`,
        salaryAdjoiningList: (id) => `v1/salary/${id}/adjoining`,
        postSalary: "v1/salary",
        insight: "v1/salary/insight/by-role",
    },
    company: {
        detail: (id) => `v1/company/${id}`,
        list: "v1/company",
        curatedList: "v1/company/curated",
        insight: "v1/company/insight/by-name",
    },
    forum: {
        allList: "v1/forum/all/list",
        discussionList: "v1/forum/discussion/list",
        lfcList: "v1/forum/lfc/list",
        lfrList: "v1/forum/lfr/list",
        discussionDetailAdjoiningList: (id) =>
            `v1/forum/discussion/${id}/adjoining`,
        lfcDetailAdjoiningList: (id) => `v1/forum/lfc/${id}/adjoining`,
        lfrDetailAdjoiningList: (id) => `v1/forum/lfr/${id}/adjoining`,
        discussionDetail: (id) => `v1/forum/discussion/${id}`,
        lfcDetail: (id) => `v1/forum/lfc/${id}`,
        lfrDetail: (id) => `v1/forum/lfr/${id}`,
        mainList: "v1/forum/all/main-page",
    },
    search: {
        search: "v1/forum/search/all-content",
    },
    comment: {
        list: "v1/comment/list",
    },
    membership: {
        checkMobile: (number) =>
            `v1/user/auth-status/by-phone-number/${number}`,
        requestOTP: "v1/user/otp/send",
        verifyOTP: "v1/user/otp/check",
        setPassword: "v1/user/password",
        signUp: "v1/user/signup",
        signIn: "v1/user/login",
        education: "v1/service/education",
        reissueToken: "v1/user/reissue-token",
        myInformation: "v1/user/me",
        cvAttachment: "v1/user/cvs/add",
        cvDelete: (id) => `v1/user/cvs/${id}`,
        setMyInformation: "v1/user/me",
        setMyProfileImage: "v1/user/me/profile-image",
        myInformationByClientData: "v1/user/my/by-client-data",
    },
    seo: {
        jdList: "v1/jd/seo/list",
        salaryList: "v1/salary/seo/list",
    },
    main: {
        keywords: "v1/page/main/keywords-and-counts",
    },
};
