import React, { useContext, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Text from "../../atoms/Text";
import NormalTextField from "../../atoms/NormalTextField";
import { ALERT_TYPE, SIGNUP_STEP } from "../../../constants/constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { isPhoneNumber, isRequired } from "../../../utils/validator";
import styled from "styled-components";
import Colors from "themes/colors";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, AlertTitle, Collapse, IconButton } from "@mui/material";
import { useStateCallback } from "../../atoms/useStateCallback";
import { requestOTP, verifyOTP } from "../../../communication/membership";
import { Logger } from "utils";
import { AlertContext } from "context/AlertContext";
import ZigupSpinner from "../../organisms/ZigupSpinner";
import ReactGA from "react-ga4";
import MobileTextField from "components/organisms/MobileTextField";

const VerifyButton = styled.div`
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
    background-color: ${({ disabled }) =>
        disabled ? `${Colors.grey}` : `${Colors.purple_blue}`};
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;
const VerifyMobile = (props) => {
    const { mobileNumber, nextStep, onHandleStep, onHandleClose, style } =
        props;
    const [OTP, setOTP] = useState("");
    const [mobile, setMobile] = useState("");
    const [isValid, setIsValid] = useStateCallback(false);
    const [isValidMobile, setIsValidMobile] = useStateCallback(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRequestOTP, setIsRequestOTP] = useState(false);
    const [requestOTPAvailable, setRequestOTPAvailable] = useState(true);
    const [otpSubmitCnt, setOtpSubmitCnt] = useState(0);
    const [gotOtpTimestamp, setGotOtpTimestamp] = useState(0);
    const [OTPCollapseAlertState, setOTPCollapseAlertState] = useState({
        status: -1,
        title: "title",
        message: "message",
    });
    const { openAlert } = useContext(AlertContext);

    useEffect(() => {
        if (nextStep === SIGNUP_STEP.FORGOT_PASSWORD) {
            if (props.title) {
                props.title("Reset Password");
            }
        } else {
            if (props.title) {
                props.title("Verify mobile");
            }
            sendOTP();
        }
    }, []);

    useEffect(() => {
        props.title(
            isRequestOTP
                ? `OTP has been sent (${otpSubmitCnt} / 5)`
                : "Verify Mobile"
        );
    }, [isRequestOTP, otpSubmitCnt]);

    useEffect(() => {
        if (!otpSubmitCnt) return;
        setRequestOTPAvailable(false);
        setTimeout(
            () => {
                setRequestOTPAvailable(true);
            },
            otpSubmitCnt >= 5 ? 1000 * 60 * 10 : 10000
        );
    }, [otpSubmitCnt]);

    useEffect(() => {
        setIsValid(isRequired(OTP).valid, () => {
            checkButtonDisable();
        });
        return () => {
            setIsValid(false);
        };
    }, [OTP]);

    useEffect(() => {
        const _isMobile = isPhoneNumber(mobile.replace(/(-)/g, ""));
        setIsValidMobile(_isMobile.valid);
        return () => setIsValidMobile(false);
    }, [mobile]);

    const checkButtonDisable = () => {
        if (isLoading) return true;
        if (isRequestOTP) {
            return !isValid;
        }
        if (nextStep === SIGNUP_STEP.FORGOT_PASSWORD) {
            return !isValidMobile;
        }
        return false;
    };

    const verify = () => {
        if (isRequestOTP) {
            ReactGA.event({
                category: "Button",
                action: "verify_otp_click",
                label: "sign_in",
            });
            doVerifyOTPFromServer();
        } else {
            ReactGA.event({
                category: "Button",
                action: "request_otp",
                label: "sign_in",
            });
            sendOTP();
        }
    };

    const sendOTP = async () => {
        if (!requestOTPAvailable) return;
        if (otpSubmitCnt >= 5) {
            openAlert({
                text: "Cannot send more OTP",
                alertType: ALERT_TYPE.ERROR,
            });
            return;
        }
        try {
            setIsLoading(true);
            openAlert({
                text: "Successfully sent OTP!",
                alertType: ALERT_TYPE.SUCCESS,
            });

            const _phoneNumber =
                nextStep === SIGNUP_STEP.FORGOT_PASSWORD
                    ? mobile.replace(/(-)/g, "")
                    : mobileNumber.replace(/(-)/g, "");

            const res = await requestOTP({
                phoneNumber: _phoneNumber,
            });
            if (res) {
                setOtpSubmitCnt(otpSubmitCnt + 1);
                setGotOtpTimestamp(new Date());
                setIsRequestOTP(true);
            }
        } catch (e) {
            Logger.log("e", e);
        } finally {
            setIsLoading(false);
        }
    };

    const doVerifyOTPFromServer = async () => {
        try {
            setIsLoading(true);
            const _phoneNumber =
                nextStep === SIGNUP_STEP.FORGOT_PASSWORD
                    ? mobile.replace(/(-)/g, "")
                    : mobileNumber.replace(/(-)/g, "");

            const res = await verifyOTP({
                phoneNumber: _phoneNumber,
                otp: OTP,
            });
            if (res) {
                switch (nextStep) {
                    case SIGNUP_STEP.SET_PASSWORD:
                    case SIGNUP_STEP.FORGOT_PASSWORD:
                        onHandleStep({
                            step: SIGNUP_STEP.SET_PASSWORD_AFTER_VERIFY,
                            params: {
                                mobileNumber: _phoneNumber,
                                previousStep: nextStep,
                            },
                        });
                        break;
                    case SIGNUP_STEP.SIGN_UP:
                        onHandleClose({
                            status: SIGNUP_STEP.SIGN_UP,
                            params: {
                                mobileNumber: _phoneNumber,
                            },
                        });
                        break;
                }
            }
        } catch (e) {
            openAlert({
                text: "OTP is not correct. Please try again",
                alertType: ALERT_TYPE.ERROR,
            });
            Logger.log("e", e);
        } finally {
            setIsLoading(false);
        }
    };

    const onHandleBackPress = () => {
        onHandleStep(SIGNUP_STEP.SIGN_IN);
    };

    const renderInputArea = () => {
        return (
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                style={{ width: "100%" }}
            >
                <MobileTextField
                    value={mobile}
                    disabled={
                        nextStep === SIGNUP_STEP.FORGOT_PASSWORD ? false : true
                    }
                    onChange={setMobile}
                    containerStyle={{ margin: 0 }}
                    onPress={sendOTP}
                />

                <Collapse
                    in={isRequestOTP}
                    style={{
                        width: "100%",
                    }}
                >
                    <NormalTextField
                        value={OTP}
                        onChange={(event) => setOTP(event.target.value)}
                        fullWidth
                        type="number"
                        placeholder="OTP"
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                Logger.log("OTP");
                                doVerifyOTPFromServer();
                            }
                        }}
                    />
                    {OTPCollapseAlertState.status !== -1 ? (
                        <Alert
                            severity={
                                OTPCollapseAlertState.status
                                    ? "success"
                                    : "error"
                            }
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOTPCollapseAlertState({
                                            status: -1,
                                            title: "",
                                            message: "",
                                        });
                                        setOTP("");
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            <AlertTitle>
                                {OTPCollapseAlertState.title}
                            </AlertTitle>
                            {OTPCollapseAlertState.message}
                        </Alert>
                    ) : null}
                </Collapse>

                <VerifyButton
                    justifyContent="center"
                    alignItems="center"
                    onClick={verify}
                    disabled={checkButtonDisable()}
                    style={{
                        width: "100%",
                        height: "3rem",
                        backgroundColor: checkButtonDisable()
                            ? "grey"
                            : "#6837f2",
                        cursor: "pointer",
                    }}
                >
                    {isLoading ? (
                        <ZigupSpinner />
                    ) : (
                        <Text style={{ color: Colors.white }}>
                            {isRequestOTP ? "Verify OTP" : "Get OTP"}
                        </Text>
                    )}
                </VerifyButton>
                {isRequestOTP ? (
                    requestOTPAvailable ? (
                        <div
                            onClick={sendOTP}
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                textDecoration: "underline",
                            }}
                        >
                            <Text style={{ fontSize: 12, cursor: "pointer" }}>
                                Didn't get OTP? Click here
                            </Text>
                        </div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                            }}
                        >
                            <Text style={{ fontSize: 12 }}>
                                {otpSubmitCnt >= 5
                                    ? "Request too many OTP. Can request after 10 mins"
                                    : "Kindly wait 10 sec to get OTP"}
                            </Text>
                        </div>
                    )
                ) : null}
            </Stack>
        );
    };

    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{
                width: "100%",
                ...style,
            }}
        >
            <Stack justifyContent="flex-start">
                <Stack
                    onClick={onHandleBackPress}
                    style={{
                        cursor: "pointer",
                        width: "max-content",
                    }}
                >
                    <ArrowBackIcon sx={{ fontSize: "2rem", color: "gray" }} />
                </Stack>
            </Stack>
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                style={{
                    width: "100%",
                    height: "100%",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                }}
            >
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        width: "100%",
                    }}
                >
                    {renderInputArea()}
                </Stack>
            </Stack>
            <Stack justifyContent="flex-end">
                <Stack
                    style={{
                        width: "2rem",
                    }}
                />
            </Stack>
        </Stack>
    );
};
export default VerifyMobile;
