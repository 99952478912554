import React, { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router";
import Done from "components/organisms/Done";
import { Container, Stack } from "@mui/material";
import { AuthContext } from "../../../context/AuthContext";
import MyOfferList from "../../organisms/MyOfferList";
import RecommendJds from "./../../organisms/jd/RecommendJds";
import Typo from "components/atoms/Typo";

const JdDonePage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { userState, getTmpLoginHash } = useContext(AuthContext);
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const pageText = {
        title: "It’s done!",
        desc: [
            "Thank you for your application.\n" +
                "You will get a call from HR if shortlisted.\n\n",
            "To track your application or view more current openings, download Zigup app.",
        ],
    };

    const scrollToTop = () => window.scrollTo(0, 0);
    useEffect(() => {
        if (!state) navigate("/");
        scrollToTop();
        const preventGoBack = () => {
            history.pushState(null, "", location.href);
        };
        history.pushState(null, "", location.href);
        window.addEventListener("popstate", preventGoBack);
        return () => {
            window.removeEventListener("popstate", preventGoBack);
        };
    }, [state]);

    return state ? (
        <Container maxWidth="lg" sx={{ boxSizing: "border-box" }}>
            <Stack direction="column" sx={{ marginBottom: "4rem" }}>
                <Container maxWidth="sm" sx={{ paddingBottom: "2rem" }}>
                    <Done isMobile={isMobile} pageText={pageText} />
                </Container>
                {userState.auth ? (
                    <MyOfferList name={userState.info.name} />
                ) : getTmpLoginHash() ? (
                    <MyOfferList hash={getTmpLoginHash()} />
                ) : null}
                <Stack
                    direction={"column"}
                    style={{
                        width: "100%",
                        marginTop: "60px",
                        marginBottom: isMobile ? "1rem" : null,
                        boxSizing: "border-box",
                    }}
                    spacing={2}
                >
                    <Stack
                        justifyContent={"flex-start"}
                        style={{ width: "100%" }}
                    >
                        <Typo.Body1Bold style={{ fontSize: "1.25rem" }}>
                            Similar Jobs
                        </Typo.Body1Bold>
                    </Stack>
                    {state.jdId ? (
                        <RecommendJds
                            id={state.jdId}
                            isMobile={isMobile}
                            maxCount={20}
                        />
                    ) : null}
                </Stack>
            </Stack>
        </Container>
    ) : null;
};

export default JdDonePage;
