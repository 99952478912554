import React, { useEffect, useState } from "react";
import Text from "components/atoms/Text";
import { useMediaQuery } from "react-responsive";
import Stack from "@mui/material/Stack";
import Colors from "themes/colors";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import { detail } from "communication/jd";
import directApplyIcon from "assets/icons/direct-apply4x.png";
import ShareMenu from "components/organisms/ShareMenu";
import { Helmet } from "react-helmet-async";
import { Container, Skeleton } from "@mui/material";
import ZigupButton from "../../atoms/ZigupButton";
import Typo from "../../atoms/Typo";
import ReactGA from "react-ga4";
import SkillTag from "components/atoms/SkillTag";
import CompanyLogo from "components/atoms/CompanyLogo";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { toast } from "react-toastify";
import { getFullDate } from "utils/DateUtil";
import ConfirmModal from "../../organisms/ConfirmModal";
import { Logger } from "utils";
import RecommendJds from "./../../organisms/jd/RecommendJds";

const JdDetailPage = () => {
    const navigate = useNavigate();
    const _location = useLocation();
    const { jdId } = useOutletContext();
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const [jd, setJd] = useState();
    const [confirmModalState, setConfirmModalState] = useState({
        open: false,
        title: "",
        content: "",
        image: {
            src: "",
            alt: "",
        },
        button: {
            confirm: {
                text: "",
                id: "",
                handle: () => undefined,
            },
            reject: {
                text: "",
                id: "",
                handle: () => undefined,
            },
        },
        closeModal: () => undefined,
        closeOnEsc: true,
        closeOnOverlayClick: true,
    });

    const closeConfirmModal = () => {
        setConfirmModalState({
            ...confirmModalState,
            open: false,
        });
    };

    const openConfirmModal = (params) => {
        setConfirmModalState(params);
    };

    const handleConfirmExpiredPressed = () => {
        closeConfirmModal();
        navigate("/");
    };

    useEffect(async () => {
        try {
            const res = await detail(jdId);
            setJd(res.jd);
            if (res.jd.isCompanyHireDirectly) {
                ReactGA.event({
                    category: "Job",
                    action: "page_view_jd",
                    label: "jd",
                });
            } else {
                ReactGA.event({
                    category: "Job",
                    action: "page_view_jd_crawled",
                    label: "jd",
                });
            }
            if (!res.jd.opened) {
                openConfirmModal({
                    open: true,
                    title: "This job is expired",
                    content: "Ask Team Zigup for further update.",
                    button: {
                        confirm: {
                            text: "GOT IT",
                            id: "jd-close-confirm-detail-btn",
                            handle: handleConfirmExpiredPressed,
                        },
                    },
                    closeModal: closeConfirmModal,
                    closeOnEsc: false,
                    closeOnOverlayClick: false,
                });
            }
        } catch (e) {
            navigate("/");
        }
    }, [jdId]);

    const handleButton = () => {
        if (jd?.isCompanyHireDirectly) {
            apply();
        } else {
            viewOnCompanySite();
        }
    };

    const apply = () => {
        ReactGA.event({
            category: "Button",
            action: "go_to_jd_apply_pressed",
        });
        navigate("apply", { state: { jdId: jdId, jd: jd } });
    };

    const viewOnCompanySite = () => {
        ReactGA.event({
            category: "Button",
            action: "go_to_company_site_pressed",
        });
        window.open(jd?.link, "_blank");
    };

    const sectionStyle = {
        padding: "0rem",
        width: "100%",
        height: "max-content",
        display: "flex",
    };

    const printInfo = () => {
        if (!jd) return "";
        const location = jd.locations?.slice(0, 3).join(", ") ?? "";

        let YoEString = null;
        if (jd.fromYear && jd.toYear)
            YoEString = jd.fromYear + "~" + jd.toYear + "y";
        else if (jd.fromYear) YoEString = jd.fromYear + "+y";
        else if (jd.toYear) YoEString = "0~" + jd.toYear + "y";

        return location + (YoEString ? " | " + YoEString : "");
    };

    const renderJdHeader = () => {
        return (
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1.5}
                style={sectionStyle}
            >
                <CompanyLogo
                    source={jd?.companyData?.logoURL || ""}
                    height={100}
                    width={100}
                />
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    style={{
                        width: "100%",
                        height: "max-content",
                        wordWrap: "break-word",
                    }}
                >
                    <Typo.H1Regular
                        style={{
                            fontSize: "1.5rem",
                            maxWidth: "100%",
                            lineHeight: "1.5rem",
                            wordBreak: "keep-all",
                            margin: 0,
                        }}
                    >
                        {jd ? (
                            jd?.title
                        ) : (
                            <Skeleton variant="text" width={200} />
                        )}
                    </Typo.H1Regular>
                    <ShareMenu url={window.location.href} />
                </Stack>
                <Text
                    style={{
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: "1rem",
                        color: Colors.headerPurple,
                    }}
                >
                    {jd?.company}
                </Text>
                <Text
                    style={{
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: "0.8rem",
                        color: Colors.jd_text_grey,
                    }}
                >
                    {printInfo()}
                </Text>
                {jd?.customInfo?.role?.value &&
                jd?.customInfo?.role?.value.length ? (
                    <Stack direction="row">
                        {jd?.customInfo?.role?.value.map((skill, index) => (
                            <SkillTag key={skill + index} skill={skill} />
                        ))}
                    </Stack>
                ) : null}
            </Stack>
        );
    };

    const renderJdContent = () => {
        return jd ? (
            <Stack
                sx={{
                    flex: 7,
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "1rem",
                    lineHeight: "1.5rem",
                }}
                justifyContent="flex-start"
                alignItems="start"
                dangerouslySetInnerHTML={{
                    __html: jd?.content?.split("\n").join("<br>"),
                }}
            />
        ) : (
            <Stack sx={{ flex: 7 }} spacing={2}>
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
            </Stack>
        );
    };

    const renderJdInfo = () => {
        return (
            <Stack
                direction="column"
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={1}
                sx={{ flex: 4 }}
            >
                {jd?.isCompanyHireDirectly ? (
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        sx={{
                            backgroundColor: Colors.direct_hiring_background,
                            padding: "14px 12px",
                            boxSizing: "border-box",
                            width: "100%",
                            borderRadius: "0.25rem",
                        }}
                    >
                        <img
                            src={directApplyIcon}
                            alt="directApplyIcon"
                            width="24px"
                            height="24px"
                        />
                        <Text
                            style={{
                                color: Colors.jd_text_grey,
                                fontSize: "0.8rem",
                                marginLeft: "0.5rem",
                            }}
                        >
                            Direct hiring from company
                        </Text>
                    </Stack>
                ) : null}
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        backgroundColor: Colors.jd_text_background,
                        boxSizing: "border-box",
                        padding: "1rem",
                        width: "100%",
                        borderRadius: "0.25rem",
                    }}
                >
                    <Stack
                        direction={isMobile ? "column" : "row"}
                        spacing={isMobile ? 1 : 0}
                    >
                        <Text style={{ flex: 3, fontWeight: 700 }}>
                            Work Type
                        </Text>
                        <Text style={{ flex: 5 }}>{jd?.workPlace || "-"}</Text>
                    </Stack>
                    <Stack
                        direction={isMobile ? "column" : "row"}
                        spacing={isMobile ? 1 : 0}
                    >
                        <Text style={{ flex: 3, fontWeight: 700 }}>
                            Salary budget
                        </Text>
                        <Text style={{ flex: 5 }}>
                            {jd?.salaryBudget || "-"}
                        </Text>
                    </Stack>
                    <Stack
                        direction={isMobile ? "column" : "row"}
                        spacing={isMobile ? 1 : 0}
                    >
                        <Text style={{ flex: 3, fontWeight: 700 }}>
                            Employement type
                        </Text>
                        <Text style={{ flex: 5 }}>
                            {jd?.employType?.join(", ") || "-"}
                        </Text>
                    </Stack>
                    <Stack
                        direction={isMobile ? "column" : "row"}
                        spacing={isMobile ? 1 : 0}
                    >
                        <Text style={{ flex: 3, fontWeight: 700 }}>Team</Text>
                        <Text style={{ flex: 5 }}>{jd?.team || "-"}</Text>
                    </Stack>
                    <Stack
                        direction={isMobile ? "column" : "row"}
                        spacing={isMobile ? 1 : 0}
                    >
                        <Text style={{ flex: 3, fontWeight: 700 }}>
                            Hiring process
                        </Text>
                        <Text style={{ flex: 5 }}>
                            {jd?.hiringProcess || "-"}
                        </Text>
                    </Stack>
                    <Stack
                        direction={isMobile ? "column" : "row"}
                        spacing={isMobile ? 1 : 0}
                    >
                        <Text style={{ flex: 3, fontWeight: 700 }}>
                            Uploaded At
                        </Text>
                        <Text style={{ flex: 5 }}>
                            {getFullDate(jd?.createdAt) || "-"}
                        </Text>
                    </Stack>
                </Stack>
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={() => {
                        navigate(
                            `/company/${
                                jd?.companyData?.seoURL || jd?.companyData?._id
                            }`
                        );
                    }}
                    sx={{
                        width: "100%",
                        padding: "1rem",
                        boxSizing: "border-box",
                        cursor: "pointer",
                        border: `1px ${Colors.outline_2} solid`,
                        borderRadius: "0.25rem",
                    }}
                >
                    <CompanyLogo
                        source={jd?.companyData?.logoURL || ""}
                        height={100}
                        width={100}
                    />
                    <Stack
                        direction="column"
                        alignItems="flex-start"
                        justifyContent="center"
                        spacing={0.25}
                        sx={{ flex: 1 }}
                    >
                        <Text style={{ color: "#aaa", fontSize: "0.8rem" }}>
                            About company
                        </Text>
                        <Text style={{ fontWeight: 700, fontSize: "1.125rem" }}>
                            {jd?.company || jd?.companyData.name}
                        </Text>
                    </Stack>
                    <KeyboardArrowRightIcon />
                </Stack>
            </Stack>
        );
    };

    const renderRecommendJds = () => {
        return (
            <Stack
                direction={"column"}
                style={{
                    width: "100%",
                    marginTop: "60px",
                    marginBottom: isMobile ? "1rem" : null,
                }}
                spacing={2}
            >
                <Stack justifyContent={"flex-start"} style={{ width: "100%" }}>
                    <Typo.Body1Bold style={{ fontSize: "1.25rem" }}>
                        Similar Jobs
                    </Typo.Body1Bold>
                </Stack>
                {jd?._id ? (
                    <RecommendJds id={jd._id} isMobile={isMobile} />
                ) : null}
            </Stack>
        );
    };

    const getSEOTitle = () => {
        return `${jd?.company || jd?.companyData.name} | ${jd?.title}${
            " | " + jd?.locations?.slice(0, 3).join(", ") || ""
        }`;
    };

    const getSEODescription = () => {
        return `Job Description of ${jd?.title} by ${jd?.company}. See details and do simple apply`;
    };

    const getSEOKeywords = () => {
        const keywords = [
            "zigup",
            "jd",
            "job",
            "job description",
            "offer",
            "career",
            "change job",
            "job opportunity",
            "opportunity",
            "naukri",
        ];
        if (!jd) return keywords.join(", ");
        keywords.push([
            jd?.company || jd?.companyData.name,
            jd?.title,
            jd?.locations?.slice(0, 3).join(", "),
        ]);
        jd?.customInfo?.role &&
        jd?.customInfo?.role?.value &&
        jd?.customInfo?.role?.value?.length
            ? keywords.push(jd?.customInfo?.role?.value[0])
            : null;

        return keywords.join(", ");
    };

    const getJsonLd = () => {
        if (!jd) return "";
        try {
            const data = {
                "@context": "https://schema.org/",
                "@type": "JobPosting",
                title: jd.title.split("(")[0],
                description: jd.content,
                datePosted: getFullDate(jd.createdAt),
                validThrough: getFullDate(
                    jd.createdAt + 1000 * 60 * 60 * 24 * 60
                ),
                baseSalary: {
                    "@type": "MonetaryAmount",
                    currency: "INR",
                    value: {
                        "@type": "QuantitativeValue",
                        value: jd.salaryBudget || "N/A",
                        unitText: "YEAR",
                    },
                },
                hiringOrganization: {
                    "@type": "Organization",
                    name: jd.companyData.name,
                    logo: jd.companyData.logoURL,
                },
                jobLocation:
                    jd?.locations && jd?.locations.length
                        ? jd?.locations.map((jobLocation) => ({
                              "@type": "Place",
                              address: {
                                  "@type": "PostalAddress",
                                  streetAddress: "NA",
                                  addressLocality: jobLocation,
                                  addressRegion: "NA",
                                  postalCode: "NA",
                                  addressCountry: "IN",
                              },
                          }))
                        : {
                              "@type": "Place",
                              address: {
                                  "@type": "PostalAddress",
                                  streetAddress: "NA",
                                  addressLocality: "NA",
                                  addressRegion: "NA",
                                  postalCode: "NA",
                                  addressCountry: "IN",
                              },
                          },
                directApply: !!jd?.isCompanyHireDirectly,
                employmentType:
                    jd?.employType && jd?.employType.length > 1
                        ? jd?.employType.map((item) => {
                              switch (item) {
                                  case "Full-time":
                                      return "FULL_TIME";
                                  case "Part-time":
                                      return "PART_TIME";
                              }
                          })
                        : jd?.employType &&
                          jd?.employType.length &&
                          jd?.employType[0] === "Full-time"
                        ? "FULL_TIME"
                        : "PART_TIME",
            };
            if (jd.locations && jd.locations.includes("Remote")) {
                data.jobLocationType = "TELECOMMUTE";
            }
            if (jd.fromYear) {
                data.experienceRequirements = {
                    "@type": "OccupationalExperienceRequirements",
                    monthsOfExperience: jd.fromYear * 12,
                };
            }
            return JSON.stringify(data);
        } catch (e) {
            Logger.error(e);
            return "";
        }
    };

    const getHelmet = () => {
        if (!jd)
            return (
                <Helmet>
                    <title>Job Detail</title>
                    <meta property="description" content="Job Description" />
                </Helmet>
            );
        const jsonLd = getJsonLd();
        return (
            <Helmet>
                <title>{getSEOTitle()}</title>
                <meta
                    name="description"
                    property="description"
                    content={getSEODescription()}
                />
                <meta
                    name="keywords"
                    property="keywords"
                    content={getSEOKeywords()}
                />
                <meta content={getSEOTitle()} property="og:title" />
                <meta property="og:description" content={getSEODescription()} />
                <meta
                    content={"https://zigup.in/jd/" + jd.seoURL}
                    name="url"
                    property="og:url"
                />
                <meta
                    content="https://hire.zigup.in/static/media/logo-horizontal.4663103fd0a4bca8ea17.png"
                    property="og:image"
                />
                <link
                    rel="canonical"
                    href={"https://zigup.in/jd/" + jd.seoURL}
                />
                {jd.seoURL &&
                !_location.pathname.includes(encodeURI(jd.seoURL)) ? (
                    <meta
                        httpEquiv="refresh"
                        content={`0; url=/jd/${jd.seoURL}`}
                    />
                ) : null}
                {jd.seoURL &&
                !_location.pathname.includes(encodeURI(jd.seoURL)) ? (
                    <meta name="robots" content="noindex" />
                ) : null}
                {jsonLd ? (
                    <script type="application/ld+json">{jsonLd}</script>
                ) : null}
            </Helmet>
        );
    };

    return (
        <>
            {getHelmet()}
            <Container
                maxWidth="lg"
                sx={{
                    paddingTop: "2rem",
                    paddingBottom: "2rem",
                    minHeight: "100vh",
                }}
            >
                <Stack
                    direction="column"
                    spacing={2}
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{ minHeight: "50vh" }}
                >
                    {renderJdHeader()}
                    {isMobile ? (
                        <Stack
                            direction="column"
                            spacing={2}
                            style={{ ...sectionStyle, padding: "1rem" }}
                        >
                            {renderJdInfo()}
                            {renderJdContent()}
                        </Stack>
                    ) : (
                        <Stack
                            direction="row"
                            spacing={0}
                            style={{ ...sectionStyle, padding: "1rem" }}
                        >
                            {renderJdContent()}
                            <Stack sx={{ flex: 1 }} />
                            {renderJdInfo()}
                        </Stack>
                    )}
                    <ZigupButton
                        id="detail-apply-btn"
                        buttonText={
                            jd?.opened
                                ? jd?.isCompanyHireDirectly
                                    ? "APPLY"
                                    : "View on company site"
                                : "This Job is closed"
                        }
                        buttonTextSize={"1rem"}
                        disabled={!jd?.opened}
                        onClickHandler={handleButton}
                    />
                    {renderRecommendJds()}
                </Stack>
                <ConfirmModal
                    open={confirmModalState.open}
                    title={confirmModalState.title}
                    content={confirmModalState.content}
                    image={confirmModalState.image}
                    button={confirmModalState.button}
                    closeModal={confirmModalState.closeModal}
                    closeOnOverlayClick={confirmModalState.closeOnOverlayClick}
                    closeOnEsc={confirmModalState.closeOnEsc}
                />
            </Container>
        </>
    );
};

export default JdDetailPage;
