import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Text from "../../atoms/Text";
import NormalTextField from "../../atoms/NormalTextField";
import { SIGNUP_STEP } from "../../../constants/constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styled from "styled-components";
import Colors from "themes/colors";
import { savePassword } from "../../../communication/membership";
import { Logger } from "utils";
import ZigupSpinner from "../../organisms/ZigupSpinner";
import ReactGA from "react-ga4";

const SetPasswordButton = styled.div`
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
    background-color: ${({ disabled }) =>
        disabled ? `${Colors.grey}` : `${Colors.purple_blue}`};
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

const SetPassword = (props) => {
    const {
        mobileNumber,
        onHandleStep,
        onHandleClose,
        style,
        previousStep,
        setDescription,
    } = props;
    const [password, setPassword] = useState("");
    const [passwordRe, setPasswordRe] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [isMatchPassword, setIsMatchPassword] = useState(false);
    useEffect(() => {
        if (props.title) {
            if (previousStep === SIGNUP_STEP.FORGOT_PASSWORD) {
                props.title("Change your password");
            } else {
                props.title("Set password");
            }
        }
        if (setDescription) {
            if (previousStep === SIGNUP_STEP.FORGOT_PASSWORD) {
                setDescription(
                    "Use at least 8 characters, including alphabets, numbers, special characters."
                );
            }
        }
        return () => {
            setIsValidPassword(false);
            setIsMatchPassword(false);
            setPassword("");
            setPasswordRe("");
            setDescription("");
        };
    }, []);

    useEffect(() => {
        const passwordRule =
            /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
        if (password.length > 0) {
            setIsValidPassword(passwordRule.test(password));
        }
        if (passwordRe.length > 0 && isValidPassword) {
            setIsMatchPassword(passwordRe === password);
        }
    }, [password, passwordRe]);

    const getAlertMessage = () => {
        if (password.length > 0) {
            if (isValidPassword) {
                return "";
            }
            return "Type 8 or more letter, number, and special character.";
        }
        return "";
    };

    const getPasswordReAlertMessage = () => {
        if (passwordRe.length > 0) {
            if (password.length > 0) {
                if (isMatchPassword) {
                    return "Passwords match";
                }
                return "Passwords don’t match";
            }
            return "Enter the password";
        }
        return "";
    };

    const handleSavePasswordPressed = async () => {
        try {
            ReactGA.event({
                category: "Button",
                action: "set_password_pressed",
                label: "sign_in",
            });
            setPassword("");
            setPasswordRe("");
            setIsMatchPassword(false);
            setIsLoading(true);
            const res = await savePassword({
                phoneNumber: mobileNumber.replace(/(-)/g, ""),
                password: password,
            });
            if (res) {
                ReactGA.event({
                    category: "Button",
                    action: "set_password_completed",
                    label: "sign_in",
                });
                onHandleClose({
                    status: SIGNUP_STEP.SET_PASSWORD_AFTER_VERIFY,
                    params: {
                        phoneNumber: mobileNumber.replace(/(-)/g, ""),
                        password: password,
                        previousStep,
                    },
                });
            }
        } catch (e) {
            Logger.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const onHandleBackPress = () => {
        onHandleStep(SIGNUP_STEP.SIGN_IN);
    };

    const renderInputArea = () => {
        return (
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                style={{ width: "100%" }}
            >
                <NormalTextField
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    fullWidth
                    type="password"
                    alertMessage={getAlertMessage()}
                    alertMessageColor={Colors.alert}
                    placeholder="Password"
                />
                <NormalTextField
                    value={passwordRe}
                    onChange={(event) => setPasswordRe(event.target.value)}
                    fullWidth
                    type="password"
                    alertMessage={getPasswordReAlertMessage()}
                    alertMessageColor={
                        isMatchPassword ? Colors.success : Colors.alert
                    }
                    placeholder="Re-enter Password"
                />
                <SetPasswordButton
                    justifyContent="center"
                    alignItems="center"
                    onClick={handleSavePasswordPressed}
                    disabled={isLoading || !isMatchPassword}
                    style={{
                        width: "100%",
                        height: "3rem",
                        backgroundColor: "#6837f2",
                        cursor: "pointer",
                    }}
                >
                    {isLoading ? (
                        <ZigupSpinner />
                    ) : (
                        <Text style={{ color: Colors.white }}>
                            {previousStep === SIGNUP_STEP.FORGOT_PASSWORD
                                ? "Change Password"
                                : "Save Password"}
                        </Text>
                    )}
                </SetPasswordButton>
            </Stack>
        );
    };

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%", height: "100%", ...style }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ width: "100%", height: "100%" }}
            >
                <Stack justifyContent="flex-start">
                    <Stack
                        onClick={onHandleBackPress}
                        style={{
                            cursor: "pointer",
                            width: "max-content",
                        }}
                    >
                        <ArrowBackIcon
                            sx={{ fontSize: "2rem", color: "gray" }}
                        />
                    </Stack>
                </Stack>
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{
                        width: "100%",
                        height: "100%",
                        padding: "16px",
                    }}
                >
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            flex: 1,
                            width: "100%",
                        }}
                    >
                        {renderInputArea()}
                    </Stack>
                </Stack>
                <Stack justifyContent="flex-end">
                    <Stack
                        style={{
                            width: "2rem",
                        }}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};
export default SetPassword;
