import React, { useState } from "react";
import { TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Colors from "themes/colors";
import Text from "components/atoms/Text";
import Stack from "@mui/material/Stack";
import NormalAutocomplete from "components/atoms/NormalAutoComplete";
import ZigupSpinner from "./ZigupSpinner";

const FETCH_STATUS = {
    DEFAULT: -1,
    LOADING: 1,
    DONE: 2,
    FAILED: 3,
};

const EducationSpecializationAutoComplete = (props) => {
    const {
        isMobile,
        defaultValue,
        value,
        setValue,
        fetchData,
        fetchStatus,
        reload,
    } = props;
    const [onMouse, setOnMouse] = useState(false);
    const filter = createFilterOptions();

    const ReloadButtonStyle = {
        border: onMouse ? "1px solid #6837f2" : "1px solid #d4d6dd",
        width: "100%",
        height: "3rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Colors.white,
        cursor: "pointer",
        marginBottom: "1rem",
    };

    switch (fetchStatus) {
        case FETCH_STATUS.LOADING:
            return (
                <Stack style={{ ...ReloadButtonStyle, cursor: "default" }}>
                    <ZigupSpinner size={"1rem"} />
                </Stack>
            );
        case FETCH_STATUS.DONE:
            return (
                <NormalAutocomplete
                    disablePortal
                    freeSolo
                    clearOnBlur
                    id="educationSpecializationAutoComplete"
                    title="Specialization"
                    options={fetchData}
                    style={{ width: "100%", marginBottom: "1rem" }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        const isExisting = options.some(
                            (option) => inputValue === option.title
                        );
                        if (inputValue !== "" && !isExisting) {
                            filtered.push(inputValue);
                        }

                        return filtered;
                    }}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, _value) =>
                        value.includes(option)
                    }
                    defaultValue={defaultValue}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                />
            );
        case FETCH_STATUS.FAILED:
            return (
                <Stack
                    id="fetch-specialization-btn"
                    style={ReloadButtonStyle}
                    onClick={reload}
                    onMouseEnter={() => setOnMouse(true)}
                    onMouseLeave={() => setOnMouse(false)}
                >
                    <Text
                        style={
                            onMouse
                                ? {
                                      color: Colors.purple_blue,
                                      fontSize: "1rem",
                                  }
                                : {
                                      color: Colors.text_grey,
                                      fontSize: "1rem",
                                  }
                        }
                    >
                        Reload specialization
                    </Text>
                </Stack>
            );
        default:
            return null;
    }
};

export default EducationSpecializationAutoComplete;
