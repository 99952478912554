import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import Text from "components/atoms/Text";
import { useMediaQuery } from "react-responsive";
import Stack from "@mui/material/Stack";
import Colors from "themes/colors";
import styled from "styled-components";
import { EXPERIENCE_TYPE } from "../../../constants/constants";
import { Container, InputAdornment } from "@mui/material";
import NormalTextField from "components/atoms/NormalTextField";
import InputMask from "react-input-mask";
import { isRequired } from "utils/validator";
import ReactGA from "react-ga4";

const ExperienceButton = styled(Stack)`
    width: 50%;
    max-width: 300px;
    height: auto;
    min-height: 100px;
    max-height: 300px;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border: ${({ selected }) =>
        selected ? "1px solid #6837f2" : "1px solid #dedede"};
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex: 1;
`;
const ExperienceButtonTitle = styled(Text)`
    margin-vertical: 24px;
    font-size: 20px;
    font-weight: 700;
    color: ${(props) => (props.selected ? "#5e14e9" : "#646464")};
`;

const ExperienceButtonDesc = styled(Text)`
    color: ${(props) => (props.selected ? "#5e14e9" : "#646464")};
    text-align: center;
`;

const NextButton = styled(Stack)`
    width: ${({ mobile }) => (mobile ? "90%" : "60%")};
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
    background-color: ${({ disabled }) =>
        disabled ? `${Colors.grey}` : `${Colors.purple_blue}`};
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
    border-radius: 4px;
`;

const SignUpFirstPage = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const outletContext = useOutletContext();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [mobile, setMobile] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRe, setPasswordRe] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [isMatchPassword, setIsMatchPassword] = useState(false);
    const [name, setName] = useState("");
    const [isExperienced, setIsExperienced] = useState(EXPERIENCE_TYPE.UNKNOWN);
    const [pageText, setPageText] = useState({
        title: "Tell us about yourself",
        fresher: { title: "Fresher", desc: "Students, Graduates and Interns" },
        experienced: { title: "Experienced" },
    });

    useEffect(() => {
        if (!outletContext) navigate("/");
        ReactGA.event({
            category: "SignUp",
            action: "sign_up_first_page_start",
            label: "sign_up",
        });
    }, [outletContext]);

    useEffect(() => {
        const check =
            isRequired(name).valid &&
            isExperienced !== EXPERIENCE_TYPE.UNKNOWN &&
            isMatchPassword;
        setIsValid(!check);
        return () => {
            setIsValid(false);
        };
    }, [name, isExperienced, isMatchPassword]);

    useEffect(() => {
        const passwordRule =
            /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
        if (password.length > 0) {
            setIsValidPassword(passwordRule.test(password));
        }
        if (passwordRe.length > 0 && isValidPassword) {
            setIsMatchPassword(passwordRe === password);
        }
    }, [password, passwordRe]);

    const getAlertMessage = () => {
        if (password.length > 0) {
            if (isValidPassword) {
                return "";
            }
            return "Type 8 or more letter, number, and special character.";
        }
        return "";
    };

    const getPasswordReAlertMessage = () => {
        if (passwordRe.length > 0) {
            if (password.length > 0) {
                if (isMatchPassword) {
                    return "Passwords match";
                }
                return "Passwords don’t match";
            }
            return "Enter the password";
        }
        return "";
    };

    const goToNextPage = () => {
        ReactGA.event({
            category: "SignUp",
            action: "sign_up_first_page_next",
            label: "sign_up",
        });
        navigate("/sign-up/additional", {
            state: {
                mobileNumber: outletContext.mobileNumber,
                isExperienced,
                name,
                password,
                requestPath: state.requestPath,
            },
        });
    };

    return (
        <Container maxWidth="sm" sx={{ minHeight: "60vh" }}>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ margin: "1rem" }}
            >
                <Stack
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{ width: "100%" }}
                >
                    <Text style={{ fontSize: 24, marginBottom: 16 }}>
                        Basic Information ( 1 / 2 )
                    </Text>
                </Stack>
                <NormalTextField
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    title="Name"
                    placeholder="Your full name"
                />
                <InputMask
                    mask={
                        mobile.length === 11 ? "999-9999-9999" : "99999-99999"
                    }
                    value={state?.mobileNumber}
                    disabled={true}
                    onChange={(event) => setMobile(event.target.value)}
                    maskChar=""
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Stack
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Text
                                        style={{
                                            color: "gray",
                                            fontSize: 14,
                                        }}
                                    >
                                        +91
                                    </Text>
                                </Stack>
                            </InputAdornment>
                        ),
                    }}
                >
                    {(inputProps) => (
                        <NormalTextField
                            {...inputProps}
                            title="Mobile"
                            fullWidth
                            placeholder="e.g. 9999999999"
                            containerStyle={{ marginBottom: "1rem" }}
                            disabled={true}
                        />
                    )}
                </InputMask>
                <NormalTextField
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    type="password"
                    alertMessage={getAlertMessage()}
                    alertMessageColor="red"
                    title="Password"
                    placeholder="Password"
                />

                <NormalTextField
                    value={passwordRe}
                    onChange={(event) => setPasswordRe(event.target.value)}
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    type="password"
                    alertMessage={getPasswordReAlertMessage()}
                    alertMessageColor={
                        isMatchPassword ? Colors.success : Colors.alert
                    }
                    title="Re-enter Password"
                    placeholder="Re-enter Password"
                />
                <Stack
                    direction={"row"}
                    justifyContent="space-around"
                    alignItems="flex-start"
                    style={{
                        width: "100%",
                        minHeight: "25vh",
                    }}
                >
                    <ExperienceButton
                        mobile={isMobile ? 1 : 0}
                        selected={isExperienced === EXPERIENCE_TYPE.FRESHER}
                        onClick={() =>
                            setIsExperienced(EXPERIENCE_TYPE.FRESHER)
                        }
                    >
                        <ExperienceButtonTitle
                            selected={isExperienced === EXPERIENCE_TYPE.FRESHER}
                        >
                            {pageText.fresher.title}
                        </ExperienceButtonTitle>
                        <ExperienceButtonDesc
                            selected={isExperienced === EXPERIENCE_TYPE.FRESHER}
                        >
                            {pageText.fresher.desc}
                        </ExperienceButtonDesc>
                    </ExperienceButton>
                    <div style={{ width: 16 }} />
                    <ExperienceButton
                        mobile={isMobile ? 1 : 0}
                        selected={isExperienced === EXPERIENCE_TYPE.EXPERIENCED}
                        onClick={() =>
                            setIsExperienced(EXPERIENCE_TYPE.EXPERIENCED)
                        }
                    >
                        <ExperienceButtonTitle
                            selected={
                                isExperienced === EXPERIENCE_TYPE.EXPERIENCED
                            }
                        >
                            {pageText.experienced.title}
                        </ExperienceButtonTitle>
                    </ExperienceButton>
                </Stack>
                <NextButton
                    id="sign-up-check-experience-apply-btn"
                    mobile={isMobile ? 1 : 0}
                    onClick={goToNextPage}
                    disabled={isValid}
                    style={{ width: "100%" }}
                >
                    <Text style={{ color: "white", fontSize: "1rem" }}>
                        NEXT
                    </Text>
                </NextButton>
            </Stack>
        </Container>
    );
};
export default SignUpFirstPage;
