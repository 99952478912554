import React from "react";
import Text from "components/atoms/Text";
import { Stack } from "@mui/material";

import styled from "styled-components";
import frameIcon from "assets/icons/icon-frame.png";
import CompanyLogo from "components/atoms/CompanyLogo";

const CountText = styled(Text)`
    color: #646464;
`;

const ViewedCompanyList = ({ disabled, onPress, item, isMobile }) => {
    const { companiesOfViewerLength, companiesOfViewer } = item;
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={0.5}
            disabled={disabled}
            onClick={onPress}
            sx={{ cursor: "pointer" }}
        >
            <img
                src={frameIcon}
                alt="frameIcon"
                width={isMobile ? 16 : 24}
                height={isMobile ? 16 : 24}
            />
            <Stack direction="row" alignItems="center" spacing={0.25}>
                {companiesOfViewer
                    ? companiesOfViewer.map((company) => (
                          <CompanyLogo
                              style={{
                                  borderRadius: 8,
                                  backgroundColor: "#f6f6f6",
                                  marginRight: 2,
                              }}
                              width={isMobile ? 16 : 24}
                              height={isMobile ? 16 : 24}
                              source={company.logoURL}
                              key={company.name}
                          />
                      ))
                    : null}
                {companiesOfViewerLength > 3 ? (
                    <CountText>+{companiesOfViewerLength - 3}</CountText>
                ) : null}
                {companiesOfViewerLength === 0 ? (
                    <CountText>0</CountText>
                ) : null}
            </Stack>
        </Stack>
    );
};

export default ViewedCompanyList;
