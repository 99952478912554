import React, { useContext, useEffect, useRef, useState } from "react";
import Text from "components/atoms/Text";
import { useMediaQuery } from "react-responsive";
import Stack from "@mui/material/Stack";
import Colors from "themes/colors";
import { useLocation, useNavigate } from "react-router";
import NormalTextField from "components/atoms/NormalTextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { isRequired } from "utils/validator";
import InputMask from "react-input-mask";
import styled from "styled-components";
import SkillSetAutocomplete from "components/organisms/SkillSetAutocomplete";
import FileUploadButton from "components/organisms/FileUploadButton";
import Checkbox from "@mui/material/Checkbox";
import { apply } from "communication/jd";
import TechnicalAreaSelector from "components/organisms/TechnicalAreaSelector";
import ZigupSpinner from "../../organisms/ZigupSpinner";
import { AuthContext } from "../../../context/AuthContext";

const ApplyButton = styled.div`
    width: ${({ isMobile }) => (isMobile ? "90vw" : "60vw")};
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
    background-color: ${({ disabled }) =>
        disabled ? `${Colors.grey}` : `${Colors.purple_blue}`};
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

const ReferralApplyPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const [name, setName] = useState("");
    const [organisation, setOrganisation] = useState("");
    const [designation, setDesignation] = useState("");
    const [experience, setExperience] = useState("");
    const [skillSet, setSkillSet] = useState([]);
    const [mobile, setMobile] = useState("");
    const [jobId, setJobId] = useState();
    const [brief, setBrief] = useState();
    const [file, setFile] = useState(undefined);
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [allowJobSuggestion, setAllowJobSuggestion] = useState(true);
    const agreeReceiveCheckBoxRef = useRef();
    const [techArea, setTechArea] = useState({
        area: { value: "" },
        role: { value: [] },
    });
    const [techAreaOthers, setTechAreaOthers] = useState();
    const { userState } = useContext(AuthContext);

    const scrollToTop = () => window.scrollTo(0, 0);

    useEffect(() => {
        if (userState.auth && Object.keys(userState.info).length) {
            setName(userState.info?.name);
            setMobile(userState.info?.phoneNumber);
            setOrganisation(userState.info?.company);
            setDesignation(userState.info?.designation);
            setExperience(userState.info?.relevantExperience);
            setExperience(userState.info?.relevantExperience);
            if (userState.info?.customInfo.area.custom) {
                setTechArea({
                    area: { value: userState.info?.customInfo.area.custom.key },
                });
                setTechAreaOthers(userState.info?.customInfo.area.custom.value);
            } else {
                setTechArea({
                    area: userState.info?.customInfo.area,
                    role: userState.info?.customInfo.role,
                });
            }
            setSkillSet(userState.info?.skillset.all);
        }
    }, [userState.auth, userState.info]);

    useEffect(() => {
        if (!state) navigate("/");
        scrollToTop();
    }, [state]);

    useEffect(() => {
        const check =
            isRequired(name).valid &&
            isRequired(mobile).valid &&
            isRequired(organisation).valid &&
            isRequired(designation).valid &&
            isRequired("" + experience).valid &&
            skillSet.length > 0 &&
            file !== undefined &&
            (techArea.area.value === "others"
                ? techAreaOthers
                : techArea.role.value.length > 0);
        setIsValid(check);
        return () => {
            setIsValid(false);
        };
    }, [
        name,
        mobile,
        organisation,
        designation,
        experience,
        skillSet,
        file,
        techArea,
        techAreaOthers,
    ]);

    const applyProc = async () => {
        const formData = new FormData();

        let params = {
            forumReferralId: state.referralId,
            name: name,
            company: organisation,
            designation: designation,
            relevantExperience: experience,
            role:
                techArea.area.value === "others"
                    ? techAreaOthers
                    : techArea.role.value[0],
            skillset: skillSet,
            phoneNumber: mobile.replace(/(-)/g, ""),
            allowJobSuggestion: allowJobSuggestion,
            file: formData,
        };

        if (jobId) {
            params = { ...params, jobId: jobId };
        }

        if (brief) {
            params = { ...params, aboutYourself: brief };
        }

        formData.append("file", file);
        formData.append("params", JSON.stringify(params));

        try {
            setIsLoading(true);
            const res = await apply(formData);
            setIsLoading(false);
            if (res) {
                navigate("./../done", {
                    state: {
                        result: "success",
                        email: state.referral.writer.email,
                    },
                });
            } else {
                alert("Failed to apply. please try again");
            }
        } catch (e) {
            setIsLoading(false);
            switch (e.response?.status) {
                case 409:
                    navigate("./../done", {
                        state: {
                            result: "duplicate",
                            email: state.referral.writer.email,
                        },
                    });
                    break;
                default:
                    alert("Failed to apply. please try again");
                    break;
            }
        }
    };

    const sectionStyle = {
        padding: "1rem",
        width: isMobile ? "90vw" : "60vw",
        height: "max-content",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    return state ? (
        <Stack
            direction="column"
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
        >
            <Stack direction={"column"} style={sectionStyle}>
                <NormalTextField
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    title="Name"
                    placeholder="Your full name"
                />

                <NormalTextField
                    value={organisation}
                    onChange={(event) => setOrganisation(event.target.value)}
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    title="Current/Last Organisation"
                    placeholder="Name of the organisation"
                />
                <NormalTextField
                    value={designation}
                    onChange={(event) => setDesignation(event.target.value)}
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    title="Designation"
                    placeholder="Your designation"
                />
                <div style={{ marginBottom: "1rem" }}>
                    <FormControl
                        sx={
                            isMobile
                                ? { m: 1, width: "90vw" }
                                : { m: 1, width: "60vw" }
                        }
                    >
                        <InputLabel id="referral-experience-selector-label">
                            Years of experience
                        </InputLabel>
                        <Select
                            id="referral-experience-selector"
                            value={experience}
                            onChange={(event) =>
                                setExperience(event.target.value)
                            }
                            fullWidth={true}
                            label="Years of experience"
                        >
                            <MenuItem value={"0"}>
                                <em>No experience</em>
                            </MenuItem>
                            <MenuItem value={"1"}>1 year or less</MenuItem>
                            <MenuItem value={"2"}>2 years</MenuItem>
                            <MenuItem value={"3"}>3 years</MenuItem>
                            <MenuItem value={"4"}>4 years</MenuItem>
                            <MenuItem value={"5"}>5 years</MenuItem>
                            <MenuItem value={"6"}>6 years</MenuItem>
                            <MenuItem value={"7"}>7 years</MenuItem>
                            <MenuItem value={"8"}>8 years</MenuItem>
                            <MenuItem value={"9"}>9 years</MenuItem>
                            <MenuItem value={"10"}>10 years or more</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <TechnicalAreaSelector
                    isMobile={isMobile}
                    techArea={techArea}
                    setTechArea={setTechArea}
                    techAreaOthers={techAreaOthers}
                    setTechAreaOthers={setTechAreaOthers}
                />
                <SkillSetAutocomplete
                    value={skillSet}
                    setValue={setSkillSet}
                    isMobile={isMobile}
                    style={{ marginBottom: "1rem" }}
                />
                <InputMask
                    mask="99999-99999"
                    value={mobile}
                    disabled={false}
                    onChange={(event) => setMobile(event.target.value)}
                    maskChar=""
                >
                    {() => (
                        <NormalTextField
                            title="Mobile"
                            fullWidth
                            placeholder="e.g. 9999999999"
                            containerStyle={{ marginBottom: "1rem" }}
                        />
                    )}
                </InputMask>

                <NormalTextField
                    value={jobId ?? ""}
                    onChange={(event) => setJobId(event.target.value)}
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    title="Job ID(Optional)"
                    placeholder="Write Job IDs from company career page"
                />
                <NormalTextField
                    value={brief ?? ""}
                    onChange={(event) => setBrief(event.target.value)}
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    title="Brief about yourself(Optional)"
                    placeholder="Brief about yourself"
                    multiline
                    rows={4}
                />
            </Stack>
            <Stack style={{ width: isMobile ? "90vw" : "60vw" }}>
                <FileUploadButton
                    isMobile={isMobile}
                    value={file}
                    setValue={setFile}
                />
            </Stack>
            <ApplyButton
                id="referral-apply-proc-btn"
                onClick={applyProc}
                disabled={!isValid || isLoading}
                isMobile={isMobile}
                style={
                    isLoading
                        ? {
                              border: "1px solid #6837f2",
                              backgroundColor: "white",
                          }
                        : null
                }
            >
                {isLoading ? (
                    <ZigupSpinner size={"2rem"} />
                ) : (
                    <Text style={{ color: "white", fontSize: 22 }}>Apply</Text>
                )}
            </ApplyButton>
            <Stack
                id="referral-agree-check-btn"
                direction="row"
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
                style={
                    isMobile
                        ? {
                              width: "90vw",
                              cursor: "pointer",
                              marginLeft: "-1rem",
                              marginBottom: "1rem",
                          }
                        : {
                              width: "60vw",
                              cursor: "pointer",
                              marginLeft: "-1rem",
                              marginBottom: "1rem",
                          }
                }
                onClick={() => {
                    agreeReceiveCheckBoxRef.current?.click();
                }}
            >
                <Checkbox
                    checked={!!allowJobSuggestion}
                    onChange={(e) => {
                        setAllowJobSuggestion(e.target.checked);
                    }}
                    inputRef={agreeReceiveCheckBoxRef}
                    style={{ pointerEvents: "none" }}
                />
                <Text
                    style={
                        isMobile
                            ? { fontSize: "0.6rem", fontColor: "#646464" }
                            : { fontSize: "1rem", fontColor: "#646464" }
                    }
                >
                    Receive similar job suggestions on my mobile number
                </Text>
            </Stack>
        </Stack>
    ) : null;
};

export default ReferralApplyPage;
