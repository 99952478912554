import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Colors from "themes/colors";
import Text from "components/atoms/Text";
import Stack from "@mui/material/Stack";
import { fetchCompanies } from "../../communication/common";
import noLogo from "assets/icons/no-logo.png";
import NormalAutocomplete from "components/atoms/NormalAutoComplete";
import ZigupSpinner from "./ZigupSpinner";

const FETCH_STATUS = {
    DEFAULT: -1,
    LOADING: 1,
    DONE: 2,
    FAILED: 3,
};

const CompanyAutocomplete = (props) => {
    const { value, setValue, title, style, placeholder } = props;
    const [companies, setCompanies] = useState([]);
    const [fetchStatus, setFetchStatus] = useState(FETCH_STATUS.DEFAULT);
    const [onMouse, setOnMouse] = useState(false);
    const filter = createFilterOptions({ limit: 50 });

    const ReloadButtonStyle = {
        border: onMouse ? "1px solid #6837f2" : "1px solid #d4d6dd",
        width: "100%",
        height: "3rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Colors.white,
        cursor: "pointer",
        marginBottom: "1rem",
    };

    const fetchData = async () => {
        try {
            setFetchStatus(FETCH_STATUS.LOADING);
            const res = await fetchCompanies();
            setFetchStatus(FETCH_STATUS.DONE);
            setCompanies(res.companies);
        } catch (e) {
            setFetchStatus(FETCH_STATUS.FAILED);
        }
    };

    useEffect(() => {
        fetchData();
        return () => setCompanies([]);
    }, []);

    switch (fetchStatus) {
        case FETCH_STATUS.LOADING:
            return (
                <Stack style={{ ...ReloadButtonStyle, cursor: "default" }}>
                    <ZigupSpinner size={"1rem"} />
                </Stack>
            );
        case FETCH_STATUS.DONE:
            return (
                <NormalAutocomplete
                    value={value || null}
                    freeSolo
                    clearOnBlur
                    disablePortal
                    title={title || ""}
                    id="company"
                    options={companies}
                    style={{
                        width: "100%",
                        marginBottom: "1rem",
                        ...style,
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={placeholder || "Type Company name"}
                        />
                    )}
                    onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                            setValue(newValue);
                            return;
                        }
                        setValue(newValue.name);
                    }}
                    getOptionLabel={(option) => {
                        if (typeof option === "string") {
                            return option;
                        }
                        return option.name;
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                            (option) => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                            filtered.push({
                                inputValue,
                                name: inputValue,
                                prefix: "Click to add ",
                            });
                        }

                        return filtered;
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option._id}>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    {option.logoURL ? (
                                        <img
                                            src={option.logoURL}
                                            alt="logo"
                                            width={40}
                                            height={40}
                                            loading="lazy"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = noLogo;
                                            }}
                                        />
                                    ) : null}
                                    <Text>
                                        {(option.prefix ? option.prefix : "") +
                                            option.name}
                                    </Text>
                                </Stack>
                            </li>
                        );
                    }}
                />
            );
        case FETCH_STATUS.FAILED:
            return (
                <Stack
                    id="fetch-company-btn"
                    style={ReloadButtonStyle}
                    onClick={fetchData}
                    onMouseEnter={() => setOnMouse(true)}
                    onMouseLeave={() => setOnMouse(false)}
                >
                    <Text
                        style={
                            onMouse
                                ? {
                                      color: Colors.purple_blue,
                                      fontSize: "1rem",
                                  }
                                : {
                                      color: Colors.text_grey,
                                      fontSize: "1rem",
                                  }
                        }
                    >
                        Reload companies
                    </Text>
                </Stack>
            );
        default:
            return null;
    }
};

export default CompanyAutocomplete;
