import React from "react";
import Text from "components/atoms/Text";
import Colors from "themes/colors";
import Footer from "components/organisms/Footer";
import GlobalHeader from "components/organisms/GlobalHeader";
import { Container, Stack } from "@mui/material";
import NotFoundImage from "assets/images/illust-404@2x.png";
import ZigupButton from "components/atoms/ZigupButton";
import { useMediaQuery } from "react-responsive";

const NotFound = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

    return (
        <Stack
            justifyContent="space-between"
            sx={{
                flex: 1,
                display: "flex",
                height: "100vh",
            }}
        >
            <GlobalHeader />
            <Container maxWidth="lg">
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                    sx={{ padding: "2rem" }}
                >
                    <img
                        src={NotFoundImage}
                        height={isMobile ? "250" : "300"}
                    />
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                    >
                        <Text
                            style={{
                                fontFamily: "Lato",
                                fontWeight: 700,
                                fontSize: isMobile ? "1.8rem" : "2.125rem",
                            }}
                        >
                            Page Not Found
                        </Text>
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            spacing={0}
                        >
                            <Text
                                style={{
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                    color: "#aaa",
                                    textAlign: "center",
                                }}
                            >
                                We’re sorry, the page you requested could not be
                                found.
                            </Text>
                            <Text
                                style={{
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                    color: "#aaa",
                                    textAlign: "center",
                                }}
                            >
                                Please go back to the homepage.
                            </Text>
                        </Stack>
                    </Stack>
                    <a
                        href="/"
                        style={{
                            textDecoration: "none",
                            color: "black",
                        }}
                    >
                        <ZigupButton
                            id="go-home-button"
                            buttonText={"GO HOME"}
                            containerStyle={{
                                height: 40,
                                width: 150,
                                borderRadius: 20,
                                boxSizing: "border-box",
                                backgroundColor: Colors.button_background_404,
                            }}
                            buttonTextStyle={{
                                color: Colors.white,
                                fontWeight: 700,
                            }}
                        />
                    </a>
                </Stack>
            </Container>
            <Footer />
        </Stack>
    );
};

export default NotFound;
