import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import SalaryCard from "./SalaryCard";
import { recommend } from "../../../communication/salary";
import Text from "../../atoms/Text";
import Colors from "themes/colors";
import { Logger } from "utils";

const RecommendSalaries = (props) => {
    const { id, isMobile, maxCount = 10 } = props;
    const [salaries, setSalaries] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(async () => {
        try {
            if (!id) return;
            setIsLoading(true);
            const res = await recommend(id, { limit: maxCount });
            setSalaries(res.salaries || []);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            Logger.log("e", e);
        }
    }, [id]);

    if (!id || salaries === undefined || isLoading) {
        return (
            <Stack justifyContent="center" alignItems="center">
                <Grid container spacing={2}>
                    {new Array(maxCount).fill()?.map((salary, index) => (
                        <Grid item xs={12} key={index + "recommend_tmp"}>
                            <SalaryCard showWriter={false} />
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        );
    }
    if (salaries.length < 1) {
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                style={{ height: "20vh" }}
            >
                <Text style={{ color: Colors.light_grey }}>
                    No recommended posts yet
                </Text>
            </Stack>
        );
    } else {
        return (
            <Stack justifyContent="center" alignItems="center">
                <Grid container spacing={2}>
                    {salaries?.map((salary, index) => (
                        <Grid item xs={12} key={index + "recommend"}>
                            <SalaryCard salary={salary} showWriter={false} />
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        );
    }
};

export default RecommendSalaries;
