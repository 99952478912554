import qs from "qs";

import { RetryAxiosInstance } from "./retryAxios";
import * as Api from "./api";

import { getCookie } from "../utils/cookieManager";
import { COOKIE } from "../constants/constants";
import { getAccessToken } from "../context/AuthContext";

function getBaseHeader() {
    return {
        "Access-Control-Allow-Origin": "http://localhost:3000",
        // 'Access-Control-Allow-Origin': 'http://13.125.102.87',
        "Access-Control-Allow-Headers":
            "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
    };
}

function getSimpleHeader() {
    return getBaseHeader();
}

function getWithoutTokenHeader() {
    return {
        ...getBaseHeader(),
    };
}
function getHeaderWithRefreshToken() {
    return {
        ...getBaseHeader(),
        "refresh-token": getCookie(COOKIE.REFRESH_TOKEN),
    };
}

function getHeaderWithAccessToken() {
    return {
        ...getBaseHeader(),
        authorization: getAccessToken(),
    };
}

function getHeaderFileUpload() {
    return {
        ...getBaseHeader(),
        "Content-Type": "multipart/form-data",
    };
}

function getHeaderFileUploadWithAccessToken() {
    return {
        ...getHeaderFileUpload(),
        authorization: getAccessToken(),
    };
}

async function getHeader(type) {
    switch (type) {
        case Api.Types.Simple:
            return getSimpleHeader();
        case Api.Types.WithoutToken:
            return getWithoutTokenHeader();
        case Api.Types.WithAccessToken:
            return getHeaderWithAccessToken();
        case Api.Types.FileUpload:
            return getHeaderFileUpload();
        case Api.Types.WithRefreshToken:
            return getHeaderWithRefreshToken();
        case Api.Types.FileUploadWithAccessToken:
            return getHeaderFileUploadWithAccessToken();
        default:
            break;
    }
    return null;
}

async function getUrlAndHeaders(_url, params, type, fileDownload) {
    const headers = await getHeader(type);
    let url = "";
    if (fileDownload) {
        url = CDN(_url);
    } else {
        const endpoint = Api.Urls.prefix + _url;
        url = process.env.REACT_APP_API_HOST + endpoint;
    }
    return { url, headers };
}

async function addQueryString(url, params) {
    const paramsString = qs.stringify(params, { encodeValuesOnly: true });
    if (paramsString.length > 0) {
        return `${url}?${paramsString}`;
    }
    return url;
}

// const callback = {
//     onUploadProgress: (progressEvent) => {
//         setUploadProofProgress(
//             Math.round(
//                 (progressEvent.loaded * 100) / progressEvent.total
//             )
//         );
//     },
// };

async function _request(
    method,
    _url,
    params,
    type,
    uploadProgressCallback,
    fileDownload
) {
    const retryAxiosInstance = RetryAxiosInstance();
    const headerInfo = await getUrlAndHeaders(
        _url,
        params,
        type,
        fileDownload || false
    );

    let { url } = headerInfo;
    const { headers } = headerInfo;
    if (method === "GET") {
        url = await addQueryString(url, params);
    }

    const options = {
        method,
        url,
        headers,
        data: method !== "GET" ? params : null,
        fileDownload,
    };
    return retryAxiosInstance(options).then(function (response) {
        return response.data;
    });
}

export function get(
    url,
    params = {},
    type = Api.Types.WithAccessToken,
    fileDownload
) {
    return _request("GET", url, params, type, null, fileDownload);
}

export function post(
    url,
    params = {},
    type = Api.Types.WithAccessToken,
    uploadProgressCallback,
    fileDownload
) {
    if (uploadProgressCallback !== undefined) {
        return _request(
            "POST",
            url,
            params,
            type,
            uploadProgressCallback,
            fileDownload
        );
    }
    return _request("POST", url, params, type);
}

export function patch(url, params = {}, type = Api.Types.WithAccessToken) {
    return _request("PATCH", url, params, type);
}

export function put(url, params = {}, type = Api.Types.WithAccessToken) {
    return _request("PUT", url, params, type);
}

export function deleteProc(url, params = {}, type = Api.Types.WithAccessToken) {
    return _request("DELETE", url, params, type);
}

export const CDN = (url) => {
    if (!url) return null;
    return `${process.env.REACT_APP_CDN_HOST}raw${url}`;
};

// function thumbnail(url, type = Api.ThumbnailTypes.Profile) {
//     const basePath = `${process.env.REACT_APP_CDN_HOST}thumb_${type}`;

//     if (!url) {
//         if (type === Api.ThumbnailTypes.Push) {
//             return `${basePath}/default.png`;
//         }
//         return null;
//     }
//     return basePath + url;
// }
