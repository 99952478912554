import { Stack } from "@mui/material";
import Typo from "components/atoms/Typo";
import { AuthContext } from "context/AuthContext";
import React, { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import Colors from "themes/colors";
import { showSignUpModal } from "./GlobalHeader";
import ItemCard from "./my/ItemCard";

const ContributeZigup = (props) => {
    const { title, description } = props;
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const { userState } = useContext(AuthContext);

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                width: "100%",
                height: "100%",
            }}
        >
            <Stack
                justifyContent={"flex-start"}
                sx={{
                    width: "100%",
                }}
                spacing={1}
            >
                <Typo.H3
                    style={{
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "1rem",
                    }}
                >
                    {title}
                </Typo.H3>
                {description ? (
                    <Typo.Body1
                        style={{
                            fontSize: "0.875rem",
                            fontFamily: "Roboto",
                            fontWeight: "normal",
                            color: Colors.light_grey_3,
                        }}
                    >
                        {description}
                    </Typo.Body1>
                ) : null}
            </Stack>
            <Stack
                direction={isMobile ? "column" : "row"}
                alignItems="flex-start"
                justifyContent="center"
                spacing={2}
                sx={{
                    height: "100%",
                    width: "100%",
                }}
            >
                <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                    sx={{
                        flex: 1,
                        display: "flex",
                        backgroundColor: Colors.background_contribute_card,
                        boxSizing: "border-box",
                        borderRadius: "0.25rem",
                        height: "100%",
                        width: "100%",
                    }}
                >
                    <ItemCard
                        id="contribute-add-salary-btn"
                        content={
                            "Add salary of your current or past company anonymously"
                        }
                        buttonText={"Add a salary"}
                        onClickHandler={() => {
                            userState.auth
                                ? navigate("/salary/add")
                                : showSignUpModal("/salary/add");
                        }}
                        containerStyle={{
                            backgroundColor: Colors.background_contribute_card,
                            width: "100%",
                            padding: isMobile ? "2.25rem" : "2.25rem 1rem",
                        }}
                    />
                </Stack>
                <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                    sx={{
                        flex: 1,
                        display: "flex",
                        backgroundColor: Colors.background_contribute_card,
                        boxSizing: "border-box",
                        borderRadius: "0.25rem",
                        height: "100%",
                        width: "100%",
                    }}
                >
                    <ItemCard
                        id="contribute-discuss-btn"
                        content={
                            "Do you have any questions about the company?\nAsk questions you have in mind."
                        }
                        buttonText={`Discuss with others`}
                        onClickHandler={() =>
                            navigate(`/forum/list?type=discussion&page=1`)
                        }
                        containerStyle={{
                            backgroundColor: Colors.background_contribute_card,
                            width: "100%",
                            padding: isMobile ? "2.25rem" : "2.25rem 1rem",
                        }}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default ContributeZigup;
