import React, { useContext } from "react";
import Modal from "react-modal";
import { Stack } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import Typo from "../atoms/Typo";
import CVList from "./my/CVList";
import { AuthContext } from "../../context/AuthContext";

const CVModal = ({ open, closeModal, handleSelect }) => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const { userState } = useContext(AuthContext);

    const customModalStyles = {
        content: {
            width: "max-content",
            minWidth: "30vw",
            maxWidth: isMobile ? "80vw" : "50vw",
            height: "max-content",
            minHeight: "15vh",
            maxHeight: isMobile ? "80vh" : "70vh",
            backgroundColor: "white",
            padding: "1rem",
            border: "0",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            boxSizing: "border-box",
        },
        overlay: {
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 10,
        },
    };
    return (
        <Modal
            isOpen={open}
            style={customModalStyles}
            contentLabel="Applicant Modal"
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            ariaHideApp={false}
        >
            <Stack
                direction="column"
                spacing={2}
                alignItems="flex-start"
                justifyContent="center"
                style={{
                    width: "100%",
                    height: "max-content",
                }}
            >
                <Typo.Body1
                    style={{
                        fontSize: "1.25rem",
                        margin: 0,
                    }}
                >
                    {`My CVs ${
                        userState.info.cvs
                            ? `( ${userState.info.cvs.length} / 5 )`
                            : ""
                    }`}
                </Typo.Body1>
                <CVList type={"selector"} handleSelect={handleSelect} />
            </Stack>
        </Modal>
    );
};
export default CVModal;
