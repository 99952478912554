import React from "react";
import Modal from "react-modal";
import { Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Typo from "../atoms/Typo";
import Text from "../atoms/Text";
import Colors from "themes/colors";
import AppStoreImage from "assets/images/appstore-badge.png";
import PlayStoreImage from "assets/images/playstore-badge.png";

const DownloadAppModal = ({ open, close }) => {
    const customModalStyles = {
        content: {
            width: "480px",
            maxWidth: "80vw",
            height: "max-content",
            maxHeight: "60vh",
            backgroundColor: "white",
            border: "0",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
        },
        overlay: {
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
    };

    return (
        <Modal
            isOpen={open}
            style={customModalStyles}
            contentLabel="Applicant Modal"
            onRequestClose={close}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc
            ariaHideApp={false}
        >
            <Stack
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={4}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    style={{
                        width: "100%",
                    }}
                >
                    <div onClick={close} style={{ cursor: "pointer" }}>
                        <CloseIcon size={12} />
                    </div>
                </Stack>
                <Stack>
                    <Typo.H2>
                        Download{" "}
                        <span style={{ color: Colors.purple_blue }}>Zigup</span>{" "}
                        App!
                    </Typo.H2>
                </Stack>
                <Stack>
                    <Text style={{ fontSize: "1rem", textAlign: "center" }}>
                        Referrals / DM / Forum / Offers / Group Chat
                    </Text>
                </Stack>
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-evenly"
                    alignItems="center"
                    sx={{ padding: "16px" }}
                >
                    <a href="https://play.google.com/store/apps/details?id=in.zigup">
                        <img
                            src={PlayStoreImage}
                            alt="Playstore"
                            height="44"
                            width="auto"
                        />
                    </a>
                    <a href="https://apps.apple.com/in/app/zigup/id1562615317?l=en">
                        <img
                            src={AppStoreImage}
                            alt="Appstore"
                            height="44"
                            width="auto"
                        />
                    </a>
                </Stack>
            </Stack>
        </Modal>
    );
};
export default DownloadAppModal;
