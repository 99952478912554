import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { Stack } from "@mui/material";
import Typo from "components/atoms/Typo";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import SearchTextField from "components/atoms/SearchTextField";
import { list } from "communication/salary";
import GlobalHeader from "components/organisms/GlobalHeader";
import Footer from "components/organisms/Footer";
import Colors from "themes/colors";
import Pagination from "@mui/material/Pagination";
import QueryString from "qs";
import BackgroundWebImage from "assets/images/company/bg-company.png";
import BackgroundMobileImage from "assets/images/company/bg-company-mobile.png";
import BannerWebImage from "assets/images/company/banner-job.png";
import BannerMobileImage from "assets/images/company/banner-job-mobile.png";
import { Helmet } from "react-helmet-async";
import { capitalize } from "utils/TextUtils";
import { SEARCH_TYPE } from "constants/constants";
import communityImage from "assets/icons/company/icon-community@2x.png";
import graphImage from "assets/icons/company/icon-graph@2x.png";
import jobsImage from "assets/icons/company/icon-jobs@2x.png";
import Text from "components/atoms/Text";
import ItemCard from "components/organisms/my/ItemCard";
import { AuthContext } from "context/AuthContext";
import amazonLogo from "assets/images/main/amazon-horizontal.png";
import SearchCard from "components/organisms/search/SearchCard";
import CompanyCard from "components/organisms/company/CompanyCard";
import ContributeZigup from "components/organisms/ContributeZigup";
import { companyCuratedList, companyList } from "communication/company";
import { Logger } from "utils";
import CompanyLongCard from "components/organisms/company/CompanyLongCard";

const CompanyListPage = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const { keyword, page } = QueryString.parse(location.search, {
        ignoreQueryPrefix: true,
    });
    const { userState } = useContext(AuthContext);
    const [pagination, setPagination] = useState({
        hasNext: false,
        lastIndex: 0,
        totalCount: 0,
    });
    const [paginationPage, setPaginationPage] = useState(1);
    const [companies, setCompanies] = useState([]);
    const [curatedCompanies, setCuratedCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const limit = 40;
    const headerData = [
        {
            img: graphImage,
            alt: "Graph Image",
            description: "Graphs with salary data",
        },
        {
            img: jobsImage,
            alt: "Jobs Image",
            description: "Jobs from official sites",
        },
        {
            img: communityImage,
            alt: "Community Image",
            description: "Share community opinions",
        },
    ];

    useEffect(async () => {
        // window.scrollTo(0, 0);

        setPaginationPage(Number(page || 1));
        fetchCompanies();
        fetchCuratedCompanies();
    }, [keyword, page]);

    const fetchCompanies = async () => {
        const lastIndex =
            paginationPage === 1 ? 0 : (paginationPage - 1) * limit - 1;
        try {
            const res = await companyList({ keyword, lastIndex, limit });
            setCompanies(res.companies.companies);
            setPagination(res.companies.pagination);
        } catch (e) {
            Logger.error(e);
        }
    };

    const fetchCuratedCompanies = async () => {
        try {
            setIsLoading(true);
            const res = await companyCuratedList();
            setCuratedCompanies(res.companies);
        } catch (e) {
            Logger.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const onPageChanged = async (event, value) => {
        if (keyword) {
            navigate(`/company/list/?keyword=${keyword}&page=${value}`);
        } else {
            navigate(`/company/list/?page=${value}`);
        }
    };

    const renderHeader = () => {
        return (
            <Stack
                alignItems="center"
                justifyContent="center"
                spacing={1}
                sx={{
                    display: "flex",
                    width: "100%",
                    height: "max-content",
                    minHeight: isMobile ? "300px" : "26.875rem",
                    boxSizing: "border-box",
                    padding: "2.25rem",
                    backgroundImage: `url(${
                        isMobile ? BackgroundMobileImage : BackgroundWebImage
                    })`,
                    backgroundSize: "cover",
                }}
            >
                <Typo.H1
                    style={{
                        fontFamily: "Lato",
                        fontSize: isMobile ? "1.5rem" : "2.125rem",
                        color: Colors.white,
                    }}
                >
                    Discover Companies
                </Typo.H1>
                <Text
                    style={{
                        fontSize: "1rem",
                        color: "#dbd3fd",
                        whiteSpace: "pre-line",
                        textAlign: "center",
                    }}
                >
                    Find Companies you're looking for, get salary and job
                    information about company,{isMobile ? "" : "\n"} discuss
                    anything within this company
                </Text>
                <Container maxWidth="lg" sx={{ paddingTop: "1rem" }}>
                    <Stack
                        direction={isMobile ? "column" : "row"}
                        alignItems="center"
                        justifyContent="center"
                        spacing={isMobile ? 0 : 2}
                    >
                        {headerData.map((data, index) => (
                            <Stack
                                key={"header" + index}
                                direction={isMobile ? "row" : "column"}
                                spacing={2}
                                alignItems="center"
                                justifyContent={
                                    isMobile ? "flex-start" : "center"
                                }
                                sx={{
                                    width: "23.25rem",
                                    height: isMobile
                                        ? "max-content"
                                        : "8.75rem",
                                    backgroundColor: "#ffffff33",
                                    borderRadius: isMobile ? 0 : "0.25rem",
                                    padding: "0.75rem",
                                    boxSizing: "border-box",
                                }}
                            >
                                <img
                                    src={data.img}
                                    height={isMobile ? 36 : 60}
                                    alt={data.alt}
                                />
                                <Text
                                    style={{
                                        color: "#ede9ff",
                                        textAlign: "center",
                                    }}
                                >
                                    {data.description}
                                </Text>
                            </Stack>
                        ))}
                    </Stack>
                </Container>
            </Stack>
        );
    };

    const renderSearchBar = () => {
        return (
            <SearchTextField
                showTags={false}
                placeholder="Search with Company Name"
                defaultType={SEARCH_TYPE.COMPANY.value}
                prevSearchKeyword={keyword}
            />
        );
    };

    const renderContribute = () => {
        return keyword ? null : (
            <ContributeZigup title="Contribute to Zigup Community" />
        );
    };

    const renderCuratedCompanies = () => {
        const _data = keyword
            ? curatedCompanies.filter((item) =>
                  item.name.toLowerCase().includes(keyword.toLowerCase())
              )
            : curatedCompanies;
        const firstData = _data.length > 9 ? _data.slice(undefined, 9) : _data;
        return (
            <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={2}
            >
                <Grid container spacing={0}>
                    {firstData.length
                        ? firstData.map((company, index) => (
                              <Grid
                                  item
                                  key={"curated" + index}
                                  xs={isMobile ? 6 : 4}
                                  sx={{ paddingRight: 1, paddingBottom: 1 }}
                              >
                                  <CompanyLongCard company={company} />
                              </Grid>
                          ))
                        : isLoading
                        ? new Array(9).fill("").map((company, index) => (
                              <Grid
                                  item
                                  key={"curated_tmp_" + index}
                                  xs={isMobile ? 6 : 4}
                                  sx={{ paddingRight: 1, paddingBottom: 1 }}
                              >
                                  <CompanyLongCard />
                              </Grid>
                          ))
                        : null}
                </Grid>
                {renderBanner()}
                {_data.length > 9 ? (
                    <Grid container spacing={0}>
                        {_data.slice(9).map((company, index) => (
                            <Grid
                                item
                                key={"curated2_" + index}
                                xs={isMobile ? 6 : 4}
                                sx={{ paddingRight: 1, paddingBottom: 1 }}
                            >
                                <CompanyLongCard company={company} />
                            </Grid>
                        ))}
                    </Grid>
                ) : null}
            </Stack>
        );
    };

    const renderBanner = () => {
        return keyword ? null : (
            <Stack
                sx={{
                    backgroundImage: `url(${
                        isMobile ? BannerMobileImage : BannerWebImage
                    })`,
                    width: "100%",
                    height: isMobile ? "7rem" : "11.625rem",
                    backgroundSize: "cover",
                }}
            />
        );
    };

    const renderCompanies = () => {
        return (
            <Stack direction="column" spacing={4}>
                {companies.length ? (
                    <Grid container spacing={0}>
                        {companies.map((company, index) => (
                            <Grid
                                item
                                key={"company" + index}
                                xs={isMobile ? 6 : 3}
                                sx={{ paddingRight: 1, paddingBottom: 1 }}
                            >
                                <CompanyCard
                                    company={company}
                                    isMobile={isMobile}
                                />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            width: "100%",
                            minHeight: "25rem",
                        }}
                    >
                        <Text
                            style={{
                                color: "#aaaaaa",
                                fontSize: "1rem",
                                fontFamily: "Roboto",
                            }}
                        >
                            No companies found
                        </Text>
                    </Stack>
                )}
                {companies.length ? (
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        style={{ marginTop: "2rem" }}
                    >
                        <Pagination
                            count={Math.ceil(pagination.totalCount / limit)}
                            siblingCount={isMobile ? 1 : 3}
                            page={paginationPage}
                            onChange={onPageChanged}
                        />
                    </Stack>
                ) : null}
            </Stack>
        );
    };

    const getSEOTitle = () => {
        return "Companies in India by Zigup";
    };

    const getSEODescription = () => {
        return `Find salaries of leading product-based companies in India, learn about job benefits, bonuses, reviews and get referrals from current employees.`;
    };

    const getSEOKeywords = () => {
        const keywords = [
            "zigup",
            "company",
            "product based company",
            "pbcs",
            "referral",
            "company in india",
            "india product based company",
            "curated company",
            "company for developers",
            "front end developer jobs in india",
            "mncs",
            "mnc in india",
            "india mnc",
            "multinational companies in india",
        ];

        return keywords.join(", ");
    };

    const getSEOHelmet = () => {
        return (
            <Helmet>
                <title>{getSEOTitle()}</title>
                <meta
                    name="description"
                    property="description"
                    content={getSEODescription()}
                />
                <meta
                    name="keywords"
                    property="keywords"
                    content={getSEOKeywords()}
                />
                <meta content={getSEOTitle()} property="og:title" />
                <meta property="og:description" content={getSEODescription()} />
                <meta
                    content="https://hire.zigup.in/static/media/logo-horizontal.4663103fd0a4bca8ea17.png"
                    property="og:image"
                />
            </Helmet>
        );
    };

    return (
        <Stack
            sx={{
                minHeight: "100vh",
            }}
        >
            {getSEOHelmet()}
            <GlobalHeader />
            {renderHeader()}
            <Container
                maxWidth="lg"
                sx={{
                    marginTop: "2rem",
                    marginBottom: "2rem",
                }}
            >
                <Stack
                    direction="column"
                    spacing={4}
                    sx={{ display: "flex", flex: 1, width: "100%" }}
                >
                    <Stack alignItems="center" justifyContent="center">
                        {renderSearchBar()}
                    </Stack>
                    {renderCuratedCompanies()}
                    {renderContribute()}
                    {renderCompanies()}
                </Stack>
            </Container>
            <Footer />
        </Stack>
    );
};
export default CompanyListPage;
