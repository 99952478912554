import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Text from "components/atoms/Text";
import Colors from "themes/colors";
import Footer from "components/organisms/Footer";
import GlobalHeader from "components/organisms/GlobalHeader";
import { Container, Grid, Stack } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import Typo from "components/atoms/Typo";
import FeedbackImage from "assets/images/illust-feedback@2x.png";
import BackgroundWebImage from "assets/images/bg-feedback.png";
import BackgroundMobileImage from "assets/images/bg-feedback-mobile.png";
import NormalTextField from "components/atoms/NormalTextField";
import ZigupButton from "components/atoms/ZigupButton";
import { isEmail } from "utils/validator";
import { init, send } from "emailjs-com";
import { Logger } from "utils";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet-async";

const HeaderContainer = styled.div`
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${({ isMobile }) => (isMobile ? "200px" : "300px")};
    background-image: url(${({ isMobile }) =>
        isMobile ? BackgroundMobileImage : BackgroundWebImage});
    background-size: cover;
`;

const ContactUsPage = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [valid, setValid] = useState(false);
    const [showSentMsg, setShowSentMsg] = useState(false);
    const [showErrorMsg, setErrorMsg] = useState(false);

    useEffect(() => {
        const _valid = isEmail(email).valid && !!title && !!message;
        setValid(_valid);
    }, [mobile, email, title, message]);

    useEffect(() => {
        window.scrollTo(0, 0);
        init(process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY);
    }, []);

    const sendFeedback = async () => {
        if (!valid) {
            setErrorMsg(true);
            setTimeout(() => {
                setErrorMsg(false);
            }, 5000);
            return;
        }
        try {
            // do send email
            const myHeader = new Headers();
            myHeader.append(
                "Content-Type",
                "application/x-www-form-urlencoded"
            );
            const formData = new URLSearchParams();
            formData.append("channel", "C03BKCLF5JT");
            const txtMsg = `Web Feedback from User\n>Email: ${email}\n>Phone: ${mobile}\n>Title: ${title}\n>Message: ${message}`;
            formData.append("text", txtMsg);
            formData.append(
                "token",
                "xoxb-1500199372342-3401378928518-qzsiY3WQqTot2EfnQy8h5IMj"
            );
            const requestOptions = {
                method: "POST",
                headers: myHeader,
                body: formData,
                redirect: "follow",
            };
            fetch("https://slack.com/api/chat.postMessage", requestOptions)
                .then((response) => response.text())
                .then((result) => Logger.log(result))
                .catch((error) => Logger.log("error", error));

            const { status } = await send(
                "service_bjsf6rh",
                "template_j7005sd",
                {
                    email,
                    mobile,
                    title,
                    message,
                }
            );
            if (status === 200) {
                // show sent
                setShowSentMsg(true);
                setTimeout(() => {
                    setShowSentMsg(false);
                }, 5000);

                setMobile("");
                setEmail("");
                setTitle("");
                setMessage("");
            }
        } catch (e) {
            Logger.error(e);
        }
    };

    const renderHeader = () => {
        return (
            <HeaderContainer isMobile={isMobile}>
                <Typo.H1
                    style={{
                        margin: 0,
                        color: Colors.white,
                    }}
                >
                    Contact Us
                </Typo.H1>
            </HeaderContainer>
        );
    };

    const renderContactUs = () => {
        return (
            <Container
                maxWidth="lg"
                sx={{ paddingTop: "120px", paddingBottom: "120px" }}
            >
                <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={4}
                    sx={{ width: "100%" }}
                >
                    <Stack
                        direction="column"
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        spacing={2}
                        style={{ width: "100%" }}
                    >
                        <Text
                            style={{
                                fontFamily: "Lato",
                                fontWeight: 400,
                                fontSize: "1.5rem",
                                textAlign: "center",
                            }}
                        >
                            Couldn’t find what you’re looking for?
                        </Text>
                        <Text
                            style={{
                                fontFamily: "Lato",
                                fontWeight: 400,
                                fontSize: "1.25rem",
                                color: Colors.salary_text_grey,
                                textAlign: "center",
                            }}
                        >
                            Contact Us
                        </Text>
                    </Stack>
                    <Stack
                        direction={isMobile ? "column" : "row"}
                        spacing={8}
                        style={{ width: "100%" }}
                    >
                        <Stack
                            direction="column"
                            alignItems="flex-start"
                            justifyContent="center"
                            spacing={2}
                            sx={{
                                flex: 1,
                                display: "flex",
                                paddingTop: "2rem",
                                boxSizing: "border-box",
                            }}
                        >
                            <NormalTextField
                                fullWidth
                                value={email}
                                onChange={(event) =>
                                    setEmail(event.target.value)
                                }
                                style={{
                                    backgroundColor: Colors.default_color,
                                    borderRadius: 4,
                                }}
                                containerStyle={{ height: "4rem" }}
                                placeholder="eMail to receive reply"
                            />
                            <NormalTextField
                                fullWidth
                                value={mobile}
                                style={{
                                    backgroundColor: Colors.default_color,
                                    borderRadius: 4,
                                }}
                                onChange={(event) =>
                                    setMobile(event.target.value)
                                }
                                placeholder="Mobile to receive reply (Optional)"
                            />
                            <NormalTextField
                                fullWidth
                                value={title}
                                style={{
                                    backgroundColor: Colors.default_color,
                                    borderRadius: 4,
                                }}
                                onChange={(event) =>
                                    setTitle(event.target.value)
                                }
                                placeholder="Title"
                            />
                            <NormalTextField
                                fullWidth
                                value={message}
                                style={{
                                    backgroundColor: Colors.default_color,
                                    borderRadius: 4,
                                    color: showSentMsg
                                        ? "#52be80"
                                        : Colors.error,
                                    fontSize: "0.875rem",
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                }}
                                onChange={(event) =>
                                    setMessage(event.target.value)
                                }
                                multiline
                                rows={20}
                                placeholder="Message"
                            />
                            <Stack
                                direction="column"
                                alignItems="flex-start"
                                justifyContent="center"
                                spacing={0.5}
                                sx={{ width: "100%" }}
                            >
                                <ZigupButton
                                    id="feedback-submit"
                                    buttonText="Submit"
                                    buttonTextStyle={{ fontSize: "1rem" }}
                                    containerStyle={{ width: "84px" }}
                                    onClickHandler={sendFeedback}
                                />
                                <Text
                                    style={{
                                        color: showSentMsg
                                            ? "#52be80"
                                            : Colors.error,
                                        fontSize: "0.875rem",
                                        fontFamily: "Roboto",
                                        fontWeight: 400,
                                    }}
                                >
                                    {showSentMsg
                                        ? "Successfully sent message!"
                                        : showErrorMsg
                                        ? "Invalid Email or No message attached"
                                        : null}
                                </Text>
                            </Stack>
                        </Stack>
                        <Stack
                            direction="column"
                            justifyContent="space-between"
                            spacing={4}
                            sx={{
                                flex: 1,
                                display: "flex",
                            }}
                        >
                            <img
                                src={FeedbackImage}
                                alt="Feedback Image"
                                width="100%"
                                height="auto"
                            />
                            <Grid container spacing={0}>
                                <Grid
                                    item
                                    xs={6}
                                    sx={{ paddingRight: 1, paddingBottom: 1 }}
                                >
                                    <Stack
                                        direction="column"
                                        spacing={2}
                                        sx={{ marginTop: "2rem" }}
                                    >
                                        <Text
                                            style={{
                                                fontSize: "1rem",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                color: Colors.search_card_black,
                                            }}
                                        >
                                            Have general questions?
                                        </Text>
                                        <a
                                            href={"/faq"}
                                            style={{
                                                textDecoration: "none",
                                                width: "max-content",
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    color: Colors.primary_purple,
                                                }}
                                            >
                                                FAQ
                                            </Text>
                                        </a>
                                    </Stack>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    sx={{ paddingRight: 1, paddingBottom: 1 }}
                                >
                                    <Stack
                                        direction="column"
                                        spacing={2}
                                        sx={{ marginTop: "2rem" }}
                                    >
                                        <Text
                                            style={{
                                                fontSize: "1rem",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                color: Colors.search_card_black,
                                            }}
                                        >
                                            Support
                                        </Text>
                                        <Text
                                            style={{
                                                color: Colors.primary_purple,
                                                cursor: "pointer",
                                                width: "max-content",
                                            }}
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    "contact@hyperhire.in"
                                                );
                                                toast("Copied text!");
                                            }}
                                        >
                                            contact@hyperhire.in
                                        </Text>
                                    </Stack>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    sx={{ paddingRight: 1, paddingBottom: 1 }}
                                >
                                    <Stack
                                        direction="column"
                                        spacing={2}
                                        sx={{ marginTop: "2rem" }}
                                    >
                                        <Text
                                            style={{
                                                fontSize: "1rem",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                color: Colors.search_card_black,
                                            }}
                                        >
                                            Company Information
                                        </Text>
                                        <a
                                            href={"/intro"}
                                            style={{
                                                textDecoration: "none",
                                                width: "max-content",
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    color: Colors.primary_purple,
                                                }}
                                            >
                                                About us
                                            </Text>
                                        </a>
                                    </Stack>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    sx={{ paddingRight: 1, paddingBottom: 1 }}
                                >
                                    <Stack
                                        direction="column"
                                        spacing={2}
                                        sx={{ marginTop: "2rem" }}
                                    >
                                        <Text
                                            style={{
                                                fontSize: "1rem",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                color: Colors.search_card_black,
                                                width: "max-content",
                                            }}
                                        >
                                            Business Inquiries
                                        </Text>
                                        <a
                                            href={"https://hire.zigup.in/intro"}
                                            style={{
                                                textDecoration: "none",
                                            }}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Text
                                                style={{
                                                    color: Colors.primary_purple,
                                                }}
                                            >
                                                Business site
                                            </Text>
                                        </a>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        );
    };

    const renderHelmet = () => {
        return (
            <Helmet>
                <title>Contact Us - Zigup</title>
                <meta
                    name="description"
                    property="description"
                    content={
                        "We're here to help! If you have any questions, concerns or feedback--about your Zigup account, you can submit the form, else email us at  contact@hyperhire.in"
                    }
                />
                <meta content={"Contact Us - Zigup"} property="og:title" />
                <meta
                    property="og:description"
                    content={
                        "We're here to help! If you have any questions, concerns or feedback--about your Zigup account, you can submit the form, else email us at  contact@hyperhire.in"
                    }
                />
                <link rel="canonical" href={"https://zigup.in/contact-us"} />
            </Helmet>
        );
    };

    return (
        <>
            {renderHelmet()}
            <Stack
                justifyContent="flex-start"
                sx={{
                    flex: 1,
                    display: "flex",
                    minHeight: "100vh",
                }}
            >
                <GlobalHeader />
                {renderHeader()}
                {renderContactUs()}
                <Footer />
                <ToastContainer
                    position="top-right"
                    autoClose={500}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    draggable
                />
            </Stack>
        </>
    );
};

export default ContactUsPage;
