import React, { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Container, Stack } from "@mui/material";
import Typo from "components/atoms/Typo";
import ProgressBar from "../../organisms/my/ProgressBar";
import UserInformation from "../../organisms/my/UserInformation";
import BasicInformation from "../../organisms/my/BasicInformation";
import WorkExperience from "../../organisms/my/WorkExperience";
import TechnicalArea from "../../organisms/my/TechnicalArea";
import Preference from "../../organisms/my/Preference";
import ItemCard from "../../organisms/my/ItemCard";
import Feedback from "../../organisms/my/Feedback";
import { useMediaQuery } from "react-responsive";
import { ExperienceType } from "../../../constants/constants";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const MyProfilePage = () => {
    const { userState } = useContext(AuthContext);
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const navigate = useNavigate();

    const getProfilePerfection = () => {
        const {
            isExperienced,
            name,
            linkedinLink,
            email,
            currentSalary,
            desiredSalary,
            customInfo,
            employed,
            company,
            designation,
            companyStartedAt,
            relevantExperience,
            noticePeriod,
            education,
            skillset,
            preferred,
        } = userState.info;

        let cnt = 0;
        const total = isExperienced === ExperienceType.FRESHER ? 15 : 17;
        const area = customInfo?.area?.value || customInfo?.area?.custom?.key;
        const role = customInfo?.role?.value || customInfo?.area?.custom?.value;
        if (isExperienced === ExperienceType.FRESHER) {
            cnt +=
                Boolean(isExperienced === ExperienceType.FRESHER) * 1 +
                Boolean(name) * 1 +
                Boolean(linkedinLink) * 1 +
                Boolean(area) * 1 +
                Boolean(role) * 1 +
                Boolean(email) * 1 +
                Boolean(noticePeriod) * 1 +
                Boolean(education?.course?.value) * 1 +
                Boolean(education?.specialization?.value) * 1 +
                Boolean(education?.university?.value) * 1 +
                Boolean(currentSalary >= 0) * 1 +
                Boolean(desiredSalary >= 0) * 1 +
                Boolean(skillset?.main?.length === 3) * 1 +
                Boolean(skillset?.sub?.length > 0) * 1 +
                Boolean(preferred?.locations?.length > 0) * 1;
        } else {
            cnt +=
                Boolean(isExperienced === ExperienceType.EXPERIENCED) * 1 +
                Boolean(name) * 1 +
                Boolean(linkedinLink) * 1 +
                Boolean(area) * 1 +
                Boolean(role) * 1 +
                Boolean(email) * 1 +
                Boolean(employed !== undefined) * 1 +
                Boolean(company) * 1 +
                Boolean(designation) * 1 +
                Boolean(
                    relevantExperience !== undefined && relevantExperience >= 0
                ) *
                    1 +
                Boolean(companyStartedAt?.month) * 1 +
                Boolean(noticePeriod) * 1 +
                Boolean(currentSalary >= 0) * 1 +
                Boolean(desiredSalary >= 0) * 1 +
                Boolean(skillset?.main?.length === 3) * 1 +
                Boolean(skillset?.sub?.length > 0) * 1 +
                Boolean(preferred?.locations?.length > 0) * 1;
        }

        return cnt / total;
    };

    function renderLeftSideComponent() {
        return Object.keys(userState.info).length ? (
            <>
                <Stack
                    style={{
                        width: "100%",
                    }}
                >
                    <BasicInformation
                        userInfo={{
                            isExperienced: userState.info?.isExperienced,
                            mobile: userState.info?.phoneNumber,
                            name: userState.info?.name,
                            linkedinLink: userState.info?.linkedinLink,
                            anonymousName: userState.info?.anonymousName,
                            email: userState.info?.email,
                            currentSalary: userState.info?.currentSalary,
                            desiredSalary: userState.info?.desiredSalary,
                            noticePeriod: userState.info?.noticePeriod,
                            lastWorkingDay: userState.info?.lastWorkingDay,
                        }}
                    />
                </Stack>
                <Stack
                    style={{
                        width: "100%",
                    }}
                >
                    <WorkExperience
                        userInfo={{
                            isExperienced: userState.info?.isExperienced,
                            employed: userState.info?.employed,
                            company: userState.info?.company,
                            designation: userState.info?.designation,
                            relevantExperience:
                                userState.info?.relevantExperience,
                            education: userState.info?.education,
                        }}
                    />
                </Stack>
                <Stack
                    style={{
                        width: "100%",
                    }}
                >
                    <TechnicalArea
                        userInfo={{
                            customInfo: userState.info?.customInfo,
                            skillset: userState.info?.skillset,
                        }}
                    />
                </Stack>
                <Stack
                    style={{
                        width: "100%",
                    }}
                >
                    <Preference
                        userInfo={{
                            preferred: userState.info?.preferred,
                        }}
                    />
                </Stack>
            </>
        ) : null;
    }

    function renderRightSideComponent() {
        return (
            <>
                <Stack
                    style={{
                        width: "100%",
                    }}
                >
                    <Stack
                        id={"my-profile-post-btn"}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        padding={2}
                        margin={1}
                        style={{
                            backgroundColor: "white",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            toast("Preparing My Post/Comment Page...");
                        }}
                    >
                        <Typo.Body1>My post/comment</Typo.Body1>
                        <Typo.Body1>{">"}</Typo.Body1>
                    </Stack>
                </Stack>
                <Stack
                    style={{
                        width: "100%",
                    }}
                >
                    <Stack
                        id={"my-profile-manage-cv-btn"}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        padding={2}
                        margin={1}
                        style={{
                            backgroundColor: "white",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            navigate("edit/cv");
                        }}
                    >
                        <Typo.Body1>Manage my CV</Typo.Body1>
                        <Typo.Body1>{">"}</Typo.Body1>
                    </Stack>
                </Stack>
                <Stack
                    style={{
                        width: "100%",
                    }}
                >
                    <ItemCard
                        id={"my-profile-add-salary-btn"}
                        content={
                            "Add salary of your current or past company anonymously"
                        }
                        buttonText={"Add a salary"}
                        onClickHandler={() => navigate("/salary/add")}
                        containerStyle={{ margin: "0.5rem" }}
                    />
                </Stack>
                <Stack
                    style={{
                        width: "100%",
                    }}
                >
                    <ItemCard
                        id={"my-profile-add-salary-btn"}
                        content={
                            "Do you have any questions about the company? \nExchange questions and answers."
                        }
                        buttonText={`Discuss with others`}
                        onClickHandler={() =>
                            navigate(`/forum/list?type=discussion&page=1`)
                        }
                        containerStyle={{ margin: "0.5rem" }}
                    />
                </Stack>
                <Stack
                    style={{
                        width: "100%",
                    }}
                >
                    <Feedback />
                </Stack>
            </>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ flex: 1 }}>
            <Stack
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                style={{
                    width: "100%",
                    height: "max-content",
                    paddingTop: isMobile ? "0.5rem" : "2rem",
                    paddingBottom: isMobile ? "0.5rem" : "2rem",
                    boxSizing: "border-box",
                }}
            >
                <Stack
                    style={{
                        width: "100%",
                    }}
                >
                    <UserInformation
                        userInfo={{
                            profileImage: userState.info?.profileImagePath,
                            name: userState.info?.name,
                            designation: userState.info?.designation,
                            company: userState.info?.company,
                            anonymousName: userState.info?.anonymousName,
                            invitationCode: userState.info?.invitationCode,
                        }}
                    />
                </Stack>
                <Stack
                    direction="row"
                    style={{
                        width: "100%",
                    }}
                >
                    <ProgressBar
                        progress={Math.round(getProfilePerfection() * 100)}
                    />
                    {isMobile ? null : (
                        <div style={{ display: "flex", flex: 1 }} />
                    )}
                </Stack>
                {isMobile ? (
                    <>
                        {renderLeftSideComponent()}
                        {renderRightSideComponent()}
                    </>
                ) : (
                    <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"flex-start"}
                        style={{ width: "100%" }}
                    >
                        <Stack
                            direction={"column"}
                            justifyContent={"start"}
                            alignItems={"center"}
                            style={{
                                flex: 1,
                                height: "max-content",
                            }}
                        >
                            {renderLeftSideComponent()}
                        </Stack>
                        <Stack
                            direction={"column"}
                            justifyContent={"start"}
                            alignItems={"center"}
                            style={{
                                flex: 1,
                                height: "max-content",
                            }}
                        >
                            {renderRightSideComponent()}
                        </Stack>
                    </Stack>
                )}
            </Stack>
            <ToastContainer
                position="top-right"
                autoClose={500}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
            />
        </Container>
    );
};
export default MyProfilePage;
