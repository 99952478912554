import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Text from "components/atoms/Text";
import Colors from "themes/colors";
import Footer from "components/organisms/Footer";
import GlobalHeader from "components/organisms/GlobalHeader";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Stack,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typo from "components/atoms/Typo";
import BackgroundWebImage from "assets/images/bg-faq.png";
import BackgroundMobileImage from "assets/images/bg-faq-mobile.png";
import NormalTextField from "components/atoms/NormalTextField";
import ZigupButton from "components/atoms/ZigupButton";
import { isEmail } from "./../../utils/validator";
import { init, send } from "emailjs-com";
import { Logger } from "utils";
import { Helmet } from "react-helmet-async";

const FaqHeaderContainer = styled.div`
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${({ isMobile }) => (isMobile ? "200px" : "300px")};
    padding: 2rem 1rem;
    box-sizing: border-box;
    background-image: url(${({ isMobile }) =>
        isMobile ? BackgroundMobileImage : BackgroundWebImage});
    background-size: cover;
`;

const FaqButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Colors.faq_text};
    cursor: pointer;
    background-color: ${Colors.white};
    border: 1px ${Colors.faq_border} solid;
    border-radius: 0.25rem;
    height: 3rem;
    box-sizing: border-box;
    padding: 12px;
    width: 10rem;
    font-size: 1rem;
    font-family: Lato;
    font-weight: 400;
    text-align: center;
`;

const FaqPage = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const allFaqs = [
        {
            title: "About Zigup",
            faqs: [
                {
                    title: "What is Zigup?",
                    description:
                        "Zigup is a career platform that allows you to share and explore information about salary breakups, job referrals, and interviews to help you advance your career.",
                },
                {
                    title: "What exactly Zigup do?",
                    description:
                        "At Zigup, we help you with\n- Finding detailed salary breakup data of Startups, MNCs \n- Network with others based on your intrests \n- Employee referral opportunities\n- Explore amazing job offers from top companies",
                },
                {
                    title: "How Zigup Works?",
                    description:
                        "Zigup is run by our amazing community members like you.",
                },
                {
                    title: "How Zigup help candidates?",
                    description:
                        "Zigup is helping candidates for seeking new career opportunities, giving accurate salary breakup information & interview reviews and networking with respect to their career/skillsets\nIf the candidate is seeking a new opportunity, he/she can get job opportunities from Zigup and can get referral opportunities by employees from top companies \nIf the candidate is in the negotiation stage, he/she can utilize the salary breakup information to gain negotiation power\nIf the candidate has a query, he/she can upload the questions to the group chat / DM / Forum sections",
                },
                {
                    title: "How Zigup help Businesses?",
                    description:
                        "Zigup helps you find high-quality candidates which will make your business much more efficient and fast.\nYou can get more detailed information on how we help businesses at https://hire.zigup.in/",
                },
                {
                    title: "Why should I trust Zigup?",
                    description:
                        "Zigup is Anonymous Professional Community which means your personal data will not be shared publicly, we will keep your information anonymous.\nIn addition, the salary breakup, jobs and referral opportunities are shared by verified employees & amazing community members.",
                },
            ],
        },
        {
            title: "Salaries",
            faqs: [
                {
                    title: "Is Salary Breakup Data on Zigup accurate?",
                    description:
                        "Yes, it’s accurate. To report inaccurate data, simply click the Report button. If enough reports are received, the data will be blocked for our users.",
                },
            ],
        },
        {
            title: "Registration",
            faqs: [
                {
                    title: "Is Zigup Free?",
                    description:
                        "Yes, Zigup is totally free. Users of Zigup are not required to make any kind of financial commitment.",
                },
                {
                    title: "How do I register with Zigup?",
                    description:
                        "To join the Zigup community, your phone number should be verified. You can verify your phone number when signing up on the website and create a password for sign-in.\nAfter completing the registration process, you will have free access to all of Zigup's features.",
                },
            ],
        },
        {
            title: "Referral",
            faqs: [
                {
                    title: "How to get referrals on Zigup?",
                    description:
                        "There are many posts related to referrals on our Forum - Candidates section. You can find various companies looking for candidates through referrals.\nIf you are unable to find any suitable company/position, you may upload a post in Forum - Referral section. You can express your interests or specify the company that you are looking for.",
                },
            ],
        },
    ];

    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [valid, setValid] = useState(false);
    const [showSentMsg, setShowSentMsg] = useState(false);
    const [showErrorMsg, setErrorMsg] = useState(false);

    useEffect(() => {
        const _valid = isEmail(email).valid && !!title && !!message;
        setValid(_valid);
    }, [mobile, email, title, message]);

    useEffect(() => {
        window.scrollTo(0, 0);

        init(process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY);
    }, []);

    const sendFeedback = async () => {
        if (!valid) {
            setErrorMsg(true);
            setTimeout(() => {
                setErrorMsg(false);
            }, 5000);
            return;
        }
        try {
            // do send email
            const myHeader = new Headers();
            myHeader.append(
                "Content-Type",
                "application/x-www-form-urlencoded"
            );
            const formData = new URLSearchParams();
            formData.append("channel", "C03BKCLF5JT");
            const txtMsg = `Web Feedback from User\n>Email: ${email}\n>Phone: ${mobile}\n>Title: ${title}\n>Message: ${message}`;
            formData.append("text", txtMsg);
            formData.append(
                "token",
                "xoxb-1500199372342-3401378928518-qzsiY3WQqTot2EfnQy8h5IMj"
            );
            const requestOptions = {
                method: "POST",
                headers: myHeader,
                body: formData,
                redirect: "follow",
            };
            fetch("https://slack.com/api/chat.postMessage", requestOptions)
                .then((response) => response.text())
                .then((result) => Logger.log(result))
                .catch((error) => Logger.log("error", error));

            const { status } = await send(
                "service_bjsf6rh",
                "template_j7005sd",
                {
                    email,
                    mobile,
                    title,
                    message,
                }
            );
            if (status === 200) {
                // show sent
                setShowSentMsg(true);
                setTimeout(() => {
                    setShowSentMsg(false);
                }, 5000);

                setMobile("");
                setEmail("");
                setTitle("");
                setMessage("");
            }
        } catch (e) {
            Logger.error(e);
        }
    };

    const renderHeader = () => {
        return (
            <FaqHeaderContainer isMobile={isMobile}>
                <Typo.H1
                    style={{
                        margin: 0,
                        color: Colors.white,
                        marginBottom: "2rem",
                    }}
                >
                    FAQ
                </Typo.H1>
                <Stack direction="row" spacing={2}>
                    <a
                        href={"/contact-us"}
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <FaqButton>Contact Us</FaqButton>
                    </a>
                    <a
                        href={"https://hire.zigup.in"}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <FaqButton>Visit business site</FaqButton>
                    </a>
                </Stack>
            </FaqHeaderContainer>
        );
    };

    const renderFaq = () => {
        return (
            <Container
                maxWidth="md"
                sx={{
                    paddingTop: isMobile ? "2rem" : "120px",
                    paddingBottom: isMobile ? "2rem" : "120px",
                    flex: 1,
                }}
            >
                {allFaqs.map((subject, index) => (
                    <Stack key={index} sx={{ marginBottom: "2.25rem" }}>
                        <Typo.H2
                            style={{
                                margin: 0,
                                fontSize: "1.25rem",
                                marginBottom: "1rem",
                            }}
                        >
                            {subject.title}
                        </Typo.H2>
                        {subject.faqs.map((faq, index) => (
                            <Accordion
                                key={subject.title + index}
                                disableGutters={true}
                                sx={{
                                    border: `1px ${Colors.outline_2} solid;`,
                                    elevation: 0,
                                    zIndex: 0,
                                    boxShadow: "none",
                                    padding: "8px",
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Text
                                        style={{
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "1rem",
                                        }}
                                    >
                                        {faq.title}
                                    </Text>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Text
                                        style={{
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "1rem",
                                            color: Colors.salary_text_grey,
                                            whiteSpace: "pre-line",
                                        }}
                                    >
                                        {faq.description}
                                    </Text>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Stack>
                ))}
            </Container>
        );
    };

    const renderFeedback = () => {
        return (
            <Container maxWidth="sm" sx={{ paddingBottom: "120px" }}>
                <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={8}
                >
                    <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                    >
                        <Text
                            style={{
                                fontFamily: "Lato",
                                fontWeight: 400,
                                fontSize: "1.5rem",
                                textAlign: "center",
                            }}
                        >
                            Couldn’t find what you’re looking for?
                        </Text>
                        <Text
                            style={{
                                fontFamily: "Lato",
                                fontWeight: 400,
                                color: Colors.salary_text_grey,
                                textAlign: "center",
                            }}
                        >
                            Send us feedback or ask us questions
                        </Text>
                    </Stack>
                    <Stack
                        direction="column"
                        alignItems="flex-start"
                        justifyContent="center"
                        spacing={2}
                        sx={{ width: "100%" }}
                    >
                        <NormalTextField
                            fullWidth
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            style={{
                                backgroundColor: Colors.default_color,
                                borderRadius: 4,
                            }}
                            placeholder="eMail to receive reply"
                        />
                        <NormalTextField
                            fullWidth
                            value={mobile}
                            style={{
                                backgroundColor: Colors.default_color,
                                borderRadius: 4,
                            }}
                            onChange={(event) => setMobile(event.target.value)}
                            placeholder="Mobile to receive reply (Optional)"
                        />
                        <NormalTextField
                            fullWidth
                            value={title}
                            style={{
                                backgroundColor: Colors.default_color,
                                borderRadius: 4,
                            }}
                            onChange={(event) => setTitle(event.target.value)}
                            placeholder="Title"
                        />
                        <NormalTextField
                            fullWidth
                            value={message}
                            style={{
                                backgroundColor: Colors.default_color,
                                borderRadius: 4,
                            }}
                            onChange={(event) => setMessage(event.target.value)}
                            multiline
                            rows={6}
                            placeholder="Message"
                        />
                        <Stack
                            direction="column"
                            alignItems="flex-start"
                            justifyContent="center"
                            spacing={0.5}
                            sx={{ width: "100%" }}
                        >
                            <ZigupButton
                                id="feedback-submit"
                                buttonText="Submit"
                                buttonTextStyle={{ fontSize: "1rem" }}
                                containerStyle={{ width: "84px" }}
                                onClickHandler={sendFeedback}
                            />
                            <Text
                                style={{
                                    color: showSentMsg
                                        ? "#52be80"
                                        : Colors.error,
                                    fontSize: "0.875rem",
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                }}
                            >
                                {showSentMsg
                                    ? "Successfully sent message!"
                                    : showErrorMsg
                                    ? "Invalid Email or No message attached"
                                    : null}
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        );
    };

    const getJsonLd = () => {
        const mainEntity = [];
        for (const subject of allFaqs) {
            for (const faq of subject.faqs) {
                mainEntity.push({
                    "@type": "Question",
                    name: faq.title,
                    acceptedAnswer: {
                        "@type": "Answer",
                        text: faq.description,
                    },
                });
            }
        }
        const data = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: mainEntity,
        };
        return JSON.stringify(data);
    };

    const renderHelmet = () => {
        const jsonLd = getJsonLd();
        return (
            <Helmet>
                <title>Frequently Asked Questions - Zigup</title>
                <meta
                    name="description"
                    property="description"
                    content={
                        "You've landed on the Zigup's FAQ page! This is where we answer a lot of frequently asked questions. Have a look around and if you can't find what you're looking for, don't hesitate to ask!"
                    }
                />
                <meta
                    content={"Frequently Asked Questions - Zigup"}
                    property="og:title"
                />
                <meta
                    property="og:description"
                    content={
                        "You've landed on the Zigup's FAQ page! This is where we answer a lot of frequently asked questions. Have a look around and if you can't find what you're looking for, don't hesitate to ask!"
                    }
                />
                <link rel="canonical" href={"https://zigup.in/faq"} />
                <script type="application/ld+json">{jsonLd}</script>
            </Helmet>
        );
    };

    return (
        <>
            {renderHelmet()}
            <Stack
                justifyContent="space-between"
                sx={{
                    flex: 1,
                    display: "flex",
                    minHeight: "100vh",
                }}
            >
                <GlobalHeader />
                {renderHeader()}
                {renderFaq()}
                {renderFeedback()}
                <Footer />
            </Stack>
        </>
    );
};

export default FaqPage;
