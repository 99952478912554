import { Stack } from "@mui/material";
import Typo from "../../atoms/Typo";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { list } from "../../../communication/salary";
import { useMediaQuery } from "react-responsive";
import { Logger } from "utils";
import ViewMoreButton from "../ViewMoreButton";
import SalaryCard from "components/organisms/salary/SalaryCard";

const TrendingSalaries = () => {
    const SALARY_COUNT = 4;
    const [trendingSalaries, setTrendingSalaries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const _emptyArr = new Array(SALARY_COUNT).fill();

    const getTrendingSalaries = async () => {
        setIsLoading(true);
        try {
            fetchData();
        } catch (e) {
            Logger.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchData = async () => {
        const { salaries } = await list({
            limit: SALARY_COUNT,
            sort: "trending",
        });
        setTrendingSalaries([...salaries]);
    };

    useEffect(() => {
        getTrendingSalaries();
        return () => setTrendingSalaries([]);
    }, []);

    return (
        <Stack sx={{ marginBottom: "3rem" }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ marginBottom: "0.5rem" }}
            >
                <Typo.H2
                    style={{
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "1rem",
                    }}
                >
                    Top Trending Salary Breakups this week
                </Typo.H2>
            </Stack>
            <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                {trendingSalaries.length
                    ? trendingSalaries.map((salary, index) => (
                          <Grid
                              item
                              xs={isMobile ? 12 : 6}
                              key={index + "trending"}
                          >
                              <SalaryCard salary={salary} />
                          </Grid>
                      ))
                    : _emptyArr.map((_, index) => (
                          <Grid
                              item
                              xs={isMobile ? 12 : 6}
                              key={index + "trending_temp"}
                          >
                              <SalaryCard />
                          </Grid>
                      ))}
            </Grid>
            <ViewMoreButton href={"/salary/list"} />
        </Stack>
    );
};

export default TrendingSalaries;
