import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Logger } from "utils";
import ViewMoreButton from "../ViewMoreButton";
import { FORUM_OBJECT_TYPE } from "../../../constants/constants";
import Text from "components/atoms/Text";
import ForumCard from "components/organisms/forum/ForumCard";
import Colors from "themes/colors";
import { forumMainList } from "communication/forum";

const ForumMainPosts = () => {
    const limit = 6;
    const types = [
        { key: "all", name: "Forum" },
        { key: FORUM_OBJECT_TYPE.DISCUSSION, name: "Discussion" },
        { key: FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFR, name: "Referrals" },
        { key: FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFC, name: "Candidates" },
    ];
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

    const _emptyArr = new Array(limit).fill();
    const [allPosts, setAllPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [type, setType] = useState(types[0].key);

    const getNewPosts = async () => {
        setIsLoading(true);
        try {
            const { discussions, LFRs, LFCs } = await forumMainList({});
            setAllPosts([...discussions, ...LFRs, ...LFCs]);
        } catch (e) {
            Logger.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(async () => {
        await getNewPosts();
        return () => setAllPosts([]);
    }, []);

    const getShowPosts = () => {
        if (type === types[0].key) {
            return allPosts;
        } else {
            return allPosts.filter((post) => post.type === type);
        }
    };

    return (
        <Stack sx={{ marginBottom: "3rem" }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                sx={{
                    width: "100%",
                    marginBottom: "1rem",
                    overflowX: "scroll",
                    "::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
                spacing={1}
            >
                <Text
                    style={{
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "1rem",
                        whiteSpace: "nowrap",
                    }}
                >
                    What's new on
                </Text>
                {types.map((_type) => {
                    return (
                        <Stack
                            key={_type.key}
                            onClick={() => setType(_type.key)}
                            alignItems="center"
                            justifyContent="center"
                            style={{
                                cursor: "pointer",
                                borderTop:
                                    type === _type.key
                                        ? "1px white solid"
                                        : null,
                                borderBottom:
                                    type === _type.key
                                        ? "1px black solid"
                                        : null,
                                height: "40px",
                                boxSizing: "border-box",
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: "1rem",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    color:
                                        type === _type.key
                                            ? Colors.header_black
                                            : Colors.text_grey,
                                    height: "max-content",
                                }}
                            >
                                {_type.name}
                            </Text>
                        </Stack>
                    );
                })}
            </Stack>
            {isLoading ? (
                <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                    {_emptyArr.map((_, index) => (
                        <Grid
                            item
                            xs={isMobile ? 12 : 6}
                            key={index + "forum_tmp"}
                        >
                            <ForumCard />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                    {getShowPosts().map((post, index) => (
                        <Grid item xs={isMobile ? 12 : 6} key={index + "forum"}>
                            <ForumCard item={post} />
                        </Grid>
                    ))}
                </Grid>
            )}
            <ViewMoreButton href={`/forum/list?type=${type}`} />
        </Stack>
    );
};

export default ForumMainPosts;
