import React, { useState, useEffect } from "react";
import styled from "styled-components";
import upvoteIcon from "assets/icons/icon-upvote.png";
import upvoteLinedIcon from "assets/icons/icon-upvote-lined.png";
import Text from "components/atoms/Text";
import { Stack } from "@mui/material";
import { useMediaQuery } from "react-responsive";
const UpvoteButtonContainer = styled(Stack)`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const CountText = styled(Text)`
    margin-right: 16px;
    color: #646464;
`;

const UpvoteButton = (props) => {
    const { item, setItem, disabled } = props;
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

    const [upvoted, setUpvoted] = useState(!!item.upvoted);
    const [upvoteCount, setUpvoteCount] = useState(
        item.upvoteCount || "Upvote"
    );
    // setItem : detail 페이지의 salary 데이터를 직접 수정하기 위해서 전달해줌.
    useEffect(() => {
        setUpvoted(!!item.upvoted);
        setUpvoteCount(item.upvoteCount || "Upvote");
    }, [item.upvoted, item.upvoteCount, item]);

    const onUpvoteButtonClick = async () => {
        const initialUpvoteCount = item.upvoteCount;
        if (upvoted) {
            setUpvoteCount(initialUpvoteCount > 0 ? initialUpvoteCount - 1 : 0);
            if (setItem) {
                setItem({
                    ...item,
                    upvoteCount:
                        initialUpvoteCount > 0 ? initialUpvoteCount - 1 : 0,
                    upvoted: false,
                });
            }
        } else {
            setUpvoteCount(initialUpvoteCount + 1);
            if (setItem) {
                setItem({
                    ...item,
                    upvoteCount: initialUpvoteCount + 1,
                    upvoted: true,
                });
            }
        }

        setUpvoted(!upvoted);
    };

    return (
        <UpvoteButtonContainer
            disabled={!!disabled}
            onClick={onUpvoteButtonClick}
        >
            <div>
                <img
                    src={upvoted ? upvoteIcon : upvoteLinedIcon}
                    alt="skillsIcon"
                    width={isMobile ? 16 : 24}
                    height={isMobile ? 16 : 24}
                    style={{ marginRight: 4 }}
                />
            </div>
            <CountText
                style={{
                    color: upvoted ? "#6837f2" : "#646464",
                }}
            >
                {upvoteCount > 0 ? upvoteCount : "Upvote"}
            </CountText>
        </UpvoteButtonContainer>
    );
};

export default UpvoteButton;
