import styled from "styled-components";
import Colors from "themes/colors";
import { Paper, Stack } from "@mui/material";
import Text from "components/atoms/Text";
import Typo from "components/atoms/Typo";
import { timeFromNow } from "utils/DateUtil";
import upvoteIcon from "assets/icons/icon-upvote-lined.png";
import viewIcon from "assets/icons/icon-view.png";
import commentIcon from "assets/icons/icon-comment.png";
import dmIcon from "assets/icons/dm@3x.png";
import {
    ExperienceType,
    FORUM_OBJECT_TYPE,
} from "../../../constants/constants";
import React from "react";
import ZigupSpinner from "../ZigupSpinner";

const HoverWrapper = styled.div`
    height: 100%;
    &:hover {
        background-color: ${Colors.background_2};
    }
`;
const titleStyle = {
    maxWidth: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
};

const contentStyle = {
    ...titleStyle,
    WebkitLineClamp: 2,
    lineHeight: 1.5,
};

const ForumCard = ({ item }) => {
    const getUrl = () => {
        switch (item?.type) {
            case FORUM_OBJECT_TYPE.DISCUSSION:
                return `/forum/discussion/${item.seoURL || item._id}`;
            case FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFC:
                return `/forum/looking-for-candidate/${
                    item.seoURL || item._id
                }`;
            case FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFR:
                return `/forum/looking-for-referral/${item.seoURL || item._id}`;
            default:
                return null;
        }
    };

    const renderType = () => {
        switch (item?.type) {
            case FORUM_OBJECT_TYPE.DISCUSSION:
                return (
                    <Typo.Body2
                        style={{
                            fontSize: "0.75rem",
                            textDecoration: "underline",
                            color: Colors.forum_discussion,
                            marginBottom: "0.25rem",
                        }}
                    >
                        #Discussion
                    </Typo.Body2>
                );
            case FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFC:
                return (
                    <Typo.Body2
                        style={{
                            fontSize: "0.75rem",
                            textDecoration: "underline",
                            color: Colors.forum_lfc,
                            marginBottom: "0.25rem",
                        }}
                    >
                        #Looking for candidates
                    </Typo.Body2>
                );
            case FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFR:
                return (
                    <Typo.Body2
                        style={{
                            fontSize: "0.75rem",
                            textDecoration: "underline",
                            color: Colors.primary_purple,
                            marginBottom: "0.25rem",
                        }}
                    >
                        #Looking for referrals
                    </Typo.Body2>
                );
            default:
                return null;
        }
    };

    const renderTitle = () => {
        switch (item?.type) {
            case FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFC:
                return (
                    <Typo.H4Regular
                        style={{
                            margin: 0,
                            marginBottom: "0.25rem",
                            ...titleStyle,
                        }}
                    >
                        {`${item?.company} | ${item?.title}`}
                    </Typo.H4Regular>
                );
            default:
                return (
                    <Typo.H4Regular
                        style={{
                            margin: 0,
                            marginBottom: "0.25rem",
                            ...titleStyle,
                        }}
                    >
                        {item?.title}
                    </Typo.H4Regular>
                );
        }
    };

    const renderSubTitle = () => {
        return (
            <Typo.Body2
                style={{
                    fontSize: "0.75rem",
                    margin: 0,
                    color: Colors.text_grey,
                }}
            >
                {item?.writer.displayName} | {timeFromNow(item?.createdAt)}
            </Typo.Body2>
        );
    };

    const renderContent = () => {
        return (
            <Stack
                style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                }}
            >
                <Typo.Body2
                    style={
                        item?.type === FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFR
                            ? { ...contentStyle, WebkitLineClamp: 1 }
                            : contentStyle
                    }
                >
                    {item?.type === FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFR ? (
                        <Typo.Body2Bold>
                            {item.writer?.isExperienced ===
                            ExperienceType.EXPERIENCED
                                ? item?.company
                                    ? `At ${item?.company} | ${item?.writer?.yearsOfExperience}year(s)`
                                    : "No data"
                                : item?.writer?.university
                                ? `At ${item?.writer?.university}`
                                : "No data"}
                        </Typo.Body2Bold>
                    ) : null}
                    {item?.content}
                </Typo.Body2>
            </Stack>
        );
    };

    const renderCounts = () => {
        return (
            <Stack direction="row" spacing={2}>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <img
                        src={upvoteIcon}
                        alt="upvoteIcon"
                        width={20}
                        height={20}
                    />
                    <Text style={{ color: Colors.salary_text_grey }}>
                        {item?.upvoteCount || 0}
                    </Text>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <img src={viewIcon} alt="viewIcon" width={20} height={20} />
                    <Text style={{ color: Colors.salary_text_grey }}>
                        {item?.viewCount || 0}
                    </Text>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <img
                        src={commentIcon}
                        alt="commentIcon"
                        width={20}
                        height={20}
                    />
                    <Text style={{ color: Colors.salary_text_grey }}>
                        {item?.commentCount || 0}
                    </Text>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <img src={dmIcon} alt="dmIcon" width={20} height={20} />
                    <Text style={{ color: Colors.salary_text_grey }}>
                        {item?.dmCount || 0}
                    </Text>
                </Stack>
            </Stack>
        );
    };

    return item ? (
        <a href={getUrl()} style={{ textDecoration: "none", color: "black" }}>
            <Paper
                elevation={0}
                sx={{
                    height: "100%",
                    minHeight: "150px",
                    cursor: "pointer",
                    borderWidth: 1,
                    borderColor: Colors.outline_2,
                    borderStyle: "solid",
                    boxSizing: "border-box",
                    borderRadius: "0.25rem",
                }}
            >
                <HoverWrapper>
                    <Stack
                        direction="column"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        spacing={0}
                        style={{
                            padding: "1rem",
                            height: "100%",
                            boxSizing: "border-box",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={5}
                            sx={{ width: "100%" }}
                        >
                            {renderType()}
                            {renderTitle()}
                            {renderSubTitle()}
                        </Stack>
                        {renderContent()}
                        {renderCounts()}
                    </Stack>
                </HoverWrapper>
            </Paper>
        </a>
    ) : (
        <Paper
            elevation={0}
            sx={{
                height: "100%",
                minHeight: "150px",
                cursor: "pointer",
                borderWidth: 1,
                borderColor: Colors.outline_2,
                borderStyle: "solid",
                boxSizing: "border-box",
                borderRadius: "0.25rem",
            }}
        >
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ height: "100%", width: "100%" }}
            >
                <ZigupSpinner size={"2rem"} />
            </Stack>
        </Paper>
    );
};

export default ForumCard;
