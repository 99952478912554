import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Outlet } from "react-router-dom";

const AuthRoute = () => {
    const { userState, onRefreshToken } = useContext(AuthContext);
    useEffect(async () => {
        await onRefreshToken();
    }, []);

    const renderPage = () => {
        return userState.auth !== undefined ? <Outlet /> : null;
    };

    return renderPage();
};
export default AuthRoute;
