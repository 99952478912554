import React, { useEffect, useRef, useState } from "react";
import { fetchService } from "../../communication/jd";
import Chip from "@mui/material/Chip";
import { TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Colors from "themes/colors";
import Text from "components/atoms/Text";
import Stack from "@mui/material/Stack";
import NormalAutocomplete from "components/atoms/NormalAutoComplete";
import { ThemeProvider } from "@mui/material/styles";
import { zigup } from "themes/zigup";
import ZigupSpinner from "./ZigupSpinner";

const FETCH_STATUS = {
    DEFAULT: -1,
    LOADING: 1,
    DONE: 2,
    FAILED: 3,
};

const PreferenceLocationAutoComplete = (props) => {
    const { title, placeholder, maxCount, setValue } = props;
    const [preferredLocations, setPreferredLocations] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState(
        props.value || []
    );
    const [locations, setLocations] = useState([]);
    const [fetchStatus, setFetchStatus] = useState(FETCH_STATUS.DEFAULT);
    const [onMouse, setOnMouse] = useState(false);
    const preferredLocationInputRef = useRef();
    const filter = createFilterOptions();
    const index = useRef(0);
    const ReloadButtonStyle = {
        border: onMouse ? "1px solid #6837f2" : "1px solid #d4d6dd",
        width: "100%",
        height: "3rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Colors.white,
        cursor: "pointer",
        marginBottom: "1rem",
    };

    const fetchData = async () => {
        try {
            setFetchStatus(FETCH_STATUS.LOADING);
            const res = await fetchService();
            setFetchStatus(FETCH_STATUS.DONE);
            setLocations([...res.data.Preferred.Locations, ...res.data.Cities]);
            setPreferredLocations([...res.data.Preferred.Locations]);
        } catch (e) {
            setFetchStatus(FETCH_STATUS.FAILED);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setValue(selectedLocations);
    }, [selectedLocations]);

    const renderChips = () => {
        return preferredLocations.map((city, index) => {
            return (
                <ThemeProvider key={index} theme={zigup}>
                    <Chip
                        label={city}
                        disabled={false}
                        style={{ margin: "0.2rem" }}
                        onClick={() => {
                            if (
                                selectedLocations.find(
                                    (value) => city === value
                                )
                            ) {
                                const newArr = [...selectedLocations];
                                newArr.splice(
                                    newArr.findIndex((value) => value === city),
                                    1
                                );
                                setSelectedLocations(newArr);
                            } else {
                                if (selectedLocations.length >= maxCount)
                                    return;
                                setSelectedLocations([
                                    ...selectedLocations,
                                    city,
                                ]);
                            }
                        }}
                        color={
                            selectedLocations.find((value) => value === city)
                                ? "primary"
                                : "secondary"
                        }
                    />
                </ThemeProvider>
            );
        });
    };

    const renderTag = (tagValue, getTagProps) => {
        return selectedLocations.map((option, index) => (
            <Chip
                key={index}
                label={option}
                {...getTagProps({ index })}
                disabled={false}
            />
        ));
    };

    switch (fetchStatus) {
        case FETCH_STATUS.LOADING:
            return (
                <Stack style={{ ...ReloadButtonStyle, cursor: "default" }}>
                    <ZigupSpinner />
                </Stack>
            );
        case FETCH_STATUS.DONE:
            return (
                <Stack
                    direction={"column"}
                    style={{ width: "100%", marginBottom: "1rem" }}
                >
                    <NormalAutocomplete
                        value={selectedLocations}
                        multiple
                        id="preference-location-auto-complete"
                        title={title || "Preferred Location(s)"}
                        onChange={(event, newValue) => {
                            if (newValue.length > maxCount) return;
                            setSelectedLocations([...newValue]);
                        }}
                        options={locations}
                        isOptionEqualToValue={(option, _value) =>
                            option === _value
                        }
                        getOptionLabel={(option) => option}
                        renderTags={renderTag}
                        style={{ marginBottom: "0.5rem", width: "100%" }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            const isExisting = options.some(
                                (option) => inputValue === option.title
                            );
                            if (inputValue !== "" && !isExisting) {
                                filtered.push(inputValue);
                            }

                            return filtered;
                        }}
                        renderOption={(props, option) => {
                            const key = index.current + option;
                            index.current += 1;
                            return (
                                <li {...props} key={key}>
                                    {option}
                                </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={
                                    placeholder ||
                                    "Select preferred location(s)"
                                }
                                inputRef={preferredLocationInputRef}
                                // onKeyPress={(event) => {
                                //     if (event.key === "Enter") {
                                //         if (
                                //             !value.includes(
                                //                 preferredLocationInputRef.current
                                //                     ?.value
                                //             )
                                //         ) {
                                //             setValue([
                                //                 ...value,
                                //                 preferredLocationInputRef.current
                                //                     ?.value,
                                //             ]);
                                //         }
                                //     }
                                // }}
                            />
                        )}
                    />
                    {preferredLocations.length > 0 ? (
                        <Stack
                            direction={"row"}
                            style={{ width: "100%", flexWrap: "wrap" }}
                        >
                            {renderChips()}
                        </Stack>
                    ) : null}
                </Stack>
            );
        case FETCH_STATUS.FAILED:
            return (
                <Stack
                    id="fetch-skill-btn"
                    style={ReloadButtonStyle}
                    onClick={fetchData}
                    onMouseEnter={() => setOnMouse(true)}
                    onMouseLeave={() => setOnMouse(false)}
                >
                    <Text
                        style={
                            onMouse
                                ? {
                                      color: Colors.purple_blue,
                                      fontSize: "1rem",
                                  }
                                : {
                                      color: Colors.text_grey,
                                      fontSize: "1rem",
                                  }
                        }
                    >
                        Reload skill sets
                    </Text>
                </Stack>
            );
        default:
            return null;
    }
};

export default PreferenceLocationAutoComplete;
