import Cookies from "universal-cookie";
const cookies = new Cookies();

export const setCookie = (key, value, option) => {
    cookies.set(key, value, { ...option });
};

export const getCookie = (key) => {
    return cookies.get(key, { path: "/" });
};

export const removeCookie = (key) => {
    cookies.remove(key, { path: "/" });
};
