import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { education } from "../../communication/membership";
import EducationCourseSelector from "./EducationCourseSelector";
import { FETCH_STATUS } from "constants/constants";
import EducationSpecializationAutoComplete from "./EducationSpecializationAutoComplete";
import EducationUniversityAutoComplete from "./EducationUniversityAutoComplete";
import { Logger } from "utils";

const Education = (props) => {
    const {
        isMobile,
        defaultEducationCourse,
        defaultEducationSpecialization,
        defaultEducationUniversity,
        educationCourse,
        educationSpecialization,
        educationUniversity,
        setEducationCourse,
        setEducationSpecialization,
        setEducationUniversity,
    } = props;

    const [course, setCourse] = useState([]);
    const [specializations, setSpecializations] = useState([]);
    const [universities, setUniversities] = useState([]);
    const [fetchStatus, setFetchStatus] = useState(FETCH_STATUS.DEFAULT);

    useEffect(() => {
        fetchEducation();
        return () => {
            setCourse([]);
            setSpecializations([]);
            setUniversities([]);
        };
    }, []);

    const fetchEducation = async () => {
        try {
            setFetchStatus(FETCH_STATUS.LOADING);
            const res = await education();
            setCourse(res.educations.course);
            setSpecializations(res.educations.specializations);
            setUniversities(res.educations.universities);
            setFetchStatus(FETCH_STATUS.DONE);
        } catch (e) {
            Logger.log(e);
            setFetchStatus(FETCH_STATUS.FAILED);
        }
    };
    return (
        <Stack style={{ width: "100%" }}>
            <EducationCourseSelector
                isMobile={isMobile}
                defaultValue={defaultEducationCourse}
                value={educationCourse}
                setValue={setEducationCourse}
                fetchStatus={fetchStatus}
                fetchData={course}
                reload={fetchEducation}
            />
            <EducationSpecializationAutoComplete
                isMobile={isMobile}
                defaultValue={defaultEducationSpecialization}
                value={educationSpecialization}
                setValue={setEducationSpecialization}
                fetchStatus={fetchStatus}
                fetchData={specializations}
                reload={fetchEducation}
            />
            <EducationUniversityAutoComplete
                isMobile={isMobile}
                defaultValue={defaultEducationUniversity}
                value={educationUniversity}
                setValue={setEducationUniversity}
                fetchStatus={fetchStatus}
                fetchData={universities}
                reload={fetchEducation}
            />
        </Stack>
    );
};

export default Education;
