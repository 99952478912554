import React, { useContext, useEffect, useRef, useState } from "react";
import Text from "components/atoms/Text";
import { useMediaQuery } from "react-responsive";
import Stack from "@mui/material/Stack";
import Colors from "themes/colors";
import { useNavigate, useOutletContext } from "react-router";
import NormalTextField from "components/atoms/NormalTextField";
import { isPhoneNumber, isRequired } from "utils/validator";
import InputAdornment from "@mui/material/InputAdornment";
import SkillSetAutocomplete from "components/organisms/SkillSetAutocomplete";
import FileUploadButton from "components/organisms/FileUploadButton";
import Checkbox from "@mui/material/Checkbox";
import TechnicalAreaSelector from "components/organisms/TechnicalAreaSelector";
import YearSelector from "components/organisms/YearSelector";
import NoticePeriodSelector from "components/organisms/NoticePeriodSelector";
import CompanyAutocomplete from "components/organisms/CompanyAutoComplete";
import Typo from "components/atoms/Typo";
import { AuthContext } from "../../../context/AuthContext";
import { Collapse, Container } from "@mui/material";
import ZigupButton from "../../atoms/ZigupButton";
import { apply, applyWithAccessToken, detail } from "communication/jd";
import ReactGA from "react-ga4";
import saveDiskIcon from "assets/icons/save.png";
import { showSignUpModal } from "../../organisms/GlobalHeader";
import ConfirmModal from "../../organisms/ConfirmModal";
import { useLocation } from "react-router-dom";
import ZigupSpinner from "components/organisms/ZigupSpinner";
import MobileTextField from "components/organisms/MobileTextField";
import CVModal from "../../organisms/CVModal";
import LoginInducerModal from "components/organisms/LoginInducerModal";
import NormalDatePicker from "../../atoms/NormalDatePicker";
import moment from "moment";
import { myInformationByClientData } from "../../../communication/membership";
import { Logger } from "utils";
import PreferenceLocationAutoComplete from "components/organisms/PreferenceLocationAutoComplete";

const JdApplyPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { jdId } = useOutletContext();
    const { userState, getTmpLoginHash, getLoginInducerModalWatchCnt } =
        useContext(AuthContext);
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const [pageLoadCounter, setPageLoadCounter] = useState(0);
    const [isOtpValid, setIsOtpValid] = useState(false);
    const [name, setName] = useState("");
    const [organisation, setOrganisation] = useState("");
    const [designation, setDesignation] = useState("");
    const [experience, setExperience] = useState("");
    const [skillSet, setSkillSet] = useState([]);
    const [subSkillSet, setSubSkillSet] = useState([]);
    const [mobile, setMobile] = useState("");
    const [salary, setSalary] = useState({ current: "", expected: "" });
    const [noticePeriod, setNoticePeriod] = useState("");
    const [file, setFile] = useState(undefined);
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
    const [allowJobSuggestion, setAllowJobSuggestion] = useState(true);
    const [isLastWorkingDayChecked, setIsLastWorkingDayChecked] =
        useState(false);
    const [dateOfLastWorkingDay, setDateOfLastWorkingDay] = useState(
        moment(null, "DD/MM/YYYY")
    );
    const [currentLocation, setCurrentLocation] = useState([]);
    const [answers, setAnswers] = useState([]);
    const agreeReceiveCheckBoxRef = useRef();
    const lastWorkingDayCheckBoxRef = useRef();
    const [techArea, setTechArea] = useState({
        area: { value: "" },
        role: { value: [] },
    });
    const [techAreaOthers, setTechAreaOthers] = useState("");
    const [jd, setJd] = useState(undefined);
    const [selectedCV, setSelectedCV] = useState(undefined);
    const scrollToTop = () => window.scrollTo(0, 0);
    const [confirmModalState, setConfirmModalState] = useState({
        open: false,
        title: "",
        content: "",
        image: {
            src: "",
            alt: "",
        },
        button: {
            confirm: {
                text: "",
                id: "",
                handle: () => undefined,
            },
            reject: {
                text: "",
                id: "",
                handle: () => undefined,
            },
        },
        closeModal: () => undefined,
        closeOnEsc: true,
        closeOnOverlayClick: true,
    });
    const [cvModalState, setCvModalState] = useState({
        open: false,
        closeModal: () => undefined,
    });
    const [showLoginModal, setShowLoginModal] = useState(false);

    const onHandleSelected = (cv) => {
        closeCvModal();
        setSelectedCV(cv);
    };

    const handleConfirmExpiredPressed = () => {
        closeConfirmModal();
        navigate("/");
    };

    useEffect(async () => {
        const parseJdId = () => {
            const arrIds = jdId.split("-");
            return arrIds[arrIds.length - 1];
        };
        const parsedJdId = parseJdId();
        try {
            const res = await detail(parsedJdId);
            setJd(res.jd);
            if (!res.jd?.opened) {
                openConfirmModal({
                    open: true,
                    title: "This job is expired",
                    content: "Ask Team Zigup for further update.",
                    button: {
                        confirm: {
                            text: "GOT IT",
                            id: "jd-close-confirm-apply-btn",
                            handle: handleConfirmExpiredPressed,
                        },
                    },
                    closeModal: closeConfirmModal,
                    closeOnEsc: false,
                    closeOnOverlayClick: false,
                });
            }
        } catch (e) {
            navigate("/");
        }
        return () => {
            setJd(undefined);
            setNoticePeriod("");
            setTechArea({
                area: { value: "" },
                role: { value: [] },
            });
        };
    }, [jdId]);

    useEffect(() => {
        if (jd) {
            scrollToTop();
            initializeAnswers();
        }
    }, [jd]);

    useEffect(() => {
        const cnt = getLoginInducerModalWatchCnt();
        const hash = getTmpLoginHash();
        if (!userState.auth && !hash && cnt < 2) {
            setShowLoginModal(true);
        }
    }, []);

    useEffect(async () => {
        const initData = () => {
            setName("");
            setMobile("");
            setIsOtpValid(false);
            setOrganisation("");
            setDesignation("");
            setExperience("");
            setTechArea({ area: { value: "" }, role: { value: [] } });
            setTechAreaOthers("");
            setSkillSet([]);
            setSubSkillSet([]);
            setSalary({ current: "", expected: "" });
            setNoticePeriod("");
            setSelectedCV(undefined);
            setIsLastWorkingDayChecked(false);
            setDateOfLastWorkingDay(moment(null, "DD/MM/YYYY"));
            initializeAnswers();
        };

        if (userState.auth && Object.keys(userState.info).length) {
            setName(userState.info.name);
            setMobile(userState.info.phoneNumber);
            setOrganisation(userState.info.company);
            setDesignation(userState.info.designation);
            setExperience(userState.info.relevantExperience);
            if (userState.info?.customInfo?.area.custom) {
                setTechArea({
                    area: { value: userState.info.customInfo?.area.custom.key },
                });
                setTechAreaOthers(userState.info.customInfo?.area.custom.value);
            } else {
                setTechArea({
                    area: userState.info.customInfo?.area,
                    role: userState.info.customInfo?.role,
                });
            }
            setSkillSet(userState.info.skillset?.main);
            setSubSkillSet(userState.info.skillset?.sub);
            setSalary({
                current: userState.info.currentSalary,
                expected: userState.info.desiredSalary,
            });
            setNoticePeriod(userState.info.noticePeriod);
            setIsLastWorkingDayChecked(!!userState.info.lastWorkingDay);
            setDateOfLastWorkingDay(
                userState.info.lastWorkingDay
                    ? moment(userState.info.lastWorkingDay, "DD/MM/YYYY")
                    : moment(null, "DD/MM/YYYY")
            );
        } else if (getTmpLoginHash()) {
            try {
                const res = await myInformationByClientData({
                    hash: getTmpLoginHash(),
                });
                if (res) {
                    setName(res.user.name);
                    setMobile(res.user.phoneNumber);
                    setOrganisation(res.user.company);
                    setDesignation(res.user.designation);
                    setExperience(res.user.relevantExperience);
                    if (res.user?.customInfo?.area.custom) {
                        setTechArea({
                            area: {
                                value: userState.info.customInfo?.area.custom
                                    .key,
                            },
                        });
                        setTechAreaOthers(
                            res.user.customInfo?.area.custom.value
                        );
                    } else {
                        setTechArea({
                            area: res.user.customInfo?.area,
                            role: res.user.customInfo?.role,
                        });
                    }
                    setSkillSet(res.user.skillset?.main);
                    setSubSkillSet(res.user.skillset?.sub);
                    setSalary({
                        current: res.user.currentSalary,
                        expected: res.user.desiredSalary,
                    });
                    setNoticePeriod(res.user.noticePeriod);
                    setIsLastWorkingDayChecked(!!res.user.lastWorkingDay);
                    setDateOfLastWorkingDay(
                        res.user.lastWorkingDay
                            ? moment(res.user.lastWorkingDay, "DD/MM/YYYY")
                            : moment(null, "DD/MM/YYYY")
                    );
                }
            } catch (e) {
                Logger.error(e);
                initData();
            }
        } else {
            initData();
        }
    }, [userState.auth, userState.info, jd]);

    useEffect(() => {
        const hash = getTmpLoginHash();
        const check =
            !!name &&
            isPhoneNumber(mobile.replace(/(-)/g, "")).valid &&
            (userState.auth || hash || isOtpValid) &&
            !!organisation &&
            !!designation &&
            isRequired("" + experience).valid &&
            skillSet.length > 0 &&
            salary.current > 0 &&
            salary.expected > 0 &&
            isRequired(noticePeriod).valid &&
            noticePeriod !== "" &&
            (isLastWorkingDayChecked
                ? !!dateOfLastWorkingDay?._isValid
                : true) &&
            answers
                .filter((answer) => answer.necessary === true)
                .every((filtered) => !!filtered.answer) &&
            (techArea.area.value === "others"
                ? techAreaOthers
                : techArea.role.value.length > 0) &&
            (userState.auth
                ? isRequired(selectedCV?._id).valid
                : file !== undefined) &&
            (!userState.auth && !hash ? !!currentLocation.length : true);
        setIsValid(check);
        return () => {
            setIsValid(false);
        };
    }, [
        name,
        mobile,
        isOtpValid,
        organisation,
        designation,
        experience,
        skillSet,
        salary,
        noticePeriod,
        file,
        answers,
        techArea,
        techAreaOthers,
        selectedCV,
        isLastWorkingDayChecked,
        dateOfLastWorkingDay,
        currentLocation,
    ]);

    useEffect(() => {
        if (userState.auth) {
            ReactGA.event({
                category: "Apply",
                action: "jd_apply_page_start_with_login",
            });
        } else if (getTmpLoginHash()) {
            ReactGA.event({
                category: "Apply",
                action: "jd_apply_page_start_with_hash",
            });
        } else {
            ReactGA.event({
                category: "Apply",
                action: "jd_apply_page_start_without_login",
            });
        }
        setPageLoadCounter(1);
    }, []);

    useEffect(() => {
        if (!pageLoadCounter) return;
        if (userState.auth) {
            ReactGA.event({
                category: "Apply",
                action: "jd_apply_page_login",
            });
        } else {
            ReactGA.event({
                category: "Apply",
                action: "jd_apply_page_logout",
            });
        }
    }, [userState.auth]);

    const initializeAnswers = () => {
        if (!jd) return;
        const { questions } = jd;
        if (!questions || questions.length === 0) return;
        const _tmp = [];
        for (const _question of questions) {
            _tmp.push({
                questionId: _question._id,
                question: _question.content,
                necessary: _question.necessary,
                answer: "",
            });
        }
        setAnswers(_tmp);
    };

    const openConfirmModal = (params) => {
        setConfirmModalState(params);
    };

    const closeConfirmModal = () => {
        setConfirmModalState({
            ...confirmModalState,
            open: false,
        });
    };

    const closeCvModal = () => {
        setCvModalState({
            ...cvModalState,
            open: false,
        });
    };

    const onApplyPressed = async () => {
        if (userState.auth || getTmpLoginHash()) {
            await applyProc();
        } else {
            openConfirmModal({
                open: true,
                titleStyle: {
                    fontFamily: "Lato",
                    fontWeight: 700,
                },
                title: "Login to save your information",
                contentStyle: {
                    color: "#646464",
                },
                content:
                    "You can apply, but need to write all the information at next application.\n" +
                    "\n" +
                    "Save and load information with Simple Signup",
                button: {
                    reject: {
                        style: {
                            width: "max-content",
                            height: "max-content",
                            backgroundColor: "transparent",
                        },
                        text: "APPLY ANYWAY",
                        textStyle: {
                            fontSize: "1.2rem",
                            color: "#000000",
                        },
                        id: "application-modal-apply-anyway",
                        handle: applyProc,
                    },
                    confirm: {
                        style: {
                            width: "max-content",
                            height: "max-content",
                            backgroundColor: "transparent",
                        },
                        text: "CANCEL",
                        textStyle: {
                            fontSize: "1.2rem",
                            color: Colors.primary_purple,
                        },
                        id: "application-modal-apply-cancel",
                        handle: closeConfirmModal,
                    },
                },
                closeModal: closeConfirmModal,
                loginButtonState: {
                    show: true,
                    onClickHandler: () => {
                        closeConfirmModal();
                        showSignUpModal(location.pathname);
                    },
                },
            });
        }
    };

    const applyProc = async () => {
        if (isLoading) return;
        setIsLoading(true);
        setShowLoadingIndicator(true);
        const hash = getTmpLoginHash();
        if (userState.auth) {
            ReactGA.event({
                category: "Apply",
                action: "do_apply_pressed_with_login",
            });
        } else if (hash) {
            ReactGA.event({
                category: "Apply",
                action: "do_apply_pressed_with_hash",
            });
        } else {
            ReactGA.event({
                category: "Apply",
                action: "do_apply_pressed_without_login",
            });
        }

        try {
            const defaultParams = {
                jdId: jd._id,
                name: name,
                company: organisation,
                designation: designation,
                relevantExperience: experience,
                skillset: { main: skillSet, sub: subSkillSet },
                phoneNumber: mobile.replace(/(-)/g, ""),
                noticePeriod,
                currentSalary: salary.current,
                desiredSalary: salary.expected,
                allowJobSuggestion: allowJobSuggestion,
                role:
                    techArea.area.value === "others"
                        ? techAreaOthers
                        : techArea.role.value[0],
            };
            const formData = new FormData();
            const params = {
                ...defaultParams,
            };

            if (hash && !userState.auth) params.hash = hash;
            if (answers.length > 0) params.questionAndAnswers = answers;
            if (dateOfLastWorkingDay._isValid)
                params.lastWorkingDay =
                    dateOfLastWorkingDay.format("DD/MM/YYYY");
            if (currentLocation.length) {
                params.currentLocation = currentLocation[0];
            }
            if (userState.auth) {
                params.cv = selectedCV;
            } else {
                formData.append("file", file);
            }
            formData.append("params", JSON.stringify(params));

            const res = userState.auth
                ? await applyWithAccessToken(formData)
                : await apply(formData);

            if (res) {
                setIsLoading(false);
                setShowLoadingIndicator(false);
                if (userState.auth) {
                    ReactGA.event({
                        category: "Apply",
                        action: "apply_done_with_login",
                    });
                } else if (hash) {
                    ReactGA.event({
                        category: "Apply",
                        action: "apply_done_with_hash",
                    });
                } else {
                    ReactGA.event({
                        category: "Apply",
                        action: "apply_done_without_login",
                    });
                }
                navigate("./../done", {
                    state: { result: "success", jdId: jd._id },
                });
            } else {
                setIsLoading(false);
                setShowLoadingIndicator(false);
                alert("Failed to apply. please try again");
            }
        } catch (e) {
            setIsLoading(false);
            setShowLoadingIndicator(false);
            switch (e.response?.status) {
                case 409:
                    navigate("./../done", { state: { result: "duplicate" } });
                    break;
                default:
                    alert("Failed to apply. please try again");
                    break;
            }
        }
    };

    const renderTitle = () => {
        return (
            <Stack
                justifyContent="center"
                alignItems="flex-start"
                style={{ width: "100%" }}
            >
                <Typo.H2 style={{ fontSize: 24, marginBottom: 16 }}>
                    Application
                </Typo.H2>
            </Stack>
        );
    };

    const renderLoginInducer = () => {
        return userState.auth ? (
            <Stack
                direction={"row"}
                justifyContent="flex-start"
                alignItems="center"
                style={{
                    width: "100%",
                    height: "3rem",
                    marginBottom: "1rem",
                    backgroundColor: Colors.direct_hiring_background,
                }}
            >
                <Stack
                    direction={"row"}
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                    spacing={2}
                >
                    <Stack
                        style={{
                            width: "1.5rem",
                            height: "1.5rem",
                        }}
                    >
                        <img
                            src={saveDiskIcon}
                            alt="saveIcon"
                            width="100%"
                            height="100%"
                        />
                    </Stack>
                    <Typo.Body2
                        style={{
                            color: Colors.jd_text_grey,
                            flex: 1,
                        }}
                    >
                        These information will be automatically saved and loaded
                        when logged in, each time you apply
                    </Typo.Body2>
                </Stack>
            </Stack>
        ) : null;
    };

    const renderInputs = () => {
        const hash = getTmpLoginHash();

        return (
            <Stack
                sx={{
                    width: "100%",
                    height: "max-content",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <NormalTextField
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    title="Name"
                    placeholder="Your full name"
                />
                <MobileTextField
                    value={
                        userState.auth ? userState.info?.phoneNumber : mobile
                    }
                    disabled={userState.auth}
                    onChange={setMobile}
                    isOtpValid={isOtpValid}
                    setIsOtpValid={setIsOtpValid}
                    needOTP={!userState.auth && !hash}
                    showTitle={true}
                />
                <CompanyAutocomplete
                    isMobile={isMobile}
                    value={organisation}
                    setValue={setOrganisation}
                    title="Current/Last Company"
                    placeholder="e.g. Paytm"
                />
                <NormalTextField
                    value={designation || ""}
                    onChange={(event) =>
                        setDesignation(event.target.value || "")
                    }
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    title="Designation"
                    placeholder="Your designation"
                />
                <YearSelector
                    id="jd-experience-selector"
                    value={experience}
                    setValue={setExperience}
                />
                <TechnicalAreaSelector
                    isMobile={isMobile}
                    techArea={techArea}
                    setTechArea={setTechArea}
                    techAreaOthersRole={techAreaOthers}
                    setTechAreaOthersRole={setTechAreaOthers}
                />
                <SkillSetAutocomplete
                    id={"main-skillSet-AutoComplete"}
                    title={"Main skills"}
                    value={skillSet}
                    setValue={setSkillSet}
                    isMobile={isMobile}
                    style={{ marginBottom: "1rem" }}
                />
                <SkillSetAutocomplete
                    id={"sub-skillSet-AutoComplete"}
                    title={"Sub skills (Optional)"}
                    value={subSkillSet}
                    setValue={setSubSkillSet}
                    isMobile={isMobile}
                    style={{ marginBottom: "1rem" }}
                />
                <NormalTextField
                    value={salary.current}
                    onChange={(event) =>
                        setSalary({
                            ...salary,
                            current: event.target.value,
                        })
                    }
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    title="Current salary"
                    placeholder="e.g. 10"
                    type="number"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <Text style={{ color: Colors.purple_blue }}>
                                    Lakh per Annum
                                </Text>
                            </InputAdornment>
                        ),
                    }}
                />
                <NormalTextField
                    value={salary.expected}
                    onChange={(event) =>
                        setSalary({
                            ...salary,
                            expected: event.target.value,
                        })
                    }
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    title="Expected salary"
                    placeholder="e.g. 15"
                    type="number"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <Text style={{ color: Colors.purple_blue }}>
                                    Lakh per Annum
                                </Text>
                            </InputAdornment>
                        ),
                    }}
                />
                <NoticePeriodSelector
                    id="jd-notice-period-selector"
                    isMobile={isMobile}
                    noticePeriod={noticePeriod}
                    setNoticePeriod={setNoticePeriod}
                    isLastWorkingDayChecked={isLastWorkingDayChecked}
                    setIsLastWorkingDayChecked={setIsLastWorkingDayChecked}
                    dateOfLastWorkingDay={dateOfLastWorkingDay}
                    setDateOfLastWorkingDay={setDateOfLastWorkingDay}
                />
                {!userState.auth && !hash ? (
                    <PreferenceLocationAutoComplete
                        title="Current location (Only 1)"
                        placeholder="Select one location"
                        maxCount={1}
                        setValue={setCurrentLocation}
                    />
                ) : null}
            </Stack>
        );
    };

    const renderQuestions = () => {
        return jd.questions && jd.questions?.length ? (
            <Stack sx={{ marginTop: "2rem", width: "100%" }}>
                <Typo.H2 style={{ fontSize: 20, marginBottom: "1rem" }}>
                    Additional Questions
                </Typo.H2>
                {jd.questions?.map((question, index) => {
                    return (
                        <NormalTextField
                            key={index}
                            defaultValue={answers[index]?.answer}
                            onChange={(event) => {
                                const tmp = [...answers];
                                tmp[index] = {
                                    ...tmp[index],
                                    answer: event.target.value,
                                };
                                setAnswers(tmp);
                            }}
                            containerStyle={{ marginBottom: "1rem" }}
                            fullWidth
                            title={
                                question.content ||
                                "Question" +
                                    (question.necessary ? "" : " (Optional)")
                            }
                        />
                    );
                })}
            </Stack>
        ) : null;
    };

    return jd ? (
        <>
            <Container maxWidth="md" sx={{ padding: 2 }}>
                <Stack
                    direction="column"
                    spacing={2}
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Stack
                        direction={"column"}
                        style={{
                            padding: "1rem",
                            width: "100%",
                            height: "max-content",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {renderTitle()}
                        {renderLoginInducer()}
                        {renderInputs()}
                        {renderQuestions()}
                    </Stack>
                    {userState.auth ? (
                        <ZigupButton
                            id={"jd-apply-cv-btn"}
                            containerStyle={{
                                border: "1px solid #d7daff",
                                alignItems: "center",
                                justifyContent: "center",
                                maxHeight: "max-content",
                                backgroundColor: Colors.white,
                                width: "100%",
                                cursor: "pointer",
                            }}
                            buttonText={
                                selectedCV ? selectedCV.name : "Attach CV"
                            }
                            buttonTextStyle={{
                                fontSize: "1.2rem",
                                color: Colors.primary_purple,
                                maxWidth: "100%",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 1,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                wordBreak: "break-all",
                            }}
                            onClickHandler={() =>
                                setCvModalState({
                                    ...cvModalState,
                                    closeModal: closeCvModal,
                                    open: true,
                                })
                            }
                        />
                    ) : (
                        <FileUploadButton
                            isMobile={isMobile}
                            value={file}
                            setValue={setFile}
                        />
                    )}

                    <ZigupButton
                        id="jd-apply-proc-btn"
                        disabled={!isValid || isLoading}
                        containerStyle={{ height: "4rem" }}
                        buttonText={"Apply"}
                        buttonTextSize={"1.5rem"}
                        onClickHandler={onApplyPressed}
                    />
                    <Stack
                        id="jd-agree-check-btn"
                        direction="row"
                        spacing={1}
                        justifyContent="flex-start"
                        alignItems="center"
                        style={{
                            width: "100%",
                            cursor: "pointer",
                            marginLeft: "-1rem",
                            marginBottom: "1rem",
                        }}
                        onClick={() => {
                            agreeReceiveCheckBoxRef.current?.click();
                        }}
                    >
                        <Checkbox
                            checked={!!allowJobSuggestion}
                            onChange={(e) => {
                                setAllowJobSuggestion(e.target.checked);
                            }}
                            inputRef={agreeReceiveCheckBoxRef}
                            style={{ pointerEvents: "none" }}
                        />
                        <Text
                            style={
                                isMobile
                                    ? {
                                          fontSize: "0.8rem",
                                          fontColor: "#646464",
                                      }
                                    : { fontSize: "1rem", fontColor: "#646464" }
                            }
                        >
                            Receive similar job suggestions on my mobile number
                        </Text>
                    </Stack>
                </Stack>
                {showLoadingIndicator ? (
                    <div
                        style={{
                            position: "fixed",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                            backgroundColor: "rgba(0, 0, 0, 0.2)",
                            width: "100vw",
                            height: "100vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: 20,
                        }}
                    >
                        <ZigupSpinner size="2rem" />
                    </div>
                ) : null}
                <ConfirmModal
                    open={confirmModalState.open}
                    title={confirmModalState.title}
                    content={confirmModalState.content}
                    image={confirmModalState.image}
                    button={confirmModalState.button}
                    closeModal={confirmModalState.closeModal}
                    closeOnOverlayClick={confirmModalState.closeOnOverlayClick}
                    closeOnEsc={confirmModalState.closeOnEsc}
                    titleStyle={confirmModalState.titleStyle}
                    loginButtonState={confirmModalState.loginButtonState}
                    isLoading={isLoading}
                    smallSize={true}
                />
                <CVModal
                    open={cvModalState.open}
                    closeModal={cvModalState.closeModal}
                    handleSelect={(cv) => onHandleSelected(cv)}
                />
            </Container>
            <LoginInducerModal
                open={showLoginModal}
                closeModal={() => {
                    setShowLoginModal(false);
                }}
            />
        </>
    ) : (
        <Container maxWidth="md" sx={{ minHeight: "100vh" }} />
    );
};

export default JdApplyPage;
