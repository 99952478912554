import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router";
import GlobalHeader from "components/organisms/GlobalHeader";
import Footer from "components/organisms/Footer";
import { Stack } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import SearchTextField from "components/atoms/SearchTextField";
import SalaryCard from "components/organisms/salary/SalaryCard";
import QueryString from "qs";
import ZigupSpinner from "../../organisms/ZigupSpinner";
import { Helmet } from "react-helmet-async";
import { capitalize } from "utils/TextUtils";
import ViewMoreButton from "components/organisms/ViewMoreButton";
import { search } from "communication/search";
import JdCard from "components/organisms/jd/JdCard";
import Text from "components/atoms/Text";
import Colors from "themes/colors";
import ForumCard from "components/organisms/forum/ForumCard";
import { companyCuratedList } from "communication/company";
import CompanyLongCard from "components/organisms/company/CompanyLongCard";
import CompanyCard from "components/organisms/company/CompanyCard";

const SearchPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const MAX_CONTENTS_COUNT = 4;
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const [results, setResults] = useState({});
    const [curatedCompanies, setCuratedCompanies] = useState([]);
    const [isLoadingCurated, setIsLoadingCurated] = useState(true);
    const [isLoadingResults, setIsLoadingResults] = useState(true);
    const { keyword } = QueryString.parse(location.search, {
        ignoreQueryPrefix: true,
    });

    useEffect(() => {
        fetchCuratedCompanies();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);

        fetchResults();
        return () => {
            setResults({});
        };
    }, [keyword]);

    useEffect(() => {
        // results가 들어오면 isLoading을 바꾼다
        if (Object.keys(results).length && isLoadingResults) {
            setIsLoadingResults(false);
        }
    }, [results]);

    useEffect(() => {
        // results가 들어오면 isLoading을 바꾼다
        if (!!curatedCompanies.length && isLoadingCurated) {
            setIsLoadingCurated(false);
        }
    }, [curatedCompanies]);

    const fetchCuratedCompanies = async () => {
        try {
            setIsLoadingCurated(true);
            const res = await companyCuratedList();
            setCuratedCompanies(res.companies);
        } catch (e) {
            setIsLoadingCurated(false);
            Logger.error(e);
        }
    };

    const fetchResults = async () => {
        try {
            setIsLoadingResults(true);
            const res = await search({
                keyword,
            });
            setResults(res);
        } catch (e) {
            setIsLoadingResults(false);
            navigate(-1);
        }
    };

    const renderSearchBar = () => {
        return (
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                    minHeight: "8rem",
                    width: "100%",
                    backgroundColor: "#f0f1f5",
                }}
            >
                <SearchTextField
                    placeholder={
                        isMobile
                            ? "Company, Job title, Tech stack"
                            : "Search with Company, Job title, Tech stack"
                    }
                    prevSearchKeyword={keyword}
                />
            </Stack>
        );
    };

    const renderSalarySearchResult = () => {
        const pagination = results?.salaries?.pagination;
        const searchResults = results?.salaries?.salaries;

        return (
            <Stack
                direction={"column"}
                sx={{
                    marginBottom: "4rem",
                }}
            >
                <Text
                    style={{
                        fontSize: "1.25rem",
                        marginBottom: "0.5rem",
                        fontFamily: "Lato",
                        fontWeight: 400,
                    }}
                >
                    Salaries
                </Text>
                <Text
                    style={{
                        fontSize: "1rem",
                        marginBottom: "1rem",
                        color: Colors.text_grey,
                    }}
                >
                    {pagination?.totalCount} Results
                </Text>
                {isLoadingResults ? (
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            height: "250px",
                        }}
                    >
                        <ZigupSpinner size={"2rem"} />
                    </Stack>
                ) : searchResults?.length ? (
                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                        {searchResults?.map((salary, index) => (
                            <Grid
                                item
                                xs={isMobile ? 12 : 6}
                                key={index + "_salary"}
                            >
                                <SalaryCard salary={salary} />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{ width: "100%", height: "250px" }}
                    >
                        <Text>No posts</Text>
                    </Stack>
                )}
                {pagination?.totalCount > MAX_CONTENTS_COUNT ? (
                    <ViewMoreButton
                        href={`/salary/list?keyword=${keyword}&page=1`}
                    />
                ) : null}
            </Stack>
        );
    };

    const renderCompanySearchResult = () => {
        const pagination = results?.companies?.pagination;
        const searchResults = results?.companies?.companies || [];
        const filteredCuratedCompanies = curatedCompanies.filter((item) =>
            item.name.toLowerCase().includes((keyword || "").toLowerCase())
        );
        const companyCount =
            searchResults?.length || 0 + filteredCuratedCompanies.length;

        const renderCompanySearchCardResults = () => {
            if (isLoadingResults || isLoadingCurated)
                return (
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            height: "250px",
                        }}
                    >
                        <ZigupSpinner size={"2rem"} />
                    </Stack>
                );

            if (!companyCount)
                return (
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{ width: "100%", height: "250px" }}
                    >
                        <Text>No companies</Text>
                    </Stack>
                );

            if (filteredCuratedCompanies.length > 3)
                return (
                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                        {filteredCuratedCompanies
                            .slice(undefined, 6)
                            .map((company, index) => (
                                <Grid
                                    item
                                    xs={isMobile ? 6 : 4}
                                    key={index + "_company"}
                                >
                                    <CompanyLongCard company={company} />
                                </Grid>
                            ))}
                    </Grid>
                );

            if (filteredCuratedCompanies.length)
                return (
                    <Stack>
                        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                            {filteredCuratedCompanies
                                .slice(undefined, 3)
                                .map((company, index) => (
                                    <Grid
                                        item
                                        xs={isMobile ? 6 : 4}
                                        key={index + "_company"}
                                    >
                                        <CompanyLongCard company={company} />
                                    </Grid>
                                ))}
                        </Grid>
                        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                            {searchResults
                                .slice(undefined, 4)
                                ?.map((company, index) => (
                                    <Grid
                                        item
                                        xs={isMobile ? 6 : 3}
                                        key={index + "_company"}
                                    >
                                        <CompanyCard company={company} />
                                    </Grid>
                                ))}
                        </Grid>
                    </Stack>
                );

            return (
                <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                    {searchResults
                        .slice(undefined, 8)
                        ?.map((company, index) => (
                            <Grid
                                item
                                xs={isMobile ? 6 : 3}
                                key={index + "_company"}
                            >
                                <CompanyCard company={company} />
                            </Grid>
                        ))}
                </Grid>
            );
        };

        return (
            <Stack
                direction={"column"}
                sx={{
                    marginBottom: "4rem",
                }}
            >
                <Text
                    style={{
                        fontSize: "1.25rem",
                        marginBottom: "0.5rem",
                        fontFamily: "Lato",
                        fontWeight: 400,
                    }}
                >
                    Companies
                </Text>
                <Text
                    style={{
                        fontSize: "1rem",
                        marginBottom: "1rem",
                        color: Colors.text_grey,
                    }}
                >
                    {pagination?.totalCount + filteredCuratedCompanies.length}{" "}
                    Results
                </Text>
                {renderCompanySearchCardResults()}
                {pagination?.totalCount + filteredCuratedCompanies.length >
                MAX_CONTENTS_COUNT ? (
                    <ViewMoreButton
                        href={`/company/list?keyword=${keyword}&page=1`}
                    />
                ) : null}
            </Stack>
        );
    };

    const renderJobSearchResult = () => {
        const pagination = results?.jds?.pagination;
        const searchResults = results?.jds?.jds;
        return (
            <Stack
                direction={"column"}
                sx={{
                    marginBottom: "4rem",
                }}
            >
                <Text
                    style={{
                        fontSize: "1.25rem",
                        marginBottom: "0.5rem",
                        fontFamily: "Lato",
                        fontWeight: 400,
                    }}
                >
                    Jobs
                </Text>
                <Text
                    style={{
                        fontSize: "1rem",
                        marginBottom: "1rem",
                        color: Colors.text_grey,
                    }}
                >
                    {pagination?.totalCount} Results
                </Text>
                {isLoadingResults ? (
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            height: "250px",
                        }}
                    >
                        <ZigupSpinner size={"2rem"} />
                    </Stack>
                ) : searchResults?.length ? (
                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                        {searchResults?.map((jd, index) => (
                            <Grid
                                item
                                xs={isMobile ? 12 : 6}
                                key={index + "_jd"}
                            >
                                <JdCard jd={jd} />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{ width: "100%", height: "250px" }}
                    >
                        <Text>No posts</Text>
                    </Stack>
                )}
                {pagination?.totalCount > MAX_CONTENTS_COUNT ? (
                    <ViewMoreButton
                        href={`/jd/list?keyword=${keyword}&page=1`}
                    />
                ) : null}
            </Stack>
        );
    };

    const renderDiscussionSearchResult = () => {
        const pagination = results?.discussions?.pagination;
        const searchResults = results?.discussions?.discussions;
        return (
            <Stack
                direction={"column"}
                sx={{
                    marginBottom: "4rem",
                }}
            >
                <Text
                    style={{
                        fontSize: "1.25rem",
                        marginBottom: "0.5rem",
                        fontFamily: "Lato",
                        fontWeight: 400,
                    }}
                >
                    Discussion
                </Text>
                <Text
                    style={{
                        fontSize: "1rem",
                        marginBottom: "1rem",
                        color: Colors.text_grey,
                    }}
                >
                    {pagination?.totalCount} Results
                </Text>
                {isLoadingResults ? (
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            height: "250px",
                        }}
                    >
                        <ZigupSpinner size={"2rem"} />
                    </Stack>
                ) : searchResults?.length ? (
                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                        {searchResults?.map((item, index) => (
                            <Grid
                                item
                                xs={isMobile ? 12 : 6}
                                key={index + "_discussion"}
                            >
                                <ForumCard item={item} />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{ width: "100%", height: "250px" }}
                    >
                        <Text>No posts</Text>
                    </Stack>
                )}
                {pagination?.totalCount > MAX_CONTENTS_COUNT ? (
                    <ViewMoreButton
                        href={`/forum/list?type=discussion&keyword=${keyword}&page=1`}
                    />
                ) : null}
            </Stack>
        );
    };

    const renderLFRSearchResult = () => {
        const pagination = results?.LFRs?.pagination;
        const searchResults = results?.LFRs?.forumReferrals;
        return (
            <Stack
                direction={"column"}
                sx={{
                    marginBottom: "4rem",
                }}
            >
                <Text
                    style={{
                        fontSize: "1.25rem",
                        marginBottom: "0.5rem",
                        fontFamily: "Lato",
                        fontWeight: 400,
                    }}
                >
                    Looking for Referrals
                </Text>
                <Text
                    style={{
                        fontSize: "1rem",
                        marginBottom: "1rem",
                        color: Colors.text_grey,
                    }}
                >
                    {pagination?.totalCount} Results
                </Text>
                {isLoadingResults ? (
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            height: "250px",
                        }}
                    >
                        <ZigupSpinner size={"2rem"} />
                    </Stack>
                ) : searchResults?.length ? (
                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                        {searchResults?.map((item, index) => (
                            <Grid
                                item
                                xs={isMobile ? 12 : 6}
                                key={index + "_lfr"}
                            >
                                <ForumCard item={item} />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{ width: "100%", height: "250px" }}
                    >
                        <Text>No posts</Text>
                    </Stack>
                )}
                {pagination?.totalCount > MAX_CONTENTS_COUNT ? (
                    <ViewMoreButton
                        href={`/forum/list?type=LFR&keyword=${keyword}&page=1`}
                    />
                ) : null}
            </Stack>
        );
    };

    const renderLFCSearchResult = () => {
        const pagination = results?.LFCs?.pagination;
        const searchResults = results?.LFCs?.forumReferrals;
        return (
            <Stack
                direction={"column"}
                sx={{
                    marginBottom: "4rem",
                }}
            >
                <Text
                    style={{
                        fontSize: "1.25rem",
                        marginBottom: "0.5rem",
                        fontFamily: "Lato",
                        fontWeight: 400,
                    }}
                >
                    Looking for Candidates
                </Text>
                <Text
                    style={{
                        fontSize: "1rem",
                        marginBottom: "1rem",
                        color: Colors.text_grey,
                    }}
                >
                    {pagination?.totalCount} Results
                </Text>
                {isLoadingResults ? (
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            height: "250px",
                        }}
                    >
                        <ZigupSpinner size={"2rem"} />
                    </Stack>
                ) : searchResults?.length ? (
                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                        {searchResults?.map((item, index) => (
                            <Grid
                                item
                                xs={isMobile ? 12 : 6}
                                key={index + "_lfc"}
                            >
                                <ForumCard item={item} />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{ width: "100%", height: "250px" }}
                    >
                        <Text>No posts</Text>
                    </Stack>
                )}
                {pagination?.totalCount > MAX_CONTENTS_COUNT ? (
                    <ViewMoreButton
                        href={`/forum/list?type=LFC&keyword=${keyword}&page=1`}
                    />
                ) : null}
            </Stack>
        );
    };

    const getSEOTitle = () => {
        const roleKeywords = [
            "product panager",
            "data engineer",
            "data analyst",
            "data scientist",
            "sde",
            "sdet",
        ];
        const skillsetKeywords = [
            "angular",
            "react",
            "java",
            "devops",
            "python",
            "golang",
            ".net",
            "android",
            "aws",
        ];
        const _keyword = capitalize(keyword);
        if (roleKeywords.includes((keyword || "").toLowerCase()))
            return `${_keyword} Salary Breakup in India - Zigup`;

        if (skillsetKeywords.includes((keyword || "").toLowerCase()))
            return `${_keyword} Developer Salary breakup in India`;

        return _keyword
            ? `${_keyword} India Salary Breakup - Zigup`
            : "Salary Breakup for India by Zigup";
    };

    const getSEODescription = () => {
        const _keyword = capitalize(keyword);
        return _keyword
            ? `Get the latest salary breakup, jd, discussions, referrals of ${_keyword} India, Find ${_keyword} Salary Ranges by roles, experience & skills. Sign Up for Better Offers!`
            : "Get the latest salary breakup, jd, discussions, referrals of India, Find Salary Ranges by roles, experience & skills. Sign Up for Better Offers!";
    };

    const getSEOKeywords = () => {
        const _keyword = capitalize(keyword);
        return _keyword
            ? `jd, job, discussions, referrals, candidates, ${_keyword}, india, salary, ${_keyword} india salary, india salary breakdown`
            : "jd, job, discussions, referrals, candidates, india, salary, india salary, india salary breakdown";
    };

    const getSEOHelmet = () => {
        return (
            <Helmet>
                <title>{getSEOTitle()}</title>
                <meta
                    name="description"
                    property="description"
                    content={getSEODescription()}
                />
                <meta
                    name="keywords"
                    property="keywords"
                    content={getSEOKeywords()}
                />
                <meta content={getSEOTitle()} property="og:title" />
                <meta property="og:description" content={getSEODescription()} />
                <meta
                    content="https://hire.zigup.in/static/media/logo-horizontal.4663103fd0a4bca8ea17.png"
                    property="og:image"
                />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
        );
    };

    return (
        <>
            {getSEOHelmet()}
            <GlobalHeader />
            {renderSearchBar()}
            <Container
                maxWidth="lg"
                sx={{
                    marginTop: "32px",
                    minHeight: "100vh",
                }}
            >
                {renderSalarySearchResult()}
                {renderCompanySearchResult()}
                {renderJobSearchResult()}
                {renderDiscussionSearchResult()}
                {renderLFRSearchResult()}
                {renderLFCSearchResult()}
            </Container>
            <Footer />
        </>
    );
};
export default SearchPage;
