import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import Text from "components/atoms/Text";
import { useMediaQuery } from "react-responsive";
import Stack from "@mui/material/Stack";
import { Container } from "@mui/material";
import NormalTextField from "components/atoms/NormalTextField";
import { isRequired } from "utils/validator";
import ZigupButton from "../../atoms/ZigupButton";
import Typo from "../../atoms/Typo";
import CompanyAutocomplete from "../../organisms/CompanyAutoComplete";
import YearSelector from "../../organisms/YearSelector";
import NoticeUpdateInformation from "../../atoms/NoticeUpdateInformation";
import NavigationGuard from "../../organisms/NavigationGuard";
import { useStateCallback } from "../../atoms/useStateCallback";
import { setMyInformation } from "../../../communication/membership";
import ZigupSpinner from "components/organisms/ZigupSpinner";
import { AlertContext } from "context/AlertContext";
import { ALERT_TYPE } from "constants/constants";
import { Logger } from "utils";

const EditWorkExperiencePage = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const navigate = useNavigate();
    const { state } = useLocation();
    const { openAlert } = useContext(AlertContext);

    const [activeGuard, setActiveGuard] = useStateCallback(true);
    const [isValid, setIsValid] = useState(true);

    const [company, setCompany] = useState(state.userInfo.company);
    const [designation, setDesignation] = useState(state.userInfo.designation);
    const [experience, setExperience] = useState(
        state.userInfo.relevantExperience
    );
    const [isLoading, setIsLoading] = useState(false);
    const [pageText, setPageText] = useState({
        title: "Work experience",
    });
    useEffect(() => {
        // ReactGA.event({
        //     category: "SignUp",
        //     action: "sign_up_first_page_start",
        //     label: "sign_up",
        // });
    }, [state.userInfo]);

    useEffect(() => {
        const check =
            isRequired(company).valid &&
            isRequired(designation).valid &&
            experience > 0;
        setIsValid(check);
        return () => {
            setIsValid(false);
        };
    }, [company, designation, experience]);

    const save = async () => {
        if (isValid) {
            setActiveGuard(false, async () => {
                const userInfo = {
                    company,
                    designation,
                    relevantExperience: experience,
                };
                try {
                    setIsLoading(true);
                    const res = await setMyInformation(userInfo);
                    if (res) {
                        window.location.href = "/my/profile";
                    }
                } catch (e) {
                    openAlert({
                        showAlert: true,
                        text: "Failed to change data",
                        alertType: ALERT_TYPE.ERROR,
                    });
                    Logger.error(e);
                } finally {
                    setIsLoading(false);
                }
            });
        }
    };

    return (
        <Container maxWidth="md" sx={{ minHeight: "60vh" }}>
            <NavigationGuard when={activeGuard} />
            {isLoading ? (
                <div
                    style={{
                        position: "fixed",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "rgba(0, 0, 0, 0.2)",
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 20,
                    }}
                >
                    <ZigupSpinner size="2rem" />
                </div>
            ) : null}
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                    marginTop: "2rem",
                    marginBottom: "2rem",
                    width: "100%",
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ width: "100%" }}
                    spacing={1}
                >
                    <Typo.Button1
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/my/profile")}
                    >
                        My profile
                    </Typo.Button1>
                    <Typo.Button1>/</Typo.Button1>
                    <Typo.Button1>{pageText.title}</Typo.Button1>
                </Stack>
                <Stack
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{ width: "100%" }}
                >
                    <Text
                        style={{
                            fontSize: "2rem",
                            lineHeight: "3rem",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                        }}
                    >
                        {pageText.title}
                    </Text>
                </Stack>
                <Stack style={{ width: "100%", marginBottom: "1rem" }}>
                    <NoticeUpdateInformation />
                </Stack>
                <CompanyAutocomplete
                    isMobile={isMobile}
                    value={company}
                    setValue={setCompany}
                    title="Current/Last Company"
                    placeholder="e.g. Paytm"
                />
                <NormalTextField
                    value={designation || ""}
                    onChange={(event) =>
                        setDesignation(event.target.value || "")
                    }
                    containerStyle={{ marginBottom: "1rem" }}
                    fullWidth
                    title="Designation"
                    placeholder="Your designation"
                />
                <YearSelector
                    id="jd-experience-selector"
                    value={experience}
                    setValue={setExperience}
                />
                <ZigupButton
                    buttonText={"SAVE CHANGES"}
                    id={"my-profile-edit-tech-save-change-btn"}
                    disabled={!isValid || isLoading}
                    onClickHandler={save}
                />
            </Stack>
        </Container>
    );
};
export default EditWorkExperiencePage;
