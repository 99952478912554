import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import GlobalHeader from "components/organisms/GlobalHeader";
import Footer from "components/organisms/Footer";
import { Stack } from "@mui/material";

const CompanyPage = () => {
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!params.id) {
            navigate("/company/list");
        }
    }, [params]);

    return (
        <Stack direction="column" sx={{ minHeight: "100vh" }}>
            <Outlet
                context={{
                    companyId: params.id,
                }}
            />
        </Stack>
    );
};
export default CompanyPage;
