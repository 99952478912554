import { Stack } from "@mui/material";
import checkedImage from "assets/icons/navi-checked@3x.png";
import checkImage from "assets/icons/navi-check@3x.png";
import React from "react";
const ProfileCheck = ({ checked }) => {
    return (
        <Stack
            style={{
                width: "1.2rem",
                height: "1.2rem",
            }}
        >
            <img
                src={checked ? checkedImage : checkImage}
                alt="check-image"
                width="100%"
                height="100%"
            />
        </Stack>
    );
};
export default ProfileCheck;
