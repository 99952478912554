import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import { showSignUpModal } from "../../organisms/GlobalHeader";
import Typo from "../../atoms/Typo";
import ZigupButton from "../../atoms/ZigupButton";

const OfferAuthProtectPage = ({ isTmpPage = false }) => {
    useEffect(() => {
        showSignUpModal(isTmpPage ? "/offer/list" : null);
    }, []);

    return (
        <Stack
            direction={"column"}
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "60vh" }}
            spacing={4}
        >
            <Typo.H2>Sign in to view jobs received</Typo.H2>
            <ZigupButton
                id={"offer-auth-protect-page"}
                buttonText={"Sign In"}
                onClickHandler={() =>
                    showSignUpModal(isTmpPage ? "/offer/list" : null)
                }
            />
        </Stack>
    );
};

export default OfferAuthProtectPage;
