import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router";
import Done from "components/organisms/Done";
import { Container } from "@mui/material";

const ReferralDonePage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

    const pageText = {
        title: "It’s done!",
        desc: [
            "Referrer will review your profile and refer accordingly if your profile is suitable. \n\n",
            "For future communication with the referrer, you can reach ",
            "\n\nTo find similar opportunities, download Zigup app.",
        ],
        email: state.email,
    };

    useEffect(() => {
        if (!state) navigate("/");
    }, [state]);

    return state ? (
        <Container maxWidth="sm" style={{ marginBottom: "2rem" }}>
            <Done isMobile={isMobile} pageText={pageText} />
        </Container>
    ) : null;
};

export default ReferralDonePage;
