import React, { useEffect, useRef, useState } from "react";
import { fetchSkillSet } from "../../communication/jd";
import Chip from "@mui/material/Chip";
import { TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Colors from "themes/colors";
import Text from "components/atoms/Text";
import Stack from "@mui/material/Stack";
import NormalAutocomplete from "components/atoms/NormalAutoComplete";
import ZigupSpinner from "./ZigupSpinner";

const FETCH_STATUS = {
    DEFAULT: -1,
    LOADING: 1,
    DONE: 2,
    FAILED: 3,
};

const SkillSetAutocomplete = (props) => {
    const { id, isMobile, value, setValue, title, style, maxCount } = props;
    const [skills, setSkills] = useState([]);
    const [fetchStatus, setFetchStatus] = useState(FETCH_STATUS.DEFAULT);
    const [onMouse, setOnMouse] = useState(false);
    const skillSetInputRef = useRef();
    const filter = createFilterOptions();

    const ReloadButtonStyle = {
        border: onMouse ? "1px solid #6837f2" : "1px solid #d4d6dd",
        width: "100%",
        height: "3rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Colors.white,
        cursor: "pointer",
        marginBottom: "1rem",
    };

    const fetchSkills = async () => {
        try {
            setFetchStatus(FETCH_STATUS.LOADING);
            const res = await fetchSkillSet();
            setFetchStatus(FETCH_STATUS.DONE);
            setSkills(res.skillset);
        } catch (e) {
            setFetchStatus(FETCH_STATUS.FAILED);
        }
    };

    useEffect(() => {
        fetchSkills();
        return () => setSkills([]);
    }, []);

    switch (fetchStatus) {
        case FETCH_STATUS.LOADING:
            return (
                <Stack style={{ ...ReloadButtonStyle, cursor: "default" }}>
                    <ZigupSpinner size={"1rem"} />
                </Stack>
            );
        case FETCH_STATUS.DONE:
            return (
                <NormalAutocomplete
                    value={value || null}
                    multiple
                    freeSolo
                    clearOnBlur
                    id={id ? id : "skillSetAutoComplete"}
                    onChange={(event, newValue) => {
                        if (maxCount && newValue.length > maxCount) return;
                        setValue([...newValue]);
                    }}
                    options={skills}
                    title={title || "Skillsets"}
                    isOptionEqualToValue={(option, _value) =>
                        value.includes(option)
                    }
                    getOptionLabel={(option) => option}
                    renderTags={(tagValue, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                key={index}
                                label={option}
                                {...getTagProps({ index })}
                                disabled={false}
                            />
                        ))
                    }
                    style={{
                        width: "100%",
                        marginBottom: "1rem",
                        ...style,
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        const isExisting = options.some(
                            (option) => inputValue === option.title
                        );
                        if (inputValue !== "" && !isExisting) {
                            filtered.push(inputValue);
                        }

                        return filtered;
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Select Skillsets"
                            inputRef={skillSetInputRef}
                        />
                    )}
                />
            );
        case FETCH_STATUS.FAILED:
            return (
                <Stack
                    id="fetch-skill-btn"
                    style={ReloadButtonStyle}
                    onClick={fetchSkills}
                    onMouseEnter={() => setOnMouse(true)}
                    onMouseLeave={() => setOnMouse(false)}
                >
                    <Text
                        style={
                            onMouse
                                ? {
                                      color: Colors.purple_blue,
                                      fontSize: "1rem",
                                  }
                                : {
                                      color: Colors.text_grey,
                                      fontSize: "1rem",
                                  }
                        }
                    >
                        Reload skill sets
                    </Text>
                </Stack>
            );
        default:
            return null;
    }
};

export default SkillSetAutocomplete;
