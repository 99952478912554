import { Skeleton } from "@mui/material";
import styled from "styled-components";
import Colors from "themes/colors";

const TextContainer = styled.span`
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    color: ${Colors.black};
    display: table-cell;
    text-align: flex-start;
    vertical-align: middle;
    word-break: keep-all;
`;

const Text = (props) => {
    const {
        style,
        children,
        skeletonCount,
        skeletonWidth,
        skeletonHeight,
        showSkeleton = true,
        ...rest
    } = props;

    return (
        <TextContainer style={{ ...style }} {...rest}>
            {children === undefined || children === "" ? (
                showSkeleton ? (
                    skeletonCount ? (
                        new Array(skeletonCount)
                            .fill()
                            .map((_, index) => (
                                <Skeleton
                                    key={index + "_skeleton"}
                                    variant="text"
                                    width={"100%"}
                                    height={skeletonHeight || "1rem"}
                                />
                            ))
                    ) : (
                        <Skeleton
                            variant="text"
                            width={skeletonWidth || 200}
                            height={skeletonHeight || "1rem"}
                        />
                    )
                ) : null
            ) : (
                children
            )}
        </TextContainer>
    );
};

export default Text;
