import React, { useRef } from "react";
import Text from "components/atoms/Text";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styled from "styled-components";
import { Checkbox, Collapse, Stack } from "@mui/material";
import NormalDatePicker from "components/atoms/NormalDatePicker";

const InputTitleContainer = styled.div`
    align-items: flex-start;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 2px;
`;

const NoticePeriodSelector = (props) => {
    const {
        id,
        title,
        isMobile,
        noticePeriod,
        setNoticePeriod,
        getLastWorkingDay = true,
        isLastWorkingDayChecked,
        setIsLastWorkingDayChecked,
        dateOfLastWorkingDay,
        setDateOfLastWorkingDay,
        ...rest
    } = props;

    const lastWorkingDayCheckBoxRef = useRef();

    return (
        <Stack sx={{ width: "100%", marginBottom: "1rem" }}>
            <Stack
                sx={{
                    width: "100%",
                }}
            >
                <InputTitleContainer>
                    <Text style={{ fontSize: 12 }}>
                        {title || "Official Notice period"}
                    </Text>
                </InputTitleContainer>
                <FormControl sx={{ width: "100%" }}>
                    <Select
                        id={id || "notice-period-selector"}
                        value={noticePeriod || ""}
                        onChange={(event) =>
                            setNoticePeriod(event.target.value)
                        }
                        fullWidth={true}
                        {...rest}
                    >
                        <MenuItem value={"0"}>
                            <em>Immediately available</em>
                        </MenuItem>
                        <MenuItem value={"15"}>15 days</MenuItem>
                        <MenuItem value={"30"}>30 days</MenuItem>
                        <MenuItem value={"45"}>45 days</MenuItem>
                        <MenuItem value={"60"}>60 days</MenuItem>
                        <MenuItem value={"90"}>90 days</MenuItem>
                    </Select>
                </FormControl>
            </Stack>
            {getLastWorkingDay ? (
                <>
                    <Collapse in={!!noticePeriod} sx={{ width: "100%" }}>
                        <Stack
                            id="jd-last-working-day-check-btn"
                            direction="row"
                            spacing={1}
                            justifyContent="flex-start"
                            alignItems="center"
                            style={{
                                width: "100%",
                                cursor: "pointer",
                                marginLeft: "-0.5rem",
                                marginTop: "-0.5rem",
                                marginBottom: "0.5rem",
                            }}
                            onClick={() => {
                                lastWorkingDayCheckBoxRef.current?.click();
                            }}
                        >
                            <Checkbox
                                checked={!!isLastWorkingDayChecked}
                                onChange={(e) => {
                                    setIsLastWorkingDayChecked(
                                        e.target.checked
                                    );
                                }}
                                inputRef={lastWorkingDayCheckBoxRef}
                                style={{ pointerEvents: "none" }}
                            />
                            <Text
                                style={
                                    isMobile
                                        ? {
                                              fontSize: "0.75rem",
                                              fontColor: "#646464",
                                          }
                                        : {
                                              fontSize: "0.8rem",
                                              fontColor: "#646464",
                                          }
                                }
                            >
                                Already serving notice period
                            </Text>
                        </Stack>
                    </Collapse>
                    <Collapse
                        in={isLastWorkingDayChecked}
                        sx={{ width: "100%" }}
                    >
                        <NormalDatePicker
                            title="Last Working day"
                            views={["year", "month", "day"]}
                            value={dateOfLastWorkingDay}
                            setValue={setDateOfLastWorkingDay}
                            isMobile={isMobile}
                        />
                    </Collapse>
                </>
            ) : null}
        </Stack>
    );
};

export default NoticePeriodSelector;
