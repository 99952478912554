import styled from "styled-components";
import Colors from "themes/colors";
import { Paper, Stack } from "@mui/material";
import Typo from "components/atoms/Typo";
import { useMediaQuery } from "react-responsive";
import ZigupSpinner from "../ZigupSpinner";

const HoverWrapper = styled.div`
    &:hover {
        background-color: ${Colors.background_2};
    }
`;

const SearchCard = ({ keyword, logo, style, data }) => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

    const renderCounts = () => {
        return logo ? (
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    position: "absolute",
                    bottom: 4,
                    right: 4,
                    boxSizing: "border-box",
                }}
            >
                <div />
                <a
                    href={`/search/?keyword=${keyword}`}
                    style={{
                        textDecoration: "none",
                        color: Colors.light_grey,
                    }}
                >
                    <Typo.Body2
                        style={{
                            fontSize: "0.6rem",
                            color: Colors.light_grey,
                        }}
                    >
                        {typeof data?.salaryCount === "number"
                            ? data?.salaryCount
                            : "-"}{" "}
                        posts
                    </Typo.Body2>
                </a>
            </Stack>
        ) : (
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                    position: "absolute",
                    bottom: 4,
                    left: 0,
                    right: 0,
                }}
            >
                <Typo.Body2
                    style={{
                        fontSize: "0.6rem",
                        color: Colors.light_grey,
                    }}
                >
                    <a
                        href={`/salary/list/?keyword=${keyword}`}
                        style={{
                            textDecoration: "none",
                            color: Colors.light_grey,
                        }}
                    >
                        {typeof data?.salaryCount === "number"
                            ? data?.salaryCount
                            : "-"}{" "}
                        Salaries
                    </a>
                    {" | "}
                    <a
                        href={`/jd/list/?keyword=${keyword}`}
                        style={{
                            textDecoration: "none",
                            color: Colors.light_grey,
                        }}
                    >
                        {typeof data?.jdCount === "number"
                            ? data?.jdCount
                            : "-"}{" "}
                        Jobs
                    </a>
                </Typo.Body2>
            </Stack>
        );
    };

    return keyword ? (
        <Paper
            elevation={0}
            sx={{
                borderWidth: 1,
                borderColor: Colors.outline_2,
                borderStyle: "solid",
                position: "relative",
                justifyContent: "center",
            }}
        >
            <HoverWrapper>
                <a
                    href={`/search/?keyword=${keyword}`}
                    style={{ textDecoration: "none", color: "black" }}
                >
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        sx={{
                            minHeight: logo
                                ? isMobile
                                    ? "70px"
                                    : "90px"
                                : "60px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            boxSizing: "border-box",
                            position: "relative",
                            ...style,
                        }}
                    >
                        {logo ? (
                            isMobile ? (
                                <img
                                    src={logo}
                                    alt={`${keyword}Icon`}
                                    style={{
                                        maxWidth: "50%",
                                        maxHeight: "24px",
                                    }}
                                />
                            ) : (
                                <img
                                    src={logo}
                                    alt={`${keyword}Icon`}
                                    height="100%"
                                />
                            )
                        ) : (
                            <div style={{ width: "100%", height: "100%" }} />
                        )}
                        {logo ? null : (
                            <Typo.H4
                                style={{
                                    marginTop: 8,
                                    marginBottom: 8,
                                    marginLeft: 4,
                                    marginRight: 4,
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                    color: Colors.search_card_black,
                                    textAlign: "center",
                                    fontSize: isMobile ? "0.8rem" : "1rem",
                                }}
                            >
                                {keyword}
                            </Typo.H4>
                        )}
                        <div />
                    </Stack>
                </a>
                {renderCounts()}
            </HoverWrapper>
        </Paper>
    ) : (
        <Paper
            elevation={0}
            sx={{
                borderWidth: 1,
                borderColor: Colors.outline_2,
                borderStyle: "solid",
                position: "relative",
                justifyContent: "center",
                height: 60,
            }}
        >
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ height: "100%", width: "100%" }}
            >
                <ZigupSpinner size={"2rem"} />
            </Stack>
        </Paper>
    );
};

export default SearchCard;
