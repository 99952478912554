import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import Text from "../../atoms/Text";
import Colors from "themes/colors";
import { Logger } from "utils";
import { FORUM_OBJECT_TYPE } from "./../../../constants/constants";
import {
    discussionDetailAdjoiningList,
    lfcDetailAdjoiningList,
    lfrDetailAdjoiningList,
} from "communication/forum";
import ForumCard from "./ForumCard";

const ForumDetailAdjointList = (props) => {
    const { id, isMobile, type } = props;
    const POST_COUNT = 10;
    const [isLoading, setIsLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    const _emptyArr = new Array(POST_COUNT).fill();

    useEffect(() => {
        fetchAdjointData();
    }, [id, type]);

    const fetchAdjointData = async () => {
        try {
            setIsLoading(true);
            let res;
            switch (type) {
                case FORUM_OBJECT_TYPE.DISCUSSION:
                    res = await discussionDetailAdjoiningList(id);
                    break;
                case FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFC:
                    res = await lfcDetailAdjoiningList(id);
                    break;
                case FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFR:
                    res = await lfrDetailAdjoiningList(id);
                    break;
            }
            setPosts([...res.list]);
        } catch (e) {
            Logger.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    if (posts === undefined || isLoading) {
        return (
            <Stack justifyContent="center" alignItems="center">
                <Grid container spacing={2}>
                    {_emptyArr?.map((post, index) => (
                        <Grid
                            item
                            xs={isMobile ? 12 : 6}
                            key={index + "recommend_tmp"}
                        >
                            <ForumCard />
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        );
    }
    if (posts.length < 1) {
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                style={{ height: "20vh" }}
            >
                <Text style={{ color: Colors.light_grey }}>
                    No recommended posts yet
                </Text>
            </Stack>
        );
    } else {
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                    marginBottom: "3rem",
                }}
            >
                <Grid container spacing={2}>
                    {posts?.map((post, index) => (
                        <Grid
                            item
                            xs={isMobile ? 12 : 6}
                            key={index + "recommend"}
                        >
                            <ForumCard item={post} />
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        );
    }
};

export default ForumDetailAdjointList;
