import React, { useState } from "react";
import styled from "styled-components";
import Stack from "@mui/material/Stack";
import Text from "components/atoms/Text";
import Colors from "themes/colors";
import { Container } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RocketImage from "assets/images/rocket.png";
import AjioImage from "assets/images/logo-companies/user-companies/ajio.png";
import ByjusImage from "assets/images/logo-companies/user-companies/byjus.png";
import CalyxImage from "assets/images/logo-companies/user-companies/calyx.png";
import CleartripImage from "assets/images/logo-companies/user-companies/cleartrip.png";
import ClouderaImage from "assets/images/logo-companies/user-companies/cloudera.png";
import DelhiveryImage from "assets/images/logo-companies/user-companies/delhivery.png";
import DineoutImage from "assets/images/logo-companies/user-companies/dineout.png";
import DruvaImage from "assets/images/logo-companies/user-companies/druva.png";
import FactsetImage from "assets/images/logo-companies/user-companies/factset.png";
import FlipkartImage from "assets/images/logo-companies/user-companies/flipkart.png";
import FreechargeImage from "assets/images/logo-companies/user-companies/freecharge.png";
import GainsightImage from "assets/images/logo-companies/user-companies/gainsight.png";
import GojekImage from "assets/images/logo-companies/user-companies/gojek.png";
import GrabImage from "assets/images/logo-companies/user-companies/grab.png";
import GrouponImage from "assets/images/logo-companies/user-companies/groupon.png";
import InmobiImage from "assets/images/logo-companies/user-companies/inmobi.png";
import IntuitImage from "assets/images/logo-companies/user-companies/intuit.png";
import JfrogImage from "assets/images/logo-companies/user-companies/jfrog.png";
import JumioImage from "assets/images/logo-companies/user-companies/jumio.png";

import { useMediaQuery } from "react-responsive";

const BackgroundContainer = styled.div`
    width: 100vw;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;
`;

const RoleSection = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

    const slidesToScroll = 4;

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: isMobile ? 3 : 6,
        slidesToScroll,
        nextArrow: null,
        afterChange: (index) => {
            setCurrentIndex(index / slidesToScroll);
        },
        customPaging: (i) => (
            <div
                style={{
                    width: 6,
                    aspectRatio: 1,
                    backgroundColor: currentIndex === i ? "white" : "#7A78FF",
                    cursor: "pointer",
                    borderRadius: 3,
                }}
            />
        ),
    };

    return (
        <BackgroundContainer style={{ backgroundColor: Colors.white }}>
            <Container
                maxWidth="xl"
                style={{
                    padding: isMobile ? 16 : 64,
                }}
            >
                <span
                    style={{
                        display: "inline-block",
                        fontFamily: "Poppins",
                        fontWeight: 700,
                        fontSize: isMobile ? 42 : 84,
                        textAlign: "center",
                        color: Colors.light_grey_2,
                    }}
                >
                    Mobile Developer Backend Frontend Full Stack DevOps Data
                    Engineer Data Science/AI{"  "}
                    <span
                        style={{
                            color: Colors.blue,
                            fontFamily: "Poppins",
                            fontWeight: 700,
                            fontSize: isMobile ? 42 : 84,
                        }}
                    >
                        You can find all, working for Product
                    </span>
                    {"  "}
                    Test Engineer PM Security Data Analysis SalesForce Engineer
                    Network Product Design
                </span>
            </Container>
            <Container
                maxWidth="lg"
                style={{
                    padding: 64,
                    backgroundColor: Colors.background_blue_2,
                    paddingTop: 0,
                    paddingBottom: 96,
                    marginTop: 50,
                    boxSizing: "border-box",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div
                    style={{
                        position: "relative",
                        top: -70,
                        left: 0,
                        right: 0,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        height: 200,
                    }}
                >
                    <img
                        src={RocketImage}
                        alt="RocketImage"
                        width="auto"
                        height={isMobile ? "200" : "270"}
                    />
                </div>
                <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={3}
                >
                    <Text
                        style={{
                            color: Colors.white,
                            fontSize: isMobile ? 24 : 36,
                            fontWeight: 700,
                            fontFamily: "Poppins",
                            textAlign: "center",
                        }}
                    >
                        Professionals working only for the Product
                    </Text>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Text
                            style={{
                                color: Colors.white,
                                fontSize: 16,
                                textAlign: "center",
                            }}
                        >
                            Zigup users are fully produt-oriented professionals.
                        </Text>
                        <Text
                            style={{
                                color: Colors.white,
                                fontSize: 16,
                                textAlign: "center",
                            }}
                        >
                            Oriented only for the Quality of products.
                        </Text>
                    </div>
                    <Text
                        style={{
                            color: "#9ffff4",
                            fontSize: 14,
                            textAlign: "center",
                        }}
                    >
                        Professionals from product based company : 60,000+
                        people
                    </Text>
                </Stack>
                <div style={{ justifyContent: "center", display: "flex" }}>
                    <Slider
                        {...settings}
                        style={{ width: "90%", marginTop: 32 }}
                    >
                        <div>
                            <img src={AjioImage} alt="Ajio" width="100" />
                        </div>
                        <div>
                            <img src={ByjusImage} alt="Byjus" width="100" />
                        </div>
                        <div>
                            <img src={CalyxImage} alt="Calyx" width="100" />
                        </div>
                        <div>
                            <img
                                src={CleartripImage}
                                alt="Cleartrip"
                                width="100"
                            />
                        </div>
                        <div>
                            <img
                                src={ClouderaImage}
                                alt="Cloudera"
                                width="100"
                            />
                        </div>
                        <div>
                            <img
                                src={DelhiveryImage}
                                alt="Delhivery"
                                width="100"
                            />
                        </div>
                        <div>
                            <img src={DineoutImage} alt="Dineout" width="100" />
                        </div>
                        <div>
                            <img src={DruvaImage} alt="Druva" width="100" />
                        </div>
                        <div>
                            <img src={FactsetImage} alt="Factset" width="100" />
                        </div>
                        <div>
                            <img
                                src={FlipkartImage}
                                alt="Flipkart"
                                width="100"
                            />
                        </div>
                        <div>
                            <img
                                src={FreechargeImage}
                                alt="Freecharge"
                                width="100"
                            />
                        </div>
                        <div>
                            <img
                                src={GainsightImage}
                                alt="Gainsight"
                                width="100"
                            />
                        </div>
                        <div>
                            <img src={GojekImage} alt="Gojek" width="100" />
                        </div>
                        <div>
                            <img src={GrabImage} alt="Grab" width="100" />
                        </div>
                        <div>
                            <img src={GrouponImage} alt="Groupon" width="100" />
                        </div>
                        <div>
                            <img src={InmobiImage} alt="Inmobi" width="100" />
                        </div>
                        <div>
                            <img src={IntuitImage} alt="Intuit" width="100" />
                        </div>
                        <div>
                            <img src={JfrogImage} alt="Jfrog" width="100" />
                        </div>
                        <div>
                            <img src={JumioImage} alt="Jumio" width="100" />
                        </div>
                    </Slider>
                </div>
            </Container>
        </BackgroundContainer>
    );
};

export default RoleSection;
