import React from "react";
import styled from "styled-components";
import Stack from "@mui/material/Stack";
import Text from "components/atoms/Text";
import Colors from "themes/colors";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import AndroidImage from "assets/images/logo-skill/android.png";
import AngularImage from "assets/images/logo-skill/angular.png";
import GolangImage from "assets/images/logo-skill/golang.png";
import JavaImage from "assets/images/logo-skill/java.png";
import NodeImage from "assets/images/logo-skill/node.png";
import ReactNativeImage from "assets/images/logo-skill/reactnative.png";

import { useMediaQuery } from "react-responsive";

const BackgroundContainer = styled.div`
    width: 100vw;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
`;

const UserSection = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

    return (
        <BackgroundContainer
            style={{
                backgroundColor: Colors.white,
                marginBottom: isMobile ? 16 : 64,
            }}
        >
            <Container
                maxWidth="xl"
                style={{
                    padding: isMobile ? 0 : 128,
                }}
            >
                {isMobile ? (
                    <Stack
                        direction="column"
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            flex: 1,
                            display: "flex",
                            padding: "16px",
                            boxSizing: "border-box",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={2}
                            alignItems="flex-start"
                            justifyContent="center"
                            sx={{
                                flex: 1,
                                display: "flex",
                                boxSizing: "border-box",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: 36,
                                        color: "#2a2828",
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                    }}
                                >
                                    70,000+ Users
                                </Text>
                                <Text
                                    style={{
                                        fontSize: 36,
                                        color: "#2a2828",
                                        fontWeight: 700,
                                        fontFamily: "Poppins",
                                    }}
                                >
                                    Still growing even now
                                </Text>
                            </div>
                            <Text style={{ fontSize: 18, color: "#2a2828c7" }}>
                                Tech community is Zigup is updated everyday with
                                new users, along with various company and job
                                data.
                            </Text>
                            <Text
                                style={{
                                    fontSize: 14,
                                    color: Colors.white,
                                    backgroundColor: "#5956ff",
                                }}
                            >
                                200+ daily registration
                            </Text>
                        </Stack>
                        <div style={{ flex: 1, display: "flex" }}>
                            <Stack
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                spacing={0}
                            >
                                <Text>Zigup users with tech stacks</Text>
                                <Grid
                                    container
                                    spacing={0.2}
                                    sx={{ paddingTop: "16px" }}
                                >
                                    <Grid item xs={4}>
                                        <div
                                            style={{
                                                backgroundColor: "#EFF0FF",
                                                width: "100%",
                                                height: 200,
                                            }}
                                        >
                                            <Stack
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}
                                                sx={{
                                                    height: "100%",
                                                    padding: "32px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <img
                                                    src={NodeImage}
                                                    alt="Nodeage"
                                                    width="80%"
                                                    height="auto"
                                                    style={{ maxWidth: 100 }}
                                                />
                                                <Text
                                                    style={{
                                                        fontSize: 13,
                                                        fontFamily: "Poppins",
                                                        color: "#ACB5c5",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    Node.js
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: 14,
                                                        fontFamily: "Poppins",
                                                        color: "#5956ff",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    3,000+
                                                </Text>
                                            </Stack>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div
                                            style={{
                                                backgroundColor: "#EFF0FF",
                                                width: "100%",
                                                height: 200,
                                            }}
                                        >
                                            <Stack
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}
                                                sx={{
                                                    height: "100%",
                                                    padding: "32px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <img
                                                    src={AngularImage}
                                                    style={{ maxWidth: 100 }}
                                                    alt="Angular"
                                                    width="80%"
                                                    height="auto"
                                                />
                                                <Text
                                                    style={{
                                                        fontSize: 13,
                                                        fontFamily: "Poppins",
                                                        color: "#ACB5c5",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    Angular.js
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: 14,
                                                        fontFamily: "Poppins",
                                                        color: "#5956ff",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    2,000+
                                                </Text>
                                            </Stack>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div
                                            style={{
                                                backgroundColor: "#EFF0FF",
                                                width: "100%",
                                                height: 200,
                                            }}
                                        >
                                            <Stack
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}
                                                sx={{
                                                    height: "100%",
                                                    padding: "32px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <img
                                                    src={ReactNativeImage}
                                                    style={{ maxWidth: 100 }}
                                                    alt="ReactNative"
                                                    width="80%"
                                                    height="auto"
                                                />
                                                <Text
                                                    style={{
                                                        fontSize: 13,
                                                        fontFamily: "Poppins",
                                                        color: "#ACB5c5",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    React Native
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: 14,
                                                        fontFamily: "Poppins",
                                                        color: "#5956ff",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    600+
                                                </Text>
                                            </Stack>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div
                                            style={{
                                                backgroundColor: "#EFF0FF",
                                                width: "100%",
                                                height: 200,
                                            }}
                                        >
                                            <Stack
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}
                                                sx={{
                                                    height: "100%",
                                                    padding: "32px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <img
                                                    src={JavaImage}
                                                    style={{ maxWidth: 100 }}
                                                    alt="Javaage"
                                                    width="80%"
                                                    height="auto"
                                                />
                                                <Text
                                                    style={{
                                                        fontSize: 13,
                                                        fontFamily: "Poppins",
                                                        color: "#ACB5c5",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    Java
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: 14,
                                                        fontFamily: "Poppins",
                                                        color: "#5956ff",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    10,000+
                                                </Text>
                                            </Stack>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div
                                            style={{
                                                backgroundColor: "#EFF0FF",
                                                width: "100%",
                                                height: 200,
                                            }}
                                        >
                                            <Stack
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}
                                                sx={{
                                                    height: "100%",
                                                    padding: "32px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <img
                                                    src={AndroidImage}
                                                    style={{ maxWidth: 100 }}
                                                    alt="Android"
                                                    width="80%"
                                                    height="auto"
                                                />
                                                <Text
                                                    style={{
                                                        fontSize: 13,
                                                        fontFamily: "Poppins",
                                                        color: "#ACB5c5",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    Android
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: 14,
                                                        fontFamily: "Poppins",
                                                        color: "#5956ff",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    700+
                                                </Text>
                                            </Stack>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div
                                            style={{
                                                backgroundColor: "#EFF0FF",
                                                width: "100%",
                                                height: 200,
                                            }}
                                        >
                                            <Stack
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}
                                                sx={{
                                                    height: "100%",
                                                    padding: "32px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <img
                                                    src={GolangImage}
                                                    style={{ maxWidth: 100 }}
                                                    alt="Golange"
                                                    width="80%"
                                                    height="auto"
                                                />
                                                <Text
                                                    style={{
                                                        fontSize: 13,
                                                        fontFamily: "Poppins",
                                                        color: "#ACB5c5",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    GoLang
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: 14,
                                                        fontFamily: "Poppins",
                                                        color: "#5956ff",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    600+
                                                </Text>
                                            </Stack>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </div>
                    </Stack>
                ) : (
                    <Stack
                        direction="row"
                        spacing={4}
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            flex: 1,
                            display: "flex",
                            padding: "64px",
                            boxSizing: "border-box",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={2}
                            alignItems="flex-start"
                            justifyContent="center"
                            sx={{
                                flex: 1,
                                display: "flex",
                                boxSizing: "border-box",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: 36,
                                        color: "#2a2828",
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                    }}
                                >
                                    70,000+ Users
                                </Text>
                                <Text
                                    style={{
                                        fontSize: 36,
                                        color: "#2a2828",
                                        fontWeight: 700,
                                        fontFamily: "Poppins",
                                    }}
                                >
                                    Still growing even now
                                </Text>
                            </div>
                            <Text style={{ fontSize: 18, color: "#2a2828c7" }}>
                                Tech community is Zigup is updated everyday with
                                new users, along with various company and job
                                data.
                            </Text>
                            <Text
                                style={{
                                    fontSize: 14,
                                    color: Colors.white,
                                    backgroundColor: "#5956ff",
                                }}
                            >
                                200+ daily registration
                            </Text>
                        </Stack>
                        <div style={{ flex: 1, display: "flex" }}>
                            <Stack
                                direction="column"
                                alignItems="flex-start"
                                justifyContent="center"
                                spacing={0}
                            >
                                <Text>Zigup users with tech stacks</Text>
                                <Grid
                                    container
                                    spacing={0.2}
                                    sx={{ paddingTop: "16px" }}
                                >
                                    <Grid item xs={4}>
                                        <div
                                            style={{
                                                backgroundColor: "#EFF0FF",
                                                width: "100%",
                                                height: 200,
                                            }}
                                        >
                                            <Stack
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}
                                                sx={{
                                                    height: "100%",
                                                    padding: "32px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <img
                                                    src={NodeImage}
                                                    style={{ maxWidth: 100 }}
                                                    alt="Nodeage"
                                                    width="80%"
                                                    height="auto"
                                                />
                                                <Text
                                                    style={{
                                                        fontSize: 13,
                                                        fontFamily: "Poppins",
                                                        color: "#ACB5c5",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    Node.js
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: 14,
                                                        fontFamily: "Poppins",
                                                        color: "#5956ff",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    3,000+
                                                </Text>
                                            </Stack>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div
                                            style={{
                                                backgroundColor: "#EFF0FF",
                                                width: "100%",
                                                height: 200,
                                            }}
                                        >
                                            <Stack
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}
                                                sx={{
                                                    height: "100%",
                                                    padding: "32px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <img
                                                    src={AngularImage}
                                                    style={{ maxWidth: 100 }}
                                                    alt="Angular"
                                                    width="80%"
                                                    height="auto"
                                                />
                                                <Text
                                                    style={{
                                                        fontSize: 13,
                                                        fontFamily: "Poppins",
                                                        color: "#ACB5c5",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    Angular.js
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: 14,
                                                        fontFamily: "Poppins",
                                                        color: "#5956ff",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    2,000+
                                                </Text>
                                            </Stack>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div
                                            style={{
                                                backgroundColor: "#EFF0FF",
                                                width: "100%",
                                                height: 200,
                                            }}
                                        >
                                            <Stack
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}
                                                sx={{
                                                    height: "100%",
                                                    padding: "32px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <img
                                                    src={ReactNativeImage}
                                                    style={{ maxWidth: 100 }}
                                                    alt="ReactNative"
                                                    width="80%"
                                                    height="auto"
                                                />
                                                <Text
                                                    style={{
                                                        fontSize: 13,
                                                        fontFamily: "Poppins",
                                                        color: "#ACB5c5",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    React Native
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: 14,
                                                        fontFamily: "Poppins",
                                                        color: "#5956ff",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    600+
                                                </Text>
                                            </Stack>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div
                                            style={{
                                                backgroundColor: "#EFF0FF",
                                                width: "100%",
                                                height: 200,
                                            }}
                                        >
                                            <Stack
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}
                                                sx={{
                                                    height: "100%",
                                                    padding: "32px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <img
                                                    src={JavaImage}
                                                    style={{ maxWidth: 100 }}
                                                    alt="Javaage"
                                                    width="80%"
                                                    height="auto"
                                                />
                                                <Text
                                                    style={{
                                                        fontSize: 13,
                                                        fontFamily: "Poppins",
                                                        color: "#ACB5c5",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    Java
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: 14,
                                                        fontFamily: "Poppins",
                                                        color: "#5956ff",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    10,000+
                                                </Text>
                                            </Stack>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div
                                            style={{
                                                backgroundColor: "#EFF0FF",
                                                width: "100%",
                                                height: 200,
                                            }}
                                        >
                                            <Stack
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}
                                                sx={{
                                                    height: "100%",
                                                    padding: "32px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <img
                                                    src={AndroidImage}
                                                    style={{ maxWidth: 100 }}
                                                    alt="Android"
                                                    width="80%"
                                                    height="auto"
                                                />
                                                <Text
                                                    style={{
                                                        fontSize: 13,
                                                        fontFamily: "Poppins",
                                                        color: "#ACB5c5",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    Android
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: 14,
                                                        fontFamily: "Poppins",
                                                        color: "#5956ff",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    700+
                                                </Text>
                                            </Stack>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div
                                            style={{
                                                backgroundColor: "#EFF0FF",
                                                width: "100%",
                                                height: 200,
                                            }}
                                        >
                                            <Stack
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}
                                                sx={{
                                                    height: "100%",
                                                    padding: "32px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <img
                                                    src={GolangImage}
                                                    style={{ maxWidth: 100 }}
                                                    alt="Golange"
                                                    width="80%"
                                                    height="auto"
                                                />
                                                <Text
                                                    style={{
                                                        fontSize: 13,
                                                        fontFamily: "Poppins",
                                                        color: "#ACB5c5",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    GoLang
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: 14,
                                                        fontFamily: "Poppins",
                                                        color: "#5956ff",
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    600+
                                                </Text>
                                            </Stack>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </div>
                    </Stack>
                )}
            </Container>
        </BackgroundContainer>
    );
};

export default UserSection;
