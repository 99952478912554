import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NormalTextField from "components/atoms/NormalTextField";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import { fetchService } from "../../communication/jd";
import Colors from "themes/colors";
import Text from "components/atoms/Text";
import styled from "styled-components";
import ZigupSpinner from "./ZigupSpinner";

const FETCH_STATUS = {
    DEFAULT: -1,
    LOADING: 1,
    DONE: 2,
    FAILED: 3,
};

const InputTitleContainer = styled.div`
    align-items: flex-start;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 2px;
`;

const TechnicalAreaSelector = (props) => {
    const {
        techArea,
        setTechArea,
        techAreaOthersRole,
        setTechAreaOthersRole,
        title,
        style,
    } = props;
    const [onMouse, setOnMouse] = useState(false);
    const [fetchStatus, setFetchStatus] = useState(FETCH_STATUS.DEFAULT);
    const [techAreaData, setTechAreaData] = useState([]);
    const ReloadButtonStyle = {
        border: onMouse ? "1px solid #6837f2" : "1px solid #d4d6dd",
        width: "100%",
        height: "3rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Colors.white,
        cursor: "pointer",
        marginBottom: "1rem",
    };

    const fetchData = async () => {
        try {
            setFetchStatus(FETCH_STATUS.LOADING);
            const res = await fetchService();
            setFetchStatus(FETCH_STATUS.DONE);
            setTechAreaData(res.data.SalaryAllTags.role.listItems);
        } catch (e) {
            setFetchStatus(FETCH_STATUS.FAILED);
        }
    };

    useEffect(() => {
        fetchData();
        return () => setTechAreaData([]);
    }, []);

    const makeTechArea = () => {
        const itemList = [];
        Object.keys(techAreaData).map((key, index) => {
            itemList.push(
                <ListSubheader
                    key={index + key}
                    style={{ fontWeight: "bold", fontSize: "1.2rem" }}
                >
                    {techAreaData[key].name ?? ""}
                </ListSubheader>
            );
            techAreaData[key].items.map((item) => {
                itemList.push(
                    <MenuItem key={item.key} value={item.key}>
                        {item.name ?? ""}
                    </MenuItem>
                );
            });
        });

        return itemList;
    };

    switch (fetchStatus) {
        case FETCH_STATUS.LOADING:
            return (
                <Stack style={{ ...ReloadButtonStyle, cursor: "default" }}>
                    <ZigupSpinner />
                </Stack>
            );
        case FETCH_STATUS.DONE:
            return (
                <Stack
                    direction="row"
                    alignItems="flex-end"
                    style={{
                        marginBottom: "1rem",
                        width: "100%",
                        height: "30%",
                        ...style,
                    }}
                >
                    <FormControl style={{ flex: 1 }}>
                        <InputTitleContainer>
                            <Text style={{ fontSize: 12 }}>
                                {title || "Technical Area"}
                            </Text>
                        </InputTitleContainer>
                        <Select
                            value={
                                techArea.area?.value === "others"
                                    ? "others-others"
                                    : techArea.role?.value || null
                            }
                            id="technical-area-grouped-select"
                            onChange={(event) => {
                                Object.keys(techAreaData).map((key, index) => {
                                    if (
                                        techAreaData[key].items.find(
                                            (item) =>
                                                item.key === event.target.value
                                        )
                                    ) {
                                        setTechArea({
                                            area: { value: key },
                                            role: {
                                                value: [event.target.value],
                                            },
                                        });
                                    }
                                });
                                setTechAreaOthersRole("");
                            }}
                        >
                            {makeTechArea()}
                        </Select>
                    </FormControl>
                    {techArea?.area?.value === "others" ? (
                        <NormalTextField
                            value={techAreaOthersRole ?? ""}
                            onChange={(event) =>
                                setTechAreaOthersRole(event.target.value)
                            }
                            containerStyle={{
                                marginLeft: "1rem",
                                flex: 2,
                            }}
                            fullWidth
                            placeholder="Type other role"
                        />
                    ) : null}
                </Stack>
            );
        case FETCH_STATUS.FAILED:
            return (
                <Stack
                    id="fetch-skill-btn"
                    style={ReloadButtonStyle}
                    onClick={fetchData}
                    onMouseEnter={() => setOnMouse(true)}
                    onMouseLeave={() => setOnMouse(false)}
                >
                    <Text
                        style={
                            onMouse
                                ? {
                                      color: Colors.purple_blue,
                                      fontSize: "1rem",
                                  }
                                : {
                                      color: Colors.text_grey,
                                      fontSize: "1rem",
                                  }
                        }
                    >
                        Reload technical area
                    </Text>
                </Stack>
            );
        default:
            return null;
    }
};
export default TechnicalAreaSelector;
