import React, { useState } from "react";
import { usePrompt } from "../../utils/blocker";
import ConfirmModal from "./ConfirmModal";

const NavigationGuard = ({
    when,
    message = "Are you sure to leave this page?",
}) => {
    const { showPrompt, confirmNavigation, cancelNavigation } = usePrompt(when);
    const [confirmModalState, setConfirmModalState] = useState({
        open: "",
        title: message,
        content: "",
        button: {
            confirm: {
                text: "",
                handle: () => handleClickYes,
            },
            reject: {
                text: "",
                handle: () => handleClickNo,
            },
        },
        closeModal: () => closeConfirmModal,
    });

    const handleClickNo = () => {
        cancelNavigation();
    };

    const handleClickYes = () => {
        confirmNavigation();
    };

    const closeConfirmModal = () => {
        setConfirmModalState({
            ...confirmModalState,
            open: false,
        });
    };
    return (
        <ConfirmModal
            open={showPrompt}
            title={confirmModalState.title}
            containerStyle={{ height: "10rem" }}
            button={{
                confirm: {
                    handle: handleClickYes,
                },
                reject: {
                    handle: handleClickNo,
                },
            }}
            closeModal={confirmModalState.closeModal}
        />
    );
};

export default NavigationGuard;
