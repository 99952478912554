import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import GlobalHeader from "components/organisms/GlobalHeader";
import Footer from "components/organisms/Footer";
import { AuthContext } from "../../../context/AuthContext";

const JdPage = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { userState } = useContext(AuthContext);

    useEffect(async () => {
        if (!params.id) navigate("/jd/list");
    }, [params]);

    return (
        <>
            <GlobalHeader />
            {userState.auth ? (
                <Outlet
                    context={{
                        jdId: params.id,
                        userInfo: userState.info,
                    }}
                />
            ) : (
                <Outlet
                    context={{
                        jdId: params.id,
                    }}
                />
            )}
            <Footer />
        </>
    );
};
export default JdPage;
