import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import GlobalHeader from "../../organisms/GlobalHeader";
import { AuthContext } from "../../../context/AuthContext";
import Footer from "../../organisms/Footer";
import OfferAuthProtectPage from "./OfferAuthProtectPage";
import { Container, Stack } from "@mui/material";

const OfferPage = () => {
    const { userState } = useContext(AuthContext);
    return (
        <Stack direction="column" sx={{ minHeight: "100vh" }}>
            <GlobalHeader color="#7959f2" />
            {userState.auth ? (
                <Outlet />
            ) : (
                <Container maxWidth="sm" sx={{ flex: 1 }}>
                    <OfferAuthProtectPage />
                </Container>
            )}
            <Footer />
        </Stack>
    );
};
export default OfferPage;
