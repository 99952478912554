import React, { createContext, useContext, useState } from "react";
import {
    myInformation,
    refreshToken,
    signIn,
} from "../communication/membership";
import { getCookie, removeCookie, setCookie } from "../utils/cookieManager";

import { ALERT_TYPE, COOKIE } from "../constants/constants";
import { AlertContext } from "./AlertContext";
import { Logger } from "utils";

let accessToken = undefined;

const JWT_EXPIRY_TIME = 1000 * 60 * 20;

const AuthContext = createContext(undefined);
const AuthProvider = ({ children }) => {
    const { openAlert } = useContext(AlertContext);
    const [isLoading, setIsLoading] = useState(false);
    const [userState, setUserState] = useState({
        info: {},
        auth: undefined,
    });

    const clearData = () => {
        accessToken = undefined;
        removeCookie(COOKIE.REFRESH_TOKEN);
        setUserState({ info: {}, auth: false });
    };

    const login = async (loginParams) => {
        return new Promise(async (resolve) => {
            let retVal = false;
            setIsLoading(true);
            try {
                const res = await signIn(loginParams);
                if (res) {
                    await onLoginSuccess(res);
                    openAlert({
                        showAlert: true,
                        text: "Successfully logged in",
                        alertType: ALERT_TYPE.SUCCESS,
                    });
                    retVal = true;
                }
            } catch (e) {
                setUserState({ ...userState, auth: false });
            } finally {
                setIsLoading(false);
                resolve(retVal);
            }
        });
    };

    const onLoginSuccess = (res) => {
        return new Promise((resolve) => {
            try {
                setToken(res.tokens);
                getUserInfo().then((user) => {
                    if (!userState.auth) {
                        setUserState({
                            info: user,
                            auth: true,
                        });
                    }
                    setTimeout(onRefreshToken, JWT_EXPIRY_TIME);
                    resolve(true);
                });
            } catch (e) {
                resolve(false);
            }
        });
    };

    const logout = () => {
        return new Promise(async (resolve) => {
            setIsLoading(true);
            try {
                clearData();
            } catch (e) {
                Logger.error("failed log out", e);
            } finally {
                setIsLoading(false);
                resolve();
            }
        });
    };

    const refreshUserInfo = async () => {
        try {
            getUserInfo().then((user) => {
                setUserState({
                    info: user,
                    auth: true,
                });
            });
        } catch (e) {
            Logger.error("failed", e);
        }
    };

    const getUserInfo = async () => {
        return new Promise(async (resolve) => {
            try {
                const res = await myInformation();
                if (res) resolve(res.user);
            } catch (e) {
                Logger.error("failed fetch me", e);
            }
        });
    };

    const onRefreshToken = async () => {
        return new Promise(async (resolve) => {
            if (isLoading) return;
            let retVal = false;
            setIsLoading(true);
            try {
                const res = await refreshToken();
                if (res) {
                    await onLoginSuccess(res);
                    retVal = true;
                }
            } catch (e) {
                clearData();
            } finally {
                setIsLoading(false);
                resolve(retVal);
            }
        });
    };

    const setToken = (tokens) => {
        const setRefreshToken = (refreshToken) => {
            setCookie(COOKIE.REFRESH_TOKEN, refreshToken, { path: "/" });
        };
        accessToken = tokens.accessToken;
        setRefreshToken(tokens.refreshToken);
    };

    const setTmpLoginHash = (hash) => {
        setCookie(COOKIE.TMP_LOGIN_HASH, hash, {
            path: "/",
            expires: new Date(Date.now() + 60 * 60 * 24 * 1000),
        });
    };

    const getTmpLoginHash = () => {
        return getCookie(COOKIE.TMP_LOGIN_HASH);
    };

    const addLoginInducerModalWatchCnt = () => {
        const cnt = getLoginInducerModalWatchCnt();
        if (cnt >= 2) return;

        setCookie(COOKIE.LOGIN_INDUCER_MODAL_WATCH_CNT, cnt + 1, {
            path: "/",
            expires: new Date(Date.now() + 1000 * 60 * 60 * 24),
        });
    };

    const getLoginInducerModalWatchCnt = () => {
        const res = getCookie(COOKIE.LOGIN_INDUCER_MODAL_WATCH_CNT);
        return res === undefined ? 0 : 1 * res;
    };

    AuthContext.getAccessToken = () => {
        return accessToken;
    };

    return (
        <AuthContext.Provider
            value={{
                userState,
                setUserState,
                login,
                logout,
                onRefreshToken,
                getTmpLoginHash,
                setTmpLoginHash,
                getLoginInducerModalWatchCnt,
                addLoginInducerModalWatchCnt,
                refreshUserInfo,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const getAccessToken = () => {
    return AuthContext.getAccessToken();
};

export { AuthContext, AuthProvider, getAccessToken };
