import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Colors from "themes/colors";
import Text from "components/atoms/Text";

const NormalTextFieldBase = styled(TextField)({
    "& label.Mui-focused": {
        color: Colors.purple,
    },
    "& .Mui-disabled": {
        backgroundColor: Colors.disabled_grey,
        color: Colors.white,
        borderRadius: 4,
        "& fieldset": {
            color: Colors.white,
        },
        "& input": {
            color: Colors.white,
        },
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: Colors.outline,
        },
        "&.Mui-focused fieldset": {
            borderColor: Colors.purple,
            borderWidth: "1px",
        },
    },
    "& .MuiOutlinedInput-input": {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
        },
    },
});

const NormalTextField = (props) => {
    const {
        title,
        containerStyle,
        titleColor,
        alertMessage,
        alertMessageColor,
        InputProps,
        ...rest
    } = props;

    return (
        <div
            style={{
                alignItems: "flex-start",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                ...containerStyle,
            }}
        >
            {title ? (
                <>
                    <Text
                        style={
                            titleColor
                                ? { fontSize: 12, color: titleColor }
                                : { fontSize: 12 }
                        }
                    >
                        {title}
                    </Text>
                    <div style={{ height: 2 }} />
                </>
            ) : null}
            <NormalTextFieldBase
                {...rest}
                InputProps={{ ...InputProps, style: { fontSize: 14 } }}
            />
            {alertMessage ? (
                <>
                    <Text
                        style={
                            alertMessageColor
                                ? { fontSize: 12, color: alertMessageColor }
                                : { fontSize: 12 }
                        }
                    >
                        {alertMessage}
                    </Text>
                    <div style={{ height: 2 }} />
                </>
            ) : null}
        </div>
    );
};

export default NormalTextField;
