import React, { useEffect, useState } from "react";
import Text from "components/atoms/Text";
import { Stack } from "@mui/material";
import Colors from "themes/colors";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useOutletContext } from "react-router";
import directApplyIcon from "assets/icons/direct-apply4x.png";
import { detail } from "../../../communication/referral";
import ShareMenu from "components/organisms/ShareMenu";
import ZigupSpinner from "../../organisms/ZigupSpinner";

const ReferralDetailPage = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const navigate = useNavigate();
    const { referralId } = useOutletContext();
    const [referral, setReferral] = useState();

    const sectionStyle = {
        padding: "1rem",
        border: "1px solid #6837f2",
        width: isMobile ? "80vw" : "60vw",
        height: "max-content",
        display: "flex",
    };

    const buttonStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "4rem",
        backgroundColor: Colors.purple_blue,
        width: isMobile ? "80vw" : "60vw",
        cursor: "pointer",
        marginBottom: "1rem",
    };

    useEffect(async () => {
        try {
            const res = await detail(referralId);
            setReferral(res.forumreferral);
        } catch (e) {
            navigate("/");
        }
    }, [referralId]);

    const apply = () => {
        navigate("apply", {
            state: { referralId: referralId, referral: referral },
        });
    };

    return referral ? (
        <Stack
            direction="column"
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
        >
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                style={sectionStyle}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={1}
                    style={{ width: "100%" }}
                >
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Stack style={{ width: "1.5rem", height: "1.5rem" }}>
                            <img
                                src={directApplyIcon}
                                alt="directApplyIcon"
                                width="100%"
                                height="100%"
                            />
                        </Stack>
                        <Text>
                            {referral?.isCompanyHireDirectly
                                ? "Direct hiring from company"
                                : "Referral post by employee"}
                        </Text>
                    </Stack>
                    <ShareMenu url={window.location.href} />
                </Stack>
                <Text
                    style={{
                        fontSize: "1.5rem",
                        fontFamily: "Roboto",
                        fontWeight: 700,
                    }}
                >
                    {referral?.company + " | " + referral?.title}
                </Text>
                <Text style={{ whiteSpace: "pre-line", fontSize: "1rem" }}>
                    {referral?.content}
                </Text>
            </Stack>
            <Stack style={buttonStyle} onClick={apply} id="detail-apply-btn">
                <Text style={{ color: "white", fontSize: 22 }}>Apply</Text>
            </Stack>
        </Stack>
    ) : (
        <Stack
            justifyContent="center"
            alignItems="center"
            style={{
                height: "50vh",
            }}
        >
            <ZigupSpinner size={"2rem"} />
        </Stack>
    );
};
export default ReferralDetailPage;
