import amazonLogo from "assets/images/main/amazon-horizontal.png";
import flipkartLogo from "assets/images/main/flipkart-horizontal.png";
import oracleLogo from "assets/images/main/oracle-horizontal.png";
import googleLogo from "assets/images/main/google-horizontal.png";
import walmartLogo from "assets/images/main/walmart-horizontal.png";
import microsoftLogo from "assets/images/main/microsoft-horizontal.png";
import logo from "assets/images/logo-graphic.png";
import logoZigupColor from "assets/images/logo-zigup-color.png";
import logoZigupWhite from "assets/images/logo-zigup.png";
import noLogo from "assets/icons/no-logo.png";

export const ExperienceType = {
    UNKNOWN: -1,
    FRESHER: 1,
    EXPERIENCED: 2,
};
export const UPVOTE_TYPE = {
    SALARY: 0,
    COMMENT: 1,
    REPLY: 2,
    FORUM_REFERRAL: 3,
    DISCUSSION: 4,
};
export const FORUM_POST_TYPE = {
    SALARY: 1,
    DISCUSSION: 2,
    REFERRAL: 3,
};
export const DELETED_USER = "DELETED_USER";
export const FORUM_OBJECT_TYPE = {
    ALL: "all",
    SALARY: "salary",
    DISCUSSION: "discussion",
    FORUM_REFERRAL_LFR: "LFR",
    FORUM_REFERRAL_LFC: "LFC",
};
export const COMMENT_HANDLE_TYPE = {
    WRITE_POST: 0,
    EDIT_POST: 1,
    DELETE_POST: 2,
    WRITE_COMMENT: 3,
    EDIT_COMMENT: 4,
    DELETE_COMMENT: 5,
    REPLY_LOAD_MORE: 6,
    PATCH_COMMENT: 7,
    CANCEL_EDIT: 8,
};
export const COMMENT_VIEW_TYPE = {
    COMMENT: 1,
    REPLY: 2,
};
export const CREATE_CHATROOM_TYPE = {
    Salary: "salary",
    Comment: "comment",
    Reply: "reply",
    Discussion: "discussion",
    LFR: "LFR",
    LFC: "LFC",
};
export const EXPERIENCE_TYPE = {
    UNKNOWN: -1,
    FRESHER: 1,
    EXPERIENCED: 2,
};

export const SIGNUP_STEP = {
    SIGN_IN: 1,
    SET_PASSWORD: 2,
    SIGN_UP: 3,
    FORGOT_PASSWORD: 4,
    SET_PASSWORD_AFTER_VERIFY: 101,
};

export const FETCH_STATUS = {
    DEFAULT: -1,
    LOADING: 1,
    DONE: 2,
    FAILED: 3,
};

export const ALERT_TYPE = {
    ERROR: "error",
    WARNING: "warning",
    INFO: "info",
    SUCCESS: "success",
};

export const COOKIE = {
    REFRESH_TOKEN: "refreshToken",
    TMP_LOGIN_HASH: "tmpLoginHash",
    LOGIN_INDUCER_MODAL_WATCH_CNT: "loginInducerModalWatchCnt",
};

export const YOE = [
    { value: 0, text: "No experience" },
    { value: 1, text: "1 year or less" },
    { value: 2, text: "2 years" },
    { value: 3, text: "3 years" },
    { value: 4, text: "4 years" },
    { value: 5, text: "5 years" },
    { value: 6, text: "6 years" },
    { value: 7, text: "7 years" },
    { value: 8, text: "8 years" },
    { value: 9, text: "9 years" },
    { value: 10, text: "10 years" },
    { value: 11, text: "11 years" },
    { value: 12, text: "12 years" },
    { value: 13, text: "13 years" },
    { value: 14, text: "14 years" },
    { value: 15, text: "15 years" },
    { value: 16, text: "16 years" },
    { value: 17, text: "17 years" },
    { value: 18, text: "18 years" },
    { value: 19, text: "19 years" },
    { value: 20, text: "20 years" },
    { value: 21, text: "21 years" },
    { value: 22, text: "22 years" },
    { value: 23, text: "23 years" },
    { value: 24, text: "24 years" },
    { value: 25, text: "25 years" },
    { value: 26, text: "26 years" },
    { value: 27, text: "27 years" },
    { value: 28, text: "28 years" },
    { value: 29, text: "29 years" },
    { value: 30, text: "30 years or more" },
];

export const ROLE_TEXT_ARR = [
    { key: "talentAcquisition", name: "Talent Acquisition" },
    { key: "hrBusinessPartner", name: "HR Business Partner" },
    { key: "hrOthers", name: "Others" },
    { key: "others-others", name: "Others" },
    { key: "mobile", name: "Mobile" },
    { key: "frontend", name: "Front-end" },
    { key: "backend", name: "Back-end" },
    { key: "fullstack", name: "Full Stack" },
    { key: "devops", name: "DevOps" },
    { key: "dataEngineering", name: "Data Engineering" },
    { key: "dataScience/ai", name: "Data Science/ AI" },
    { key: "testing/qa", name: "Test Engineer" },
    { key: "pm", name: "PM" },
    { key: "security", name: "Security" },
    { key: "dataAnalysis", name: "Data Analysis" },
    { key: "salesforce", name: "Salesforce" },
    { key: "network", name: "Network" },
    { key: "productDesign", name: "Product Design(UI/UX)" },
    { key: "desktopApp", name: "Desktop App" },
    { key: "technicalSupport", name: "Technical Support" },
    { key: "teamManager", name: "Team Manager" },
    { key: "others", name: "Tech - Others" },
];

export const SEARCH_TYPE = {
    ALL: { value: FORUM_OBJECT_TYPE.ALL, text: "All" },
    SALARY: { value: FORUM_OBJECT_TYPE.SALARY, text: "Salary" },
    COMPANY: { value: "company", text: "Company" },
    JOB: { value: "job", text: "Job" },
    DISCUSSIONS: { value: FORUM_OBJECT_TYPE.DISCUSSION, text: "Discussion" },
    LFR: {
        value: FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFR,
        text: "Looking for Referral",
    },
    LFC: {
        value: FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFC,
        text: "Looking for Candidate",
    },
};

export const PRELOAD_IMAGES = [
    logo,
    amazonLogo,
    flipkartLogo,
    oracleLogo,
    googleLogo,
    walmartLogo,
    microsoftLogo,
    logoZigupColor,
    logoZigupWhite,
    noLogo,
];
