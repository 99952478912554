import * as Api from "./api";
import { post, get, patch } from "./process";

export async function detail(jdId) {
    return get(Api.Urls.jd.detail(jdId), {}, Api.Types.Simple);
}

export async function fetchSkillSet() {
    return get(Api.Urls.jd.skillSet, {}, Api.Types.Simple);
}

export async function apply(params) {
    return patch(Api.Urls.jd.apply, params, Api.Types.FileUpload);
}

export async function applyWithAccessToken(params) {
    return patch(Api.Urls.jd.apply, params, Api.Types.WithAccessToken);
}

export async function fetchService() {
    return get(Api.Urls.service, {}, Api.Types.Simple);
}

export async function fetchOffers() {
    return get(Api.Urls.jd.offers, {}, Api.Types.WithAccessToken);
}

export async function fetchTmpOffers(hash) {
    return get(Api.Urls.jd.tmpOffers(hash), {}, Api.Types.Simple);
}

export async function tmpOffersByClientData(params) {
    const screen = {
        availWidth: window.screen.availWidth,
        availHeight: window.screen.availHeight,
        width: window.screen.width,
        height: window.screen.height,
        colorDepth: window.screen.colorDepth,
    };
    return post(
        Api.Urls.jd.tmpOffersByClientData,
        {
            ...params,
            os: window.navigator.userAgent,
            screen: JSON.stringify(screen),
        },
        Api.Types.Simple
    );
}

export async function jdList(params) {
    return get(Api.Urls.jd.list, params, Api.Types.Simple);
}

export async function jdListByRole(params) {
    return get(Api.Urls.jd.jdRoleList, params, Api.Types.Simple);
}

export async function recommendJdListById(id, params = {}) {
    return get(Api.Urls.jd.recommend(id), params, Api.Types.Simple);
}
