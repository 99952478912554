import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Text from "components/atoms/Text";
import Stack from "@mui/material/Stack";
import { Container } from "@mui/material";
import Typo from "components/atoms/Typo";
import CVList from "../../organisms/my/CVList";

const ManageCVPage = () => {
    const navigate = useNavigate();
    const [pageText, setPageText] = useState({
        title: "Manage my cv",
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container maxWidth="md" sx={{ minHeight: "60vh" }}>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ margin: "1rem" }}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ width: "100%" }}
                    spacing={1}
                >
                    <Typo.Button1
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/my/profile")}
                    >
                        My profile
                    </Typo.Button1>
                    <Typo.Button1>/</Typo.Button1>
                    <Typo.Button1>{pageText.title}</Typo.Button1>
                </Stack>
                <Stack
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{ width: "100%" }}
                >
                    <Text
                        style={{
                            fontSize: "2rem",
                            lineHeight: "3rem",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                        }}
                    >
                        {pageText.title}
                    </Text>
                </Stack>
                <CVList />
            </Stack>
        </Container>
    );
};
export default ManageCVPage;
