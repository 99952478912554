import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";

import Colors from "themes/colors";
import Text from "components/atoms/Text";
import styled from "styled-components";

import { FETCH_STATUS } from "constants/constants";
import ZigupSpinner from "./ZigupSpinner";

const InputTitleContainer = styled.div`
    align-items: flex-start;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 2px;
`;

const EducationCourseSelector = (props) => {
    const {
        isMobile,
        defaultValue,
        value,
        setValue,
        fetchStatus,
        fetchData,
        reload,
    } = props;
    const [onMouse, setOnMouse] = useState(false);
    const ReloadButtonStyle = {
        border: onMouse ? "1px solid #6837f2" : "1px solid #d4d6dd",
        width: "100%",
        height: "3rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Colors.white,
        cursor: "pointer",
        marginBottom: "1rem",
    };

    const makeCourse = () => {
        const itemList = [];
        fetchData.map((item, index) => {
            itemList.push(
                <MenuItem key={index} value={item}>
                    {item ?? ""}
                </MenuItem>
            );
        });

        return itemList;
    };

    switch (fetchStatus) {
        case FETCH_STATUS.LOADING:
            return (
                <Stack style={{ ...ReloadButtonStyle, cursor: "default" }}>
                    <ZigupSpinner size={"1rem"} />
                </Stack>
            );
        case FETCH_STATUS.DONE:
            return (
                <Stack
                    direction="row"
                    alignItems="center"
                    style={{
                        marginBottom: "1rem",
                        width: "100%",
                    }}
                >
                    <FormControl style={{ flex: 1 }}>
                        <InputTitleContainer>
                            <Text style={{ fontSize: 12 }}>Course</Text>
                        </InputTitleContainer>
                        <Select
                            defaultValue={defaultValue}
                            id="education-course-select"
                            onChange={(event) => setValue(event.target.value)}
                        >
                            {makeCourse()}
                        </Select>
                    </FormControl>
                </Stack>
            );
        case FETCH_STATUS.FAILED:
            return (
                <Stack
                    id="fetch-skill-btn"
                    style={ReloadButtonStyle}
                    onClick={reload}
                    onMouseEnter={() => setOnMouse(true)}
                    onMouseLeave={() => setOnMouse(false)}
                >
                    <Text
                        style={
                            onMouse
                                ? {
                                      color: Colors.purple_blue,
                                      fontSize: "1rem",
                                  }
                                : {
                                      color: Colors.text_grey,
                                      fontSize: "1rem",
                                  }
                        }
                    >
                        Reload course
                    </Text>
                </Stack>
            );
        default:
            return null;
    }
};
export default EducationCourseSelector;
