import React, { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Modal from "react-modal";
import { Stack } from "@mui/material";
import loginImage from "assets/images/illust-login@2x.png";
import Colors from "themes/colors";
import Text from "components/atoms/Text";
import ZigupButton from "components/atoms/ZigupButton";
import { showSignUpModal } from "./GlobalHeader";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { AuthContext } from "context/AuthContext";

const LoginInducerModal = ({ open, closeModal }) => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const location = useLocation();
    const { addLoginInducerModalWatchCnt } = useContext(AuthContext);

    const customModalStyles = {
        content: {
            width: isMobile ? "100vw" : "70rem",
            minWidth: "30vw",
            maxWidth: isMobile ? "90vw" : "90vw",
            height: isMobile ? "max-content" : "22rem",
            minHeight: "15vh",
            maxHeight: isMobile ? "80vh" : "70vh",
            backgroundColor: Colors.button_background_404,
            padding: "2rem",
            border: "0",
            left: "50%",
            top: "100%",
            transform: isMobile
                ? "translate(-50%, -140%)"
                : "translate(-50%, -110%)",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            boxSizing: "border-box",
            borderRadius: "1rem",
            position: "absolute",
        },
        overlay: {
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0)",
            zIndex: 10,
        },
    };

    useEffect(() => {
        if (open) {
            addLoginInducerModalWatchCnt();
        }
    }, [open]);

    return (
        <Modal
            isOpen={open}
            style={customModalStyles}
            contentLabel="Login Inducer Modal"
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={true}
            ariaHideApp={false}
        >
            <CloseIcon
                style={{ color: Colors.white, cursor: "pointer" }}
                onClick={closeModal}
                sx={{
                    position: "absolute",
                    top: "1rem",
                    right: "1rem",
                }}
            />
            <Stack
                direction={isMobile ? "column" : "row"}
                spacing={2}
                alignItems="center"
                justifyContent="flex-start"
                style={{
                    width: "100%",
                    height: "max-content",
                    boxSizing: "border-box",
                    paddingLeft: isMobile ? 0 : "4rem",
                    paddingRight: isMobile ? 0 : "4rem",
                }}
            >
                {isMobile ? null : (
                    <img
                        src={loginImage}
                        alt="loginImage"
                        width={"40%"}
                        height={"auto"}
                        style={{ minWidth: 300 }}
                    />
                )}
                <Stack
                    direction="column"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    spacing={isMobile ? 1 : 2}
                    sx={{
                        flex: 1,
                        display: "flex",
                        height: "100%",
                        boxSizing: "border-box",
                    }}
                >
                    <Text
                        style={{
                            fontFamily: "Lato",
                            fontWeight: "bold",
                            fontSize: isMobile ? "1.5rem" : "2rem",
                            color: Colors.white,
                            whiteSpace: "pre-line",
                            lineHeight: isMobile ? "2.25rem" : "3rem",
                        }}
                    >
                        Login and apply{"\n"}only in 3 seconds
                    </Text>
                    <Text
                        style={{
                            fontFamily: "Lato",
                            fontSize: isMobile ? "1rem" : "1.25rem",
                            lineHeight: isMobile ? "1.5rem" : "1.8rem",
                            color: "#dbd3fd",
                        }}
                    >
                        Login to save your information and load each time you
                        apply
                    </Text>
                    {isMobile ? null : (
                        <Stack
                            direction="column"
                            alignItems={"flex-start"}
                            justifyContent="center"
                            sx={{ width: "100%" }}
                        >
                            <ZigupButton
                                id={"login-on-apply-detail-btn"}
                                buttonText={"Login to load my information"}
                                containerStyle={{
                                    width: "max-content",
                                    height: "max-content",
                                    margin: 0,
                                    padding: "0.75rem 1.5rem",
                                    borderRadius: "1.5rem",
                                    backgroundColor: "#ede9ff",
                                }}
                                buttonTextSize={"1.25rem"}
                                buttonTextStyle={{
                                    fontFamily: "Lato",
                                    fontWeight: 500,
                                    color: Colors.primary_purple,
                                }}
                                onClickHandler={() => {
                                    closeModal();
                                    showSignUpModal(location.pathname);
                                }}
                            />
                        </Stack>
                    )}
                </Stack>
                {isMobile ? (
                    <Stack
                        direction="column"
                        alignItems={"center"}
                        justifyContent="center"
                        sx={{ width: "100%" }}
                    >
                        <ZigupButton
                            id={"login-on-apply-detail-btn"}
                            buttonText={"Login to load my information"}
                            containerStyle={{
                                width: "100%",
                                height: "max-content",
                                margin: 0,
                                padding: "1rem 0rem",
                                borderRadius: "1.5rem",
                                backgroundColor: "#ede9ff",
                            }}
                            buttonTextSize={"1.25rem"}
                            buttonTextStyle={{
                                fontFamily: "Lato",
                                fontWeight: 500,
                                color: Colors.primary_purple,
                            }}
                            onClickHandler={() => {
                                closeModal();
                                showSignUpModal(location.pathname);
                            }}
                        />
                    </Stack>
                ) : null}
            </Stack>
        </Modal>
    );
};

export default LoginInducerModal;
