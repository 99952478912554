import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FORUM_POST_TYPE } from "constants/constants";
import { Stack } from "@mui/material";
import CompanyLogo from "../../atoms/CompanyLogo";
import { viewedCompanies } from "../../../communication/salary";
import Text from "components/atoms/Text";

const DescriptionText = styled(Text)`
    color: #646464;
    margin: 16px;
`;

const EmptyDescriptionText = styled(Text)`
    color: #c4c4c4;
    font-size: 20px;
`;

const CompanyText = styled(Text)`
    font-size: 16px;
    margin-left: 16px;
    color: #1f1534;
`;

const ViewedCompanies = (props) => {
    const { postId, postType } = props;
    const [pageText, setPageText] = useState({
        headerTitle: "Who viewed your posts",
        description:
            "Below is a full list of current employer of those who view your posts. Only authors can access the full list.",
        emptyDescription: "No one has viewed the post yet",
    });
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        hasNext: false,
        lastIndex: 0,
        totalCount: 0,
    });

    useEffect(() => {
        const fetchData = async () => {
            let _data;
            if (postType === FORUM_POST_TYPE.SALARY) {
                _data = await viewedCompanies(postId, {
                    li: pagination.lastIndex,
                });
            }
            // else if (postType === FORUM_POST_TYPE.DISCUSSION) {
            //     _data = await ForumDiscussion.viewedCompanies(postId, {
            //         li: pagination.lastIndex,
            //     });
            // } else if (postType === FORUM_POST_TYPE.REFERRAL) {
            //     _data = await ForumReferral.viewedCompanies(postId, {
            //         li: pagination.lastIndex,
            //     });
            // }

            setData(_data.companiesOfViewer);
            setPagination(_data.pagination);
            props.setIsLoading(false);
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchMoreData = async () => {
        if (!pagination.hasNext) return;
        let _data;
        if (postType === FORUM_POST_TYPE.SALARY) {
            _data = await viewedCompanies(postId, {
                li: pagination.lastIndex,
            });
        }
        // else if (postType === FORUM_POST_TYPE.DISCUSSION) {
        //     _data = await ForumDiscussion.viewedCompanies(postId, {
        //         li: pagination.lastIndex,
        //     });
        // } else if (postType === FORUM_POST_TYPE.REFERRAL) {
        //     _data = await ForumReferral.viewedCompanies(postId, {
        //         li: pagination.lastIndex,
        //     });
        // }

        setData([...data, ..._data.companiesOfViewer]);
        setPagination(_data.pagination);
    };

    const renderItem = (item) => {
        return (
            <Stack style={{ marginBottom: 12, marginHorizontal: 16 }}>
                <CompanyLogo
                    style={{
                        width: 50,
                        height: 50,
                        borderRadius: 8,
                        backgroundColor: "#f6f6f6",
                    }}
                    source={item.logoURL}
                    resizeMode={FastImage.resizeMode.cover}
                />
                <CompanyText>{item.name}</CompanyText>
            </Stack>
        );
    };

    const renderHeaderComponent = () => {
        return data.length ? (
            <DescriptionText>{pageText.description}</DescriptionText>
        ) : null;
    };

    const renderEmptyComponent = () => {
        return (
            <Stack
                style={{
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <EmptyDescriptionText>
                    {pageText.emptyDescription}
                </EmptyDescriptionText>
            </Stack>
        );
    };

    return (
        <Stack>
            {renderHeaderComponent()}
            {data.length > 0
                ? data.map((item, index) => {
                      renderItem(item);
                  })
                : null}
            {/*<FlatList*/}
            {/*    data={data}*/}
            {/*    keyExtractor={(item, index) => item.name + index}*/}
            {/*    style={{ flex: 1 }}*/}
            {/*    renderItem={renderItem}*/}
            {/*    ListHeaderComponent={renderHeaderComponent}*/}
            {/*    ListEmptyComponent={renderEmptyComponent}*/}
            {/*    onEndReached={fetchMoreData}*/}
            {/*    contentContainerStyle={{ flexGrow: 1 }}*/}
            {/*/>*/}
        </Stack>
    );
};
export default ViewedCompanies;
