import React, { useState, useEffect } from "react";
import { Stack, Tooltip, tooltipClasses } from "@mui/material";
import styled from "styled-components";
import Text from "components/atoms/Text";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Colors from "themes/colors";
import { useMediaQuery } from "react-responsive";
import { showSignUpModal } from "../GlobalHeader";
import ReactGA from "react-ga4";

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 150,
    },
});

const SalaryDetailInsightGraph = (props) => {
    const { salary, salaryInsight, auth } = props;
    const [salaryData, setSalaryData] = useState([]);
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

    useEffect(() => {
        if (!Object.keys(salary).length || !Object.keys(salaryInsight).length)
            return;
        const _data = getData();
        setSalaryData(_data);
    }, [salary, salaryInsight]);

    const getData = () => {
        if (!Object.keys(salary).length || !Object.keys(salaryInsight).length)
            return [];

        const yoe = salary?.yearsOfExperience;

        const index = yoe < 3 ? 0 : yoe < 6 ? 1 : yoe < 11 ? 2 : 3;

        return [
            {
                id: salaryInsight.salary[index].id,
                data: salaryInsight.salary[index].data.map((item) => {
                    return {
                        ...item,
                        x: item.dateOfOffer,
                        y: item.totalCompensations,
                    };
                }),
            },
        ];
    };

    const renderGraph = () => {
        return (
            <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={0}
                sx={
                    auth
                        ? {
                              position: "relative",
                              width: "100%",
                              height: "100%",
                          }
                        : {
                              position: "relative",
                              width: "100%",
                              height: "100%",
                              filter: "blur(4px)",
                              pointerEvents: "none",
                              userSelect: "none",
                              msUserSelect: "none",
                          }
                }
            >
                <ResponsiveScatterPlot
                    data={salaryData}
                    isInteractive={true}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 32,
                        left: 40,
                    }}
                    xFormat="time:%Y-%m-%d"
                    xScale={{
                        type: "time",
                        format: "%Y-%m-%d",
                        useUTC: false,
                        precision: "day",
                        min: "2018-01-01",
                        max: "2023-01-01",
                    }}
                    yScale={{
                        type: "linear",
                        min: "auto",
                        max: "auto",
                    }}
                    yFormat=">-.2f"
                    blendMode="multiply"
                    axisRight={null}
                    onClick={(e) =>
                        window.open(`/salary/${e.data.seoURL}`, "_blank")
                    }
                    tooltip={renderToolTip}
                    axisBottom={{
                        format: "%Y",
                        tickRotation: 0,
                        tickSize: 0,
                    }}
                    axisLeft={{
                        orient: "left",
                        tickSize: 0,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "LPA",
                        legendPosition: "end",
                        legendOffset: -30,
                    }}
                />
            </Stack>
        );
    };

    const renderSignupInducer = () => {
        return auth ? null : (
            <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    zIndex: 20,
                }}
            >
                <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={3}
                    sx={{
                        width: "20rem",
                        height: "11rem",
                        right: "50%",
                        bottom: "50%",
                        backgroundColor: "white",
                        boxShadow: "0 4px 4px 0 rgba(0,0,0,0.25)",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        ReactGA.event({
                            category: "Button",
                            action: "sign_in_button_pressed_salary_detail_graph",
                            label: "sign_in",
                        });
                        showSignUpModal();
                    }}
                >
                    <Text
                        style={{
                            fontFamily: "Lato",
                            fontSize: "1.25rem",
                            color: Colors.search_card_black,
                        }}
                    >
                        Sign up to see whole data
                    </Text>
                    <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            backgroundColor: "#ede9ff",
                            padding: "0.75rem 1.5rem",
                            boxSizing: "border-box",
                            borderRadius: "24px",
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: "Roboto",
                                fontSize: "1rem",
                                color: Colors.primary_purple,
                            }}
                        >
                            Sign up
                        </Text>
                    </Stack>
                </Stack>
            </Stack>
        );
    };

    const renderInaccurateData = () => {
        return !salaryData.length ||
            (salaryInsight &&
                salaryData.length &&
                salaryData[0]?.data.length < 5) ? (
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={0.5}
                sx={{ width: "100%" }}
            >
                <ErrorOutlineIcon
                    sx={{ color: Colors.error, fontSize: "1rem" }}
                />
                <Text
                    style={{
                        color: Colors.error,
                        fontSize: "0.75rem",
                    }}
                >
                    The data may be inaccurate due to lack of data.
                </Text>
            </Stack>
        ) : null;
    };

    const renderNoData = () => {
        return salaryInsight && salaryData.length == 0 ? (
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={0.5}
                sx={{
                    position: "absolute",
                    right: "50%",
                    bottom: "50%",
                    transform: "translate(45px, -50%)",
                    zIndex: 15,
                }}
            >
                <Text
                    style={{
                        color: "#7e7e7e",
                        fontSize: "0.75rem",
                    }}
                >
                    There's no data to display
                </Text>
                <CustomWidthTooltip
                    title="If the data isn't accurate or number of data isn't enough, it is not being displayed"
                    arrow
                    placement="top"
                >
                    <InfoOutlinedIcon
                        sx={{ color: "#7e7e7e", fontSize: "1rem" }}
                    />
                </CustomWidthTooltip>
            </Stack>
        ) : null;
    };

    const renderToolTip = ({ node: { data } }) => (
        <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            spacing={1}
            sx={{
                backgroundColor: Colors.white,
                border: "1px #e5e5e5 solid",
                boxSizing: "border-box",
                padding: "0.5rem",
                width: "12.5rem",
                boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)",
            }}
        >
            <Text
                style={{
                    fontSize: "0.75rem",
                    fontWeight: 500,
                }}
            >
                {data?.title || "-"}
            </Text>
            <Text
                style={{
                    fontSize: "0.75rem",
                    color: Colors.primary_purple,
                }}
            >
                {data?.company || "-"}
            </Text>
            <Text
                style={{
                    fontSize: "0.75rem",
                    color: "#646464",
                }}
            >
                {`Date of offer: ${data?.dateOfOffer || "-"}`}
            </Text>
            <Stack direction="column" spacing={0}>
                <Text
                    style={{
                        fontSize: "0.75rem",
                        color: "#646464",
                    }}
                >
                    {`Years of experience: ${data?.yearsOfExperience || "-"}`}
                </Text>
                <Text
                    style={{
                        fontSize: "0.75rem",
                        color: "#646464",
                    }}
                >
                    {`Salary: ${data?.salary || "-"}`}
                </Text>
                <Text
                    style={{
                        fontSize: "0.75rem",
                        color: "#646464",
                    }}
                >
                    {`Hike: ${data?.hike || "-"}`}
                </Text>
                <Text
                    style={{
                        fontSize: "0.75rem",
                        color: "#646464",
                    }}
                >
                    {`Bonus: ${data?.bonus || "-"}`}
                </Text>
                <Text
                    style={{
                        fontSize: "0.75rem",
                        color: "#646464",
                    }}
                >
                    {`Stock: ${data?.stock || "-"}`}
                </Text>
                <Text
                    style={{
                        fontSize: "0.75rem",
                        color: "#646464",
                    }}
                >
                    {`Total comp: ${data?.totalCompensations || "-"}`}
                </Text>
            </Stack>
        </Stack>
    );

    return (
        <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={0}
            sx={{
                position: "relative",
                width: "100%",
                height: isMobile ? "300px" : "400px",
            }}
        >
            {renderNoData()}
            {renderGraph()}
            {renderSignupInducer()}
            {renderInaccurateData()}
        </Stack>
    );
};

export default SalaryDetailInsightGraph;
