import Stack from "@mui/material/Stack";
import Text from "components/atoms/Text";
import React, { useRef, useState } from "react";
import Colors from "themes/colors";
import ZigupSpinner from "./ZigupSpinner";
import ReactGA from "react-ga4";

const UPLOAD_STATUS = {
    DEFAULT: -1,
    LOADING: 1,
    DONE: 2,
};

const FileUploadButton = (props) => {
    const {
        isMobile,
        value,
        setValue,
        buttonStyle,
        hiddenFileName = false,
        buttonIcon,
        acceptType = "application/pdf, com.adobe.pdf, application/msword, com.microsoft.word.doc, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    } = props;
    const inputRef = useRef();
    const [currentStatus, setCurrentStatus] = useState({
        status: UPLOAD_STATUS.DEFAULT,
        progress: {},
    });

    const cvButtonStyle = {
        border: "1px solid #6837f2",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "4rem",
        maxHeight: "max-content",
        backgroundColor: Colors.white,
        width: "100%",
        cursor: "pointer",
        marginBottom: "1rem",
        ...buttonStyle,
    };

    const handleChangeFile = (event) => {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onprogress = (e) => {
            setCurrentStatus({ status: UPLOAD_STATUS.LOADING, progress: e });
        };
        reader.onloadend = async () => {
            setCurrentStatus({
                ...currentStatus,
                status: UPLOAD_STATUS.DONE,
            });
            setValue(event.target.files[0]);
            ReactGA.event({
                category: "Button",
                action: "upload_file_done",
            });
        };
    };

    const renderByStatus = () => {
        switch (currentStatus.status) {
            case UPLOAD_STATUS.LOADING:
                return (
                    <ZigupSpinner
                        size={"2rem"}
                        value={Math.round(
                            (currentStatus.progress.loaded * 100) /
                                currentStatus.progress.total
                        )}
                    />
                );
            case UPLOAD_STATUS.DONE:
            case UPLOAD_STATUS.DEFAULT:
                return (
                    <>
                        {hiddenFileName ? (
                            buttonIcon
                        ) : (
                            <Text
                                style={{
                                    color: Colors.purple_blue,
                                    fontSize: 22,
                                }}
                            >
                                {value ? value.name : "Upload CV"}
                            </Text>
                        )}
                    </>
                );
            default:
                return null;
        }
    };

    const handleOnClick = () => {
        ReactGA.event({
            category: "Button",
            action: "upload_file_pressed",
        });
        if (currentStatus.status !== UPLOAD_STATUS.LOADING) {
            inputRef.current?.click();
        }
    };

    return (
        <>
            <Stack
                id="file-upload-btn"
                style={cvButtonStyle}
                onClick={handleOnClick}
            >
                {renderByStatus()}
            </Stack>
            <input
                ref={inputRef}
                type="file"
                accept={acceptType ? acceptType : null}
                id="file"
                hidden
                onChange={handleChangeFile}
                multiple={false}
            />
        </>
    );
};
export default FileUploadButton;
