import React, { useContext } from "react";
import { useNavigate } from "react-router";
import GlobalHeader from "components/organisms/GlobalHeader";
import Footer from "components/organisms/Footer";
import { Container, Stack } from "@mui/material";
import zigupLogo from "assets/images/logo-zigup-color.png";
import welcomeImg from "assets/images/img-welcome.png";
import Colors from "themes/colors";
import ZigupButton from "components/atoms/ZigupButton";
import Typo from "components/atoms/Typo";

import { AuthContext } from "../../../context/AuthContext";
import { Navigate } from "react-router-dom";

const SignUpDonePage = () => {
    const navigate = useNavigate();
    const { userState } = useContext(AuthContext);
    const handleUploadPressed = () => {
        navigate("/salary/add", { replace: true });
    };
    const handleNotNowPressed = () => {
        navigate("/", { replace: true });
    };

    return (
        <>
            <GlobalHeader />
            {userState.auth ? (
                <Container
                    maxWidth="md"
                    sx={{ backgroundColor: Colors.background }}
                >
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                        sx={{ height: "80vh", paddingTop: 4, paddingBottom: 4 }}
                    >
                        <img
                            src={zigupLogo}
                            alt="ZigupLogo"
                            height="50px"
                            width="auto"
                        />
                        <img
                            src={welcomeImg}
                            alt="Welcome"
                            height="300px"
                            width="auto"
                        />
                        <Typo.Body1 style={{ textAlign: "center" }}>
                            Now, upload salary to view all salary data in Zigup!
                        </Typo.Body1>
                        <ZigupButton
                            id="welcome-upload-salary-btn"
                            buttonText="Upload salary data"
                            onClickHandler={handleUploadPressed}
                        />
                        <Typo.Body2
                            onClick={handleNotNowPressed}
                            style={{
                                cursor: "pointer",
                                marginTop: 4,
                                textDecoration: "underline",
                            }}
                        >
                            Not now
                        </Typo.Body2>
                    </Stack>
                </Container>
            ) : (
                <Navigate to="/" replace />
            )}
            <Footer />
        </>
    );
};
export default SignUpDonePage;
