import React from "react";
import styled from "styled-components";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";

import FbIcon from "assets/icons/icon-fb-neg.png";
import InstaIcon from "assets/icons/icon-insta-neg.png";
import LinkedinIcon from "assets/icons/icon-linkedin-neg.png";
import TwitterIcon from "assets/icons/icon-twitter-neg.png";
import ZigupImage from "assets/images/logo-zigup.png";

import AppStoreImage from "assets/images/appstore-badge.png";
import PlayStoreImage from "assets/images/playstore-badge.png";

import Text from "components/atoms/Text";
import Colors from "themes/colors";

import { useMediaQuery } from "react-responsive";
import { Grid } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

const BackgroundContainer = styled.div`
    width: 100vw;
    align-items: center;
    justify-content: center;
    display: flex;
`;

const Footer = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const zigupInfo = [
        { title: "Salaries", url: "/salary/list" },
        { title: "Companies", url: "/company/list" },
        { title: "Jobs", url: "/jd/list" },
        { title: "Discussion", url: "/forum/list?type=discussion" },
        { title: "Looking for Candidates", url: "/forum/list?type=LFC" },
        { title: "Looking for Referrals", url: "/forum/list?type=LFR" },
        { title: "Credits" },
    ];
    const zigupBusinessInfo = [
        { title: "About Zigup", url: "/intro" },
        { title: "Business site", url: "https://hire.zigup.in/intro" },
        { title: "Cilents", url: "https://hire.zigup.in/intro/#clients" },
        { title: "Press", url: "https://hire.zigup.in/intro/#press" },
    ];
    const contactInfo = [
        {
            title: "contact@hyperhire.in",
        },
        { title: "FAQ", url: "/faq" },
        { title: "Contact Us", url: "/contact-us" },
        {
            title: "Terms and Conditions",
            url: "https://api.zigup.in/terms.html",
        },
    ];
    const socialLink = [
        {
            name: "Facebook",
            href: "https://www.facebook.com/jobs.zigup.in/",
            icon: FbIcon,
            alt: "Zigup Facebook",
        },
        {
            name: "Instagram",
            href: "https://www.instagram.com/zigupindia/",
            icon: InstaIcon,
            alt: "Zigup Instagram",
        },
        {
            name: "Linkedin",
            href: "https://www.linkedin.com/showcase/zigup/",
            icon: LinkedinIcon,
            alt: "Zigup Linkedin",
        },
        {
            name: "Twitter",
            href: "https://twitter.com/zigup_india",
            icon: TwitterIcon,
            alt: "Zigup Twitter",
        },
    ];

    const copyClipboardText = (text) => {
        navigator.clipboard.writeText(text);
    };

    const renderZigup = () => {
        return (
            <Stack
                direction="column"
                spacing={2}
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{ flex: 1 }}
            >
                <img
                    src={ZigupImage}
                    width={131}
                    height={41}
                    alt="Zigup Logo"
                />
                <Text
                    style={{
                        fontFamily: "inter",
                        color: Colors.footer,
                        fontSize: isMobile ? 11 : 14,
                    }}
                >
                    Product-based tech community
                </Text>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    sx={{ width: "100%" }}
                    spacing={2}
                >
                    {socialLink.map((social) => (
                        <a
                            key={social.name}
                            href={social.href}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={social.icon}
                                width={20}
                                height={20}
                                alt={social.alt}
                            />
                        </a>
                    ))}
                </Stack>
                <Stack direction={isMobile ? "row" : "column"} spacing={2}>
                    <a
                        href="https://play.google.com/store/apps/details?id=in.zigup"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={PlayStoreImage}
                            alt="Playstore"
                            height={isMobile ? 46 : (160 * 82) / 273}
                            width={isMobile ? (46 * 273) / 82 : 160}
                        />
                    </a>
                    <a
                        href="https://apps.apple.com/in/app/zigup/id1562615317?l=en"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={AppStoreImage}
                            alt="Appstore"
                            height={isMobile ? 46 : (160 * 80) / 241}
                            width={isMobile ? (46 * 241) / 80 : 160}
                        />
                    </a>
                </Stack>
            </Stack>
        );
    };

    const renderPages = () => {
        return (
            <div style={{ flex: 1, display: "flex" }}>
                <Stack direction="column" spacing={4}>
                    <Stack direction="column" spacing={2}>
                        <Text
                            style={{
                                color: Colors.white,
                                fontSize: isMobile ? 12 : 14,
                                fontWeight: 700,
                            }}
                        >
                            Zigup
                        </Text>
                        {zigupInfo.map((info) =>
                            info.url ? (
                                <a
                                    key={info.title}
                                    href={info.url}
                                    style={{
                                        textDecoration: "none",
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: Colors.footer,
                                            fontSize: isMobile ? 12 : 14,
                                        }}
                                    >
                                        {info.title}
                                    </Text>
                                </a>
                            ) : (
                                <Text
                                    key={info.title}
                                    style={{
                                        color: Colors.footer,
                                        fontSize: isMobile ? 12 : 14,
                                    }}
                                >
                                    {info.title}
                                </Text>
                            )
                        )}
                    </Stack>
                </Stack>
            </div>
        );
    };

    const renderZigupBusiness = () => {
        return (
            <div style={{ flex: 1, display: "flex" }}>
                <Stack direction="column" spacing={4}>
                    <Stack direction="column" spacing={2}>
                        <Text
                            style={{
                                color: Colors.white,
                                fontSize: isMobile ? 12 : 14,
                                fontWeight: 700,
                            }}
                        >
                            Zigup for Business
                        </Text>
                        {zigupBusinessInfo.map((info) =>
                            info.url ? (
                                <a
                                    key={info.title}
                                    href={info.url}
                                    style={{
                                        textDecoration: "none",
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: Colors.footer,
                                            fontSize: isMobile ? 12 : 14,
                                        }}
                                    >
                                        {info.title}
                                    </Text>
                                </a>
                            ) : (
                                <Text
                                    key={info.title}
                                    style={{
                                        color: Colors.footer,
                                        fontSize: isMobile ? 12 : 14,
                                    }}
                                >
                                    {info.title}
                                </Text>
                            )
                        )}
                    </Stack>
                </Stack>
            </div>
        );
    };

    const renderContact = () => {
        return (
            <div style={{ flex: 1, display: "flex", width: "100%" }}>
                <Stack direction="column" spacing={4} sx={{ width: "100%" }}>
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{ width: "100%" }}
                    >
                        <Text
                            style={{
                                color: Colors.white,
                                fontSize: isMobile ? 12 : 14,
                                fontWeight: 700,
                            }}
                        >
                            Contact
                        </Text>
                        {isMobile ? (
                            <Grid container spacing={0} sx={{ width: "100%" }}>
                                {contactInfo.map((info, index) => (
                                    <Grid
                                        item
                                        xs={6}
                                        key={"contact_" + index}
                                        sx={{
                                            paddingRight: 1,
                                            paddingBottom: 2,
                                        }}
                                    >
                                        {info.url ? (
                                            <a
                                                href={info.url}
                                                style={{
                                                    textDecoration: "none",
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        color: Colors.footer,
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    {info.title}
                                                </Text>
                                            </a>
                                        ) : (
                                            <Text
                                                style={{
                                                    color: Colors.footer,
                                                    fontSize: 12,
                                                }}
                                            >
                                                {info.title}
                                            </Text>
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            contactInfo.map((info) =>
                                info.url ? (
                                    <a
                                        key={info.title}
                                        href={info.url}
                                        style={{
                                            textDecoration: "none",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: Colors.footer,
                                                fontSize: isMobile ? 12 : 14,
                                            }}
                                        >
                                            {info.title}
                                        </Text>
                                    </a>
                                ) : (
                                    <div
                                        key={info.title}
                                        onClick={() => {
                                            if (
                                                info.title !==
                                                "contact@hyperhire.in"
                                            )
                                                return;
                                            copyClipboardText(info.title);
                                            toast("Copied text!");
                                        }}
                                        style={{
                                            cursor:
                                                info.title !==
                                                "contact@hyperhire.in"
                                                    ? null
                                                    : "pointer",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: Colors.footer,
                                                fontSize: isMobile ? 12 : 14,
                                            }}
                                        >
                                            {info.title}
                                        </Text>
                                    </div>
                                )
                            )
                        )}
                    </Stack>
                </Stack>
            </div>
        );
    };

    return (
        <BackgroundContainer
            style={{ backgroundColor: Colors.background_black }}
        >
            <Container
                maxWidth="lg"
                style={{
                    paddingHorizontal: isMobile ? 16 : 64,
                    paddingTop: isMobile ? "2rem" : "4rem",
                    paddingBottom: isMobile ? "2rem" : "4rem",
                }}
            >
                {isMobile ? (
                    <Stack
                        direction="column"
                        alignItems="flex-start"
                        justifyContent="center"
                        spacing={4}
                        sx={{ width: "100%" }}
                    >
                        {renderZigup()}
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            sx={{ width: "100%" }}
                        >
                            {renderPages()}
                            {renderZigupBusiness()}
                        </Stack>
                        {renderContact()}
                    </Stack>
                ) : (
                    <Stack
                        direction="row"
                        spacing={4}
                        justifyContent="space-between"
                        alignItems="flex-start"
                        sx={{ width: "100%" }}
                    >
                        {renderZigup()}
                        {renderPages()}
                        {renderZigupBusiness()}
                        {renderContact()}
                    </Stack>
                )}
            </Container>
            <ToastContainer
                position="top-right"
                autoClose={500}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
            />
        </BackgroundContainer>
    );
};

export default Footer;
