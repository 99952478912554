import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import { LinearProgress } from "@mui/material";
import Typo from "./Typo";
import React from "react";
import { zigup } from "themes/zigup";

const LinearProgressWithLabel = (props) => {
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <ThemeProvider theme={zigup}>
                    <LinearProgress
                        color={"zigup"}
                        variant="determinate"
                        sx={{ height: "0.5rem" }}
                        {...props}
                    />
                </ThemeProvider>
            </Box>
            <Box sx={{ minWidth: "2rem" }}>
                <Typo.Body2>{`${Math.round(props.value)}%`}</Typo.Body2>
            </Box>
        </Box>
    );
};
export default LinearProgressWithLabel;
