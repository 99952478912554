import moment from "moment";
import NormalTextField from "./NormalTextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Text from "./Text";

const NormalDatePicker = (props) => {
    const {
        views,
        value,
        setValue,
        titleColor,
        title,
        containerStyle,
        isMobile,
        ...rest
    } = props;

    // const _DatePicker = isMobile ? MobileDatePicker : DesktopDatePicker;
    const _DatePicker = MobileDatePicker;

    return (
        <div
            style={{
                alignItems: "flex-start",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                ...containerStyle,
            }}
        >
            {title ? (
                <>
                    <Text
                        style={
                            titleColor
                                ? { fontSize: 12, color: titleColor }
                                : { fontSize: 12 }
                        }
                    >
                        {title}
                    </Text>
                    <div style={{ height: 2 }} />
                </>
            ) : null}
            <_DatePicker
                views={views}
                value={moment(value, "DD/MM/YYYY")}
                minDate={moment()}
                onChange={(newValue) => {
                    if (newValue.isValid()) {
                        setValue(newValue);
                    }
                }}
                renderInput={(params) => (
                    <NormalTextField {...params} helperText={null} fullWidth />
                )}
                inputFormat="DD/MM/YYYY"
                {...rest}
            />
        </div>
    );
};

export default NormalDatePicker;
