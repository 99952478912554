import React from "react";
import HyperhireSection from "components/sections/intro/HyperhireSection";
import RoleSection from "components/sections/intro/RoleSection";
import ZigupDetailSection from "components/sections/intro/ZigupDetailSection";
import Footer from "components/organisms/Footer";
import UserSection from "components/sections/intro/UserSection";
import MainSection from "components/sections/intro/MainSection";
import { Helmet } from "react-helmet-async";

const IntroPage = () => {
    return (
        <>
            <Helmet>
                <title>The Irreplaceable tech community - Zigup</title>
                <meta
                    name="description"
                    property="description"
                    content={
                        "Zigup is an anonymous career platform that allows you to apply for jobs at top product-based companies, share and explore information about salary breakups, job referrals, and interviews to help you advance your career."
                    }
                />
                <meta
                    content={"The Irreplaceable tech community - Zigup"}
                    property="og:title"
                />
                <meta
                    property="og:description"
                    content={
                        "Zigup is an anonymous career platform that allows you to apply for jobs at top product-based companies, share and explore information about salary breakups, job referrals, and interviews to help you advance your career."
                    }
                />
                <link rel="canonical" href={"https://zigup.in/intro"} />
            </Helmet>
            <MainSection />
            <UserSection />
            <RoleSection />
            <ZigupDetailSection />
            <HyperhireSection />
            <Footer />
        </>
    );
};

export default IntroPage;
