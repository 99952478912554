import React, { useEffect, useRef, useState } from "react";
import Text from "components/atoms/Text";
import { Stack } from "@mui/material";
import NormalTextField from "components/atoms/NormalTextField";
import styled from "styled-components";
import { timeFromNow } from "utils/DateUtil";

import commentIcon from "assets/icons/icon-comment.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";

import { Send } from "react-feather";
import Colors from "themes/colors";

import {
    COMMENT_HANDLE_TYPE,
    COMMENT_VIEW_TYPE,
    CREATE_CHATROOM_TYPE,
} from "constants/constants";

import { DELETED_USER, UPVOTE_TYPE } from "../../constants/constants";
import UpvoteButton from "./UpvoteButton";
import { useMediaQuery } from "react-responsive";
import DownloadAppModal from "./DownloadAppModal";
import upvoteIcon from "assets/icons/icon-upvote-lined.png";

const CountText = styled(Text)`
    margin-right: 16px;
    color: #646464;
`;
const EditInputContainer = styled(Stack)`
    align-items: flex-end;
    background: #fff;
`;
const InputContainer = styled(Stack)`
    flex-direction: row;
    align-items: flex-end;
    background: #fff;
    padding: 8px 16px;
    padding-left: 6px;
`;

const InputArea = styled(Stack)`
    border: 1px solid #dbdbdb;
    border-radius: 14px;
    flex: 1;
    flex-direction: row;
    align-items: flex-end;
`;

const InputWrapper = styled(Stack)`
    flex: 1;
    padding: 10px 8px;
    justify-content: center;
`;

const GreyText = styled(Text)`
    font-size: 12px;
    color: #646464;
`;

const Input = styled(NormalTextField)`
    padding: 0px 0px;
    font-size: 14px;
    min-height: 20px;
    max-height: 72px;
    width: 100%;
    color: ${Colors.black};
`;

const Comment = (props) => {
    const {
        comment,
        updateCommentData,
        isNotice,
        eventHandler,
        setBackgroundColor,
        setDisableDots,
        viewType,
        isMyPost,
        requiredCredit,
        isFirstComment,
    } = props;
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

    const [pageText, setPageText] = useState({
        commentLabel: "Comment",
        no_comment: "No comment",
        unable_comment_placeholder: "You can't add a comment to notice",
        placeholder: "add a comment",
        load_more: "Load more",
        thread_load_more: "View previous replies",
        delete_comment: "Deleted comment",
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [inputText, setInputText] = useState("");

    const MODAL_DATA = isMyPost
        ? [
              {
                  title: "Delete comment",
                  type: COMMENT_HANDLE_TYPE.DELETE_COMMENT,
              },
          ]
        : [
              {
                  title: "Edit comment",
                  type: COMMENT_HANDLE_TYPE.EDIT_COMMENT,
              },
              {
                  title: "Delete comment",
                  type: COMMENT_HANDLE_TYPE.DELETE_COMMENT,
              },
          ];

    const [valid, setValid] = useState(false);
    const [commentY, setCommentY] = useState(0);
    const [isApplyModalVisible, setIsApplyModalVisible] = useState(false);

    const inputRef = useRef();

    useEffect(() => {
        setValid(!!inputText);
    }, [inputText]);

    const onPressHandler = () => {
        setIsModalVisible(false);
    };

    const onSelectHandler = (item) => {
        switch (item.type) {
            case COMMENT_HANDLE_TYPE.EDIT_COMMENT:
                eventHandler(
                    { comment: comment, y: commentY },
                    COMMENT_HANDLE_TYPE.EDIT_COMMENT
                );
                setInputText(comment.content);
                break;
            case COMMENT_HANDLE_TYPE.DELETE_COMMENT:
                eventHandler(
                    { comment: comment },
                    COMMENT_HANDLE_TYPE.DELETE_COMMENT
                );
                break;
        }
        setIsModalVisible(false);
    };

    const cancelEdit = () => {
        eventHandler({ comment: comment }, COMMENT_HANDLE_TYPE.CANCEL_EDIT);
    };

    const replyLoadMore = () => {
        eventHandler({ comment: comment }, COMMENT_HANDLE_TYPE.REPLY_LOAD_MORE);
    };

    const patchComment = () => {
        eventHandler(
            { comment: comment, newContent: inputText },
            COMMENT_HANDLE_TYPE.PATCH_COMMENT
        );
    };

    const goToCommentPage = (comment) => {
        props.navigation.navigate(Routes.ForumNav.name, {
            screen: Routes.ForumCommentReply.name,
            params: { comment: comment },
        });
    };

    const renderReply = (data) => {
        return data.map((reply, index) => {
            return (
                <Stack
                    key={index}
                    style={{
                        backgroundColor: "#f5f6ff",
                        borderTopColor: "#d5d8f5",
                        borderTopWidth: 0.5,
                        width: "100%",
                        padding: "1rem",
                    }}
                >
                    <Stack
                        spacing={1}
                        style={{
                            flexDirection: "column",
                        }}
                    >
                        <Stack
                            style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <Stack
                                direction={"row"}
                                alignItems="center"
                                spacing={1}
                            >
                                {reply.writer?.companyOrUniv ? (
                                    <Text style={{ color: "#7c6aed" }}>
                                        {reply.writer?.companyOrUniv} ·
                                    </Text>
                                ) : null}
                                {reply.writer?.displayName ? (
                                    <Text>{reply.writer?.displayName}</Text>
                                ) : null}
                                <GreyText>
                                    {timeFromNow(
                                        reply.updatedAt ?? reply.createdAt
                                    )}
                                </GreyText>
                            </Stack>
                        </Stack>
                        <Text
                            style={{
                                marginVertical: 8,
                            }}
                        >
                            {reply.content}
                        </Text>
                        <Stack
                            style={{
                                marginTop: 4,
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <UpvoteButton
                                item={reply}
                                type={UPVOTE_TYPE.REPLY}
                            />
                            {renderDirectMessage(
                                reply._id,
                                CREATE_CHATROOM_TYPE.Reply,
                                reply.isMine
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            );
        });
    };

    const renderLoadMore = () => {
        return (
            <Stack
                style={{
                    backgroundColor: "#f5f6ff",
                    borderTopColor: "#d5d8f5",
                    borderTopWidth: 0.5,
                    padding: 12,
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                onClick={replyLoadMore}
            >
                <AddIcon sx={{ color: "#646464", fontSize: 20 }} />
                <Text style={{ color: "#646464", fontSize: 14 }}>
                    {pageText.thread_load_more}
                </Text>
            </Stack>
        );
    };

    const renderInput = () => {
        return (
            <EditInputContainer>
                <InputContainer style={{ paddingBottom: 0 }}>
                    <InputArea>
                        <InputWrapper>
                            <Input
                                ref={inputRef}
                                value={inputText}
                                maxLength={8000}
                                multiline
                                placeholder={
                                    !isNotice
                                        ? pageText.placeholder
                                        : pageText.unable_comment_placeholder
                                }
                                placeholderTextColor={Colors.overline}
                                onChangeText={(text) => setInputText(text)}
                            />
                        </InputWrapper>
                    </InputArea>
                </InputContainer>
                <Stack
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <Stack
                        style={{
                            padding: 8,
                            marginRight: 8,
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                        onClick={cancelEdit}
                    >
                        <Text
                            style={{
                                color: "#646464",
                                fontSize: 14,
                            }}
                        >
                            CANCEL EDIT
                        </Text>
                    </Stack>
                    <Stack onClick={patchComment} disabled={!valid}>
                        <div
                            style={{
                                marginVertical: 12,
                                marginRight: 16,
                            }}
                        >
                            <Send
                                size={24}
                                color={"#7959f2"}
                                style={{ marginRight: 4 }}
                            />
                        </div>
                    </Stack>
                </Stack>
            </EditInputContainer>
        );
    };

    const renderContent = () => {
        return (
            <>
                {isMyPost ? (
                    <Text style={{ marginVertical: 8, color: "#646464" }}>
                        Commented on '{comment.title}'
                    </Text>
                ) : (
                    <Text style={comment.deleted ? { color: "#c4c4c4" } : null}>
                        {comment.deleted
                            ? pageText.delete_comment
                            : comment.content}
                    </Text>
                )}
            </>
        );
    };

    const renderDots = () => {
        return (
            <Stack
                style={{
                    position: "relative",
                    right: 0,
                    height: 20,
                    width: 20,
                    alignItems: "center",
                    justifyContent: "center",
                }}
                disabled={setDisableDots}
                onClick={() => {
                    if (!comment.isEditing) {
                        setIsModalVisible(true);
                    }
                }}
            >
                <MoreVertIcon sx={{ color: "#646464", fontSize: 16 }} />
            </Stack>
        );
    };

    const renderReplyCount = () => {
        return (
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                style={{
                    marginRight: 16,
                }}
                spacing={0.5}
                disabled={comment.isEditing || isNotice}
                onClick={() => goToCommentPage(comment)}
            >
                <div>
                    <img
                        src={commentIcon}
                        alt="commentIcon"
                        width={isMobile ? 16 : 24}
                        height={isMobile ? 16 : 24}
                    />
                </div>
                {/*<Icon*/}
                {/*    style={{ marginRight: 4 }}*/}
                {/*    source={commentIcon}*/}
                {/*    size={16}*/}
                {/*/>*/}
                <Text style={{ color: "#646464" }}>
                    {comment.replyData?.pagination.totalCount}
                </Text>
            </Stack>
        );
    };

    const goToPostPage = (comment) => {
        // switch (comment.belongTo.type) {
        //     case FORUM_OBJECT_TYPE.COMPENSATION:
        //         props.navigation.navigate(Routes.SalaryPageNav.name, {
        //             screen: Routes.SalaryDetail.name,
        //             params: {
        //                 salaryId: comment.belongTo.objectId,
        //             },
        //         });
        //         break;
        //     case FORUM_OBJECT_TYPE.DISCUSSION:
        //         props.navigation.navigate(Routes.ForumNav.name, {
        //             screen: Routes.ForumDiscussionsDetail.name,
        //             params: {
        //                 discussionId: comment.belongTo.objectId,
        //             },
        //         });
        //         break;
        //     case FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFR:
        //         props.navigation.navigate(Routes.ForumNav.name, {
        //             screen: Routes.ForumReferralLookingForReferralsDetail.name,
        //             params: {
        //                 postId: comment.belongTo.objectId,
        //             },
        //         });
        //         break;
        //     case FORUM_OBJECT_TYPE.FORUM_REFERRAL_LFC:
        //         props.navigation.navigate(Routes.ForumNav.name, {
        //             screen: Routes.ForumReferralLookingForCandidatesDetail.name,
        //             params: {
        //                 postId: comment.belongTo.objectId,
        //             },
        //         });
        //         break;
        // }
    };

    const connectDirectMessage = async (params) => {
        const { message, room } = await ChatRoom.createDirectMessage(params);
        if (message === DELETED_USER) {
            SimpleModal.show({
                text: "You can’t send a message as the account is deleted.",
                actions: [
                    {
                        title: "GOT IT",
                        onPress: (modal) => {
                            SimpleModal.hide(modal);
                        },
                        textStyle: { fontSize: 16 },
                    },
                ],
            });
            return;
        }
        const roomId = room._id;

        if (roomId) {
            props.fetchCurrentUser();
            props.navigation.navigate(Routes.ChatNav.name, {
                screen: Routes.ChatRoom.name,
                params: {
                    chatRoomId: roomId,
                },
            });
        }
        return;
    };

    const onDirectMessagePressed = (objectId, objectType) => {
        setIsApplyModalVisible(true);
        // const didSawDirectMessageIntro = Value.getBoolValue(
        //     Value.Keys.DID_SAW_DIRECT_MESSAGE_INTRO,
        //     false
        // );
        // const params = {
        //     objectId,
        //     objectType,
        // };
        // if (props.currentUser?.credit > 0 || requiredCredit === 0) {
        //     didSawDirectMessageIntro
        //         ? connectDirectMessage(params)
        //         : props.navigation.navigate(Routes.DirectMessageIntro.name, {
        //               ...params,
        //               requiredCredit,
        //           });
        //     return;
        // } else {
        //     SimpleModal.show({
        //         text: "Not enough credits to send DM",
        //         actions: [
        //             {
        //                 title: "GOT IT",
        //                 onPress: (modal) => {
        //                     SimpleModal.hide(modal);
        //                 },
        //                 textStyle: { fontSize: 16 },
        //             },
        //         ],
        //     });
        // }
    };

    const renderTitle = () => {
        return (
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack
                    direction="row"
                    alignItems="center"
                    style={{
                        flexWrap: "wrap",
                        flexGrow: 1,
                    }}
                >
                    {isMyPost ? (
                        <Text style={{ fontSize: 16 }}>{comment.content}</Text>
                    ) : (
                        <Stack
                            direction={"row"}
                            alignItems="center"
                            spacing={1}
                        >
                            {comment.writer?.companyOrUniv ? (
                                <Text style={{ color: "#7c6aed" }}>
                                    {comment.writer?.companyOrUniv} ·
                                </Text>
                            ) : null}
                            {comment.writer?.companyOrUniv ? (
                                <Text>{comment.writer?.displayName}</Text>
                            ) : null}
                            <GreyText>
                                {timeFromNow(
                                    comment.updatedAt ?? comment.createdAt
                                )}
                            </GreyText>
                        </Stack>
                    )}
                </Stack>
                {comment.isMine && !comment.isEditing ? renderDots() : null}
            </Stack>
        );
    };

    const renderCounts = () => {
        return (
            <Stack
                style={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                }}
            >
                {/*<UpvoteButton*/}
                {/*    item={comment}*/}
                {/*    setItem={updateCommentData}*/}
                {/*    type={*/}
                {/*        viewType === COMMENT_VIEW_TYPE.REPLY*/}
                {/*            ? UPVOTE_TYPE.REPLY*/}
                {/*            : UPVOTE_TYPE.COMMENT*/}
                {/*    }*/}
                {/*/>*/}
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={0.5}
                    style={{ marginRight: 16 }}
                >
                    <img
                        src={upvoteIcon}
                        alt="upvoteIcon"
                        width={isMobile ? 16 : 24}
                        height={isMobile ? 16 : 24}
                    />
                    <CountText>{comment.upvoteCount}</CountText>
                </Stack>
                {viewType === COMMENT_VIEW_TYPE.REPLY
                    ? null
                    : renderReplyCount()}
                {renderDirectMessage(
                    comment._id,
                    viewType === COMMENT_VIEW_TYPE.REPLY
                        ? CREATE_CHATROOM_TYPE.Reply
                        : CREATE_CHATROOM_TYPE.Comment,
                    comment.isMine
                )}
            </Stack>
        );
    };

    const renderDirectMessage = (objectId, objectType, isMine) => {
        return isMine ? null : (
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                spacing={0.5}
                onClick={() => {
                    onDirectMessagePressed(objectId, objectType);
                }}
            >
                <div>
                    <Send size={isMobile ? 14 : 18} color={"#646464"} />
                </div>
                <Text style={{ color: "#646464" }}>DM</Text>
            </Stack>
        );
    };

    const renderComment = () => {
        return (
            <Stack
                style={{
                    flexDirection: "column",
                    borderTopColor: "#d5d8f5",
                    borderTopWidth: isMyPost || isFirstComment ? 0 : 0.5,
                }}
                onClick={() => goToPostPage(comment)}
                disabled={!isMyPost}
                spacing={2}
            >
                {renderTitle()}
                {comment.isEditing ? renderInput() : renderContent()}
                {isMyPost ? (
                    <GreyText>
                        {timeFromNow(comment.updatedAt ?? comment.createdAt)}
                    </GreyText>
                ) : (
                    renderCounts()
                )}
            </Stack>
        );
    };

    return (
        <Stack style={{ backgroundColor: setBackgroundColor }}>
            {renderComment()}
            {/*todo implement reply*/}
            {/*{viewType === COMMENT_VIEW_TYPE.REPLY*/}
            {/*    ? null*/}
            {/*    : comment.replyData?.pagination.totalCount > 3 &&*/}
            {/*      comment.replyData?.pagination.hasNext*/}
            {/*    ? renderLoadMore()*/}
            {/*    : null}*/}
            {/*{viewType === COMMENT_VIEW_TYPE.REPLY*/}
            {/*    ? null*/}
            {/*    : comment.replyData?.replies.length > 0*/}
            {/*    ? renderReply(comment.replyData?.replies)*/}
            {/*    : null}*/}
            <DownloadAppModal
                open={isApplyModalVisible}
                close={() => setIsApplyModalVisible(false)}
            />
        </Stack>
    );
};
export default React.memo(Comment);
