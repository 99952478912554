import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import logoZigupColor from "assets/images/logo-zigup-color.png";
import logoZigupWhite from "assets/images/logo-zigup.png";
import Container from "@mui/material/Container";
import Colors from "themes/colors";
import Typo from "components/atoms/Typo";
import { Stack } from "@mui/material";
import { SIGNUP_STEP } from "../../constants/constants";
import SignInModal from "../pages/signIn/SignInModal";
import MemberMenu from "./MemberMenu";
import { AuthContext } from "../../context/AuthContext";
import AddIcon from "@mui/icons-material/Add";

import ReactGA from "react-ga4";

const HeaderContainer = styled.div`
    flex-direction: row;
    display: flex;
    box-sizing: border-box;
    height: 56px;
    align-items: center;
    justify-content: flex-start;
    padding: 0px;
`;

const HeaderRightContainer = styled(Stack)`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
`;

const GlobalHeader = ({ color }) => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    // const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    // const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [signUpModalState, setSignUpModalState] = useState({
        open: false,
        initialStep: SIGNUP_STEP.SIGN_IN,
        closeModal: () => undefined,
        requestPath: "/",
    });

    const { userState, login } = useContext(AuthContext);

    const pages = [
        {
            name: "Salaries",
            url: "/salary/list",
            key: "/salary",
        },
        { name: "Companies", url: "/company/list", key: "/company" },
        { name: "Jobs", url: "/jd/list", key: "/jd" },
        { name: "Forum", url: "/forum/list?type=all", key: "/forum" },
        { name: "About", url: "/intro", key: "/intro" },
    ];

    const defaultColor = Colors.default_color;

    const openSignUpModal = (params) => {
        setSignUpModalState(params);
    };

    const closeSignUpModal = () => {
        setSignUpModalState({
            ...signUpModalState,
            open: false,
        });
    };

    const onSignInPressed = (requestPath) => {
        openSignUpModal({
            open: true,
            closeModal: closeSignUpModal,
            requestPath: requestPath ? requestPath : location.pathname,
        });
    };

    GlobalHeader.showSignUp = (requestPath) => {
        onSignInPressed(requestPath);
    };

    const handleAddSalary = () => {
        navigate("/salary/add");
    };

    // const handleAccessToken = () => {
    //     Logger.log("token", getAccessToken());
    // };

    const landingPageAfterCloseSignUpModal = (_requestPath) => {
        switch (_requestPath) {
            case "/":
                navigate("/welcome", { replace: true });
                break;
            default:
                navigate(_requestPath, { replace: true });
                break;
        }
    };

    const handleSignUpModalClose = async (result) => {
        switch (result.status) {
            case SIGNUP_STEP.SIGN_UP: {
                // navigate("/signUp/" + result.params?.mobileNumber);
                navigate("/sign-up", {
                    state: {
                        mobileNumber: result.params?.mobileNumber,
                        requestPath: result.requestPath,
                    },
                });
                break;
            }
            case SIGNUP_STEP.SET_PASSWORD_AFTER_VERIFY: {
                login(result.params).then((isLogin) => {
                    if (isLogin) {
                        ReactGA.event({
                            category: "Button",
                            action: "sign_in_completed",
                            label: "sign_in",
                        });
                        if (
                            result.params?.previousStep !==
                            SIGNUP_STEP.FORGOT_PASSWORD
                        ) {
                            landingPageAfterCloseSignUpModal(
                                result.requestPath
                            );
                        }
                    }
                });

                break;
            }
            case SIGNUP_STEP.SIGN_IN: {
                navigate(result.requestPath, { replace: true });
                break;
            }
            default:
                navigate("/");
                break;
        }
    };

    const renderDrawer = () => {
        // return (
        //     <Drawer
        //         anchor="left"
        //         open={isOpenDrawer}
        //         onClose={() => {
        //             setIsOpenDrawer(false);
        //         }}
        //     >
        //         <Box
        //             role="presentation"
        //             onClick={() => {
        //                 setIsOpenDrawer(false);
        //             }}
        //             onKeyDown={() => {
        //                 setIsOpenDrawer(false);
        //             }}
        //         >
        //             <List>
        //                 <ListItem disablePadding>
        //                     <ListItemButton
        //                         onClick={() => {
        //                             setIsOpenDrawer(false);
        //                         }}
        //                     >
        //                         <ListItemIcon>
        //                             <CloseIcon
        //                                 style={{
        //                                     color: "black",
        //                                 }}
        //                             />
        //                         </ListItemIcon>
        //                     </ListItemButton>
        //                 </ListItem>
        //                 <ListItem>
        //                     <ListItemText primary={"hello"} />
        //                 </ListItem>
        //             </List>
        //         </Box>
        //     </Drawer>
        // );
        return null;
    };

    const renderOnMobile = () => {
        return (
            <Stack direction="column" sx={{ paddingBottom: "0.5rem" }}>
                <HeaderContainer>
                    {/* <div
            style={{
                cursor: "pointer",
                marginRight: "1rem",
            }}
            onClick={() => {
                setIsOpenDrawer(true);
            }}
        >
            <MenuIcon
                sx={{
                    color: color ? defaultColor : "black",
                }}
            />
        </div> */}
                    <div>
                        <a href="/" style={{ cursor: "pointer" }}>
                            <img
                                src={color ? logoZigupWhite : logoZigupColor}
                                alt="ZigupMainLogo"
                                height={30}
                                width={(616 * 30) / 198}
                            />
                        </a>
                    </div>
                    <HeaderRightContainer direction="row" spacing={1}>
                        {userState.auth ? (
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                onClick={handleAddSalary}
                                style={{
                                    cursor: "pointer",
                                    border: `1px ${
                                        color ? defaultColor : "black"
                                    } solid`,
                                    borderRadius: 4,
                                    paddingTop: 4,
                                    paddingBottom: 4,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                }}
                            >
                                <AddIcon
                                    sx={{
                                        color: color ? defaultColor : "black",
                                        fontSize: 16,
                                    }}
                                />
                                <Typo.Body2
                                    style={{
                                        fontSize: 12,
                                        color: color ? defaultColor : "black",
                                    }}
                                >
                                    Add Salary
                                </Typo.Body2>
                            </Stack>
                        ) : null}
                        {userState.auth ? (
                            <MemberMenu
                                color={color ? defaultColor : "black"}
                                requestPath={location.pathname}
                            />
                        ) : (
                            <Stack
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    minWidth: "3rem",
                                    marginLeft: 1,
                                    cursor: "pointer",
                                    height: "max-content",
                                    width: "max-content",
                                }}
                                onClick={() => onSignInPressed()}
                            >
                                <Typo.Button2
                                    style={{
                                        color: color ? defaultColor : "black",
                                        border: `1px ${
                                            color ? defaultColor : "black"
                                        } solid`,
                                        borderRadius: 4,
                                        padding: 6,
                                        fontSize: isMobile ? null : "0.8rem",
                                        cursor: "pointer",
                                    }}
                                >
                                    Login
                                </Typo.Button2>
                            </Stack>
                        )}
                    </HeaderRightContainer>
                </HeaderContainer>
                <Stack direction="row">
                    {pages.map((page) => {
                        const isCurrent = location.pathname.includes(page.key);
                        const TextComponent = isCurrent
                            ? Typo.Body2Bold
                            : Typo.Body2;
                        return (
                            <a
                                key={page.name}
                                href={page.url}
                                style={{
                                    textDecoration: "none",
                                    marginRight: 12,
                                }}
                            >
                                <TextComponent
                                    style={{
                                        fontSize: 14,
                                        color: isCurrent
                                            ? color
                                                ? defaultColor
                                                : Colors.header_black
                                            : color
                                            ? "#dbd3fd"
                                            : "#aaaaaa",
                                    }}
                                >
                                    {page.name}
                                </TextComponent>
                            </a>
                        );
                    })}
                </Stack>
            </Stack>
        );
    };

    const renderOnDesktop = () => {
        return (
            <HeaderContainer
                style={{
                    justifyContent: "space-between",
                }}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                >
                    <a href="/" style={{ cursor: "pointer" }}>
                        <img
                            src={color ? logoZigupWhite : logoZigupColor}
                            alt="ZigupMainLogo"
                            height={30}
                            width={(616 * 30) / 198}
                        />
                    </a>
                    <div style={{ width: 4 }} />
                    {pages.map((page) => {
                        const isCurrent = location.pathname.includes(page.key);
                        const TextComponent = isCurrent
                            ? Typo.Body2Bold
                            : Typo.Body2;
                        return (
                            <a
                                key={page.name}
                                href={page.url}
                                style={{
                                    textDecoration: "none",
                                    marginLeft: 12,
                                }}
                            >
                                <TextComponent
                                    style={{
                                        fontSize: 14,
                                        color: isCurrent
                                            ? color
                                                ? defaultColor
                                                : Colors.header_black
                                            : color
                                            ? "#dbd3fd"
                                            : "#aaaaaa",
                                    }}
                                >
                                    {page.name}
                                </TextComponent>
                            </a>
                        );
                    })}
                </Stack>
                <HeaderRightContainer direction="row" spacing={1}>
                    {userState.auth ? (
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            onClick={handleAddSalary}
                            style={{
                                cursor: "pointer",
                                border: `1px ${
                                    color ? defaultColor : "black"
                                } solid`,
                                borderRadius: 4,
                                paddingTop: 4,
                                paddingBottom: 4,
                                paddingLeft: 8,
                                paddingRight: 8,
                            }}
                        >
                            <AddIcon
                                sx={{
                                    color: color ? defaultColor : "black",
                                    fontSize: 16,
                                }}
                            />
                            <Typo.Body2
                                style={{
                                    fontSize: 12,
                                    color: color ? defaultColor : "black",
                                }}
                            >
                                Add Salary
                            </Typo.Body2>
                        </Stack>
                    ) : null}
                    {userState.auth ? (
                        <MemberMenu
                            color={color ? defaultColor : "black"}
                            requestPath={location.pathname}
                        />
                    ) : (
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                minWidth: "3rem",
                                marginLeft: 1,
                                cursor: "pointer",
                                height: "max-content",
                                width: "max-content",
                            }}
                            onClick={() => onSignInPressed()}
                        >
                            <Typo.Button2
                                style={{
                                    color: color ? defaultColor : "black",
                                    border: `1px ${
                                        color ? defaultColor : "black"
                                    } solid`,
                                    borderRadius: 4,
                                    padding: 6,
                                    fontSize: isMobile ? null : "0.8rem",
                                    cursor: "pointer",
                                }}
                            >
                                Login / Signup
                            </Typo.Button2>
                        </Stack>
                    )}
                </HeaderRightContainer>
            </HeaderContainer>
        );
    };

    return (
        <div style={{ backgroundColor: color || defaultColor }}>
            <Container maxWidth="lg">
                {isMobile ? renderOnMobile() : renderOnDesktop()}
            </Container>
            {renderDrawer()}
            <SignInModal
                open={signUpModalState.open}
                closeModal={signUpModalState.closeModal}
                initialStep={signUpModalState.initialStep}
                handleClose={handleSignUpModalClose}
                requestPath={signUpModalState.requestPath}
            />
        </div>
    );
};

export default GlobalHeader;
export const showSignUpModal = (requestPath) => {
    GlobalHeader.showSignUp(requestPath);
};
