import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import Text from "../../atoms/Text";
import NormalTextField from "../../atoms/NormalTextField";
import { isPhoneNumber } from "utils/validator";
import Colors from "themes/colors";
import { Alert, AlertTitle, Collapse, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InputMask from "react-input-mask";
import styled from "styled-components";
import InputAdornment from "@mui/material/InputAdornment";
import { SIGNUP_STEP } from "../../../constants/constants";
import { checkMobile } from "../../../communication/membership";
import { Logger } from "utils";
import { AuthContext } from "../../../context/AuthContext";
import ZigupSpinner from "../../organisms/ZigupSpinner";
import ReactGA from "react-ga4";

const LoginButton = styled.div`
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
    background-color: ${({ disabled }) =>
        disabled ? `${Colors.grey}` : `${Colors.purple_blue}`};
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

const SignIn = (props) => {
    const { onHandleStep, onHandleClose, style } = props;
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    // const userDispatch = useUserDispatch();
    // const setUser = (user) => userDispatch({ type: "SET_USER", user });
    // const cookies = new Cookies();
    const [mobile, setMobile] = useState("");
    const [password, setPassword] = useState("");
    // const [isValidPassword, setIsValidPassword] = useStateCallback(false);
    const [isValidMobile, setIsValidMobile] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [passwordCollapseAlertState, setPasswordCollapseAlertState] =
        useState({
            status: -1,
            title: "title",
            message: "message",
        });
    const [existMobile, setExistMobile] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { login } = useContext(AuthContext);

    useEffect(() => {
        const _isMobile = isPhoneNumber(mobile.replace(/(-)/g, ""));
        setIsValidMobile(_isMobile.valid);
        return () => setIsValidMobile(false);
    }, [mobile]);

    useEffect(() => {
        const passwordRule =
            /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
        if (password.length > 0) {
            const _validPassword = passwordRule.test(password);
            setIsValidPassword(_validPassword);
        }
        return () => setIsValidPassword(false);
    }, [password]);

    useEffect(() => {
        setIsValid(isValidMobile && isValidPassword);
        return () => setIsValid(false);
    }, [isValidMobile, isValidPassword]);

    useEffect(() => {
        if (existMobile) {
            if (props.title) {
                props.title("Sign in");
            }
        } else {
            if (props.title) {
                props.title("Sign in / Sign up");
            }
        }
        return () => {
            if (props.title) {
                props.title("");
            }
        };
    }, [existMobile]);

    const handleGoogleToken = (data) => {
        // googleLogin({ googleToken: data })
        //     .then((result) => {
        //         Logger.log("handleGoogleToken", result);
        //     })
        //     .catch((e) => {
        //         Logger.log("handleGoogleToken error", e.message);
        //         openConfirmModal({
        //             open: true,
        //             title: e.response?.data ? e.response?.data : e.message,
        //             button: {
        //                 confirm: { text: "OK", handle: closeConfirmModal },
        //             },
        //             closeModal: closeConfirmModal,
        //         });
        //     });
    };

    const renderGoogleLoginArea = () => {
        return (
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: "2rem" }}
            >
                {/*<NewGoogleLogin onHandleGoogleLogin={handleGoogleToken} />*/}
            </Stack>
        );
    };

    const renderSeparator = () => {
        return (
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                    width: "70%",
                    height: "max-content",
                    marginTop: "2rem",
                }}
            >
                <div
                    style={{
                        backgroundColor: "#b9b9b9",
                        height: 1,
                        width: "50%",
                    }}
                />
                <Text style={{ margin: "0.5rem" }}>or</Text>
                <div
                    style={{
                        backgroundColor: "#b9b9b9",
                        height: 1,
                        width: "50%",
                    }}
                />
            </Stack>
        );
    };

    const goToMain = () => {
        // Logger.log("cookies", cookies);
        // if (cookies.get("isShowWelcome")) {
        //     navigate("/welcome");
        // } else {
        //     navigate("/");
        // }
        navigate("/");
    };

    const doLogin = async (e) => {
        if (existMobile && isValid) {
            e.preventDefault();
            ReactGA.event({
                category: "Button",
                action: "sign_in_button_pressed_with_pw",
                label: "sign_in",
            });
            setPassword("");
            setIsLoading(true);
            login({ phoneNumber: mobile.replace(/(-)/g, ""), password })
                .then((isLogin) => {
                    if (isLogin) {
                        ReactGA.event({
                            category: "Button",
                            action: "sign_in_completed",
                            label: "sign_in",
                        });
                        onHandleClose({
                            status: SIGNUP_STEP.SIGN_IN,
                        });
                    } else {
                        setPasswordCollapseAlertState({
                            status: false,
                            title: "Sign in failed",
                            message: e.response?.status ?? "",
                        });
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            try {
                setIsLoading(true);
                ReactGA.event({
                    category: "Button",
                    action: "sign_in_button_pressed_mobile_input",
                    label: "sign_in",
                });
                const res = await checkMobile(mobile.replace(/(-)/g, ""));
                switch (Number(res.status)) {
                    case SIGNUP_STEP.SIGN_IN: {
                        setExistMobile(true);
                        break;
                    }
                    case SIGNUP_STEP.SET_PASSWORD: {
                        onHandleStep({
                            step: SIGNUP_STEP.SET_PASSWORD,
                            params: { mobileNumber: mobile },
                        });
                        break;
                    }
                    case SIGNUP_STEP.SIGN_UP: {
                        onHandleStep({
                            step: SIGNUP_STEP.SIGN_UP,
                            params: { mobileNumber: mobile },
                        });
                        break;
                    }
                }
            } catch (e) {
                Logger.log("e", e);
            } finally {
                setIsLoading(false);
            }
        }

        // login({ email, password })
        //     .then((result) => {
        //         setToken(result.tokens.accessToken, result.tokens.refreshToken);
        //         setUser(result.business);
        //         goToMain();
        //     })
        //     .catch((err) => {
        //         onHandleClose();
        //         openConfirmModal({
        //             open: true,
        //             title: "Login failed",
        //             content: err.response?.data?.message
        //                 ? err.response?.data?.message
        //                 : "",
        //             button: {
        //                 confirm: {
        //                     text: "OK",
        //                     handle: closeConfirmModal,
        //                 },
        //             },
        //             closeModal: closeConfirmModal,
        //         });
        //     });
    };

    const getAlertMessage = () => {
        if (password.length > 0 && !isValidPassword) {
            return "Type 8 or more letter, number, and special character.";
        }
        return "";
    };

    const resetVerify = () => {
        setMobile("");
        setExistMobile(false);
    };

    const renderInputArea = () => {
        return (
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                style={{ width: "80%" }}
            >
                <InputMask
                    mask={
                        mobile.replace(/(-)/g, "").startsWith("010") ||
                        mobile.replace(/(-)/g, "").startsWith("55555")
                            ? "999-9999-9999"
                            : "99999-99999"
                    }
                    maskChar=""
                    name="mobile"
                    value={mobile}
                    disabled={existMobile}
                    onChange={(event) => setMobile(event.target.value)}
                >
                    {(inputProps) => (
                        <NormalTextField
                            {...inputProps}
                            fullWidth
                            placeholder="Mobile"
                            disabled={existMobile}
                            type="tel"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    doLogin(e);
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Stack
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Text
                                                style={{
                                                    color: "gray",
                                                    fontSize: 14,
                                                }}
                                            >
                                                +91
                                            </Text>
                                        </Stack>
                                    </InputAdornment>
                                ),
                                endAdornment: existMobile ? (
                                    <InputAdornment position="start">
                                        <Stack
                                            onClick={resetVerify}
                                            style={{
                                                cursor: "pointer",
                                            }}
                                        >
                                            <CloseIcon
                                                style={{
                                                    color: "black",
                                                }}
                                            />
                                        </Stack>
                                    </InputAdornment>
                                ) : null,
                            }}
                        />
                    )}
                </InputMask>
                <Collapse
                    in={existMobile}
                    style={{
                        width: "100%",
                    }}
                >
                    <NormalTextField
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        fullWidth
                        type="password"
                        alertMessage={getAlertMessage()}
                        alertMessageColor="red"
                        placeholder="Password"
                        disabled={isLoading}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                doLogin(e);
                            }
                        }}
                    />
                    {passwordCollapseAlertState.status !== -1 ? (
                        <Alert
                            severity={
                                passwordCollapseAlertState.status
                                    ? "success"
                                    : "error"
                            }
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setPasswordCollapseAlertState({
                                            status: -1,
                                            title: "",
                                            message: "",
                                        });
                                        setPassword("");
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            <AlertTitle>
                                {passwordCollapseAlertState.title}
                            </AlertTitle>
                            {passwordCollapseAlertState.message}
                        </Alert>
                    ) : null}
                </Collapse>
                <LoginButton
                    justifyContent="center"
                    alignItems="center"
                    isMobile={isMobile}
                    onClick={doLogin}
                    disabled={
                        !(existMobile ? isValid : isValidMobile) || isLoading
                    }
                    style={{
                        width: "100%",
                        height: "3rem",
                        backgroundColor:
                            !(existMobile ? isValid : isValidMobile) ||
                            isLoading
                                ? "gray"
                                : "#6837f2",
                        cursor: "pointer",
                    }}
                >
                    {isLoading ? (
                        <ZigupSpinner />
                    ) : (
                        <Text style={{ color: Colors.white }}>
                            {isMobile
                                ? "Sign in / Sign up"
                                : "Sign in / Sign up with this number"}
                        </Text>
                    )}
                </LoginButton>
                <Text
                    style={{
                        textDecoration: "underline",
                        fontSize: "0.875rem",
                        fontFamily: "Roboto",
                        fontWeight: "normal",
                        color: Colors.search_tag_background,
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        onHandleStep({
                            step: SIGNUP_STEP.FORGOT_PASSWORD,
                            params: {},
                        });
                    }}
                >
                    Forgot password?
                </Text>
            </Stack>
        );
    };

    const renderTitle = () => {
        return (
            <Stack
                justifyContent="flex-start"
                alignItems="center"
                style={{
                    width: "100%",
                    height: "100%",
                    whiteSpace: "pre",
                    marginTop: "2rem",
                }}
            >
                <Text
                    style={{
                        fontSize: 24,
                        whiteSpace: "pre-line",
                    }}
                >
                    Sign in
                </Text>
            </Stack>
        );
    };

    return (
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            style={{ width: "100%", ...style }}
        >
            {renderInputArea()}
        </Stack>
    );
};

export default SignIn;
