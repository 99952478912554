import styled, { keyframes } from "styled-components";
import Stack from "@mui/material/Stack";
import Text from "../atoms/Text";
import logo from "assets/images/logo-graphic.png";
import React from "react";
const spinner = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }`;

const SpinnerContainer = styled.div`
    align-items: center;
    justify-content: center;
    animation: ${spinner} 2s infinite linear;
`;

const ZigupSpinner = ({ size = "1rem", value }) => {
    return (
        <Stack
            justifyContent={"center"}
            alignItems={"center"}
            style={{
                margin: "0.2rem",
                width: size,
                height: "100%",
            }}
        >
            <SpinnerContainer>
                <img src={logo} alt="logo" width={"100%"} height={"auto"} />
            </SpinnerContainer>
            {value ? <Text>{value}%</Text> : null}
        </Stack>
    );
};

export default ZigupSpinner;
