import * as Api from "./api";
import { post, get, patch, deleteProc } from "./process";

export async function checkMobile(mobileNumber) {
    return get(
        Api.Urls.membership.checkMobile(mobileNumber),
        {},
        Api.Types.Simple
    );
}

export async function requestOTP(params) {
    return post(Api.Urls.membership.requestOTP, params, Api.Types.Simple);
}

export async function verifyOTP(params) {
    return post(Api.Urls.membership.verifyOTP, params, Api.Types.Simple);
}

export async function savePassword(params) {
    return patch(Api.Urls.membership.setPassword, params, Api.Types.Simple);
}

export async function signUp(params) {
    return post(Api.Urls.membership.signUp, params, Api.Types.Simple);
}

export async function signIn(params) {
    return post(Api.Urls.membership.signIn, params, Api.Types.Simple);
}

export async function education(params) {
    return get(Api.Urls.membership.education, params, Api.Types.Simple);
}

export async function refreshToken() {
    return get(
        Api.Urls.membership.reissueToken,
        {},
        Api.Types.WithRefreshToken
    );
}

export async function myInformation() {
    return get(
        Api.Urls.membership.myInformation,
        {},
        Api.Types.WithAccessToken
    );
}

export async function myInformationByClientData(params) {
    const screen = {
        availWidth: window.screen.availWidth,
        availHeight: window.screen.availHeight,
        width: window.screen.width,
        height: window.screen.height,
        colorDepth: window.screen.colorDepth,
    };
    return post(
        Api.Urls.membership.myInformationByClientData,
        {
            ...params,
            os: window.navigator.userAgent,
            screen: JSON.stringify(screen),
        },
        Api.Types.Simple
    );
}

export async function cvAttachment(obj, uploadProgressCallback) {
    const formData = new FormData();
    formData.append("file", obj);

    if (uploadProgressCallback !== undefined) {
        return await post(
            Api.Urls.membership.cvAttachment,
            formData,
            Api.Types.WithAccessToken,
            uploadProgressCallback
        );
    } else {
        return await post(
            Api.Urls.membership.cvAttachment,
            formData,
            Api.Types.WithAccessToken
        );
    }
}

export async function cvDelete(id) {
    return await deleteProc(
        Api.Urls.membership.cvDelete(id),
        {},
        Api.Types.WithAccessToken
    );
}

export async function setMyProfileImage(params) {
    return patch(
        Api.Urls.membership.setMyProfileImage,
        params,
        Api.Types.WithAccessToken
    );
}

export async function setMyInformation(params) {
    return patch(
        Api.Urls.membership.setMyInformation,
        params,
        Api.Types.WithAccessToken
    );
}
