import React from "react";
import noLogo from "assets/icons/no-logo.png";

const CompanyLogo = (props) => {
    const { source, style, height, width } = props;
    return (
        <div
            style={{
                boxSizing: "border-box",
                padding: 0,
                ...style,
            }}
        >
            <img
                src={source ?? noLogo}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = noLogo;
                }}
                alt="CompanyLogo"
                width={width || style.width || 80}
                height={height || style.height || 80}
                style={{
                    boxSizing: "border-box",
                    objectFit: "cover",
                    borderRadius: 10,
                }}
            />
        </div>
    );
};

export default CompanyLogo;
