import Stack from "@mui/material/Stack";
import CvItem from "../../atoms/CvItem";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import Colors from "themes/colors";
import AddIcon from "@mui/icons-material/Add";
import FileUploadButton from "../FileUploadButton";

import { cvAttachment, cvDelete } from "../../../communication/membership";
import { Logger } from "utils";

const CVList = ({ type, handleSelect }) => {
    const { userState, refreshUserInfo } = useContext(AuthContext);
    const [file, setFile] = useState(undefined);

    useEffect(async () => {
        if (file) {
            try {
                const callback = {
                    onUploadProgress: (progressEvent) => {
                        // setUploadProgress(
                        //     Math.round(
                        //         (progressEvent.loaded * 100) / progressEvent.total
                        //     )
                        // );
                        Logger.log("progressEvent", progressEvent);
                    },
                };
                const res = await cvAttachment(file, callback);
            } catch (e) {
                Logger.log("failed attach cv");
            } finally {
                Logger.log("finish attach cv");
                refreshUserInfo();
            }
        }
    }, [file]);

    const onHandleDelete = async (id) => {
        Logger.log("onHandleDelete");
        try {
            const res = await cvDelete(id);
        } catch (e) {
            Logger.log("failed delete cv");
        } finally {
            Logger.log("finish delete cv");
            refreshUserInfo();
        }
    };

    const onHandleSelected = (file) => {
        handleSelect(file);
    };
    return (
        <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
            style={{ width: "100%" }}
        >
            {userState.info.cvs.map((cv, index) => {
                return (
                    <CvItem
                        key={index + cv._id}
                        file={cv}
                        type={type}
                        handleSelected={onHandleSelected}
                        handleDelete={onHandleDelete}
                    />
                );
            })}
            {userState.info?.cvs?.length < 5 ? (
                <FileUploadButton
                    value={file}
                    setValue={setFile}
                    buttonStyle={{
                        width: "100%",
                        borderRadius: 8,
                        borderWidth: 1,
                        borderColor: Colors.light_grey_3,
                        borderStyle: "dashed",
                        cursor: "pointer",
                        minHeight: "max-content",
                        maxHeight: "max-content",
                        padding: "1rem",
                        boxSizing: "border-box",
                    }}
                    hiddenFileName={true}
                    buttonIcon={
                        <AddIcon
                            sx={{
                                color: Colors.light_grey_3,
                                fontSize: "1.2rem",
                            }}
                        />
                    }
                />
            ) : null}
        </Stack>
    );
};
export default CVList;
