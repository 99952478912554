import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { useLocation, useNavigate } from "react-router";
import Text from "components/atoms/Text";
import { useMediaQuery } from "react-responsive";
import Stack from "@mui/material/Stack";
import Colors from "themes/colors";
import { Container } from "@mui/material";

import ZigupButton from "../../atoms/ZigupButton";
import Typo from "../../atoms/Typo";
import NoticeUpdateInformation from "../../atoms/NoticeUpdateInformation";
import NavigationGuard from "../../organisms/NavigationGuard";
import { useStateCallback } from "../../atoms/useStateCallback";
import PreferenceLocationAutoComplete from "../../organisms/PreferenceLocationAutoComplete";
import { setMyInformation } from "../../../communication/membership";
import ZigupSpinner from "components/organisms/ZigupSpinner";
import { AlertContext } from "context/AlertContext";
import { ALERT_TYPE } from "constants/constants";
import { Logger } from "utils";

const EditPreferencePage = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const navigate = useNavigate();
    const { state } = useLocation();
    const { openAlert } = useContext(AlertContext);

    const [activeGuard, setActiveGuard] = useStateCallback(true);
    const [isValid, setIsValid] = useState(true);

    const [preferredLocations, setPreferredLocations] = useState([
        ...state.userInfo.preferred.locations,
    ]);
    const [isLoading, setIsLoading] = useState(false);

    const [pageText, setPageText] = useState({
        title: "Preference",
    });
    useEffect(() => {
        // ReactGA.event({
        //     category: "SignUp",
        //     action: "sign_up_first_page_start",
        //     label: "sign_up",
        // });
    }, [state.userInfo]);

    useEffect(() => {
        const check = preferredLocations.length > 0;
        setIsValid(check);
        return () => {
            setIsValid(false);
        };
    }, [preferredLocations]);

    const save = async () => {
        if (isValid) {
            setActiveGuard(false, async () => {
                const userInfo = {
                    preferred: {
                        locations: preferredLocations,
                    },
                };
                try {
                    setIsLoading(true);
                    const res = await setMyInformation(userInfo);
                    if (res) {
                        window.location.href = "/my/profile";
                    }
                } catch (e) {
                    openAlert({
                        showAlert: true,
                        text: "Failed to change data",
                        alertType: ALERT_TYPE.ERROR,
                    });
                    Logger.error(e);
                } finally {
                    setIsLoading(false);
                }
            });
        }
    };

    return (
        <Container maxWidth="md" sx={{ minHeight: "60vh" }}>
            <NavigationGuard when={activeGuard} />
            {isLoading ? (
                <div
                    style={{
                        position: "fixed",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "rgba(0, 0, 0, 0.2)",
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 20,
                    }}
                >
                    <ZigupSpinner size="2rem" />
                </div>
            ) : null}
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                    marginTop: "2rem",
                    marginBottom: "2rem",
                    width: "100%",
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ width: "100%" }}
                    spacing={1}
                >
                    <Typo.Button1
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/my/profile")}
                    >
                        My profile
                    </Typo.Button1>
                    <Typo.Button1>/</Typo.Button1>
                    <Typo.Button1>{pageText.title}</Typo.Button1>
                </Stack>
                <Stack
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{ width: "100%" }}
                >
                    <Text
                        style={{
                            fontSize: "2rem",
                            lineHeight: "3rem",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                        }}
                    >
                        {pageText.title}
                    </Text>
                </Stack>
                <Stack style={{ width: "100%", marginBottom: "1rem" }}>
                    <NoticeUpdateInformation />
                </Stack>
                <PreferenceLocationAutoComplete
                    value={preferredLocations}
                    setValue={setPreferredLocations}
                    isMobile={isMobile}
                />
                <ZigupButton
                    buttonText={"SAVE CHANGES"}
                    id={"my-profile-edit-preference-save-change-btn"}
                    disabled={!isValid || isLoading}
                    onClickHandler={save}
                />
            </Stack>
        </Container>
    );
};
export default EditPreferencePage;
