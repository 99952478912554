import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Colors from "themes/colors";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Text from "../atoms/Text";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router";
import { useContext, useState } from "react";
import ConfirmModal from "./ConfirmModal";
import { AlertContext } from "../../context/AlertContext";
import { ALERT_TYPE } from "../../constants/constants";
import ProfileImage from "assets/icons/profile/profile@3x.png";
import ProfileWhiteImage from "assets/icons/profile/profile_white@2x.png";
import { AuthContext } from "../../context/AuthContext";

const MemberMenu = (props) => {
    const { color, requestPath } = props;
    const navigate = useNavigate();
    const { openAlert } = useContext(AlertContext);
    const [hover, setHover] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { logout } = useContext(AuthContext);

    const [confirmModalState, setConfirmModalState] = useState({
        open: false,
        title: "Are you sure to sign out?",
        content: "",
        button: {
            confirm: {
                text: "",
                handle: () => handleClickYes,
            },
            reject: {
                text: "",
                handle: () => handleClickNo,
            },
        },
        closeModal: () => closeConfirmModal,
    });

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfilePressed = () => {
        handleClose();
        navigate("/my/profile", { replace: true });
    };

    const handleOffersPressed = () => {
        handleClose();
        // toast("Preparing Offers Page...");
        navigate("/offer/list", { replace: true });
    };

    const handleLogoutPressed = () => {
        handleClose();
        setConfirmModalState({
            ...confirmModalState,
            open: true,
        });
    };

    const handleClickNo = () => {
        closeConfirmModal();
    };

    const handleClickYes = async () => {
        handleClose();
        closeConfirmModal();
        await logout();
        navigate(requestPath, { replace: true });
        openAlert({
            showAlert: true,
            text: "Successfully logout",
            alertType: ALERT_TYPE.SUCCESS,
        });
    };

    const closeConfirmModal = () => {
        setConfirmModalState({
            ...confirmModalState,
            open: false,
        });
    };

    return (
        <div>
            <Button
                id="member-menu-button"
                aria-controls={open ? "share-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                onMouseEnter={() => {
                    setHover(true);
                }}
                onMouseLeave={() => {
                    setHover(false);
                }}
                style={{
                    backgroundColor: hover
                        ? "rgba(0, 0, 0, 0.1)"
                        : "transparent",
                }}
            >
                <img
                    src={
                        color === Colors.default_color
                            ? ProfileWhiteImage
                            : ProfileImage
                    }
                    alt="Profile"
                    height="24"
                    width="24"
                    style={{ color: "white" }}
                />
            </Button>
            <Menu
                id="member-menu"
                aria-labelledby="member-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem onClick={handleProfilePressed}>
                    <Text>PROFILE</Text>
                </MenuItem>
                <MenuItem onClick={handleOffersPressed}>
                    <Text>OFFERS</Text>
                </MenuItem>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        height: 1,
                        backgroundColor: Colors.footer,
                        margin: "0.5rem",
                    }}
                />
                <MenuItem onClick={handleLogoutPressed}>
                    <Text>LOGOUT</Text>
                </MenuItem>
            </Menu>
            <ToastContainer
                position="top-right"
                autoClose={500}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
            />
            <ConfirmModal
                open={confirmModalState.open}
                title={confirmModalState.title}
                button={{
                    confirm: {
                        handle: handleClickYes,
                    },
                    reject: {
                        handle: handleClickNo,
                    },
                }}
                closeModal={confirmModalState.closeModal}
            />
        </div>
    );
};
export default MemberMenu;
