import React from "react";
import { Stack } from "@mui/material";
import Typo from "components/atoms/Typo";
import Box from "@mui/material/Box";
import LinearProgressWithLabel from "../../atoms/LinearProgressWithLabel";
import Colors from "themes/colors";

const ProgressBar = ({ progress }) => {
    return (
        <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
            padding={2}
            sx={{ flex: 1, display: "flex" }}
        >
            <Stack>
                <Typo.Body1
                    style={{
                        fontFamily: "Lato",
                        fontWeight: 700,
                        fontSize: "1.25rem",
                        color: Colors.search_card_black,
                    }}
                >
                    My Profile
                </Typo.Body1>
            </Stack>
            <Box sx={{ flex: 1 }}>
                <LinearProgressWithLabel value={progress} />
            </Box>
        </Stack>
    );
};
export default ProgressBar;
