import React, { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

import MyOfferList from "../../organisms/MyOfferList";
import { Container } from "@mui/material";

const OfferListPage = () => {
    const { userState } = useContext(AuthContext);
    if (userState.auth) {
        return (
            <Container maxWidth="lg" sx={{ flex: 1 }}>
                <MyOfferList name={userState.info?.name} />
            </Container>
        );
    }
};

export default OfferListPage;
