import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import Typo from "components/atoms/Typo";
import ZigupButton from "../../atoms/ZigupButton";
import styled from "styled-components";
import Chip from "@mui/material/Chip";
import { ThemeProvider } from "@emotion/react";
import { lightPurpleWithBorder } from "themes/zigup";
import { useNavigate } from "react-router";
import Colors from "themes/colors";
import { fetchService } from "communication/jd";

const ItemContainer = styled(Stack)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const SkillContainer = styled(Stack)`
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
`;

const LabelContainer = styled(Stack)`
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;

const TechnicalArea = ({ userInfo }) => {
    const navigate = useNavigate();
    const [roles, setRoles] = useState(undefined);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await fetchService();
        setRoles(data.Roles);
    };

    const getArea = () => {
        return userInfo.customInfo.area.custom
            ? userInfo.customInfo.area.custom.key
            : userInfo.customInfo.area.value;
    };

    const getAreaText = (_area) => {
        if (roles && Object.keys(roles.KeyTextMap).includes(_area)) {
            return roles.KeyTextMap[_area];
        }
        return _area;
    };

    const getRoleText = (_role) => {
        if (roles) {
            const target = roles[getArea()][0].options.filter(
                (item) => item.key === _role
            );
            if (target.length) {
                return target[0].text;
            }
        }
        return _role;
    };

    return (
        <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
            padding={2}
            margin={1}
            style={{ backgroundColor: "white" }}
        >
            <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
                <ItemContainer>
                    <Stack>
                        <Typo.Body1
                            style={{
                                fontSize: "1rem",
                                color: Colors.light_grey_3,
                                fontFamily: "Roboto",
                                fontWeight: 400,
                            }}
                        >
                            My technical area
                        </Typo.Body1>
                    </Stack>
                    <Stack>
                        <ZigupButton
                            id={"my-technical-area-edit-btn"}
                            buttonText={"EDIT"}
                            containerStyle={{
                                width: "max-content",
                                height: "max-content",
                                backgroundColor: "transparent",
                            }}
                            buttonTextSize={"1rem"}
                            buttonTextStyle={{ color: "#646464" }}
                            onClickHandler={() =>
                                navigate("edit/tech", {
                                    state: { userInfo: userInfo },
                                })
                            }
                        />
                    </Stack>
                </ItemContainer>
                <SkillContainer>
                    <ThemeProvider theme={lightPurpleWithBorder}>
                        <Chip
                            label={getAreaText(getArea())}
                            disabled={false}
                            style={{
                                margin: "0.2rem",
                            }}
                            color={"primary"}
                        />
                    </ThemeProvider>
                    {userInfo.customInfo.area.custom ? (
                        <ThemeProvider theme={lightPurpleWithBorder}>
                            <Chip
                                label={getRoleText(
                                    userInfo.customInfo.area.custom.value
                                )}
                                disabled={false}
                                style={{
                                    margin: "0.2rem",
                                }}
                                color={"primary"}
                            />
                        </ThemeProvider>
                    ) : (
                        userInfo.customInfo.role.value.map((v, index) => {
                            return (
                                <ThemeProvider
                                    theme={lightPurpleWithBorder}
                                    key={index + v}
                                >
                                    <Chip
                                        label={getRoleText(v)}
                                        disabled={false}
                                        style={{
                                            margin: "0.2rem",
                                        }}
                                        color={"primary"}
                                    />
                                </ThemeProvider>
                            );
                        })
                    )}
                </SkillContainer>
            </Stack>
            <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
                <LabelContainer>
                    <Typo.Body1 style={{ fontSize: "0.8rem" }}>
                        Main skills
                    </Typo.Body1>
                </LabelContainer>
                <SkillContainer>
                    {userInfo.skillset.main.map((skill, index) => {
                        return (
                            <ThemeProvider
                                theme={lightPurpleWithBorder}
                                key={index + skill}
                            >
                                <Chip
                                    label={skill}
                                    disabled={false}
                                    style={{
                                        margin: "0.2rem",
                                    }}
                                    color={"primary"}
                                />
                            </ThemeProvider>
                        );
                    })}
                </SkillContainer>
            </Stack>
            <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
                <LabelContainer>
                    <Typo.Body1 style={{ fontSize: "0.8rem" }}>
                        Sub skills
                    </Typo.Body1>
                </LabelContainer>
                <SkillContainer>
                    {userInfo.skillset.sub.map((skill, index) => {
                        return (
                            <ThemeProvider
                                theme={lightPurpleWithBorder}
                                key={index + skill}
                            >
                                <Chip
                                    label={skill}
                                    disabled={false}
                                    style={{
                                        margin: "0.2rem",
                                    }}
                                    color={"primary"}
                                />
                            </ThemeProvider>
                        );
                    })}
                </SkillContainer>
            </Stack>
        </Stack>
    );
};
export default TechnicalArea;
